import { REQUEST_LOGIN
    , RECEIVE_LOGIN
    , RECEIVE_COMPANIES
    , FAILURE_COMPANIES
    , LOGIN_ERROR
    , SET_CURRENT_PAGE } from '../actionTypes'

const initialState = {
    loading: false,
    token: '',
    companies: [],
    currentPage: 1
}

export default (state=initialState, { type, payload }) => {
    switch (type) {
        case REQUEST_LOGIN:
            return Object.assign({}, state, { loading: true })
        case RECEIVE_LOGIN:
            return Object.assign({}, state, { token: payload })
        case RECEIVE_COMPANIES:
            return Object.assign({}, state, {
                loading: false,
                currentPage: 2,
                companies: payload,
            })
        case FAILURE_COMPANIES:
            return Object.assign({}, state, { loading: false })
        case LOGIN_ERROR:
            return Object.assign({}, state, { loading: false, error: true })
        case SET_CURRENT_PAGE:
            return Object.assign({}, state, { currentPage: payload })
        default:
            return state
    }
}