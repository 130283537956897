import Chat from 'twilio-chat';
import Service from './service';

class TwilioChat extends Service {
    constructor() {
        super();
        this._chat;
        this.$channel = new Map();
    }

    async setupChatClient() {
        const { token } = this.state;
        this._chat = await Chat.create(token ,{ logLevel: 'info' });
        await this._handleChatEvents();
        await this._reconnectChannels();
    }

    async _reconnectChannels() {
        let allChannelDescriptors = [];

        let channelDescriptors = await this._chat.getUserChannelDescriptors();
        allChannelDescriptors = allChannelDescriptors.concat(
            channelDescriptors.items
        );

        while (channelDescriptors.hasNextPage) {
            channelDescriptors = await channelDescriptors.nextPage();
            allChannelDescriptors = allChannelDescriptors.concat(
                channelDescriptors.items
            );
        }

        for (const channelDescriptor of allChannelDescriptors) {
            if (channelDescriptor.attributes.status === 'ACTIVE') {
                this._handleJoinChannel(
                    await channelDescriptor.getChannel()
                )
            } else {
                (await channelDescriptor.getChannel()).leave()
            }
        }
    }

    _handleChatEvents() {
        this._chat.on('connectionStateChanged', (state) => {
            switch (state) {
                case 'connected':
                    this.emit('chatConnectedState');
                    break;
                case 'disconnected':
                case 'denied':
                    this.emit('chatDisconnectedState');
                    break;
                default:
                    break;
            }
        });

        this._chat.on('connectionError', this.emit.bind(this, 'chatConnectionError'));
        this._chat.on('tokenAboutToExpire', this.updateToken);

        this._chat.on('channelJoined', async (channel) => {
            this._handleJoinChannel(channel);
        });
    }

    async _handleJoinChannel(channel) {
        if (this.$channel.has(channel.sid) || channel.status === 'ENDED') return;
        this.$channel.set(channel.sid, channel);
        const { pharmacistName } = this.state;
        await channel.updateAttributes({
            ...(await channel.getAttributes()),
            pharmacistName
        });
        await this._loadPreviousMessages(channel.sid);
        await this._handleChannelEvents(channel.sid);
    }

    async _loadPreviousMessages(channelSid) {
        const channel = this.$channel.get(channelSid);
        let paginator = await channel.getMessages();
        paginator.items.forEach(this.emit.bind(this, 'chatMessageAdded'));

        while (paginator.hasNextPage) {
            paginator = await paginator.nextPage();
            paginator.items.forEach(this.emit.bind(this, 'chatMessageAdded'));
        }

        channel.setAllMessagesConsumed()
    }
    
    async _handleChannelEvents(channelSid) {
        const channel = this.$channel.get(channelSid);
        channel.on('messageAdded', this.emit.bind(this, 'chatMessageAdded'));
        channel.on('messageAdded', (message) => {
            channel.updateLastConsumedMessageIndex(message.index)
        })
        channel.on('updated', (event) => {
            event.updateReasons.forEach(async (reason) => {
                if (reason === 'attributes') {
                    const attributes = await channel.getAttributes();
                    if (attributes.status === 'ENDED') {
                        this.emit(
                            'userLeaveChat',
                            (await channel.getAttributes()).patientId
                        )
                    }
                }
            });
        })

        const channelAttributes = await channel.getAttributes()

        const members = await channel.getMembers()
        for (const member of members) {
            if (parseInt(member.identity, 10) === channelAttributes.patientId) {
                const user = await member.getUser()
                user.on('updated', (event) => {
                    if (
                      event.updateReasons.includes('online') &&
                      !event.user.online
                    ) {
                        // TODO: Online status changes
                    }
                })
            }
        }
    }

    async typing(channelSid) {
        const channel = this.$channel.get(channelSid);
        if (channel) return channel.typing();
    }

    async sendMessage(data, channelSid) {
        const channel = this.$channel.get(channelSid);
        if (channel) return channel.sendMessage(data);
    }

    async updateToken() {
        const token = await this.getClientToken();
        this._chat.updateToken(token);
    }

    async leaveAndClearChannel(channelSid) {
        const channel = this.$channel.get(channelSid)
        if (channel) {
            await channel.updateAttributes({ status: 'ENDED' })
            await channel.leave();
            this.$channel.delete(channelSid)
        }
    }

    async leaveAndClearAllChannel() {
        for (const channel of this.$channel.values()) {
            await this.leaveAndClearChannel(channel.sid)
        }
    }
}

export default TwilioChat;
