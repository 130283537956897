export const OPEN_AWAITING_CART = 'OPEN_AWAITING_CART'
export const CLOSE_AWAITING_CART = 'CLOSE_AWAITING_CART'
export const LOAD_AWAITING_CART = 'LOAD_AWAITING_CART'
export const TOGGLE_AWAITING_CART = 'TOGGLE_AWAITING_CART'
export const DELETE_AWAITING_CART = 'DELETE_AWAITING_CART'
export const SAVE_CART = 'SAVE_CART'
export const CONTINUE_CART = 'CONTINUE_CART'
export const ADD_TO_AWAITING_CART = 'ADD_TO_AWAITING_CART'
export const LOAD_SYNC_ERROR = 'LOAD_SYNC_ERROR'
export const DELETE_SYNC_ERROR = 'DELETE_SYNC_ERROR'
export const ADD_TO_SYNC_ERROR = 'ADD_TO_SYNC_ERROR'
export const SHOW_NOTI_REMOVE_COUPON = 'SHOW_NOTI_REMOVE_COUPON'
export const CLOSE_NOTI_REMOVE_COUPON = 'CLOSE_NOTI_REMOVE_COUPON'