import React from 'react';
import {
    Button,
    Card,
    CardHeader,
    Col,
    Form,
    FormGroup,
    Input,
    Label,
    ListGroup,
    ListGroupItem,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Nav,
    NavItem,
    NavLink,
    Row,
    TabContent,
    Table,
    TabPane,
} from 'reactstrap';
import { connect } from 'react-redux';
import { closePickupPrescriptionModal, selectPrescriptionByCode, setBodc12Data } from 'Redux/actions';
import classnames from 'classnames';
import axios from 'axios';
import moment from 'moment';

const API3_URL = process.env.API3_URL;

class PrescriptionPickupModal extends React.Component {
    state = {
        loading: false,
        activeTab: '1',
        cartRef: null,
        hospitalModal: false,
        hospitalSearch: '',
        hospitals: [],
        formBodc12: {
            hospital_name: '',
            prescriber_name: '',
            patient_name: '',
            patient_age: '',
            patient_address: '',
            note: '',
            details: [],
        },
        formPickup: {
            code: '',
            phone_number: '',
            citizen_id: '',
        },
        formOnlineOrder: {
            reference_code: '',
            category: 'lm',
        }
    };

    toggle(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab,
            });
        }
    }

    onBodc12FromChange = (key) => (event) => {
        this.setState({
            formBodc12: {
                ...this.state.formBodc12,
                [key]: event.target.value,
            },
        });
    };

    toggleBodc12ProductList = () => {
        let { details } = this.state.formBodc12;
        const cartList = this.getCartList();

        if (details.length === cartList.length) {
            details = [];
        } else {
            details = cartList.slice();
        }

        this.setState({
            formBodc12: {
                ...this.state.formBodc12,
                details,
            },
        });
    };

    onBodc12ProductListChange = (item) => (event) => {
        const { details } = this.state.formBodc12;

        if (event.target.checked) {
            details.push(item);
        } else {
            const index = details.indexOf(item);
            details.splice(index, 1);
        }

        this.setState({
            formBodc12: {
                ...this.state.formBodc12,
                details,
            },
        });
    };

    onPickupFromChange = (key) => (event) => {
        this.setState({
            formPickup: {
                ...this.state.formPickup,
                [key]: event.target.value,
            },
        });
    };

    onOnlineOrderFormChange = (key) => (event) => {
        this.setState({
            formOnlineOrder: {
                ...this.state.formOnlineOrder,
                [key]: event.target.value,
            },
        });
    };

    isBodc12FormValid = () => {
        return this.state.formBodc12.hospital_name.length >= 1
            && this.state.formBodc12.prescriber_name.length >= 1
            && this.state.formBodc12.patient_name.length >= 1
            && isFinite(this.state.formBodc12.patient_age)
            && this.state.formBodc12.patient_address.length >= 1
            && this.state.formBodc12.details.length >= 1;
    };

    onBodc12FormSubmit = (e) => {
        if (e) {
            e.preventDefault();
        }

        this.props.onBodc12FormSubmit(this.state.formBodc12);
        this.props.onClose();
        this.resetBodc12Form();
    };

    resetBodc12Form() {
        this.setState({
            formBodc12: {
                hospital_name: '',
                prescriber_name: '',
                patient_name: '',
                patient_age: '',
                patient_address: '',
                note: '',
                details: [],
            },
        });
    }

    autoBodc12Form() {
        const { customer } = this.props;

        this.setState({
            formBodc12: {
                ...this.state.formBodc12,
                patient_name: customer.name && !this.state.formBodc12.patient_name ? customer.name : this.state.formBodc12.patient_name,
                patient_age: customer.birth_date && !this.state.formBodc12.patient_age ? moment().diff(moment(customer.birth_date), 'years') : this.state.formBodc12.patient_age,
            },
        });
    }

    isPickupFormValid = () => {
        if (this.state.activeTab === '2') {
            return this.state.formPickup.code.length >= 6
            && this.state.formPickup.phone_number.length >= 10
            && this.state.formPickup.citizen_id.length >= 1;
        }

        if (this.state.activeTab === '3') {
            return this.state.formOnlineOrder.reference_code.length >= 1;
        }
    };

    onPickupFormSubmit = (e) => {
        if (e) {
            e.preventDefault();
        }

        this.setState({ loading: true });
        const data = this.state.activeTab === '3' ? this.state.formOnlineOrder : this.state.formPickup;
        this.props.onPickupFormSubmit(data).then((found) => {
            if (found) {
                if (this.state.activeTab === '2') {
                    this.setState({
                        formPickup: {
                            code: '',
                            phone_number: '',
                            citizen_id: '',
                        },
                    });
                }

                if (this.state.activeTab === '3') {
                    this.setState({
                        formOnlineOrder: {
                            reference_code: '',
                            category: 'lm'
                        }
                    })
                }
            }
        }).finally(() => {
            this.setState({ loading: false });
        });
    };

    getHospitals() {
        axios({
            url: `${API3_URL}/hospitals`,
        }).then((response) => {
            this.setState({
                hospitals: response.data.data,
            });
        });
    }

    componentWillReceiveProps(nextProps) {
        const { isOpen, cart, qrCode } = nextProps;

        if (isOpen) {
            this.getHospitals();
            if (this.state.cartRef !== cart.reference_code) {
                if (this.state.cartRef) {
                    this.resetBodc12Form();
                }

                this.setState({
                    cartRef: cart.reference_code,
                });
            }

            if (cart.bodc12) {
                this.setState({
                    formBodc12: cart.bodc12,
                });
            } else {
                this.autoBodc12Form();
            }
        }

        if (qrCode) {
            this.setSate({
                formPickup: {
                    ...this.state.formPickup,
                    code: qrCode,
                },
            });
        }
    }

    getCartList() {
        const { cart } = this.props;

        let products = cart.details.filter(detail => !!detail.product.reference_code).map(detail => ({
            id: `${detail.product.id}${detail.unit.id}`,
            product_id: detail.product.id,
            product_reference_code: detail.product.reference_code,
            product_name: detail.product.name,
            quantity: detail.sales_quantity,
            unit_id: detail.unit.id,
            unit_name: detail.unit.name,
        }));

        cart.details.filter(detail => !detail.product.reference_code).forEach(productSet => {
            products = [
                ...products,
                ...productSet.products.map(product => ({
                    id: `${product.id}${product.unit.id}`,
                    product_id: product.id,
                    product_reference_code: product.reference_code,
                    product_name: product.name,
                    quantity: product.quantity,
                    unit_id: product.unit.id,
                    unit_name: product.unit.name,
                })),
            ];
        });

        return products;
    }

    render() {
        const { isOpen, language, onClose } = this.props;

        const cartList = this.getCartList();

        return (
            <>
                <Modal
                    className="new-modal"
                    isOpen={isOpen}
                    toggle={onClose}
                    size="lg"
                >
                    <ModalHeader className="bg-primary" toggle={onClose} style={{ borderBottom: 0 }}>
                        Prescription / Order Pickup
                    </ModalHeader>
                    <Nav className="bg-primary" tabs style={{ paddingLeft: 16 }}>
                        <NavItem>
                            <NavLink
                                className={classnames({ active: this.state.activeTab === '1' })}
                                onClick={() => {
                                    this.toggle('1');
                                }}
                                style={{ cursor: 'pointer' }}
                            >
                                Home
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                className={classnames({ active: this.state.activeTab === '2' })}
                                onClick={() => {
                                    this.toggle('2');
                                }}
                                style={{ cursor: 'pointer' }}
                            >
                                E-Prescription
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                className={classnames({ active: this.state.activeTab === '3' })}
                                onClick={() => {
                                    this.toggle('3');
                                }}
                                style={{ cursor: 'pointer' }}
                            >
                                Online Order
                            </NavLink>
                        </NavItem>
                    </Nav>
                    <ModalBody>
                        <TabContent activeTab={this.state.activeTab}>
                            <TabPane tabId="1">
                                <Form>
                                    <Row>
                                        <Col xs={2} style={{ paddingTop: 8 }}>
                                            Hospital:
                                        </Col>
                                        <Col xs={5}>
                                            <FormGroup>
                                                <Input
                                                    type="text"
                                                    name="hospital_name"
                                                    id="hospital_name"
                                                    placeholder="โรงพยาบาล"
                                                    valid={this.state.formBodc12.hospital_name.length >= 1}
                                                    invalid={!!this.state.formBodc12.hospital_name && this.state.formBodc12.hospital_name.length < 1}
                                                    value={this.state.formBodc12.hospital_name}
                                                    onChange={this.onBodc12FromChange('hospital_name')}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col xs={5}>
                                            <Button
                                                type="button"
                                                color="warning"
                                                onClick={() => this.setState({ hospitalModal: true })}
                                            >
                                                {language.search}
                                            </Button>
                                        </Col>
                                        <Col xs={2} style={{ paddingTop: 8 }}>
                                            Prescriber:
                                        </Col>
                                        <Col xs={5}>
                                            <FormGroup>
                                                <Input
                                                    type="text"
                                                    name="prescriber_name"
                                                    id="prescriber_name"
                                                    placeholder="แพทย์ผู้สั่งยา"
                                                    valid={this.state.formBodc12.prescriber_name.length >= 1}
                                                    invalid={!!this.state.formBodc12.prescriber_name && this.state.formBodc12.prescriber_name.length < 1}
                                                    value={this.state.formBodc12.prescriber_name}
                                                    onChange={this.onBodc12FromChange('prescriber_name')}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col xs={5} />
                                        <Col xs={12}>
                                            Patient:
                                        </Col>
                                        <Col xs={6}>
                                            <FormGroup>
                                                <Input
                                                    type="text"
                                                    name="patient_name"
                                                    id="patient_name"
                                                    placeholder="ชื่อผู้ป่วย"
                                                    valid={this.state.formBodc12.patient_name.length >= 1}
                                                    invalid={!!this.state.formBodc12.patient_name && this.state.formBodc12.patient_name.length < 1}
                                                    value={this.state.formBodc12.patient_name}
                                                    onChange={this.onBodc12FromChange('patient_name')}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col xs={6}>
                                            <FormGroup>
                                                <Input
                                                    type="number"
                                                    min={0}
                                                    name="patient_age"
                                                    id="patient_age"
                                                    placeholder="อายุ"
                                                    valid={this.state.formBodc12.patient_age > 1}
                                                    invalid={!!this.state.formBodc12.patient_age && this.state.formBodc12.patient_age < 1}
                                                    value={this.state.formBodc12.patient_age}
                                                    onChange={this.onBodc12FromChange('patient_age')}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col xs={12}>
                                            <FormGroup>
                                                <Input
                                                    type="text"
                                                    name="patient_address"
                                                    id="patient_address"
                                                    placeholder="ที่อยู่"
                                                    valid={this.state.formBodc12.patient_address.length >= 1}
                                                    invalid={!!this.state.formBodc12.patient_address && this.state.formBodc12.patient_address.length < 1}
                                                    value={this.state.formBodc12.patient_address}
                                                    onChange={this.onBodc12FromChange('patient_address')}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col xs={12}>
                                            <FormGroup>
                                                <Label for="bod_note">Remark:</Label>
                                                <Input
                                                    type="textarea"
                                                    name="bod_note"
                                                    id="bod_note"
                                                    placeholder="หมายเหตุ"
                                                    value={this.state.formBodc12.note}
                                                    onChange={this.onBodc12FromChange('note')}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col xs={12}>
                                            <Card className="mb-2">
                                                <CardHeader style={{ paddingLeft: 8 }}>
                                                    <FormGroup check>
                                                        <Label check>
                                                            <Input
                                                                type="checkbox"
                                                                checked={this.state.formBodc12.details.length && this.state.formBodc12.details.length === cartList.length}
                                                                disabled={!cartList.length}
                                                                onChange={this.toggleBodc12ProductList}
                                                            />
                                                            Prescription Medicines:
                                                        </Label>
                                                    </FormGroup>
                                                </CardHeader>
                                                <Table style={{ marginBottom: 0 }}>
                                                    <tbody>
                                                        {cartList.map((item, index) => (
                                                            <tr key={index}>
                                                                <td>
                                                                    <FormGroup check>
                                                                        <Label check>
                                                                            <Input
                                                                                type="checkbox"
                                                                                value={item}
                                                                                checked={this.state.formBodc12.details.map(item => item.id).includes(item.id)}
                                                                                onChange={this.onBodc12ProductListChange(item)}
                                                                            />
                                                                            {item.product_reference_code} - {item.product_name} - {item.quantity} {item.unit_name}
                                                                        </Label>
                                                                    </FormGroup>
                                                                </td>
                                                            </tr>
                                                        ))}
                                                        {cartList.length === 0 && (
                                                            <tr>
                                                                <td className="text-center text-muted">
                                                                    ยังไม่มีสินค้าในตะกร้า
                                                                </td>
                                                            </tr>
                                                        )}
                                                    </tbody>
                                                </Table>
                                            </Card>
                                        </Col>
                                    </Row>
                                </Form>
                            </TabPane>

                            <TabPane tabId="2">
                                <Form onSubmit={this.onPickupFormSubmit} style={{ maxWidth: 400, margin: 'auto' }}>
                                    <FormGroup>
                                        <Label for="eps-qr">QR Code or OTP</Label>
                                        <Input
                                            type="text"
                                            name="eps-qr"
                                            id="eps-qr"
                                            placeholder="Code"
                                            valid={this.state.formPickup.code.length >= 6}
                                            invalid={!!this.state.formPickup.code && this.state.formPickup.code.length < 6}
                                            value={this.state.formPickup.code}
                                            onChange={this.onPickupFromChange('code')}
                                        />
                                    </FormGroup>
                                    <FormGroup>
                                        <Label for="eps-phone-number">Phone number</Label>
                                        <Input
                                            type="text"
                                            name="eps-phone-number"
                                            id="eps-phone-number"
                                            placeholder="Phone number"
                                            valid={this.state.formPickup.phone_number.length >= 10}
                                            invalid={!!this.state.formPickup.phone_number && this.state.formPickup.phone_number.length < 10}
                                            value={this.state.formPickup.phone_number}
                                            onChange={this.onPickupFromChange('phone_number')}
                                        />
                                    </FormGroup>
                                    <FormGroup>
                                        <Label for="eps-citizen-id">Citizen ID</Label>
                                        <Input
                                            type="text"
                                            name="eps-citizen-id"
                                            id="eps-citizen-id"
                                            placeholder="Citizen ID or passport"
                                            valid={this.state.formPickup.citizen_id.length >= 1}
                                            invalid={!!this.state.formPickup.citizen_id && this.state.formPickup.citizen_id.length < 1}
                                            value={this.state.formPickup.citizen_id}
                                            onChange={this.onPickupFromChange('citizen_id')}
                                        />
                                    </FormGroup>
                                    <input type="submit" style={{ position: 'absolute', left: -9999 }} />
                                </Form>
                            </TabPane>

                            <TabPane tabId="3">
                                <Form onSubmit={this.onPickupFormSubmit} style={{ maxWidth: 400, margin: 'auto' }}>
                                    <FormGroup>
                                        <Label>{language.order_number}</Label>
                                        <Input
                                            type="text"
                                            placeholder={language.order_number}
                                            valid={this.state.formOnlineOrder.reference_code.length >= 1}
                                            invalid={!!this.state.formOnlineOrder.reference_code && this.state.formOnlineOrder.reference_code.length < 1}
                                            value={this.state.formOnlineOrder.reference_code}
                                            onChange={this.onOnlineOrderFormChange('reference_code')}
                                            required
                                        />
                                    </FormGroup>
                                </Form>
                            </TabPane>
                        </TabContent>
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            color="danger"
                            onClick={onClose}
                        >
                            {language.close}
                        </Button>
                        {this.state.activeTab === '1' && (
                            <Button
                                color="success"
                                disabled={this.state.loading || !this.isBodc12FormValid()}
                                onClick={this.onBodc12FormSubmit}
                            >
                                {language.confirm}
                            </Button>
                        )}
                        {['2', '3'].indexOf(this.state.activeTab) > -1 && (
                            <Button
                                color="success"
                                disabled={this.state.loading || !this.isPickupFormValid()}
                                onClick={this.onPickupFormSubmit}
                            >
                                {language.confirm}
                            </Button>
                        )}
                    </ModalFooter>
                </Modal>
                <Modal
                    isOpen={this.state.hospitalModal}
                    toggle={() => this.setState({ hospitalModal: false })}
                    size="xs"
                >
                    <ModalHeader toggle={() => this.setState({ hospitalModal: false })}>
                        เลือกโรงพยาบาล
                    </ModalHeader>
                    <ModalBody>
                        <FormGroup>
                            <Input
                                type="text"
                                name="hospital_search"
                                id="hospital_search"
                                placeholder="ค้นหา"
                                value={this.state.hospitalSearch}
                                onChange={(event) => this.setState({ hospitalSearch: event.target.value })}
                            />
                        </FormGroup>

                        <ListGroup>
                            {this.state.hospitals.filter(hospital => hospital.name.toLocaleLowerCase().match(this.state.hospitalSearch.toLocaleLowerCase())).map((hospital) => (
                                <ListGroupItem
                                    key={hospital.id}
                                    tag="button"
                                    action
                                    onClick={() => {
                                        this.setState({
                                            hospitalModal: false,
                                            formBodc12: {
                                                ...this.state.formBodc12,
                                                hospital_name: hospital.name,
                                            },
                                        });
                                    }}
                                >
                                    {hospital.name}
                                </ListGroupItem>
                            ))}
                        </ListGroup>
                    </ModalBody>
                </Modal>
            </>
        );
    }
}

const mapStateToProps = state => ({
    language: state.language,
    isOpen: state.prescription.pickupModalOpen,
    cart: state.cart,
    customer: state.customer,
});

const mapDispatchToProps = {
    onClose: closePickupPrescriptionModal,
    onBodc12FormSubmit: setBodc12Data,
    onPickupFormSubmit: selectPrescriptionByCode,
};

export default connect(mapStateToProps, mapDispatchToProps)(PrescriptionPickupModal);
