import React from "react";
import { Dropdown, DropdownToggle, DropdownMenu } from "reactstrap";

class VoiceSetting extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            open: false,
            loop : this.props.loopSetting,
        }

        this._setNotiEpSoundAlways = this._setNotiEpSoundAlways.bind(this, true);
        this._setNotiEpSoundOnce = this._setNotiEpSoundOnce.bind(this, false);
    }

    toggle = () => {
        this.setState({ open: !this.state.open });
    };

    _setNotiEpSoundOnce(value) {
        this.setState({ loop : value })
        this.props.setNotiEpSoundLoop(value);
    }

    _setNotiEpSoundAlways(value) {
        this.setState({ loop : value })
        this.props.setNotiEpSoundLoop(value);
    }

    render() {
        const listDivEnd = {
            'display' : 'flex',
            'justifyContent' : 'end'
        }

        const listDivBetween = {
            'display' : 'flex',
            'justifyContent' : 'space-between'
        }

        return (
            <Dropdown
                isOpen={this.state.open}
                toggle={this.toggle}
                className="error-message-notify"
            >
                <DropdownToggle style={{ padding: 0 }}>
                    <div
                        className="link mr-2 shadow-box"
                        style={{ padding: 8, paddingRight: 0 }}
                    >
                        <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M18.28 8.37003C18.1129 8.16316 17.8705 8.03114 17.6061 8.003C17.3416 7.97487 17.0769 8.05294 16.87 8.22003C16.6631 8.38711 16.5311 8.62953 16.503 8.89396C16.4748 9.15839 16.5529 9.42316 16.72 9.63003C17.2252 10.3169 17.4977 11.1473 17.4977 12C17.4977 12.8527 17.2252 13.6831 16.72 14.37C16.6008 14.517 16.5257 14.6948 16.5035 14.8827C16.4813 15.0706 16.5129 15.261 16.5945 15.4317C16.6762 15.6024 16.8047 15.7464 16.9649 15.847C17.1252 15.9476 17.3108 16.0007 17.5 16C17.6494 16.0005 17.797 15.9676 17.932 15.9035C18.067 15.8395 18.1859 15.746 18.28 15.63C19.0732 14.5861 19.5026 13.3111 19.5026 12C19.5026 10.6889 19.0732 9.41394 18.28 8.37003Z"
                                fill="#454545"
                            />
                            <path
                                d="M19.64 5.23C19.5389 5.14595 19.4222 5.08265 19.2966 5.04369C19.1711 5.00474 19.039 4.99091 18.9081 5.00298C18.7772 5.01505 18.6499 5.05279 18.5335 5.11405C18.4172 5.17531 18.3141 5.25888 18.23 5.36C18.146 5.46112 18.0827 5.5778 18.0437 5.70338C18.0048 5.82896 17.9909 5.96099 18.003 6.09192C18.0151 6.22285 18.0528 6.35012 18.1141 6.46647C18.1753 6.58281 18.2589 6.68595 18.36 6.77C19.1621 7.38987 19.8154 8.18121 20.2721 9.08611C20.7289 9.99101 20.9776 10.9866 21 12C20.9776 13.0134 20.7289 14.009 20.2721 14.9139C19.8154 15.8188 19.1621 16.6101 18.36 17.23C18.2588 17.3139 18.1751 17.417 18.1137 17.5334C18.0524 17.6497 18.0146 17.777 18.0025 17.908C17.9904 18.039 18.0043 18.1711 18.0433 18.2967C18.0824 18.4223 18.1458 18.5389 18.23 18.64C18.324 18.7529 18.4418 18.8437 18.5749 18.9059C18.7079 18.9681 18.8531 19.0002 19 19C19.2337 19.0005 19.4601 18.9191 19.64 18.77C20.6705 17.9644 21.5072 16.9381 22.0887 15.7665C22.6701 14.5949 22.9816 13.3078 23 12C22.9816 10.6922 22.6701 9.40511 22.0887 8.23351C21.5072 7.06191 20.6705 6.03557 19.64 5.23ZM15 3.12C14.848 3.03223 14.6756 2.98602 14.5 2.98602C14.3245 2.98602 14.152 3.03223 14 3.12L7.52002 7.57H2.52002C2.2548 7.57 2.00045 7.67535 1.81291 7.86289C1.62538 8.05043 1.52002 8.30478 1.52002 8.57V15.43C1.52002 15.6952 1.62538 15.9496 1.81291 16.1371C2.00045 16.3246 2.2548 16.43 2.52002 16.43H7.52002L13.93 20.83C14.0999 20.9399 14.2977 20.9989 14.5 21C14.7652 21 15.0196 20.8946 15.2071 20.7071C15.3947 20.5196 15.5 20.2652 15.5 20V4C15.5025 3.8221 15.4575 3.64676 15.3696 3.49207C15.2817 3.33738 15.1541 3.20894 15 3.12ZM13.53 18.12L8.40002 14.6C8.23166 14.4868 8.03286 14.4275 7.83002 14.43H3.50002V9.57H7.83002C8.03286 9.57246 8.23166 9.51317 8.40002 9.4L13.5 5.9L13.53 18.12Z"
                                fill="#454545"
                            />
                        </svg>
                        <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M12 18L6.80385 9L17.1962 9L12 18Z"
                                fill="#454545"
                            />
                        </svg>
                    </div>
                </DropdownToggle>
                <DropdownMenu
                    className="border-0 p-0"
                    right
                    style={{
                        position: "absolute",
                        willChange: "transform",
                        top: "0px",
                        left: "-10px",
                        transform: "translate3d(-94px, 43px, 0px)",
                    }}
                >
                    <div
                        className="dropdown-body"
                        style={{
                            minHeight: "initial",
                            maxHeight: "initial",
                        }}
                    >
                        <div className="text-center">
                            <ul style={{ padding: 0 }}>
                                <li onClick={this._setNotiEpSoundOnce} style={{ cursor : 'pointer'}}>
                                    <div style={!this.state.loop ? listDivBetween : listDivEnd}>
                                        {!this.state.loop && (
                                            <svg
                                                width="24"
                                                height="24"
                                                viewBox="0 0 24 24"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <g clipPath="url(#clip0_10922_115)">
                                                    <path
                                                        d="M8.9999 16.2L4.7999 12L3.3999 13.4L8.9999 19L20.9999 6.99998L19.5999 5.59998L8.9999 16.2Z"
                                                        fill="#39D78B"
                                                    />
                                                </g>
                                                <defs>
                                                    <clipPath id="clip0_10922_115">
                                                        <rect
                                                            width="24"
                                                            height="24"
                                                            fill="white"
                                                        />
                                                    </clipPath>
                                                </defs>
                                            </svg>
                                        )}
                                        <span>เล่นครั้งเดียว</span>
                                    </div>
                                </li>
                                <li onClick={this._setNotiEpSoundAlways} style={{ cursor : 'pointer'}}>
                                    <div style={this.state.loop ? listDivBetween : listDivEnd}>
                                        {this.state.loop && (
                                            <svg
                                                width="24"
                                                height="24"
                                                viewBox="0 0 24 24"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <g clipPath="url(#clip0_10922_115)">
                                                    <path
                                                        d="M8.9999 16.2L4.7999 12L3.3999 13.4L8.9999 19L20.9999 6.99998L19.5999 5.59998L8.9999 16.2Z"
                                                        fill="#39D78B"
                                                    />
                                                </g>
                                                <defs>
                                                    <clipPath id="clip0_10922_115">
                                                        <rect
                                                            width="24"
                                                            height="24"
                                                            fill="white"
                                                        />
                                                    </clipPath>
                                                </defs>
                                            </svg>
                                        )}
                                        <span>เล่นวนซ้ำ</span>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </DropdownMenu>
            </Dropdown>
        );
    }
}

export default VoiceSetting;