import {
    PRESCRIPTION_CLEAR_DATA,
    PRESCRIPTION_CLOSE_CANCEL_MODAL,
    PRESCRIPTION_CLOSE_INFO_MODAL,
    PRESCRIPTION_CLOSE_LIST_MODAL,
    PRESCRIPTION_CLOSE_PICKUP_MODAL,
    PRESCRIPTION_OPEN_CANCEL_MODAL,
    PRESCRIPTION_OPEN_INFO_MODAL,
    PRESCRIPTION_OPEN_LIST_MODAL,
    PRESCRIPTION_OPEN_PICKUP_MODAL,
    PRESCRIPTION_SET_DATA,
    PRESCRIPTION_SET_INBOX_COUNT_MODAL,
    PRESCRIPTION_SET_PICKUP_NOTE,
    PRESCRIPTION_SET_UNIT,
    PRESCRIPTION_SET_DETAIL_STATUS_TYPE,
    SHOW_NOTI_PRESCRIPTION,
    CLOSE_NOTI_PRESCRIPTION,
    ADD_TO_CART,
    SETTING_NOTI_SOUND_PLAY
} from 'Redux/actionTypes';
import axios from 'axios';
import io from 'socket.io-client';
import { notify } from 'reapop';
import { setCartValue } from 'Redux/actions/cart';
import { checkCouponBeforeSaveCart } from 'Redux/actions/awaitingCart'
import { searchCustomers } from 'Redux/actions/search';
import { getCouponByCodeAndUse, removeCartCoupon } from 'Redux/actions/coupon';
import { isCartEmpty } from '../../bl/cart';

const API3_URL = process.env.API3_URL;

export const openPickupPrescriptionModal = () => dispatch => {
    dispatch({ type: PRESCRIPTION_OPEN_PICKUP_MODAL });
};

export const openPickupPrescriptionModalWithQrCode = (qrCode) => dispatch => {
    dispatch({ type: PRESCRIPTION_OPEN_PICKUP_MODAL, payload: qrCode });
};

export const closePickupPrescriptionModal = () => dispatch => {
    dispatch({ type: PRESCRIPTION_CLOSE_PICKUP_MODAL });
};

export const selectPrescriptionByCode = (data) => (dispatch, getState) => {
    const { cart, settings, employee } = getState();
    return axios({
        method: 'post',
        url: `${API3_URL}/companies/${settings.company.slug}/branches/${settings.branch.slug}/pos/eps/pickup`,
        headers: { 'Authorization': `Bearer ${settings.token}` },
        data: {
            employee_id: employee.employee.id,
            ...data,
        },
        params: {
            category: data.category ? data.category : null,
        }
    }).then(async (response) => {
        const prescription = response.data.data;
        if (prescription) {
            if ( ! isCartEmpty(cart)) {
                await dispatch(notifyAndSaveCart())
            }
            dispatch({ type: PRESCRIPTION_SET_DATA, payload: prescription });
            dispatch({ type: PRESCRIPTION_CLOSE_PICKUP_MODAL });
            dispatch({ type: PRESCRIPTION_OPEN_INFO_MODAL });
            dispatch(setCartValue('danger_note', `${prescription.patient_first_name} ${prescription.patient_last_name}`));
            dispatch(searchCustomers(prescription.patient_phone_number, true));
            if (prescription.coupon_code) dispatch(getCouponByCodeAndUse(prescription.coupon_code));
            return true;
        }
        switch (response.data.code) {
            case '11':
                dispatch(notify({
                    id: 'eps',
                    title: 'ใบสั่งยานี้ได้รับการจ่ายยาครบแล้ว',
                    status: 'warning',
                    dismissAfter: 10000,
                }));
                break;
            case '12':
                dispatch(notify({
                    id: 'eps',
                    title: 'ใบสั่งยานี้ยังไม่ถึงกำหนดรับยา',
                    message: 'รับยาครั้งต่อไปได้เมื่อ: ' + response.data.next_pickup_date,
                    status: 'warning',
                    dismissAfter: 10000,
                }));
                break;
            case '10':
            default:
                dispatch(notify({
                    id: 'eps',
                    title: 'ไม่พบใบสั่งยา',
                    message: 'กรุณาตรวจสอบข้อมูลอีกครั้ง',
                    status: 'warning',
                    dismissAfter: 10000,
                }));
                break;
        }
        return false;
    }).catch((err) => {
        console.error(err);
        dispatch(notify({
            id: 'eps',
            title: 'เกิดข้อผิดพลาดในการค้นหาใบสั่งยา',
            message: 'กรุณาตรวจสอบข้อมูลอีกครั้ง',
            status: 'warning',
        }));
        return false;
    });
};

export const openCancelPrescriptionModal = () => (dispatch) => {
    dispatch({ type: PRESCRIPTION_OPEN_CANCEL_MODAL });
};

export const closeCancelPrescriptionModal = () => (dispatch) => {
    dispatch({ type: PRESCRIPTION_CLOSE_CANCEL_MODAL });
};

export const confirmCancelPrescription = (data) => (dispatch, getState) => {
    const { settings, employee, prescription } = getState();
    return axios({
        method: 'post',
        url: `${API3_URL}/companies/${settings.company.slug}/branches/${settings.branch.slug}/pos/eps/cancel-pickup`,
        headers: { 'Authorization': `Bearer ${settings.token}` },
        data: {
            reference_code: prescription.data.reference_code,
            employee_id: employee.employee.id,
            ...data,
        },
    }).then((response) => {
        dispatch({ type: PRESCRIPTION_CLEAR_DATA });
        dispatch({ type: PRESCRIPTION_CLOSE_CANCEL_MODAL });
        dispatch(setCartValue('danger_note', null));
        dispatch(removeCartCoupon());
        
        if (prescription.category == 'lm') {
            dispatch(cancelToLineman({
                ...prescription,
                ...data,
                employee_id: employee.id
            }))
        }
    });
};

export const openPrescriptionListModal = () => (dispatch) => {
    dispatch({ type: PRESCRIPTION_OPEN_LIST_MODAL });
};

export const closePrescriptionListModal = () => (dispatch) => {
    dispatch({ type: PRESCRIPTION_CLOSE_LIST_MODAL });
};

export const openPrescriptionInfoModal = () => (dispatch) => {
    dispatch({ type: PRESCRIPTION_OPEN_INFO_MODAL });
};

export const closePrescriptionInfoModal = () => (dispatch) => {
    dispatch({ type: PRESCRIPTION_CLOSE_INFO_MODAL });
};

export const changePrescriptionPickupNote = (payload) => (dispatch) => {
    dispatch({ type: PRESCRIPTION_SET_PICKUP_NOTE, payload });
};

export const setPrescriptionInboxCountInterval = () => (dispatch) => {
    dispatch(getPrescriptionInboxCount());
    
    setInterval(() => {
        dispatch(getPrescriptionInboxCount());
    }, 1000 * 60);
};

export const getPrescriptionInboxCount = () => (dispatch, getState) => {
    try {
        const { settings, prescription } = getState();
        return axios({
            url: `${API3_URL}/companies/${settings.company.slug}/branches/${settings.branch.slug}/pos/eps/inbox-count`,
            headers: { 'Authorization': `Bearer ${settings.token}` },
        }).then((response) => {
            if(!response) return false;

            const count = response.data.data;

            if (Number(count) > Number(prescription.inboxCount)) {
                dispatch({ type: SHOW_NOTI_PRESCRIPTION });
                dispatch({ type: SETTING_NOTI_SOUND_PLAY , payload : true });
            }

            dispatch({ type: PRESCRIPTION_SET_INBOX_COUNT_MODAL, payload: count });
        });
    } catch (error) {
        console.error('getPrescriptionInboxCount => ' + error);
    }
    
};

export const listenPrescriptionInboxOnSocket = () => (dispatch, getState) => {
    const API_SOCKET_URL = process.env.API_SOCKET_URL;
    const { settings } = getState();

    const chName = 'eps-inbox-' + settings.branch.district_id;

    const socket = io(API_SOCKET_URL);
    socket.on('connect', () => console.log('EPS: socket connected to ' + chName));
    socket.on(chName, () => {
        console.log('EPS: received new eps!');
        dispatch(getPrescriptionInboxCount());
    });
};

export const setPrescriptionUnit = (unitId, detailId) => dispatch => {
    return dispatch({
        type: PRESCRIPTION_SET_UNIT,
        payload: {
            unitId,
            detailId,
        },
    });
}

export const closeNotiPrescription = () => dispatch => {
    return dispatch({ type: CLOSE_NOTI_PRESCRIPTION });
}

const cancelToLineman = data => (dispatch, getState) => {
    const { settings } = getState();

    return axios({
        method: 'post',
        url: `${API3_URL}/companies/${settings.company.slug}/branches/${settings.branch.slug}/lineman/cancellation`,
        headers: { 'Authorization': `Bearer ${settings.token}` },
        data,
    }).then(res => {
        dispatch(notify({
            id: 'eps',
            title: 'เรียบร้อย',
            message: 'ยกเลิกใบสั่งยาแล้ว',
            status: 'info',
        }));
    }).catch(err => {
        console.error(err);
        dispatch(notify({
            id: 'eps',
            title: 'เกิดข้อผิดพลาด',
            message: 'ไม่สามารถยกเลิกใบสั่งยาได้',
            status: 'warning',
        }));
    })
};

const notifyAndSaveCart = () => (dispatch) => {
    dispatch(notify({
        id: 'eps',
        title: 'บิลขายถูกพักไว้',
        message: 'เนื่องจากเลือกใบสั่งยา บิลขายก่อนหน้าจึงถูกพักไว้ในรายการพักบิล',
        status: 'info',
        dismissAfter: 10000
    }))
    return dispatch(checkCouponBeforeSaveCart())
}

const setDetailStatusTypePrescription = (detailId, statusType) => (dispatch) => {
    dispatch({
        type: PRESCRIPTION_SET_DETAIL_STATUS_TYPE,
        payload: {
            detailId,
            statusType,
        },
    });
};

const searchProductsForPrescription = (query, settings, prescriptionId = null) => {
    return axios({
        url: `${API3_URL}/companies/${settings.company.slug}/products`,
        headers: { Authorization: `Bearer ${settings.token}` },
        params: {
            branch_id: settings.branch.id,
            fields: 'price,formula,ingredients,remaining_quantity,unit,cost,bureauOfDrugControls,wishlist,medicationManagements',
            q: query,
            form_page: 'pos',
            limit: 100,
            prescription_id: prescriptionId
        },
    });
};

export const addPrescriptionToCart = (prescription) => async (dispatch, getState) => {
    const { cart, settings, detail: initDetail, employee: { employee }, customer } = getState();

    if (prescription) {
        if (!isCartEmpty(cart)) {
            await dispatch(notifyAndSaveCart());
        }

        dispatch({ type: PRESCRIPTION_SET_DATA, payload: prescription });
        dispatch(closePrescriptionListModal());
        dispatch(setCartValue('danger_note', `${prescription.patient_first_name} ${prescription.patient_last_name}`));
        dispatch(searchCustomers(prescription.patient_phone_number, true));
        if (prescription.coupon_code) dispatch(getCouponByCodeAndUse(prescription.coupon_code));

        for (let index = 0; index < prescription.details.length; index++) {
            const detail = prescription.details[index];
            const query = detail.product ? detail.product.reference_code : detail.ingredient ? detail.ingredient.name : detail.name;
            const res = await searchProductsForPrescription(query, settings, prescription.id);
            const products = res.data.products;

            if (products.length > 0) {
                const product = products.find((product) => product.id === detail.product_id);
                const quantity = parseInt(detail.quantity, 10);
                const unit = product.units.find((unit) => unit.id === detail.unit_id);

                if (product && product.remaining_quantity >= quantity && unit) {
                    const price = prescription.category === 'mc' ? parseFloat(detail.pharmacy_price) : parseFloat(detail.partner_price);

                    await dispatch({
                        type: ADD_TO_CART,
                        payload: {
                            detail: {
                                ...initDetail,
                                product,
                                unit,
                                unit_id: unit.id,
                                sales_quantity: quantity,
                                price_per_unit: price,
                                net_amount: price * quantity,
                                product_id: product.id,
                            },
                            sales_receipt_status_id: cart.current_status_id,
                            employee_id: employee.id,
                            customer_id: customer.id,
                            customer: {
                                id: customer.id,
                                ingredient_allergies: customer.ingredient_allergies,
                                name: customer.name,
                                price_level: customer.price_level,
                                product_allergies: customer.product_allergies,
                                reference_code: customer.reference_code,
                                retail_price_level: customer.retail_price_level,
                                wholesale_price_level: customer.wholesale_price_level,
                            },
                        },
                    });
                    dispatch(setDetailStatusTypePrescription(detail.id, 'MATCH'));
                } else {
                    dispatch(setDetailStatusTypePrescription(detail.id, 'NOT_MATCH'));
                }
            } else {
                dispatch(setDetailStatusTypePrescription(detail.id, 'NOT_FOUND'));
            }
        }
    }
};
