import React from 'react';
import { Modal, ModalHeader } from 'reactstrap';

const ModalTemplate = props => {
    return (
        <Modal
            toggle={props.onCancel}
            isOpen={props.show}
            size={props.size}
            backdrop={props.backdrop}
        >
            <ModalHeader
                id={!props.closeButton ? 'disableCloseButtonModal' : ''}
                toggle={props.onCancel}
            >
                {props.title}
            </ModalHeader>
            {props.children}
        </Modal>
    );
};

ModalTemplate.defaultProps = {
    closeButton: true
}

export default ModalTemplate;
