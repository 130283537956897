import Dexie from 'dexie'

const db = new Dexie('pos.arincare')

db.version(1).stores({
    settings: ',token',
    carts: 'reference_code, current_status_id',
    products: 'id, name, reference_code'
})

db.version(2).stores({})

db.version(3).stores({
    employees: 'reference_code',
    customers: 'id'
})

export default db
