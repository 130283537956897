export const isMedcarePrescription =
    (prescription) => prescription.category === 'mc';

export const isCartSatisfyMedcarePrescription = (cartDetails, prescriptionDetails) => {

    // The number of details in cart must match number of details in prescription
    if (cartDetails.length !== prescriptionDetails.length) {
        return false;
    }

    // normalize to string and store as set for fast lookup
    const normalizedCartDetails = new Set(
        cartDetails.map(
            detail => [
                detail.product_id,
                detail.unit_id,
                detail.sales_quantity,
                detail.price_per_unit
            ].join()
        )
    )

    // normalize to string
    const normalizedPescriptionDetails = prescriptionDetails.map(
        prescription => [
            prescription.product_id,
            prescription.unit_id,
            prescription.quantity,
            prescription.pharmacy_price
        ].join()
    )

    for (const normalizedPrescriptionDetail of normalizedPescriptionDetails) {
        if ( ! normalizedCartDetails.has(normalizedPrescriptionDetail)) {
            return false;
        }
    }
    
    return true;
}