import React from 'react';
import { Button, Card, CardBody, Col, ListGroup, ListGroupItem, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';
import { connect } from 'react-redux';
import { closeServicesModal, openServicesModal } from 'Redux/actions';
import DeliveryPage from './DeliveryPage';
import foodpandaImage from '../../../images/foodpanda.png';
import ServicesHistory from './ServicesHistory';
import { ServicesModalScreen } from './constants';

const SCREEN_WITH_MENU = [
    ServicesModalScreen.DELIVERY_MENU,
    ServicesModalScreen.HISTORY,
];

const ServicesModal = (props) => {
    const { isOpen, language, onClose, screen, setScreen, screenOrderId } = props;

    const handleOpenScreen = (screen) => () => {
        setScreen(screen);
    };

    const handleClickBack = () => {
        setScreen(ServicesModalScreen.DELIVERY_MENU);
    };

    const targetDateFoodPanDaDate = new Date('2023-09-01');
    const currentDate = new Date();
    const disabledFoodPanDa = currentDate.getTime() >= targetDateFoodPanDaDate.getTime();

    const body = (
        <>
            {screen === ServicesModalScreen.DELIVERY_MENU && (
                <Card style={{ minHeight: 550 }}>
                    <CardBody>
                        <Row>
                            <Col xs={3}>
                                <Button
                                    disabled={disabledFoodPanDa}
                                    color="light"
                                    onClick={handleOpenScreen(ServicesModalScreen.FOODPANDA)}
                                    title="foodpanda"
                                    block
                                    outline
                                    style={{
                                        backgroundColor: '#d71465',
                                        padding: 0,
                                        borderRadius: 10,
                                        overflow: 'hidden',
                                    }}
                                >
                                    <img src={foodpandaImage} alt="" width="100%" />
                                </Button>
                                <h5 className="mt-2 mb-0 text-center">foodpanda</h5>
                                { !disabledFoodPanDa && (
                                     <a 
                                     href="https://blog.arincare.com/2021/01/21/arincare-delivery-service/" 
                                     target="_blank"
                                     className="d-block text-center text-decoration-underline"
                                     style={{
                                         color: "#55C2EB",
                                         fontSize: "14px",
                                     }}
                                 >ขั้นตอนการใช้งาน
                                 </a>
                                )}
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            )}
            {screen === ServicesModalScreen.FOODPANDA && (
                <DeliveryPage providerId="FOODPANDA" orderId={screenOrderId} onClickBack={handleClickBack} />
            )}
            {screen === ServicesModalScreen.HISTORY && (
                <ServicesHistory />
            )}
        </>
    );

    return (
        <>
            <Modal
                className="new-modal"
                isOpen={isOpen}
                toggle={onClose}
                size="lg"
                style={{ maxWidth: '80%', minWidth: 800 }}
            >
                <ModalHeader toggle={onClose} className="bg-primary">
                    {language.otherServices2}
                </ModalHeader>
                <ModalBody style={{ minHeight: 600, background: '#f8f8f8' }}>
                    {SCREEN_WITH_MENU.includes(screen) ? (
                        <Row>
                            <Col xs={3}>
                                <ListGroup>
                                    <ListGroupItem
                                        active={screen === ServicesModalScreen.DELIVERY_MENU}
                                        tag="button"
                                        action
                                        onClick={handleOpenScreen(ServicesModalScreen.DELIVERY_MENU)}
                                    >
                                        {language.services_delivery}
                                    </ListGroupItem>
                                    <ListGroupItem
                                        active={screen === ServicesModalScreen.HISTORY}
                                        tag="button"
                                        action
                                        onClick={handleOpenScreen(ServicesModalScreen.HISTORY)}
                                    >
                                        ประวัติการทำรายการ
                                    </ListGroupItem>
                                </ListGroup>
                            </Col>
                            <Col xs={9}>
                                {body}
                            </Col>
                        </Row>
                    ) : body}
                </ModalBody>
            </Modal>
        </>
    );
};

const mapStateToProps = state => ({
    language: state.language,
    isOpen: state.services.modalOpen,
    screen: state.services.modalScreen,
    screenOrderId: state.services.modalScreenOrderId,
});

const mapDispatchToProps = {
    setScreen: openServicesModal,
    onClose: closeServicesModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(ServicesModal);
