import moment from 'moment'
import { 
    SET_DAILY_SUMMARY_REPORT
 } from 'Redux/actionTypes'

import axiosHelper from '../middleware/axios';

let helper = new axiosHelper();

export const setDailySummaryReport = () => ( dispatch, getState) => {

    let date = moment().format('YYYY-MM-DD');
    const { settings, employee} = getState();
    const { token} = settings;

    return helper.get(`/companies/${ settings.company.slug}/branches/${ settings.branch.slug}/employee/${ employee.employee.reference_code}/pos-sales-summary?date=${ date}`,
    {},
    {
        Authorization : `Bearer ${token}`,
        Accept : 'application/json'
    },
    3).then((res) => {

        dispatch({
            type: SET_DAILY_SUMMARY_REPORT,
            payload: res.result
        });
    })
            


}