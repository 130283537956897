import {
    RECEIVE_SETTINGS,
    SET_LINE_PAY_VALUE,
    LINE_PAY_RECEIVE,
    UPDATE_LINE_PAY_FEE
} from 'Redux/actionTypes';

const initialState = {
    activate: false,
    fee_percentage: null,
    fee_type: 'included',
    oneTimeKey: ''
};

export default (state = initialState, { type, payload }) => {
    switch (type) {
        case RECEIVE_SETTINGS:
            return {
                ...state,
                activate: payload.payment.line_pay.activate,
                fee_percentage: payload.payment.line_pay.fee_percentage,
                fee_type: payload.payment.line_pay.fee_type
            };
        case SET_LINE_PAY_VALUE:
            return {
                ...state,
                [payload.path]: payload.value
            };
        case LINE_PAY_RECEIVE:
            return {
                ...state,
                oneTimeKey: ''
            };
        case UPDATE_LINE_PAY_FEE:
            return {
                ...state,
                fee_percentage: payload.feePercentage
            }
        default:
            return state;
    }
};
