import { SELECT_PRODUCT
    , ADD_STOCK_SET
    , DELETE_STOCK_SET
    , CLOSE_ADD_STOCK_SET
    , ADD_RETAIL_UNIT
    , CANCEL_RETAIL_UNIT
    , SET_ALL_UNITS } from '../actionTypes'

const initialState = {
    units:[]
    , costs: {}
    , remaining_quantity: 0
    , products: []
    , name: ""
}

export default (state=initialState, { type, payload }) => {
    switch (type) {
        case SET_ALL_UNITS:
            return Object.assign({}, state, {units : payload});
        case CANCEL_RETAIL_UNIT:
            return initialState;
        case ADD_RETAIL_UNIT:
            return Object.assign({}, initialState, payload);
        case ADD_STOCK_SET:
            return Object.assign({},state,{ products: payload.products })
        case DELETE_STOCK_SET:
            return Object.assign({},state,{
                products: state.products.filter(product => product.id !== payload)
            })
        default:
            return state
    }
}