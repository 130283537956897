import { Table } from 'reactstrap';
import React from 'react';

const DeliveryPrice = () => {
    return (
        <Table className="text-center service-price-table">
            <thead>
                <tr>
                    <th>ระยะทาง km.</th>
                    <th style={{ paddingLeft: 0, paddingRight: 0, color: '#fff', background: '#d70765' }}>Arincare x Foodpanda</th>
                    <th>GrabExpress</th>
                    <th>LINE MAN</th>
                    <th>Lalamove</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>0 - 1</td>
                    <td style={{ color: '#fff', background: '#d70765' }}>43</td>
                    <td>43.3</td>
                    <td>55.2</td>
                    <td>55.2</td>
                </tr>
                <tr>
                    <td>1 - 2</td>
                    <td style={{ color: '#fff', background: '#d70765' }}>45</td>
                    <td>50.6</td>
                    <td>62.4</td>
                    <td>62.4</td>
                </tr>
                <tr>
                    <td>2 - 3</td>
                    <td style={{ color: '#fff', background: '#d70765' }}>45</td>
                    <td>57.9</td>
                    <td>69.6</td>
                    <td>69.6</td>
                </tr>
                <tr>
                    <td>3 - 4</td>
                    <td style={{ color: '#fff', background: '#d70765' }}>60</td>
                    <td>65.2</td>
                    <td>76.8</td>
                    <td>76.8</td>
                </tr>
                <tr>
                    <td>4 - 5</td>
                    <td style={{ color: '#fff', background: '#d70765' }}>65</td>
                    <td>72.5</td>
                    <td>84</td>
                    <td>84</td>
                </tr>
                <tr>
                    <td>5 - 6</td>
                    <td style={{ color: '#fff', background: '#d70765' }}>69</td>
                    <td>79.8</td>
                    <td>91.2</td>
                    <td>91.2</td>
                </tr>
                <tr>
                    <td>6 - 7</td>
                    <td style={{ color: '#fff', background: '#d70765' }}>80</td>
                    <td>87.1</td>
                    <td>98.4</td>
                    <td>98.4</td>
                </tr>
                <tr>
                    <td>7 - 8</td>
                    <td style={{ color: '#fff', background: '#d70765' }}>85</td>
                    <td>94.4</td>
                    <td>105.6</td>
                    <td>105.6</td>
                </tr>
                <tr>
                    <td>8 - 9</td>
                    <td style={{ color: '#fff', background: '#d70765' }}>90</td>
                    <td>101.7</td>
                    <td>112.8</td>
                    <td>112.8</td>
                </tr>
                <tr>
                    <td>9 - 10</td>
                    <td style={{ color: '#fff', background: '#d70765' }}>100</td>
                    <td>109</td>
                    <td>120</td>
                    <td>120</td>
                </tr>
            </tbody>
        </Table>
    );
};

export default DeliveryPrice;
