import React from 'react'
import { ButtonGroup, 
    Button, 
    Row, 
    Col,
    Input } from 'reactstrap'
import Currency from 'react-currency-formatter';

import SelectFromGroup from 'Components/Input/SelectFormGroup'

import {feeIncludeType} from 'Utilz/shared';

const creditTypes = [
    { label: 'Master Card', type: 'master_card'},
    { label: 'VISA', type: 'visa'},
    { label: 'อื่น ๆ', type: 'etc'}]

class CreditPage extends React.Component {

    render() {
        const { cart, setCartValue, language} = this.props
        const btn = creditTypes.map((creditType) => {
            return(
                <Button 
                key={'bank_account_'+ creditType.type}
                outline
                style={{ height: '40px' }}
                onClick={() => { setCartValue('credit_types',creditType.type) }}
                active={ cart.credit_types === creditType.type }
                className='text-center'>
                    { creditType.label }
                </Button>
            )
        })
        return (
            <div style={{  marginBottom: '15px' }}>
                <ButtonGroup
                    vertical 
                    style={{ width: '100%', marginBottom: '15px' }}>
                    {btn}
                </ButtonGroup>
                <Row>
                    <Col sm={6}>
                        <div>
                            <label className="mr-2">{language.credit_fee}:</label>
                            <Currency quantity={ Number(cart.credit_charge) } currency='THB' ></Currency>
                        </div>
                        <div className="input-group input-group-xl col-6 pl-0">
                            <input
                                className="form-control"
                                name="cart_fee"
                                value={cart.fee}
                                maxLength="3"
                                min="1"
                                type='number'
                                onChange={(e) => setCartValue('fee', e.target.value)}
                                onBlur={(e) => {
                                    !e.target.value && setCartValue('fee', 0)
                                }}
                            />
                            <div className="input-group-append">
                                <span className="input-group-text">%</span>
                            </div>
                        </div>
                    </Col>
                    <Col sm={6}>
                        <SelectFromGroup
                            label={language.credit_include}
                            value={cart.credit_include}
                            onChange={(e) => {setCartValue('credit_include',e.target.value)}}
                            placeholder={language.credit_include}
                            options={feeIncludeType}
                        />
                    </Col>
                </Row>
                <Row className="mt-2">
                    <Col>
                        <label htmlFor="">{language.payment_note}</label>
                        <Input 
                            type="textarea" 
                            value={cart.payment_note}
                        />
                    </Col>
                </Row>
            </div>
        )
    }
}

export default CreditPage