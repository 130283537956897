import { COPY_CART_TO_SR
    , CLOSE_CHECKED_OUT
    , EMPLOYEE_LOGOUT 
    , RECEIVE_ABB
    , CHECK_OUT
} from '../actionTypes'

const initialState = {
        reference_code: null
        , details: []
        , bank_account_id: 0
        , company_id: null
        , branch_id: null
        , employee: {}
        , employee_id: null
        , current_status_id: 2
        , created_at: null
        , tax_types: null
        , tax_percentage: 0
        , histories:[]
        , member: { 
            reference_code : "ลูกค้าทั่วไป"
            , name: 'ลูกค้าทั่วไป'
        }
        , customer_id: null
        , member_id: null
        , prescription_reference_code: null
        , prescription_id: null
        , bodc12: null
        , net_amount: 0
        , has_allergic: false
        , total_discount_amount: 0
        , price: 0
        , total_price: 0
        , tax_amount: 0
        , total_amount: 0
        , total_paid_amount: 0
        , paid_amount: 0
        , credit_include: null
        , change_amount: 0
        , present_quantity: 1
        , abb: {
            reference_code: null
        }
        , printer: {
            id: 0
        }
        , printer_license: null
        , danger_note: null
}
    

    
export default (state=initialState, { type, payload }) => {

    switch (type) {

        case RECEIVE_ABB:{
            return Object.assign({}, state, { abb: payload });
        }
        case COPY_CART_TO_SR:
            return Object.assign({}, initialState, payload);
        case CHECK_OUT: 
        case CLOSE_CHECKED_OUT:
        case EMPLOYEE_LOGOUT:
            return Object.assign({}, initialState);
        default:
            return state;
    }
}
    
