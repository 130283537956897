import React from 'react';
import { Modal, ModalHeader, ModalBody, Row, Col, Button } from 'reactstrap';

import ArinOrderEpImg from 'Images/arincare_order_ep.png';

class NotiPrescriptionModal extends React.Component {
    render() {
        const {
            showNotiPrescription,
            language,
            closeNotiPrescription,
            openPrescriptionListModal,
        } = this.props;
        return (
            <Modal isOpen={showNotiPrescription} style={{ maxWidth: '46%' }}>
                <ModalHeader toggle={closeNotiPrescription}>{language.noti_ep_title}</ModalHeader>
                <ModalBody>
                    <Row>
                        <Col md="12" className="text-center">
                            <img src={ArinOrderEpImg} alt="" width="250" />
                            <h5 className="text-primary mb-4">{language.new_order}</h5>
                            <div>{language.noti_ep_desc1}</div>
                            <div>
                                {language.click_button} "<b>{language.view_detail}</b>"{' '}
                                {language.noti_ep_desc2}
                            </div>
                        </Col>
                    </Row>
                    <Row className="mt-4 mb-2">
                        <Col md="12" className="text-center">
                            <Button
                                color="danger"
                                onClick={closeNotiPrescription}
                                style={{ minWidth: 90, marginRight: 5 }}
                            >
                                {language.close}
                            </Button>{' '}
                            <Button
                                color="success"
                                onClick={openPrescriptionListModal}
                                style={{ minWidth: 90 }}
                            >
                                {language.view_detail}
                            </Button>
                        </Col>
                    </Row>
                </ModalBody>
            </Modal>
        );
    }
}

export default NotiPrescriptionModal;
