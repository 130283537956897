import React from 'react'
import { Row, Col, Button } from 'reactstrap'
import classnames from 'classnames'
import Autosuggest from 'react-autosuggest'

const TypeaheadGroup = props => {
    const { label, allergic, path, valueName, idName, language } = props
    const getSuggestionValue = suggestion => suggestion.name
    const renderSuggestion = suggestion => <div>{suggestion.name}</div>

    const allergicList = allergic.map((item, index) => {

        const inputProps = {
            id: path,
            name: valueName,
            value: item[valueName],
            onChange: (e, { newValue }) => props.handleAllergyChange(index, {
                id: path,
                name: valueName
            }, newValue),
            disabled: (item[valueName] && item[idName] !== '')
        }

        const selected = (e, { suggestion }) => props.handleAllergyChange(index, {
            id: path,
            name: idName
        }, suggestion.id)

        const theme = {
            container: 'autosuggest',
            input: 'form-control',
            suggestionsContainer: 'dropdown',
            suggestionsList: `dropdown-menu ${item.suggestions.length ? 'show' : ''}`,
            suggestion: 'dropdown-item',
            suggestionFocused: 'active'
        }

        return (
            <div className={classnames('form-row', { 'd-none': item.state === 'delete' })} key={index}>
                <div className="form-group col-md-10">
                    <Autosuggest
                        onSuggestionsFetchRequested={(query) => props.getAllergy(query, path, index)}
                        onSuggestionsClearRequested={() => props.clearAllergy(path, index)}
                        getSuggestionValue={getSuggestionValue}
                        renderSuggestion={renderSuggestion}
                        onSuggestionSelected={selected}
                        suggestions={item.suggestions}
                        inputProps={inputProps}
                        theme={theme}
                    />
                </div>
                <div className="form-group col-md-2">
                    <Button
                    color="danger"
                    title={language.delete}
                    onClick={() => props.removeAllergyInfo(path, index)}>
                        <i className="fa fa-trash"></i>
                    </Button>
                </div>
            </div>
        )
    })
    
    return (
        <div>
            <label>{label}</label>
            {allergicList}
            <Row>
                <Col>
                    <Button
                    color="success"
                    title={language.add}
                    onClick={() => props.addAllergyInfo(path)}><i className="fa fa-plus"></i></Button>
                </Col>
            </Row>
        </div>
    )
}

export default TypeaheadGroup