import React from 'react';
import { Modal, ModalHeader, ModalBody, Row, Col, Button } from 'reactstrap';
import moment from 'moment';

class NotiWrongTimeModal extends React.Component {
    _refreshWindow() {
        window.location.reload();
    }

    render() {
        const { notiWrongTime, language } = this.props;
        const serverTime = moment(notiWrongTime.serverTime).format('YYYY-MM-DD HH:mm:ss');
        const localTime = moment(Date.now()).format('YYYY-MM-DD HH:mm:ss');

        return (
            <Modal isOpen={notiWrongTime.showModal}>
                <ModalHeader>{language.wrong_time_title}</ModalHeader>
                <ModalBody className="px-4 text-center">
                    <div className="time-warning-img"></div>
                    <Row>
                        <Col md="6" className="text-center">
                            <h6 style={{ color }}>{language.wrong_time_server}</h6>
                            <span className="text-success" style={{ fontSize: '16px' }}>
                                {serverTime}
                            </span>
                        </Col>
                        <Col
                            md="6"
                            className="text-center"
                            style={{ borderLeft: '2px solid #f95653' }}
                        >
                            <h6 style={{ color }}>{language.wrong_time_local}</h6>
                            <span className="text-danger" style={{ fontSize: '16px' }}>
                                {localTime}
                            </span>
                        </Col>
                    </Row>
                    <br />
                    <span>{language.wrong_time_desc}</span>
                    <Button
                        color="success"
                        style={{ marginTop: '15px' }}
                        onClick={this._refreshWindow}
                    >
                        {language.login_again}
                    </Button>
                </ModalBody>
            </Modal>
        );
    }
}

const color = '#216cbf';

export default NotiWrongTimeModal;
