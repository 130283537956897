import React from 'react';
import { Modal
    , ModalHeader
    , ModalBody
 } from 'reactstrap';

import QRPaymentForm from 'Components/Form/QRPaymentForm';
import ThaiQRHeader from 'Components/Shared/ThaiQRHeader';

const QRPaymentModal = ( props) => {


    return (
        <Modal
            toggle={props.onClose}
            isOpen={props.show}
            size="md"
            contentClassName="qr-modal-content"
        >
        <ModalHeader toggle={props.onClose} className="qr-payment-header">
            <ThaiQRHeader width="150"/>
        </ModalHeader>  
        <ModalBody style={{ paddingBottom: '5px' }}>
            <QRPaymentForm {...props}/>
        </ModalBody>
     </Modal>
    )
}

export default QRPaymentModal;