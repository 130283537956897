import React from 'react'
import { render } from 'react-dom'
import { Provider } from 'react-redux'
import { ConnectedRouter } from 'react-router-redux'
import * as Sentry from '@sentry/browser';
import runtime from 'serviceworker-webpack-plugin/lib/runtime'
import registerEvents from 'serviceworker-webpack-plugin/lib/browser/registerEvents'
import store from './redux/store'
import history from './routes/history'
import App from './containers/App'
import { reSyncOnline, openNotiRefreshModal, showNotiNewVersionModal } from 'Redux/actions'
import { handleIndexedDBError } from './redux/middleware/dbHelper'
import axios from 'axios';
import './utils/duplicateTab';
import NotifyMaintenance from './pages/NotifyMaintenance';

import 'bootstrap/dist/css/bootstrap.css'
import './style/style.css'
import './style/font-awesome.css'
import './style/paymentfont.css'

Sentry.init({
  dsn: process.env.SENTRY_DSN,
  release: `pos@${process.env.APP_VERSION}`,
  environment: process.env.SENTRY_ENVIRONMENT,
})

if ('serviceWorker' in navigator && (location.protocol === 'https:' || location.hostname === 'localhost')) {
    const registration = runtime.register();
    registerEvents(registration, {
        onInstalled: () => console.log('SW installed.'),
        onUpdateReady: () => {
            console.log('SW update is ready.');
            store.dispatch(showNotiNewVersionModal());
        },
    });
} else {
    console.log('Service workers require SSL or browser not support.');
}

window.addEventListener('online', () => {
  navigator.onLine && store.dispatch(reSyncOnline())
})

window.addEventListener('unhandledrejection', handleIndexedDBError);

const min = 1000*60;
const hour = min * 60
const time = hour * 2;
setInterval(() => { store.dispatch(openNotiRefreshModal())}, time);

console.log(`POS v${process.env.APP_VERSION}`)

render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      { process.env.CLOSE_PLATFORM === "NOW" 
        ? <NotifyMaintenance />
        : <App />
      }
    </ConnectedRouter>
  </Provider>,
  document.getElementById("root")
)

/** Send error log to api */
const API3_URL = process.env.API3_URL;
const consoleError = console.error;

console.error = function (message) {
  consoleError.apply(console, arguments);
  axios({
    method: 'post',
    url: `${API3_URL}/pos-error-log`,
    data: {
      data: message,
      company_id: window.company_id,
      branch_id: window.branch_id
    }
  }).catch(() => {});
};
/** End send error log to api */
