import React from 'react';
import { Row, Col, Button } from 'reactstrap';

const CompletedScreen = (props) => {
    const { patient, callSid, recordingUrl } = props.voice;
    return (
        <React.Fragment>
            <Row className="mb-2">
                <Col md="12" className="text-center">
                    <div className="completed-call-img"></div>
                    <h5 className="font-weight-bold mb-4">
                        {patient.name ? patient.name : 'ไม่ทราบชื่อ'}
                    </h5>
                    <div>
                        {!!recordingUrl ? (
                            <a href={`${recordingUrl}?Download=true`} download={callSid}>
                                บันทึกไฟล์เสียงเก็บไว้บนเครื่อง
                            </a>
                        ) : (
                            'กำลังโหลดไฟล์เสียง...'
                        )}
                    </div>
                    {props.isCustomer ? (
                        <p className="mt-4">
                            ต้องการอัพเดทข้อมูลสมาชิก
                            <br />
                            ไว้ในระบบหรือไม่?
                        </p>
                    ) : (
                        <p className="mt-4">
                            ต้องการบันทึกข้อมูลคนไข้
                            <br />
                            ไว้ในระบบสมาชิกหรือไม่?
                        </p>
                    )}
                </Col>
            </Row>
            <Row className="mb-3">
                <Col md="12" className="text-center">
                    <Button
                        color="danger"
                        style={{ minWidth: 90, marginRight: 20 }}
                        onClick={props.cancel}
                    >
                        ไม่
                    </Button>
                    <Button
                        color="success"
                        style={{ minWidth: 90 }}
                        onClick={props.registerCustomer}
                    >
                        ตกลง
                    </Button>
                </Col>
            </Row>
        </React.Fragment>
    );
};

export default CompletedScreen;
