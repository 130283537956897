import React from 'react'
import { Modal, ModalBody, Row, Col, Button } from 'reactstrap'

class AdsPopupModal extends React.Component {
    constructor(props) {
        super(props)
        this._closeAdsEsc = this._closeAdsEsc.bind(this)
    }

    _closeAdsEsc(e) {
        if (e.keyCode === 27) {
            this.props.closeAds()
        }
    }

    render() {
        const { adsPopup, closeAds, language } = this.props
        const data = adsPopup.data[0]
        return (
            <Modal isOpen={adsPopup.showModal} toggle={this._closeAdsEsc}>
                <ModalBody>
                    <a
                        title={language.click_ads}
                        href={data.link}
                        target="_blank"
                        style={{
                            textDecoration: 'none',
                            color: '#333'
                        }}>
                        <Row className="pb-2">
                            <Col>
                                <h3 className="text-center">{data.title}</h3>
                            </Col>
                        </Row>
                        <div style={{
                            width: 'calc(100% + 2rem)',
                            backgroundImage: `url(${data.image.normal.url})`,
                            backgroundSize: 'cover',
                            paddingTop: (data.image.normal.height / data.image.normal.width * 100) + '%',
                            margin: '0 -1rem'
                        }} />
                        <Row className="py-3">
                            <Col>
                                <span
                                    dangerouslySetInnerHTML={{ __html: data.content.replace(/\n/g, '<br>') }}
                                    style={{ fontSize: '16px' }}
                                />
                            </Col>
                        </Row>
                    </a>
                    <Row>
                        <Col>
                            <Button
                                color="danger"
                                onClick={closeAds}
                                block>
                                {language.close}
                            </Button>
                        </Col>
                    </Row>
                </ModalBody>
            </Modal>
        )
    }
}

export default AdsPopupModal