import React from 'react';
import { Button, Form, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { connect } from 'react-redux';

class PrescriptionCancelModal extends React.Component {
    state = {
        loading: false,
        form: {
            reason: '',
        },
    };

    onChange = (key) => (event) => {
        this.setState({
            form: {
                ...this.state.from,
                [key]: event.target.value,
            },
        });
    };

    onSubmit = () => {
        this.setState({ loading: true });
        this.props.onSubmit(this.state.form).finally(() => {
            this.setState({
                loading: false,
                form: {
                    reason: '',
                },
            });
        });
    };

    isFormValid = () => {
        return this.state.form.reason.length > 0;
    };

    render() {
        const { isOpen, prescription, language, onClose } = this.props;

        return (
            <>
                <Modal
                    isOpen={isOpen}
                    toggle={onClose}
                    size="lg"
                >
                    <ModalHeader toggle={onClose}>
                        e-Prescription pickup cancellation
                    </ModalHeader>
                    <ModalBody>
                        <Form style={{ maxWidth: 400, margin: 'auto' }}>
                            <div className="font-weight-bold mb-3" style={{ fontSize: 20 }}>
                                Prescription: {prescription && prescription.reference_code}
                            </div>
                            <FormGroup>
                                <Label for="eps-cancel-reason">Reason of reject/cancellation</Label>
                                <Input
                                    type="textarea"
                                    name="eps-cancel-reason"
                                    id="eps-cancel-reason"
                                    placeholder="Reason..."
                                    valid={this.state.form.reason.length > 0}
                                    invalid={!!this.state.form.reason && this.state.form.reason.length < 0}
                                    value={this.state.form.reason}
                                    onChange={this.onChange('reason')}
                                />
                            </FormGroup>
                        </Form>
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            color="danger"
                            onClick={onClose}
                        >
                            {language.close}
                        </Button>
                        <Button
                            color="success"
                            disabled={this.state.loading || !this.isFormValid()}
                            onClick={this.onSubmit}
                        >
                            {language.confirm}
                        </Button>
                    </ModalFooter>
                </Modal>
            </>
        );
    }
}

const mapStateToProps = state => ({
    language: state.language,
});

export default connect(mapStateToProps)(PrescriptionCancelModal);
