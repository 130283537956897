import React from 'react';
import { Modal, ModalBody, Row, Col, Button } from 'reactstrap';

const NotiUserAsCustomer = (props) => {
    const { language, notiUserAsCustomer } = props;
    const { showModal, type, userId, customer } = notiUserAsCustomer;
    const hasCustomerName = !!customer.name;
    return (
        <Modal isOpen={showModal} zIndex={99999}>
            <ModalBody>
                <Row>
                    <Col className="text-center mt-4">
                        <h4>
                            {language.notiUserAsCustomerDesc1}
                            <p className="my-2">{hasCustomerName ? language.notiUserAsCustomerDesc2 : language.notiUserAsCustomerDesc2_1}{!hasCustomerName && language.notiUserAsCustomerDesc3}</p>
                            {hasCustomerName && <p className="mb-2">"<span className="text-secondary">{customer.name}</span>"</p>}
                            {hasCustomerName && language.notiUserAsCustomerDesc3}
                        </h4>
                    </Col>
                </Row>
                <Row className="mt-4 mb-2">
                    <Col className="text-center">
                        <Button
                            color="danger"
                            style={{ minWidth: 90, marginRight: 15 }}
                            onClick={() => props.registerCustomer(userId, type)}
                        >
                            {language.no2}
                        </Button>{' '}
                        <Button
                            color="success"
                            style={{ minWidth: 90 }}
                            onClick={() => props.updateCustomer(userId, customer.id, type)}
                        >
                            {language.yes}
                        </Button>
                    </Col>
                </Row>
            </ModalBody>
        </Modal>
    );
};

export default NotiUserAsCustomer;
