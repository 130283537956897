import React from 'react';
import { Modal, ModalHeader, ModalBody, Row, Col, Button } from 'reactstrap';

class PrescriptionModelModal extends React.Component {
    render() {
        const { show, language, onClose, prescription } = this.props;
        if (!prescription) return null;
        return (
            <Modal isOpen={show} toggle={onClose} style={{ maxWidth: '35%' }}>
                <ModalHeader toggle={onClose}>Prescription model</ModalHeader>
                <ModalBody>
                    <Row>
                        <Col md="12" className="text-center">
                            <h5 className="text-primary mb-3">{prescription.model ? prescription.model.name : 'Name'}</h5>
                            <p>{prescription.model ? prescription.model.description : 'Description'}</p>
                        </Col>
                    </Row>
                    <Row className="mt-3 mb-2">
                        <Col md="12" className="text-center">
                            <Button color="danger" onClick={onClose} style={{ minWidth: 90 }}>
                                {language.close}
                            </Button>
                        </Col>
                    </Row>
                </ModalBody>
            </Modal>
        );
    }
}

export default PrescriptionModelModal;
