import React from 'react';
import Barcode  from 'react-barcode';
import { numberFormat, priceFormat } from 'Utilz/currency';

class SaleReceiptForm extends React.Component {
    constructor(props){
        super(props)
    }

    _renderTable = (details) => {
        let res;
 
        res = details.map((d,index) => {
            let products;

            if(typeof(d.products) !== 'undefined'){
                products = d.products.map((p,index) => {
                    return(
                        <tr key={ p.id.toString() + p.unit.id.toString() + d.reference_code + index.toString() }>
                            <td valign='top' style={{ width: row1 , fontSize: fontSize8 }}></td>
                            {p.name.length >= 18 ?
                            <td valign='top' style={{ width: row2 , fontSize: fontSize5, textAlign: txtLeft }}>{ p.name }</td> :
                            <td valign='top' style={{ width: row2 , fontSize: fontSize8, textAlign: txtLeft }}>{ p.name }</td>}
                            <td valign='top' style={{ width: row4 , fontSize: fontSize8, textAlign: txtRight }}>{ numberFormat(p.quantity) } { p.unit.name }</td>
                            <td valign='top' style={{ width: row5 , fontSize: fontSize8, textAlign: txtRight }}>{ (p.product_is_free === 1) ? <span>FREE</span> : ''}</td>
                            <td valign='top' style={{ width: row6 , fontSize: fontSize8, textAlign: txtRight }}></td>
                        </tr>
                    )
                })
            }

            return(
                <React.Fragment key={`fragment_${d.unit.id}_${d.product.id}_main`}>
                    <tr>
                        <td valign='top' style={{ width: row1 , fontSize: fontSize8, textAlign: txtLeft }}>{ index+1 }</td>
                        {d.product.name.length >= 18 ?
                        <td valign='top' style={{ width: row2 , fontSize: fontSize5, textAlign: txtLeft }}>{ d.product.name }</td> :
                        <td valign='top' style={{ width: row2 , fontSize: fontSize8, textAlign: txtLeft }}>{ d.product.name }</td>}
                        <td valign='top' style={{ width: row4 , fontSize: fontSize8, textAlign: txtRight }}>{ numberFormat(d.sales_quantity) } { d.unit.name }</td>
                        <td valign='top' style={{ width: row5 , fontSize: fontSize8, textAlign: txtRight }}>{ priceFormat(d.price_per_unit) }</td>
                        <td valign='top' style={{ width: row6 , fontSize: fontSize8, textAlign: txtRight }}>{ priceFormat(d.net_amount) }</td>
                    </tr>
                    {products}
                </React.Fragment>
            )

        })

        return res;
    }
    _renderMsg = (settings) => {

        return settings.company.lastSalesReceipt.map((msg,index) => {
            switch(msg.bold_type + msg.size_type){
                case '10': {
                    return <div style={{ fontWeight: fwBold}} key={msg.message + index}>{ msg.message }</div>
                }
                case '01': {
                    return <div style={{ fontSize: fontSize14 }} key={msg.message + index}>{ msg.message }</div>
                }
                case '11': {
                    return <div style={{ fontWeight: fwBold, fontSize: fontSize14 }} key={msg.message + index}>{ msg.message }</div>
                }
                default: {
                    return <div style={{ fontSize: fontSize8 }} key={msg.message + index}>{ msg.message }</div>
                }
            }
        })
    }

    render() {
        
        const { settings, employee, saleReceipt} = this.props
        const showOnBill = (settings.branch.showOnBill) ? settings.branch.showOnBill : {company: true, branch: true, address: true, phone: true,};
        
        return (
            <div style={{ display: 'none' }}>
   
                <div ref='printContent' id='printContent'>
                    { (settings && employee)  &&
                        <div>
                            {showOnBill.company && <h4 style={{
                                textAlign: txtCenter
                                , fontSize: fontSize14
                                , padding
                                , margin
                                , fontFamily
                            }}>{ settings.company.name }</h4>}
                            {showOnBill.branch && <h6 style={{
                                textAlign: txtCenter
                                , fontSize: fontSize11
                                , fontWeight: fwBold
                                , padding
                                , margin
                            }}>{showOnBill.company && 'สาขา'} { settings.branch.name }</h6>}
                            {showOnBill.address && <h6 style={{
                                textAlign: txtCenter
                                , fontSize: fontSize11
                                , padding
                                , margin
                            }}>
                                ที่อยู่ { settings.branch.address }
                            </h6>}
                            {showOnBill.phone && settings.branch.phone_no && <h6 style={{
                                textAlign: txtCenter
                                , fontSize: fontSize11
                                , padding
                                , margin
                            }}>
                                Tel: { settings.branch.phone_no }
                            </h6>}
                        </div>
                    }
                    { (saleReceipt != null) && 
                        <div>
                            {
                                (saleReceipt.abb.reference_code != null) &&
                                <table style={{ width: maxWidth }}>
                                    <tbody>
                                        <tr>
                                            <td style={{ fontSize: fontSize8 }}>ใบกำกับภาษีอย่างย่อ #{ saleReceipt.abb.reference_code }</td>
                                        </tr>
                                        <tr>
                                            <td style={{ fontSize: fontSize8 }}>เลขประจำตัวผู้เสียภาษี { employee.company_tax_id }</td>
                                        </tr>
                                        {
                                            ( saleReceipt.printer_license != null) &&
                                            <tr>
                                                <td style={{ fontSize: fontSize8 }}>POS No. { saleReceipt.printer_license }</td>
                                            </tr>
                                        }
                                    </tbody>
                                </table>
                            }
                            <table style={{ width: maxWidth }}>
                                <tbody>
                                    <tr>
                                        <td style={{ fontSize: fontSize8 }}>หมายเลขใบเสร็จ #{ saleReceipt.reference_code }</td>
                                    </tr>
                                    {
                                        ( saleReceipt.created_at != null ) &&
                                        <tr>
                                            <td style={{ fontSize: fontSize8 }}>เมือวันที่ { saleReceipt.created_at.split(' ')[0] } เวลา { saleReceipt.created_at.split(' ')[1] }</td>
                                        </tr>
                                    }
                                    {saleReceipt.prescription_reference_code && (
                                        <tr>
                                            <td style={{ fontSize: fontSize8 }}>ใบสั่งยา #{saleReceipt.prescription_reference_code}</td>
                                        </tr>
                                    )}
                                    <tr>
                                        <td>
                                            <div style={{ float: 'left', textAlign: 'left' }}>
                                                <span style={{ fontSize: fontSize8 }}>{ saleReceipt.member.name }</span>
                                            </div>
                                            <div style={{ float: 'right', textAlign: 'right' }}>
                                                <span style={{ fontSize: fontSize8 }}>พนักงานขาย {employee.employee.first_name}</span>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <table style={{ fontSize: fontSize11 , width: maxWidth}}>
                                    <thead>
                                        <tr>
                                            <th style={{ width: row1, textAlign: txtLeft }}>#</th>
                                            <th style={{ width: row2, textAlign: txtLeft }}>ชื่อ</th>
                                            <th style={{ width: row4 }}></th>
                                            <th style={{ width: row5, textAlign: txtRight }}>ราคา</th>
                                            <th style={{ width: row6, textAlign: txtRight }}>&nbsp;&nbsp;&nbsp;รวม</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this._renderTable(saleReceipt.details)}
                                    </tbody>
                            </table>

                            <table style={{
                                borderCollapse: 'collapse'
                                , borderSpacing: 0
                                , width: maxWidth
                            }}>
                                <tfoot>

                                    { 
                                        (settings.tax_show === 1 && settings.vat_type != 0) &&
                                        <tr style={{ borderTop: border , fontSize: fontSize8 }}>
                                            <td style={{ textAlign: txtRight, width: footerW }}>ยอดชำระสุทธิ</td>
                                            <td style={{ paddingLeft: paddingL, textAlign: txtRight  }}>{priceFormat(saleReceipt.price)}</td>
                                        </tr>
                                    }

                                    <tr style={{ borderTop: border , fontSize: fontSize8}}>
                                        <td style={{ textAlign: txtRight, width: footerW }}>ส่วนลด</td>
                                        <td style={{ paddingLeft: paddingL, textAlign: txtRight }}>{priceFormat(saleReceipt.total_discount_amount)}</td>
                                    </tr>
                                    <tr style={{ borderTop: border , fontSize: fontSize8}}>
                                        <td style={{ textAlign: txtRight, width: footerW }}>ยอดชำระรวม</td>
                                        <td style={{ paddingLeft: paddingL, textAlign: txtRight  }}>{priceFormat(saleReceipt.total_paid_amount)}</td>
                                    </tr>

                                    {
                                        (settings.tax_show == 1 && saleReceipt.tax_types !== 'no_vat') &&
                                        <tr style={{ borderTop: border , fontSize: fontSize8}}>
                                            <td style={{ textAlign: txtRight, width: footerW }}>ภาษีมูลค่าเพิ่ม { saleReceipt.tax_percentage }%</td>
                                            <td style={{ paddingLeft: paddingL, textAlign: txtRight }}>{priceFormat(saleReceipt.tax_amount)}</td>
                                        </tr>
                                    }
                               
                                    <tr style={{ fontSize: fontSize8}}>
                                        <td style={{ textAlign: txtRight, width: footerW }}>
                                            {
                                                (saleReceipt.payment_types === 'transfer') ? 'โอนเงิน' : 
                                                (saleReceipt.payment_types === 'credit') ? 'บัตรเครดิต' :
                                                (saleReceipt.payment_types === 'thai_qr') ? 'THAI QR' :
                                                (saleReceipt.payment_types === 'alipay') ? 'Alipay' :
                                                (saleReceipt.payment_types === 'wechat_pay') ? 'WeChat Pay' :
                                                (saleReceipt.payment_types === 'line_pay') ? 'Rabbit LINE Pay' :
                                                'เงินสด'
                                            }
                                        </td>
                                        <td style={{ paddingLeft: paddingL, textAlign: txtRight  }}>{priceFormat(saleReceipt.paid_amount)}</td>
                                    </tr>
                                    {
                                        (saleReceipt.credit_charge > 0) &&
                                        <tr style={{  fontSize: fontSize8}}>
                                            <td style={{ textAlign: txtRight, width: footerW }}>ค่าธรรมเนียม </td>
                                            <td style={{ paddingLeft: paddingL, textAlign: txtRight }}>{ priceFormat(saleReceipt.credit_charge)}</td>
                                        </tr>
                                    }
                                    {
                                        (saleReceipt.payment_types === 'cash') &&
                                        <tr style={{ fontSize: fontSize8}}>
                                            <td style={{ textAlign: txtRight, width: footerW }}>เงินทอน</td>
                                            <td style={{ paddingLeft: paddingL, textAlign: txtRight  }}>{priceFormat(saleReceipt.change_amount)}</td>
                                        </tr>
                                    }
                                    {!!saleReceipt.coupon && (
                                        <tr style={{ fontSize: fontSize8}}>
                                            <td style={{ textAlign: txtRight }} colSpan={2}>Coupon code: {saleReceipt.coupon.owner} - {saleReceipt.coupon.code}</td>
                                        </tr>
                                    )}
                                </tfoot>
                            </table>

                            <div style={{ textAlign: txtCenter }}>
                                <div style={{ borderBottom: border, borderTop: border, fontSize: fontSize8 }}>
                                    { this._renderMsg(settings) }
                                </div>
                                {
                                    ( saleReceipt.reference_code != null ) && 
                                    <Barcode value={ saleReceipt.reference_code } style={{ textAlign: txtCenter }} height={50}></Barcode>
                                }      
                                <div style={{ fontFamily: 'Tahoma' }}>
                                    Powered by Arincare.com
                                </div>
                            </div>
                            
                        </div>
                    }
                </div>
            </div>

        )
    }
}

const margin = 0;
const padding = 0; 
const paddingL = '10px'
const fontFamily = 'Tahoma'
const fontSize5 = '14px'
const fontSize8 = '15px';
const fontSize11 = '16px';
const fontSize14 = '18px';
const txtLeft = 'left'
const txtCenter = 'center';
const txtRight = 'right';
const fwBold = 'bold';
const row1 = '2px';
const row2 = '40px';
const row3 = '30px';
const row4 = '45px';
const row5 = '40px';
const row6 = '40px';
const border = '1px solid #808080';
const footerW = '85%';
const maxWidth = '100%';


export default SaleReceiptForm
