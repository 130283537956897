import React from 'react';

import { Row
    , Col } from 'reactstrap';

import { numberFormat, priceFormat } from 'Utilz/currency';

import nestObj from 'tj-nested-obj';

const customRow = {
    padding:'5px 0'
}

const focus_row = {
    "backgroundColor": "#faf2cc"
    , "margin": "0 -15px"
    , "padding": "0 15px"
}

class ACRow extends React.Component{

    render(){

        const { data, headers, childField, isChild, index, isCurrentRow, onClick, isOfflineMode } = this.props;

        let num = Math.floor(12 / headers.length);
        let col = 0;
        let children = nestObj(data,childField)

        let treeRow = (typeof(children) !== 'undefined') ? children.map((child,index) => {
            return <ACRow data={child} headers={this.props.childHeaders} key={ 'child_'+ index }></ACRow>
        }) : undefined;

        let renderCol = headers.map((header) => {
            col = (header.size) ? header.size : num;
            let txt = nestObj(data,header.field);
 
            switch(header.type.toUpperCase()) {
                case "TEXT" : {
                    return(
                        <Col sm={ col } md={ col } lg={ col } key={ 'col_'+header.field+'_'+index } className={'no-padding'}>
                            { txt }
                        </Col>
                    )
                    break;
                }
                case "TEXT-CENTER" : {
                    return(
                        <Col sm={ col } md={ col } lg={ col } key={ 'col_'+header.field+'_'+index } className="text-center no-padding">
                            { txt }
                        </Col>
                    )
                    break;
                }
                case "NUM" : {
                    return(
                        <Col sm={ col } md={ col } lg={ col } key={ 'col_'+header.field+'_'+index } >
                            {numberFormat(txt)}
                        </Col>
                    )
                    break;
                }
                case "INDEX":{
                    return(
                        <Col sm={ col } md={ col } lg={ col } key={ 'col_index_'+ index } className="text-center">
                            { index }
                        </Col>
                    )
                    break;
                }
                case "CUSTOM" : {
                    return(
                        <Col sm={ col } md={ col } lg={ col } key={ 'col_custom_'+ header.title } >
                            {
                                header.renderCell(data)
                            }
                        </Col>
                    )
                    break;
                }
                case "CURRENCY" : {
                    return(
                        <Col sm={ col } md={ col } lg={ col } key={ 'col_'+header.field+'_'+index } >
                            {priceFormat(txt)}
                        </Col>
                    )
                    break;
                }
            }
        })

        const isExpire = !data.products_set_id && !isOfflineMode && data.product && data.product.is_expire;

        return (
            <div style={ (isCurrentRow) ? focus_row : {} } onClick={ () => { if(typeof(onClick) === 'function'){ onClick(index-1) } } }>
                <Row style={ customRow } key={ data.name+'_'+data.id }
                className={`${data.is_allergic || isExpire ? 'table-danger' : ''}`}>
                    { renderCol }
                </Row>
                { typeof(treeRow) !== 'undefined' && treeRow }
            </div>

        )
    }

}

export default ACRow;
