import React from 'react';
import {HotKeys} from 'react-hotkeys';

const keyMap = {
    'showBillModal' : 'ctrl+z',
    'pauseBill': 'ctrl+b',
    'showPauseBill': 'ctrl+[',
    'focusSearchProduct': 'ctrl+q',
    'focusSearchCustomer': 'ctrl+m',
    'showIndexProductInCart': 'ctrl+]',
    'showErrorMsgModal': 'ctrl+i',
    'focusCart': 'ctrl+.',
    'logOut': 'esc'
}

class Global extends React.Component{
    
    render(){
        
        const bindings = {
            'showBillModal' : this.props.showBillModal,
            'pauseBill': this.props.pauseBill,
            'showPauseBill': this.props.showPauseBill,
            'focusSearchProduct': this.props.focusSearchProduct,
            'focusSearchCustomer': this.props.focusSearchCustomer,
            'showIndexProductInCart': this.props.showIndexProductInCart,
            'showErrorMsgModal': this.props.showErrorMsgModal,
            'focusCart': this.props.focusCart,
            'logOut': this.props.logOut
        }

        return(
            <HotKeys keyMap={ keyMap } handlers={ bindings }>
                <div className='root'>
                    {this.props.children}
                </div>  
            </HotKeys>
        )

    }
}

export default Global;