import 'isomorphic-fetch'
import store from '../store'
import { push } from 'react-router-redux'

const API_URL = process.env.API_URL;
const API2_URL = process.env.API2_URL;
const API3_URL = process.env.API3_URL;
const STG_URL_KBANK = process.env.STG_URL_KBANK;

export default class axiosHelper {

    get(url,params,headers,url_ver){
        return request(url,params,headers,'get',url_ver);
    }
    post(url,params,headers,url_ver){
        return request(url,params,headers,'post',url_ver);
    }
    put(url,params,headers,url_ver){
        return request(url,params,headers,'put',url_ver);
    }
    delete(url,params,headers,url_ver){
        return request(url,params,headers,'delete',url_ver);
    }

}

const request = (url,params,headers,method,url_ver) => {

    let baseURL;

    switch(url_ver){
        case 'qr': {
            baseURL = STG_URL_KBANK;
            break;
        }
        case 3: {
            baseURL = API3_URL;
            break;
        }
        case 2 : {
            baseURL = API2_URL;
            break;
        }
        default :{
            baseURL = API_URL;
            break;
        }
    }

    let options = {};

    options['headers'] = headers;
    options['headers']['Accept'] = 'application/json';
    options['headers']['Content-Type'] = 'application/json';
    // options['headers']['Authorization'] = `Bearer ${token}`;
    options['method'] = method;

    if(method == 'post' || method == 'put'){
        options['body'] = JSON.stringify(params);
    }

    return fetch(baseURL+url,options)
    .then(res => { 
        if (res.status === 401) {
            store.dispatch(push('/login'));
            throw res;
        }

        if(res.ok){
            return res.json() 
        }else{
            throw res;
        } 
    },(err) => {
        return Promise.reject(err);
    })

}


