import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import classnames from 'classnames';

import { getCurrentMessages } from 'Redux/reducers/chat';

import {
    popupVoiceCallWidget,
    minimizeVoiceCallWidget,
    acceptCall,
    rejectCall,
    hangUpCall,
    closeVoiceCallWidget,
    checkUserAsCustomer,
    checkCustomerBeforeRegister,
    updateCustomerFromUser,
} from 'Redux/actions';

import CallScreen from 'Components/Form/Telepharmacy/Voice/CallScreen';
import CompletedScreen from 'Components/Form/Telepharmacy/Voice/CompletedScreen';

import '../../../../style/voice-call-widget.css';

const VoiceCallWidget = (props) => {
    const { voice, employee, chatPopup, chatMessages } = props;
    const { popup, screen, status, callSid, patient } = voice;

    const [isCustomer, setIsCustomer] = useState(false);

    useEffect(async () => {
        if (status === 'pending') {
            const is_customer = await props.checkUserAsCustomer(patient.id);
            setIsCustomer(is_customer);
        }
    }, [status]);

    useEffect(() => {
        if (!popup) {
            setIsCustomer(false);
        }
    }, [popup]);

    const checkRegisterOrUpdateCustomer = useCallback((event) => {
        if (!isCustomer) {
            props.checkCustomerBeforeRegister(patient.phone, patient.id, 'voice');
        } else {
            props.updateCustomerFromUser(patient.id, null, 'voice');
        }
    }, [patient, isCustomer]);

    const hasChatMessage = chatMessages.length > 0;

    if (!popup) {
        const hasEmployee = !!employee.employee.id;
        const isClosed = !status && !callSid;

        if (!hasEmployee || isClosed) return null;

        return (
            <div
                className={classnames(
                    'voice-call-widget-tray',
                    !chatPopup && !hasChatMessage
                        ? ''
                        : !chatPopup && hasChatMessage
                        ? 'voice-call-widget_chat_minimize'
                        : chatPopup && hasChatMessage
                        ? 'voice-call-widget_chat_popup'
                        : 'voice-call-widget_chat_popup',
                    {
                        'bg-danger': status === 'pending',
                        'bg-success': status === 'open',
                    }
                )}
                style={{
                    backgroundColor:
                        status === 'pending' ? 'red' : status === 'open' ? 'green' : '',
                    color: status === 'pending' || status === 'open' ? '#fff' : '',
                }}
                onClick={props.popupVoiceCallWidget}
            >
                <i
                    className={classnames('fa fa-phone fa-lg', {
                        flash: status === 'pending',
                    })}
                    style={{
                        marginTop: 3,
                        marginRight: 6,
                    }}
                ></i>{' '}
                {status === 'pending'
                    ? 'มีสายโทรเข้า'
                    : status === 'open'
                    ? 'กำลังปรึกษา...'
                    : 'โทรปรึกษา'}
            </div>
        );
    }
    return (
        <div
            className={classnames(
                'voice-call-widget',
                !chatPopup && !hasChatMessage
                    ? ''
                    : !chatPopup && hasChatMessage
                    ? 'voice-call-widget_chat_minimize'
                    : chatPopup && hasChatMessage
                    ? 'voice-call-widget_chat_popup'
                    : 'voice-call-widget_chat_popup'
            )}
        >
            <div className="voice-call-widget-header">
                <div></div>
                <div className="voice-call-widget_close" onClick={props.minimizeVoiceCallWidget}>
                    <i className="fa fa-minus" title="ย่อหน้าต่าง"></i>
                </div>
            </div>
            <div className="voice-call-widget-body">
                {screen === 'call' ? (
                    <CallScreen
                        voice={props.voice}
                        isCustomer={isCustomer}
                        accept={acceptCall}
                        reject={rejectCall}
                        hangUp={hangUpCall}
                        registerCustomer={checkRegisterOrUpdateCustomer}
                    />
                ) : screen === 'completed' ? (
                    <CompletedScreen
                        voice={props.voice}
                        isCustomer={isCustomer}
                        registerCustomer={checkRegisterOrUpdateCustomer}
                        cancel={props.closeVoiceCallWidget}
                    />
                ) : (
                    ''
                )}
            </div>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        voice: state.voice,
        employee: state.employee,
        chatPopup: state.chat.popup,
        chatMessages: getCurrentMessages(state.chat),
    };
};

export default connect(mapStateToProps, {
    popupVoiceCallWidget,
    minimizeVoiceCallWidget,
    closeVoiceCallWidget,
    checkUserAsCustomer,
    checkCustomerBeforeRegister,
    updateCustomerFromUser,
})(VoiceCallWidget);
