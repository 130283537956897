import React from 'react';
import { Modal, ModalBody, Row, Col, Button } from 'reactstrap';

const NotiNewVersionModal = (props) => {
    const applyUpdate = (e) => {
        e.preventDefault();
        window.location.reload();
    };

    return (
        <Modal isOpen={props.showModal} size="lg" zIndex={9999}>
            <ModalBody className="text-center">
                <Row>
                    <Col>
                        <h5 className="font-weight-bold text-secondary">Arincare POS New Version Released</h5>
                        <h5 className="font-weight-bold text-primary pb-4">Version {process.env.APP_VERSION}</h5>
                        <h6>กรุณาคลิกอัปเดตเวอร์ชั่นเพื่อการใช้งานที่ดีขึ้น</h6>
                    </Col>
                </Row>
                <Button
                    color="success"
                    style={{ marginTop: 15, minWidth: 100 }}
                    onClick={applyUpdate}
                >
                    {props.language.update}
                </Button>
            </ModalBody>
        </Modal>
    );
};

export default NotiNewVersionModal;
