import React from 'react'
import { Container, Row, Col, Card } from 'reactstrap'
import logo from '../images/logo-horizontal.svg'
import { connect } from 'react-redux'
import { getUserLogin
    , backToPos
    , setupCompanyBranch
    , getBranchLogin
    , setCurrentPage
    , checkDuplicateTab } from 'Redux/actions'

import LoginForm from 'Components/Form/StepForm/LoginForm'
import SetupForm from 'Components/Form/StepForm/SetupForm'
import SuccessForm from 'Components/Form/StepForm/SuccessForm'

class LoginPage extends React.Component {
    constructor(props) {
        super(props)
        
        checkDuplicateTab()
    }

    render() {
        const { login } = this.props
        return (
            <div className="setup setup-background">
                <section style={{ paddingTop: '5rem' }}>
                    <Container>
                        <Row className="mb-4">
                            <Col className="text-center">
                                <img src={logo} alt="" width="500px" />
                            </Col>
                        </Row>
                        <Row className="justify-content-center">
                            <Col xs="12" md="8" lg="7">
                                <Card body>
                                    {login.currentPage === 1 ?
                                    <LoginForm
                                        language={this.props.language}
                                        login={this.props.login}
                                        getUserLogin={this.props.getUserLogin}
                                        backToPos={this.props.backToPos}
                                        getBranchLogin={this.props.getBranchLogin}
                                    /> :
                                    login.currentPage === 2 ?
                                    <SetupForm
                                        language={this.props.language}
                                        companies={this.props.companies}
                                        setCurrentPage={this.props.setCurrentPage}
                                        setupCompanyBranch={this.props.setupCompanyBranch}
                                    /> :
                                    <SuccessForm
                                        language={this.props.language}
                                        backToPos={this.props.backToPos}
                                    />}
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </section>
            </div>
        )
    }
}

const mapStateToProps = state => {
    let companies = Object.assign([], state.login.companies)
    return {
        login: state.login,
        companies,
        language : state.language
    }
}

export default connect(mapStateToProps, {
    getUserLogin
    , setupCompanyBranch
    , backToPos
    , getBranchLogin
    , setCurrentPage })(LoginPage)