import axios from 'axios'
import db from '../../utils/db'
import moment from 'moment'
import { push } from 'react-router-redux'
import { notify, removeNotification, addNotification} from 'reapop'
import { addToAwaitingCart, addToSyncErrorMessage } from 'Redux/actions'
import { addLogToCart } from "./shared";
import { OPEN_NOTI_REFRESH } from '../actionTypes'
import * as Sentry from '@sentry/browser'

const API3_URL = process.env.API3_URL
const SYNC_DELAY_TIME = process.env.SYNC_DELAY_TIME
const APP_VERSION = process.env.APP_VERSION

const updateCartFailed = (cart, message) => dispatch => {
    cart = addLogToCart(cart, 'Failed to sync');
    cart = addLogToCart(cart, message, true, false);

    const updateCart = {
        current_status_id: 5,
        old_status_id: cart.current_status_id,
        message,
        sync_at: moment().format('YYYY-MM-DD HH:mm:ss'),
    }

    db.table('carts').update(cart.reference_code, Object.assign(cart, updateCart)).then(() => {
        dispatch(addToSyncErrorMessage(cart.reference_code))
    })
}

const syncFailed = referenceCode => dispatch => {
    dispatch(notify({
        id: 'syncSaleReceipt',
        title: 'ล้มเหลว',
        message: `ซิงค์บิลขาย ${referenceCode} ไม่สำเร็จ`,
        status: 'error',
        dismissible: true,
        dismissAfter: 3000
    }))

    dispatch(removeNotification('pauseCart'))
    dispatch(removeNotification('deleteCart'))
}

export const syncByAxios = (cart) => (dispatch, getState) => {
    setTimeout(async () => {
        const { settings } = getState()
        if (!settings.offline_mode) {
            dispatch(syncSaleReceipt(cart));
        } else {
            if (await db.table('carts').where({ reference_code: cart.reference_code }).count() === 0) {
                await db.table('carts').add(cart).catch(err => {

                    Sentry.setTags({ 
                        cart: cart,
                        sourceCode: 'src/redux/actions/syncSaleReceipt.js, line:55'
                    });

                    console.error(err);
                    if (process.env.ENABLE_NOTIFY_INDEXEDDB_ERROR === 'true') {
                        dispatch({
                            type: OPEN_NOTI_REFRESH,
                            payload: {
                                type: 'indexeddb_error',
                                code: '#20'
                            }
                        });
                    }        
                })
            }

            if (cart.current_status_id === 3) {
                addLogToCart(cart, 'POS is enable offline mode');
            }
        }
    }, 0)
}

export const syncSaleReceipt = (cart) => (dispatch, getState) => {
    const { language } = getState();
    dispatch(addNotification({
        id: 'loadingCheckout',
        message: language.loading_sr,
        status: 'loading',
        dismissible: true,
        dismissAfter: 0
    }));
    db.table('settings').get(0, settings => {
        const { company, branch, token } = settings

        const hasPrinter = settings.printers.find(printer => printer.id === cart.printer.id);
        
        if (!hasPrinter) {
            if (!settings.printers.length) {
                cart.printer = {
                    id: 0,
                    printer_license: null
                }
                cart.printer_license = null
            } else {
                const printerState = getState().printer;
                const choosedPrinter = localStorage.getItem('choosedPrinter') || printerState.selected.id;
                const findPrinter = settings.printers.find(printer => printer.id == choosedPrinter);

                if (!findPrinter) {
                    cart.printer = {
                        id: 0,
                        printer_license: null
                    }
                    cart.printer_license = null
                } else {
                    cart.printer = findPrinter;
                    cart.printer_license = findPrinter.printer_license;
                }
            }
        } else {
            if (cart.printer_license !== hasPrinter.printer_license) {
                cart.printer = hasPrinter;
                cart.printer_license = hasPrinter.printer_license;
            }
        }

        cart.paid_amount = Number(cart.paid_amount);
        cart.is_discount_after_tax = 1
        cart.net_amount_after_discount = cart.total_paid_amount
        cart.pos_version = APP_VERSION
        cart = addLogToCart(cart, 'Syncing to API');
        
        const syncData = JSON.parse(JSON.stringify(cart))
        delete syncData.employee.role

        return axios.post(`${API3_URL}/companies/${company.slug}/branches/${branch.slug}/pos/sales-receipts`, syncData, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        }).then(res => {
            if (res.data.sales_receipt) {
                const salesReceipt = res.data.sales_receipt

                if (salesReceipt.current_status_id === 3) {
                    db.table('carts').delete(salesReceipt.reference_code).then(() => {
                        dispatch(notify({
                            id: 'syncSaleReceipt',
                            title: 'สำเร็จ',
                            message: `ซิงค์บิลขาย ${salesReceipt.reference_code} เรียบร้อยแล้ว`,
                            status: 'success',
                            dismissible: true,
                            dismissAfter: 3000
                        }))
                    })
                } else if (salesReceipt.current_status_id === 1 && salesReceipt.id) {
                    db.table('carts').update(salesReceipt.reference_code, { id: salesReceipt.id }).then(() => {
                        dispatch(addToAwaitingCart(salesReceipt.reference_code))
                    })
                } else if (salesReceipt.current_status_id === 4) {
                    db.table('carts').delete(salesReceipt.reference_code).then(() => {
                        dispatch(removeNotification('deleteCart'))
                    })
                }
            } else {
                console.log('sales_receipt is empty.')
                dispatch(updateCartFailed(cart, 'Failed to sync.'))
                dispatch(syncFailed(cart.reference_code))
            }
            dispatch(removeNotification('loadingCheckout'))
            dispatch(removeNotification('pauseCart'))
        }).catch(err => {
            console.error(err);

            dispatch(removeNotification('loadingCheckout'))
            dispatch(removeNotification('pauseCart'))

            const res = err.response;
            
            if (res && res.status === 422) {
                const invalidKeys = Object.keys(res.data.validation)
                let message = []
                invalidKeys.forEach(key => {
                    message.push('- ' + res.data.validation[key].join(', '))
                })
                dispatch(updateCartFailed(cart, `Failed to sync, validate errors:\n${message.join('\n')}`))
                dispatch(syncFailed(cart.reference_code))
            } else if (res && res.status === 500) {
                if (cart.current_status_id === 1) {
                    dispatch(addToAwaitingCart(cart.reference_code))
                } else {
                    if (res.data.error === 'PDOException') {
                        dispatch(updateCartFailed(cart, 'Database error.'))
                    } else if (res.data.message) {
                        dispatch(updateCartFailed(cart, res.data.message))
                    }
                    
                    dispatch(syncFailed(cart.reference_code))
                }
            } else if (res && res.status === 401) {
                dispatch(updateCartFailed(cart, 'Failed to sync. token expire'))
                dispatch(syncFailed(cart.reference_code))
                dispatch(push('/login'));
            } else {
                dispatch(updateCartFailed(cart, 'Failed to sync.'))
                dispatch(syncFailed(cart.reference_code))
            }
            
        })
    })
}

export const waitDispatchSync = (fn, i) => dispatch => {
    setTimeout(() => dispatch(fn), SYNC_DELAY_TIME*i)
}

export const reSyncOnline = () => dispatch => {
    db.table('carts').toArray().then(carts => {
        carts.filter(cart => cart.current_status_id === 3 || cart.current_status_id === 4).map((item, i) => {
            dispatch(waitDispatchSync(syncSaleReceipt(item), i))
        })
    })
}

export const sendSaleReceipts = () => (dispatch, getState) => {
    const { status_online } = getState().settings
    
    if (status_online) {
        db.table('carts').toArray().then(carts => {
            carts.filter(cart => cart.current_status_id === 3).map((cart, i) => {
                cart = addLogToCart(cart, 'Bulk sync by user');
                dispatch(waitDispatchSync(syncSaleReceipt(cart), i));
            }).length === 0 && dispatch(notify({
                id: 'syncSaleReceipts',
                message: 'ไม่มีรายการบิลขายที่ต้องซิงค์',
                status: 'info',
                dismissible: true,
                dismissAfter: 3000
            }))
        })
    } else {
        dispatch(notify({
            id: 'offlineSync',
            message: 'กรุณาเชื่อมต่ออินเตอร์เน็ต',
            status: 'info',
            dismissible: true,
            dismissAfter: 3000
        }))
    }
}
