export const POPUP_VOICE_CALL_WIDGET = 'POPUP_VOICE_CALL_WIDGET';
export const MINIMIZE_VOICE_CALL_WIDGET = 'MINIMIZE_VOICE_CALL_WIDGET';
export const CLOSE_VOICE_CALL_WIDGET = 'CLOSE_VOICE_CALL_WIDGET';
export const SET_VOICE_CALL_ON = 'SET_VOICE_CALL_ON';
export const SET_VOICE_CALL_STATUS = 'SET_VOICE_CALL_STATUS';
export const SET_VOICE_CALL_SCREEN = 'SET_VOICE_CALL_SCREEN';
export const VOICE_CALL_INCOMING = 'VOICE_CALL_INCOMING';
export const VOICE_CALL_HANG_UP = 'VOICE_CALL_HANG_UP';
export const VOICE_CALL_MISSED = 'VOICE_CALL_MISSED';
export const RECEIVE_VOICE_RECORDING_URL = 'RECEIVE_VOICE_RECORDING_URL';
