import React from 'react';
import {
    Button,
    Card,
    CardBody,
    CardImg,
    Col,
    Label,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Row,
    FormGroup
} from 'reactstrap';
import { connect } from 'react-redux';
import { closeCouponModal, getCouponByCode, useCoupon } from 'Redux/actions';
import { eleFocus } from 'Business/shared';
import moment from 'moment';

import maxmeLogo from '../../images/max_me_logo.png';
import maxmeLogoDisable from '../../images/max_me_logo_disabled.png';
import { max } from 'lodash';

const enableMaxMe = process.env.ENABLE_MAX_ME_CAMPAIGN === 'true';
const parseDate = (dateString, defaultDate) => {
    const date = new Date(dateString);
    return isNaN(date.getTime()) ? new Date(defaultDate) : date;
};

const campaignMaxMeStartDate = parseDate(process.env.MAX_ME_CAMPAIGN_START_DATE + ' 00:00:00', '1970-01-01 00:00:00');
const campaignMaxMeEndDate = parseDate(process.env.MAX_ME_CAMPAIGN_END_DATE + ' 23:59:59', '1970-01-01 23:59:59');

const couponCodePrefixes = {
    MAX_ME: 'PT'
}

const initCouponType = enableMaxMe ? 'MAX_ME' : '-';

const initialState = {
    loading: false,
    input: '',
    isCheck: false,
    checkedCode: '',
    coupon: null,
    couponType: initCouponType,
    couponCodePrefix: couponCodePrefixes[initCouponType] || '',
    error: null,
};


class CouponModel extends React.Component {
    inputRef;
    state = initialState;

    componentWillReceiveProps(nextProps) {
        const { isOpen, cart } = nextProps;

        if (isOpen) {
            setTimeout(() => {
                eleFocus(this.inputRef);
            });

            if (cart.coupon) {
                this.setState({
                    isCheck: true,
                    checkedCode: cart.coupon.code,
                    coupon: cart.coupon,
                });
            } else {
                this.reset();
            }
        } else {
            if (!cart.coupon) {
                this.reset();
            }
        }
    }

    reset() {
        this.setState(initialState);
    }

    handleChange = (event) => {
        this.setState({
            input: event.target.value,
        });
    };

    handleCheck = async (event) => {
        event.preventDefault();
        this.setState({ loading: true });

        const { getCouponByCode } = this.props;
        const { input, couponType, couponCodePrefix} = this.state;
        const code = ( couponCodePrefix ? couponCodePrefix : '' ) + input.trim();

        const { error, coupon } = await getCouponByCode({ code, type: couponType });
        this.setState({
            isCheck: true,
            checkedCode: code,
            coupon: coupon ? coupon : null,
            loading: false,
            error: error ? error : null,
        });
    };

    handleConfirm = () => {
        const { onClose, useCoupon } = this.props;
        const { coupon } = this.state;

        if (!coupon) return;

        onClose();
        useCoupon(coupon);
        this.reset();
    };

    handleChangeCouponType = type => {
        this.setState({ 
            couponType: type ,
            couponCodePrefix: couponCodePrefixes[type] || '',
        });
    };

    render() {
        const { isOpen, onClose, language } = this.props;
        const { loading, input, isCheck, checkedCode, coupon, couponType, couponCodePrefix, error } = this.state;

        return (
            <Modal
                isOpen={isOpen}
                toggle={onClose}
                size="lg"
            >
                <ModalHeader>Coupon Redeem</ModalHeader>
                <ModalBody>
                    <Row className="mb-2">
                        <Col md="12">
                            <Label>Coupon Type</Label>
                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                                <div style={{ width: 103 }}>
                                    <Button
                                        title="Max Me"
                                        color="light"
                                        active={couponType === 'MAX_ME'}
                                        disabled={!enableMaxMe}
                                        style={{ paddingTop: 4, paddingBottom: 0, whiteSpace: 'pre-line', backgroundColor: couponType === 'MAX_ME' ? '' : '#fff', borderColor: '#d3d9df' }}
                                        onClick={() => this.handleChangeCouponType('MAX_ME')}
                                    >
                                        <img src={enableMaxMe ? maxmeLogo : maxmeLogoDisable} alt="Max Me" width="100%" />
                                        <div className="text-center mt-1" style={{ fontSize: 15 }}>
                                            {(()=>{
                                                let today = new Date();
                                                let campaign_start_date = campaignMaxMeStartDate;
                                                let campaign_end_date = campaignMaxMeEndDate;
                                                let res = '';
                                                if (today < campaign_start_date){
                                                    let dd = String(campaign_start_date.getDate()).padStart(2, '0');
                                                    let mm = String(campaign_start_date.getMonth() + 1).padStart(2, '0');
                                                    let yyyy = campaign_start_date.getFullYear();
                                                    res = `เริ่มแคมเปญ\n${dd}/${mm}/${yyyy}`;
                                                } else if (today >= campaign_start_date && today <= campaign_end_date){
                                                    res = 'Max Me';
                                                } else {
                                                    res = 'แคมเปญหมดอายุ';
                                                }
                                                return res;
                                            })()}
                                        </div>
                                    </Button>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <form onSubmit={this.handleCheck}>
                        <Row className="mb-3">
                            <Col xs={2}
                            style={{
                                lineHeight: '36px',
                                fontSize: '15px',
                                color: '#9B9B9B',
                                fontWeight: '400',
                                paddingRight: '0'
                            }}
                            >
                                <Label>
                                    Coupon Code : 
                                </Label>
                            </Col>
                            <Col xs={6}
                            style={{paddingLeft: '0'}}>
                                <div className="input-group">
                                    {
                                        couponCodePrefix?
                                        <div className="input-group-prepend">
                                            <span className="input-group-text">
                                                <i>{couponCodePrefix}</i>
                                            </span>
                                        </div>
                                        :
                                        <></>
                                    }
                                    
                                    <input
                                        className="form-control"
                                        ref={(c) => {
                                            this.inputRef = c;
                                        }}
                                        onChange={this.handleChange}
                                        value={input}
                                        disabled={loading}
                                        required
                                    />
                                </div>
                            </Col>
                            <Col xs={4}>
                                <Button color="primary" block type="submit" disabled={loading}>{loading ? 'Checking...' : 'Check'}</Button>
                            </Col>
                        </Row>
                    </form>
                    {isCheck && !!coupon && (
                        <Card>
                            {!!coupon.thumbail_img && <CardImg src={coupon.thumbail_img.normal.url} />}
                            <CardBody>
                                <h5>Coupon code: {coupon.code}</h5>
                                <h5>Coupon name: {!!coupon.url ? <a href={coupon.url} target="_blank">{coupon.name}</a> : coupon.name}</h5>
                                <h5>Description: {coupon.description ? coupon.description : '-'}</h5>
                                <h5>Coupon owner: {coupon.owner}</h5>
                                <h5>Coupon expired: {moment(coupon.expired_date).format('YYYY-MM-DD')}</h5>
                                <h5>Discount:
                                    {' '}
                                    <span className="text-warning">
                                        {coupon.discount_amount} {coupon.discount_amount_type === 'PERCENTAGE' ? '%' : coupon.discount_amount_type}
                                    </span>
                                </h5>
                                <h5>Quota Limit: {coupon.quota_limit || '-'}</h5>
                            </CardBody>
                        </Card>
                    )}
                    {isCheck && error && (
                        <Card>
                            <CardBody>
                                <h5>Coupon code: "{checkedCode}" is Invalid</h5>
                                <p className="text-danger">{error}</p>
                            </CardBody>
                        </Card>
                    )}
                </ModalBody>
                <ModalFooter>
                    <Button color="danger" onClick={onClose} style={{ minWidth: 80 }}>{language.close}</Button>
                    <Button
                        color="success"
                        disabled={!coupon}
                        style={{ minWidth: 80 }}

                        onClick={this.handleConfirm}
                    >
                        {language.useCoupon}
                    </Button>
                </ModalFooter>
            </Modal>
        );
    }
}

const mapStateToProps = state => ({
    language: state.language,
    isOpen: state.coupon.modalOpen,
    settings: state.settings,
    cart: state.cart,
});

const mapDispatchToProps = {
    onClose: closeCouponModal,
    useCoupon,
    getCouponByCode,
};

export default connect(mapStateToProps, mapDispatchToProps)(CouponModel);
