import React from 'react'
import { Modal, ModalHeader, ModalBody, Alert, FormGroup, Button } from 'reactstrap'
import classnames from 'classnames'

class EmployeeModal extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            username: '',
            password: '',
            currentLanguage: localStorage.getItem('language') || 'th'
        }
        this._onChange = this._onChange.bind(this)
        this._onSubmit = this._onSubmit.bind(this)
        this._switchLanguage = this._switchLanguage.bind(this)
    }

    componentDidMount() {
        if (this.refs.employeeInput) {
            this.refs.employeeInput.focus();
        }
    }

    _onChange(e) {
        this.setState({ [e.target.name]: e.target.value })
    }

    _onSubmit(e) {
        e.preventDefault()
        if (this.state.username.trim() && this.state.password.trim()) {
            this.props.employeeCheckin(this.state)
            this.setState({ username: '', password: '' })
        }
    }

    _switchLanguage(language) {
        this.props.switchLanguage(language)
        this.setState({ currentLanguage: language })
    }

    render() {
        const { username, password, currentLanguage } = this.state
        const { showEmployeeModal, employee, language, settings } = this.props
        const select = currentLanguage == 'th' ? 'en' : 'th'
        return (
            <Modal isOpen={showEmployeeModal} className={this.props.className}>
                <ModalHeader className="employee-header">{language.checkin_title} 
                    <span
                        className="float-right link"
                        onClick={this.props.toggleOffline}
                        title={settings.offline_mode ? language.disable_offline_mode : language.enable_offline_mode}>
                        <i className={classnames('fa fa-lg', {
                            'fa-toggle-off': !settings.offline_mode,
                            'fa-toggle-on': settings.offline_mode
                        })}></i>
                    </span>
                </ModalHeader>
                <ModalBody className="px-5">
                    {employee.error && <Alert color="danger">{language.checkin_error}</Alert>}
                    <form onSubmit={this._onSubmit}>
                        <FormGroup>
                            <label className="float-left">{language.employee_id}</label>
                            <span className="float-right link" onClick={() => this._switchLanguage(select)}>{currentLanguage == 'th' ? 'EN' : 'TH'}</span>
                            <input
                                type="text"
                                className="form-control"
                                name="username"
                                value={username}
                                onChange={this._onChange}
                                placeholder={language.employee_id}
                                ref='employeeInput'
                            />
                        </FormGroup>
                        <FormGroup>
                            <label>{language.password}</label>
                            <input
                                type="password"
                                className="form-control"
                                name="password"
                                value={password}
                                onChange={this._onChange}
                                placeholder={language.password}
                            />
                        </FormGroup>
                        <FormGroup>
                            <Button
                                color="primary"
                                disabled={employee.loading}>
                                {employee.loading ? `${language.checking}...` : language.login}
                            </Button>
                            {!settings.offline_mode && <a className="btn btn-link float-right" href="/login">{language.change_settings}</a>}
                        </FormGroup>
                    </form>
                </ModalBody>
            </Modal>
        )
    }
}

export default EmployeeModal