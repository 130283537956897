import React, { Fragment } from 'react';
import { Badge, Button, Card, CardBody, CardHeader, Col, Modal, ModalBody, Row, Table } from 'reactstrap';
import { connect } from 'react-redux';
import classnames from 'classnames';
import { displayPriceEp } from 'Redux/actions';

import PrescriptionModelModal from 'Components/Modal/PrescriptionModelModal';

class PrescriptionInfo extends React.Component {
    state = {
        isImageOpen: false,
        imageSrc: null,
        showModelModal: false,
    };

    openImage = (imageSrc) => () => {
        this.setState({
            isImageOpen: true,
            imageSrc: imageSrc,
        });
    };

    onCloseImage = () => {
        this.setState({
            isImageOpen: false,
        });
    };

    render() {
        const { prescription, language, hideData, showPatientName } = this.props;
        const { isImageOpen, imageSrc } = this.state;

        if (!prescription) return null;
        return (
            <>
                <Row className="mb-2">
                    <Col
                        xs={12}
                    >
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <div style={{ fontSize: 25 }}>{prescription.reference_code}</div>
                            <div style={{ marginLeft: 10 }}>
                                <span>สถานะใบสั่งยา: </span>
                                <span
                                    className={classnames('badge', {
                                        'badge-danger': prescription.status === 'PICK_UP_LOCATION_SELECTED',
                                        'badge-warning': prescription.status === 'READY_FOR_PICK_UP',
                                        'badge-success': prescription.status === 'DISPENSED',
                                    })}
                                >
                                    {language.prescriptionStatus[prescription.status]}
                                </span>
                            </div>
                        </div>
                    </Col>
                </Row>
                <Card className="mb-2">
                    <Table style={{ marginBottom: 0 }}>
                        <thead>
                            <tr>
                                <th>{language.product}</th>
                                <th>{language.ingredient}</th>
                                <th>{language.dosage}</th>
                                <th>{language.price}</th>
                                <th>{language.quantity}</th>
                                <th>{language.unit}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {prescription.details.map((detail) => (
                                <Fragment key={detail.id}>
                                    <tr>
                                        <td>{detail.name ? detail.name: ''} {!detail.name && detail.product ? detail.product.name : '-'}</td>
                                        <td>{detail.ingredient ? detail.ingredient.name : '-'}</td>
                                        <td>{prescription.category == 'lm'  ? '-': detail.dosage || '-'}</td>
                                        <td>{displayPriceEp(detail, prescription)}</td>
                                        <td>{detail.quantity}</td>
                                        <td>{detail.unit ? detail.unit.name : ''}</td>
                                    </tr>
                                    <tr key={detail.id}>
                                        <td colSpan={99}>{language.note}: {detail.note || '-'}</td>
                                    </tr>
                                </Fragment>
                            ))}
                        </tbody>
                    </Table>
                </Card>
                <Row className="mb-2">
                    <Col xs={6} className="pr-1">
                        <Card>
                            <CardHeader>ข้อมูลผู้ส่งใบสั่งยา</CardHeader>
                            <Table className="mb-0">
                                <tbody>
                                    <tr>
                                        <td>สั่งยาโดย แพทย์ / เภสัช</td>
                                        <td>{prescription.prescriber_first_name} {prescription.prescriber_last_name}</td>
                                    </tr>
                                    <tr>
                                        <td>เบอร์ติดต่อผู้สั่งยา</td>
                                        <td>{prescription.prescriber_phone_number || '-'}</td>
                                    </tr>
                                    <tr>
                                        <td>สถานที่ส่งโดย</td>
                                        <td>{prescription.prescriber_company_name}</td>
                                    </tr>
                                    <tr>
                                        <td>เบอร์ติดต่อสถานที่</td>
                                        <td>{prescription.prescriber_company_phone_number || '-'}</td>
                                    </tr>
                                    <tr>
                                        <td>Model สปสช.</td>
                                        <td>
                                            <a style={prescriptionModelStyle} onClick={() => this.setState({ showModelModal:true })}>{prescription.model ? prescription.model.name : prescription.model_no}</a>
                                        </td>
                                    </tr>
                                </tbody>
                            </Table>
                        </Card>
                    </Col>
                    <Col xs={6} className="pl-1">
                        <Card>
                            <CardHeader>
                                ข้อมูลคนไข้
                            </CardHeader>
                            <Table className="mb-0">
                                <tbody>
                                    <tr>
                                        <td>ชื่อผู้ป่วย</td>
                                        {(!hideData) ? (
                                            <td>
                                                {prescription.patient_first_name} {prescription.patient_last_name}
                                                {
                                                    (prescription.prescriber_company_name.toLowerCase() == 'lineman' || prescription.category.toLowerCase() == 'lm') ? (
                                                        <span className="btn btn-info" onClick={showPatientName} style={{'padding': '0 10px', 'margin': '0 5px', 'borderRadius': '15px'}}>Patient</span>
                                                    ): ('')
                                                }
                                            </td>
                                        ) : (
                                            <td className="text-muted">ข้อมูลถูกซ่อน</td>
                                        )}
                                    </tr>
                                    <tr>
                                        <td>แพ้ตัวยา</td>
                                        <td>{prescription.allergic_to_ingredients.map((item) => (
                                            <Badge key={item.id} className="mr-1">
                                                {item.name}
                                            </Badge>
                                        ))}</td>
                                    </tr>
                                </tbody>
                            </Table>
                        </Card>
                        <Card className="mt-2">
                            <CardHeader>
                                {language.pickup_drug}: {prescription.prescription_pickup_types && prescription.prescription_pickup_types.name ? prescription.prescription_pickup_types.name : '-'}
                            </CardHeader>
                            <Table className="mb-0">
                                <tbody>
                                    {prescription.pickup_type === 'MESSENGER_PICKUP' && <React.Fragment>
                                        <tr>
                                            <td width="200">{language.messenger_info}</td>
                                            <td>{prescription.messenger_name || '-'}</td>
                                        </tr>
                                        <tr>
                                            <td width="200">{language.messenger_phone}</td>
                                            <td>{prescription.messenger_phone || '-'}</td>
                                        </tr>
                                    </React.Fragment>}
                                    <tr>
                                        <td width="200">{language.pickup_note}</td>
                                        <td>{prescription.address_note || '-'}</td>
                                    </tr>
                                </tbody>
                            </Table>
                        </Card>
                    </Col>
                </Row>
                {prescription.images.length > 0 && <Card className="mb-2">
                    <CardHeader>{language.prescription_image}</CardHeader>
                    <CardBody className="px-2 py-1">
                        <Row className="my-2" noGutters={true}>
                            {prescription.images.map((image) => (
                                <Col xs={2} className="px-1" key={image.id}>
                                    <Card
                                        style={{ cursor: 'pointer' }}
                                        onClick={this.openImage(image.original.url)}
                                    >
                                        <div style={{
                                            width: '100%',
                                            paddingTop: '100%',
                                            backgroundImage: 'url(' + image.normal.url + ')',
                                            backgroundSize: 'cover',
                                            backgroundPosition: 'center',
                                        }} />
                                    </Card>
                                </Col>
                            ))}
                            <Modal
                                className="new-modal"
                                isOpen={isImageOpen}
                                toggle={this.onCloseImage}
                                size="lg"
                                style={{ maxWidth: '80%', minWidth: 800 }}
                            >
                                <ModalBody className="text-center">
                                    {isImageOpen && <img src={imageSrc} style={{ maxWidth: '100%' }} />}
                                </ModalBody>
                            </Modal>
                        </Row>
                    </CardBody>
                </Card>}
                <Card className="mb-2">
                    <CardHeader>Note</CardHeader>
                    <CardBody>
                        จำนวนครั้งที่รับยา: {prescription.complete_refill_count} / {prescription.maximum_refill}
                        <br />
                        บันทึก แพทย์ / เภสัช: {prescription.prescriber_note || '-'}
                        {!!prescription.coupon_code && (
                            <>
                                <br />
                                Coupon code: {prescription.coupon_code}
                            </>
                        )}
                    </CardBody>
                </Card>
                {!!prescription.fill_records && !!prescription.fill_records.length && (
                    <Card className="mb-2">
                        <CardHeader>Fill records</CardHeader>
                        <Table style={{ marginBottom: 0 }}>
                            <tbody>
                                {prescription.fill_records.map((record) => (
                                    <tr key={record.id}>
                                        <td>{record.sales_receipt ? record.sales_receipt.reference_code : '-'}</td>
                                        <td>{record.pharmacy_employee.first_name} {record.pharmacy_employee.last_name}</td>
                                        <td>{record.created_at}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </Card>
                )}
                <PrescriptionModelModal
                    show={this.state.showModelModal}
                    language={language}
                    prescription={this.props.prescription}
                    onClose={() => this.setState({ showModelModal: false })}
                />
            </>
        );
    }
}

const prescriptionModelStyle = {
    color: '#007bff',
    cursor: 'pointer',
}

const mapStateToProps = state => ({
    language: state.language,
});

export default connect(mapStateToProps)(PrescriptionInfo);
