import React from 'react'
import { Row, Col, Button } from 'reactstrap'

import TypeaheadGroup from 'Components/Input/TypeaheadGroup'

class AllergyInfoForm extends React.Component {
    render() {
        const { language
                , customer
                , addAllergyInfo
                , removeAllergyInfo
                , handleAllergyChange
                , getAllergy
                , clearAllergy } = this.props
        return (
            <div>
                <h4 className="mb-3">{language.allergic_info}</h4>
                <Row>
                    <Col sm="4">
                        <TypeaheadGroup
                            language={language}
                            label={language.product_allergic}
                            allergic={customer.data.allergic_to_products}
                            path="allergic_to_products"
                            valueName="product_name"
                            idName="product_id"
                            addAllergyInfo={addAllergyInfo}
                            removeAllergyInfo={removeAllergyInfo}
                            handleAllergyChange={handleAllergyChange}
                            getAllergy={getAllergy}
                            clearAllergy={clearAllergy}
                        />
                    </Col>
                    <Col sm="4">
                        <TypeaheadGroup
                            language={language}
                            label={language.generic_name}
                            allergic={customer.data.allergic_to_ingredients}
                            path="allergic_to_ingredients"
                            valueName="ingredient_name"
                            idName="ingredient_id"
                            addAllergyInfo={addAllergyInfo}
                            removeAllergyInfo={removeAllergyInfo}
                            handleAllergyChange={handleAllergyChange}
                            getAllergy={getAllergy}
                            clearAllergy={clearAllergy}
                        />
                    </Col>
                    <Col sm="4">
                        <TypeaheadGroup
                            language={language}
                            label={language.customer_diseases}
                            allergic={customer.data.customer_diseases}
                            path="customer_diseases"
                            valueName="disease_name"
                            idName="disease_id"
                            addAllergyInfo={addAllergyInfo}
                            removeAllergyInfo={removeAllergyInfo}
                            handleAllergyChange={handleAllergyChange}
                            getAllergy={getAllergy}
                            clearAllergy={clearAllergy}
                        />
                    </Col>
                </Row>
            </div>
        )
    }
}

export default AllergyInfoForm