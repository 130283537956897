import React from 'react'
import { connect } from 'react-redux'

import { showBillModal
, pauseBill
, showPauseBill
, focusSearchProduct
, focusSearchCustomer
, showIndexProductInCart
, showErrorMsgModal
, focusCart
, employeeLogout } from 'Redux/actions';

import Global from 'Components/Shortcuts/Global';

class GlobalShortcut extends React.Component{

    render(){

        return (
            <Global
                showBillModal= { this.props.showBillModal }
                pauseBill= { this.props.pauseBill }
                showPauseBill= { this.props.showPauseBill }
                focusSearchProduct= { this.props.focusSearchProduct }
                focusSearchCustomer= { this.props.focusSearchCustomer }
                showIndexProductInCart= { this.props.showIndexProductInCart }
                showErrorMsgModal= { this.props.showErrorMsgModal }
                focusCart= { this.props.focusCart }
                logOut={ this.props.employeeLogout }
            >
                { this.props.children }
            </Global>
        )
    }
}

const mapStateToProps = state => {
    return {
       modal:  {...state.modal}
    }
}

export default connect(mapStateToProps,
     { showBillModal
        , pauseBill
        , showBillModal
        , focusSearchProduct
        , focusSearchCustomer
        , showIndexProductInCart
        , showErrorMsgModal
        , showPauseBill
        , focusCart
        , employeeLogout
    })(GlobalShortcut)
