import {
    CHECKOUT_LOADING
    , RECEIVE_ABB
    , SALE_RETURN_LOADING
    , CLOSE_SALE_RETURN
    , FAILURE_ABB
    , REQUEST_SETTINGS
    , RECEIVE_SETTINGS
    , QR_PAYMENT_OPEN
    , QR_PAYMENT_ERROR
    , QR_LOADING
    , SYNC_SALE_RETURN
    , QR_REFRESH
    , QR_INQUIRE
    , QR_INQUIRE_ERROR
    , ADD_RETAIL_UNIT
    , SET_ALL_UNITS
    , LINE_PAY_REQUEST
    , LINE_PAY_RECEIVE
    , CHECKOUT_SUCCESS
    , CHECKOUT_FAILURE
} from '../actionTypes'

const initialState = {
    checkout: false
    , saleReturn: false
    , loadSettings: false
    , qrRefresh: false
    , qrInquire: false
    , addRetailUnit: false
}

export default (state=initialState, { type, payload }) => {
    switch (type) {
        case ADD_RETAIL_UNIT:
            return Object.assign({}, initialState,{ addRetailUnit: true});
        case QR_INQUIRE:
            return Object.assign({},initialState,{qrInquire: true});
        case QR_REFRESH:
            return Object.assign({},initialState,{qrRefresh: true});
        case SALE_RETURN_LOADING:
            return Object.assign({},initialState, { saleReturn: true });
        case QR_LOADING:
        case LINE_PAY_REQUEST:
        case CHECKOUT_LOADING: 
            return Object.assign({},initialState, { checkout: true });
        case SET_ALL_UNITS:
        case QR_PAYMENT_OPEN:
        case QR_PAYMENT_ERROR:
        case QR_INQUIRE_ERROR:
        case LINE_PAY_RECEIVE:
        case RECEIVE_SETTINGS:
        case FAILURE_ABB:
        case SYNC_SALE_RETURN:
        case RECEIVE_ABB:
        case CHECKOUT_SUCCESS:
        case CHECKOUT_FAILURE:
            return Object.assign({},initialState);
        case REQUEST_SETTINGS:
            return Object.assign({},initialState, { loadSettings: true })
        default:
            return state
    }
}