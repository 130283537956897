import { LOAD_AWAITING_CART
    , TOGGLE_AWAITING_CART
    , DELETE_AWAITING_CART
    , ADD_TO_AWAITING_CART
    , COPY_CART_TO_SR
    , ADD_TO_SYNC_ERROR } from '../actionTypes'

const initialState = {
    carts: []
}

export default (state=initialState, { type, payload }) => {
    switch (type) {
        case LOAD_AWAITING_CART:
            return Object.assign(state, { carts: payload })
        case TOGGLE_AWAITING_CART:
            return {
                carts: state.carts.map((cart) => {
                    if(cart.reference_code === payload){
                        return Object.assign({},cart,{
                            current_status_id: 2
                        });
                    }else{
                        return Object.assign({},cart,{
                            current_status_id: 1
                        });
                    }
                })
            }
        case DELETE_AWAITING_CART:
            return {
                carts: state.carts.filter(cart => cart.reference_code !== payload)
            }
        case ADD_TO_AWAITING_CART:
            const index = state.carts.findIndex((cart) => { return cart.reference_code === payload.reference_code });

            if(index > -1){
                return {
                    carts: state.carts.map((cart) => {
                        if (cart.reference_code === payload.reference_code) {
                            cart.details = payload.details
                            cart.current_status_id = 2
                        }
                        return Object.assign({},cart,{
                            current_status_id: 1
                        }); 
                    })
                }
            }else{
                return {
                    carts: [...state.carts, payload]
                }
            }
        case COPY_CART_TO_SR:
            return {
                carts: state.carts.filter(cart => cart.reference_code !== payload.reference_code)
            }
        case ADD_TO_SYNC_ERROR:
            return {
                carts: state.carts.filter(cart => cart.reference_code !== payload.reference_code)
            }
        default:
            return state
    }
}