import React from 'react'
import {
    Row
    , Col
    , Button
 } from 'reactstrap'


 import ACTable from 'Components/DataGrid/Table';

 const DailySummaryForm = ({ settings, daily, language, onClose, focus}) => {
     
    
    return(
        <div className='dailySummaryForm'>
            <div>
                <div className='text-center'>
                    <div className='title-color'><h4>{ settings.company.name}</h4></div>
                    <div className='title-color'><h5>{ settings.branch.name}</h5></div>
                    <div>{ language.daily_summary} { language.date} { daily.date}</div>
                </div>
                <div>
                    <Row>
                        <Col md={4}></Col>
                        <Col md={4} className='text-center'>{ language.daily_transactions}</Col>
                        <Col md={4} className='text-center'>{ language.total_amount}</Col>
                    </Row>
                    <Row>
                        <Col md={4} className='text-right'>{ language.cash}</Col>
                        <Col md={4} className='text-right'>{ daily.cash.transactions} รายการ</Col>
                        <Col md={4} className='text-right'>{ daily.cash.total_amount} บาท</Col>
                    </Row>
                    <Row>
                        <Col md={4} className='text-right'>{ language.transfer}</Col>
                        <Col md={4} className='text-right'>{ daily.transfer.transactions} รายการ</Col>
                        <Col md={4} className='text-right'>{ daily.transfer.total_amount} บาท</Col>
                    </Row>
                    <Row>
                        <Col md={4} className='text-right'>{ language.credit}</Col>
                        <Col md={4} className='text-right'>{ daily.credit.transactions} รายการ</Col>
                        <Col md={4} className='text-right'>{ daily.credit.total_amount} บาท</Col>
                    </Row>
                    <Row>
                        <Col md={4} className='text-right'>THAI QR</Col>
                        <Col md={4} className='text-right'>{ daily.thai_qr.transactions} รายการ</Col>
                        <Col md={4} className='text-right'>{ daily.thai_qr.total_amount} บาท</Col>
                    </Row>
                    {/* <Row>
                        <Col md={4} className='text-right'>Alipay</Col>
                        <Col md={4} className='text-right'>{ daily.alipay.transactions} รายการ</Col>
                        <Col md={4} className='text-right'>{ daily.alipay.total_amount} บาท</Col>
                    </Row>
                    <Row>
                        <Col md={4} className='text-right'>WeChat Pay</Col>
                        <Col md={4} className='text-right'>{ daily.wechat_pay.transactions} รายการ</Col>
                        <Col md={4} className='text-right'>{ daily.wechat_pay.total_amount} บาท</Col>
                    </Row> */}
                    <Row>
                        <Col md={4} className='text-right'>Rabbit LINE Pay</Col>
                        <Col md={4} className='text-right'>{ daily.line_pay.transactions} รายการ</Col>
                        <Col md={4} className='text-right'>{ daily.line_pay.total_amount} บาท</Col>
                    </Row>
                    <Row>
                        <Col md={4} className='text-right'>{ language.total}</Col>
                        <Col md={4} className='text-right'>{ daily.total.transactions} รายการ</Col>
                        <Col md={4} className='text-right'>{ daily.total.total_amount} บาท</Col>
                    </Row>
                </div>
            </div>
            <div className='text-center'>
                <Button onClick={ onClose} color="danger" autoFocus={ focus}>{ language.close}</Button>
            </div>
        </div>
    )
 }

 export default DailySummaryForm;