import React, { useState, useEffect, useCallback } from 'react';
import { Label, Input, FormGroup } from 'reactstrap';

import { getQuickPos } from 'Redux/actions';

const SettingsForm = (props) => {
    const { language, isOpen } = props;
    const [checked, setChecked] = useState(false);

    useEffect(() => {
        if (isOpen) {
            const value = getQuickPos();
            setChecked(value);
        }
    }, [isOpen]);

    const setQuickPos = useCallback((event) => {
        const { checked } = event.target;
        localStorage.setItem('quickPos', checked);
        setChecked(checked);
    });

    return (
        <div>
            <FormGroup check>
                <Label check>
                    <Input type="checkbox" checked={checked} onChange={setQuickPos} />
                    {language.quick_pos}
                </Label>
            </FormGroup>
        </div>
    );
};

export default SettingsForm;
