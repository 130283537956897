import currency from "currency.js";

const defaultOptions = {
    symbol: "",
    precision: 2,
};

export const number = (value) => currency(value, defaultOptions);

export const numberFormat = (value) => {
    return currency(value, { ...defaultOptions, precision: 0 }).format();
};

export const priceFormat = (value = 0, withSymbol = false) => {
    const options = { ...defaultOptions };
    if (withSymbol) options.symbol = "฿";
    return currency(value, options).format();
};
