import React from 'react';

import QRHeader from 'Components/Shared/QRHeader';
import logo_qr from 'Images/logo_qr_payment.png';

const ThaiQRHeader = (props) => {
    return(
        <QRHeader
            logo={logo_qr}
            align="center"
            width={props.width}
        />
    )
}

export default ThaiQRHeader;