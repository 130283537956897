import {
    PAY_WITH_ALIPAY_WECHAT
} from '../actionTypes'

const initialState = {
    terminal_id: 0,
    company_id: 0,
    total_fee: 0,
    buyer_identity_code: '',
    tran_type: '',
    out_tradeno: ''
}

export default (state=initialState, { type, payload }) => {
    switch (type) {
        default:
            return state
    }
}