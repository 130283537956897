import React from 'react';
import { 
    Row
    ,Col
} from 'reactstrap'

import ThaiQRInfoForm from 'Components/Form/ThaiQRInfoForm';
import AlipayWechatSettingForm from 'Components/Form/AlipayWechatSettingForm';
import SelectFromGroup from 'Components/Input/SelectFormGroup';

const arr = [
    {id: 1, name: 'Thai QR', value: 'thaiqr'},
    {id: 2, name: 'Alipay && WeChat', value: 'alipayWechat'},
]

class QRForm extends React.Component
{
    constructor(props){
        super(props);
        this._onChangeQrPayment = this._onChangeQrPayment.bind(this);

        this.state = {
            qrpayment: 'thaiqr'
        }

    }

    _onChangeQrPayment(res){
        this.setState({
            qrpayment: res.target.value
        });
    }

    render(){
        const {qrpayment} = this.state;
        const {language, settings} = this.props;
        return (
            <React.Fragment>
                <Row>
                    <Col md={5}>{language.choose_qr_payment}</Col>
                    <Col md={7}>
                        <SelectFromGroup md="12"                            
                            name="qrpayment"
                            value={qrpayment}
                            onChange={this._onChangeQrPayment}
                            placeholder={language.choose_qr_payment}
                            options={arr}
                        />
                    </Col>
                    <div className="qr_form_border"></div>
                </Row>
                <Row>
                    <Col>
                        { qrpayment === 'thaiqr' ?
                            <ThaiQRInfoForm {...settings}/>
                        : qrpayment === 'alipayWechat' &&
                            <AlipayWechatSettingForm
                                language={language}
                                onSelectAccount={(res) => {console.log(res)}}
                                onSelectTerminal={(res) => {console.log(res)}}
                                onRefreshAccount={() => {console.log('onRefreshAccount')}}
                                onSubmit={() => {console.log('onSubmit')}}
                                onCancel={() => {console.log('onCancel')}}
                            />
                        }
                    </Col>
                </Row>
            </React.Fragment>
        )
    }
}

export default QRForm;




