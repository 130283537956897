export const SEARCH_PRODUCTS = 'SEARCH_PRODUCTS'
export const CLEAR_PRODUCTS = 'CLEAR_PRODUCTS'
export const SELECT_PRODUCT = 'SELECT_PRODUCT'
export const SELECT_PRODUCT_SET = 'SELECT_PRODUCT_SET'
export const NOT_FOUND_PRODUCT = 'NOT_FOUND_PRODUCT'
export const FOCUS_SEARCH_PRODUCT = 'FOCUS_SEARCH_PRODUCT'
export const FOCUS_AMOUNT_PRODUCT = 'FOCUS_AMOUNT_PRODUCT'
export const ADD_STOCK_SET = 'ADD_STOCK_SET'
export const CLOSE_ADD_STOCK_SET = 'CLOSE_ADD_STOCK_SET'
export const DELETE_STOCK_SET = 'DELETE_STOCK_SET'
export const UPDATE_SEARCH_WISHLIST = 'UPDATE_SEARCH_WISHLIST'
export const UPDATE_CART_WISHLIST = 'UPDATE_CART_WISHLIST'
export const ADD_RETAIL_UNIT = 'ADD_RETAIL_UNIT'
export const SUBMIT_RETAIL_UNIT = 'SUBMIT_RETAIL_UNIT'
export const CANCEL_RETAIL_UNIT = 'CANCEL_RETAIL_UNIT'
export const SET_ALL_UNITS = 'SET_ALL_UNITS'
export const SET_FIND_ALL_PRODUCTS = 'SET_FIND_ALL_PRODUCTS'
export const UPDATE_PRODUCT_PAGINATE = 'UPDATE_PRODUCT_PAGINATE'
export const SET_PRODUCT_LOADING = 'SET_PRODUCT_LOADING'
export const OPEN_PRODUCT_ERROR_MODAL = 'OPEN_PRODUCT_ERROR_MODAL'
export const CLOSE_PRODUCT_ERROR_MODAL = 'CLOSE_PRODUCT_ERROR_MODAL'