import {
    SERVICES_CLOSE_MODAL,
    SERVICES_OPEN_MODAL,
    SERVICES_SEARCH_HISTORY_CANCELLATION,
} from '../actionTypes';
import { ServicesModalScreen } from 'Components/Page/Services/constants';

const initialState = {
    modalOpen: false,
    modalScreen: ServicesModalScreen.DELIVERY_MENU,
    modalScreenOrderId: null,
    modalScreenHistorySearchCancellation: false,
};

export default (state = initialState, { type, payload }) => {
    switch (type) {
        case SERVICES_OPEN_MODAL:
            const { modalScreen, modalScreenOrderId } = payload;
            return {
                ...state,
                modalOpen: true,
                modalScreen: modalScreen || state.modalScreen,
                modalScreenOrderId,
            };
        case SERVICES_CLOSE_MODAL:
            return {
                ...state,
                modalOpen: false,
                modalScreenHistorySearchCancellation: false,
            };
        case SERVICES_SEARCH_HISTORY_CANCELLATION:
            return {
                ...state,
                modalScreenHistorySearchCancellation: payload,
            };
        default:
            return state;
    }
};
