import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
import { connect } from 'react-redux';
import { openServicesModal, isEnableServices } from 'Redux/actions';
import React, { useState } from 'react';
import { ServicesModalScreen } from './constants';

const ServicesButton = (props) => {
    const { language, settings, openServicesModal } = props;
    const [open, setOpen] = useState(false);

    if (isEnableServices(settings)) {
        return (
            <Dropdown
                isOpen={open}
                toggle={() => setOpen(!open)}
                className="mb-2"
            >
                <DropdownToggle
                    color="warning text-white"
                    caret
                    style={{ minWidth: 110 }}
                >
                    {language.otherServices} |
                </DropdownToggle>
                <DropdownMenu>
                    <DropdownItem
                        onClick={() => openServicesModal(ServicesModalScreen.DELIVERY_MENU)}
                    >
                        {language.services_delivery}
                    </DropdownItem>
                    <DropdownItem
                        onClick={() => openServicesModal(ServicesModalScreen.HISTORY)}
                    >
                        ประวัติการทำรายการ
                    </DropdownItem>
                </DropdownMenu>
            </Dropdown>
        );
    }

    return null;
};

const mapStateToProps = state => ({
    language: state.language,
    settings: state.settings,
});

const mapDispatchToProps = {
    openServicesModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(ServicesButton);
