import { SET_INVENTORY_VALUE, SET_DETAIL_VALUE, UPDATE_PRODUCT_UNITS, UPDATE_PRODUCT_QUANTITY } from '../actionTypes';
import { setDetailValue } from './detail';
import axiosHelper from 'Redux/middleware/axios';
import { number } from 'Utilz/currency';

import { notify } from 'reapop';

let helper = new axiosHelper();

export const setInventoryValue = (path, value) => dispatch => {
    return dispatch({
        type: SET_INVENTORY_VALUE,
        payload: {
            path,
            value,
        },
    });
};

export const updateProductUnits = payload => dispatch => {
    return dispatch({
        type: UPDATE_PRODUCT_UNITS,
        payload
    });
};

export const updateProductQuantity = payload => dispatch => {
    return dispatch({
        type: UPDATE_PRODUCT_QUANTITY,
        payload
    })
}

export const addInventory = () => (dispatch, getState) => {
    const { company, branch, token } = getState().settings;
    const inventory = getState().inventory;
    const detail = getState().detail;
    const customer = getState().customer;
    const { data: prescription } = getState().prescription;

    const price1 = number(inventory.price1).value;
    const price2 = number(inventory.price2).value;
    const price3 = number(inventory.price3).value;
    const price4 = number(inventory.price4).value;
    const price5 = number(inventory.price5).value;
    
    const data = [
        {
            branch_id: branch.id,
            details: [
                {
                    branch_id: branch.id,
                    cost_per_unit: 0,
                    price1,
                    price2,
                    price3,
                    price4,
                    price5,
                    product_id: inventory.product_id,
                    quantity: inventory.quantity,
                    unit_id: inventory.unit_id,
                    units: detail.product.units
                },
            ],
        },
    ];

    dispatch(setInventoryValue('loading', true));

    dispatch(notify({
        id: 'addInventory',
        message: 'ระบบกำลังทำการเพิ่มสต๊อค...',
        status: 'loading',
        dismissible: true,
        dismissAfter: 10000
    }));

    return helper.post(`/companies/${company.slug}/good_receipts/multiple`, data, {
        'Authorization': `Bearer ${token}`
    }, 3).then(res => {
        if (res.status === 'success') {
            
            const newProductUnit = detail.product.units.map(unit => {
                if (unit.id === inventory.unit_id) {
                    return {
                        ...unit,
                        price1,
                        price2,
                        price3,
                        price4,
                        price5,
                        prices: [1,2,3,4,5].map(priceLevel => ({
                            price_level_id: priceLevel,
                            price: number(inventory[`price${priceLevel}`]).value,
                        }))
                    };
                }
                return unit
            })

            const unit = detail.product.units.find(unit => unit.id === inventory.unit_id);
            const quantityOfSmallestUnit = unit ? unit.pivot.quantity_of_smallest_unit : 0;
            const newRemainingQuantity = number(quantityOfSmallestUnit).multiply(inventory.quantity).value;
        
            dispatch(updateProductUnits(newProductUnit));
            dispatch(updateProductQuantity(newRemainingQuantity));

            if (detail.unit.id === inventory.unit_id) {
                let newUnit = {
                    ...detail.unit,
                    price1,
                    price2,
                    price3,
                    price4,
                    price5,
                    prices: [1,2,3,4,5].map(priceLevel => ({
                        price_level_id: priceLevel,
                        price: number(inventory[`price${priceLevel}`]).value,
                    }))
                };
        
                dispatch({
                    type: SET_DETAIL_VALUE,
                    payload: {
                        path: 'unit',
                        value: newUnit
                    }
                });

                const isLinemanEp = prescription && prescription.category == 'lm';
                const isMedcareEp = prescription && prescription.category == 'mc';
                const isPayInStore = prescription && prescription.model_no == "PAY_IN_STORE";
                
                if (!isLinemanEp && !isMedcareEp && !isPayInStore) {
                    const pricePerUnit = number(inventory[`price${customer.price_level}`]).value;
                    dispatch(setDetailValue('price_per_unit', pricePerUnit));
                }
            }

            dispatch(setInventoryValue('quantity', 0));

            dispatch(notify({
                id: 'addInventory',
                title: 'สำเร็จ',
                message: 'บันทึกข้อมุลเรียบร้อยแล้ว',
                status: 'success',
                dismissible: true,
                dismissAfter: 3000
            }))
        } else {
            dispatch(notify({
                id: 'addInventory',
                title: 'ล้มเหลว',
                message: 'บันทึกข้อมุลไม่สำเร็จ',
                status: 'error',
                dismissible: true,
                dismissAfter: 3000
            }))
        }
    }).catch(err => {
        console.error(err);
        dispatch(notify({
            id: 'addInventory',
            title: 'ล้มเหลว',
            message: 'บันทึกข้อมุลไม่สำเร็จ',
            status: 'error',
            dismissible: true,
            dismissAfter: 3000
        }))
    }).finally(() => {
        dispatch(setInventoryValue('loading', false));
    });
};
