import React from 'react';
import { Col, Row } from 'reactstrap';
import Currency from 'react-currency-formatter';
import { QRCode } from 'react-qrcode-logo';
import Countdown from 'react-countdown-now';
import moment from 'moment';
import Loadable from 'react-loading-overlay';
import img_promptpay from 'Images/promptpay.jpg';
import qrFooter from 'Images/qr_footer.png';
import thaiQRPaymentLogo from 'Images/thai_qr_payment_logo.png';

const QRPaymentForm = (props) => {
    const rendererCountdown = ({ hours, minutes, seconds, completed }) => {
        if (props.loader.qrRefresh) {
            return <Loadable
                active={props.loader.qrRefresh}
                spinner
                color='black'
                spinnerSize='25px' />;
        } else {
            return (
                <span onClick={props.onRefresh} className="link qr-payment-countdown-link">
                    <span className="fa fa-refresh" aria-hidden="true" style={{ margin: '0 15px' }}></span>
                    <span>{minutes}:{seconds}</span>
                </span>
            );
        }
    };

    const rendererInquireQR = () => {
        if (props.loader.qrInquire) {
            return <Loadable
                active={props.loader.qrInquire}
                spinner
                color='black'
                spinnerSize='25px' />;
        } else {
            return (
                <span onClick={props.onInquireQR} className="link qr-payment-inquire-link">
                    <span className="fa fa-search" aria-hidden="true" style={{ margin: '0 15px' }}></span>
                    <span>ตรวจสอบสถานะการจ่ายเงิน</span>
                </span>
            );
        }
    };

    const startTime = new Date(moment.now());
    const endTime = new Date(props.expDt);
    const difference = endTime.getTime() - startTime.getTime();
    const qrTimeout = difference;

    const lang = localStorage.getItem('language') || 'th';

    const sizeScreen = window.innerWidth > 800;
    const sizeQr = sizeScreen ? 350 : 140;

    return (
        <div className="qr-payment-form">
            <div className='qr-payment-content'>
                <div className='text-center'>
                    <img src={img_promptpay} alt="" />
                </div>
                <div className="text-center mb-0">
                    <Row>
                        <Col>
                            <QRCode
                                value={props.qrCode}
                                ecLevel="L"
                                size={sizeQr}
                                padding={0}
                                logoImage={thaiQRPaymentLogo}
                                logoWidth={40}
                                logoHeight={29}
                            />
                        </Col>
                    </Row>
                    <Row className="mt-2">
                        <Col style={{ height: '28px' }}>
                            {rendererInquireQR()}
                        </Col>
                    </Row>
                </div>
                <Row className='qr-payment-bolder'>
                    <Col sm={8}>
                        {lang === 'th' ? props.shopNameTh : props.shopNameEn}
                    </Col>
                    <Col sm={4} className="text-right qr-payment-total-paid-amount">
                        <Currency quantity={Number(props.txnAmount)} currency='THB' symbol=''></Currency>
                    </Col>
                </Row>
                <Row>
                    <Col sm={8}>บัญชี: {props.accountName}</Col>
                    <Col sm={4} className="text-right">บาท(Baht)</Col>
                </Row>
                <Row className="mb-2">
                    <Col sm={8}>Receipt No : {props.reference1}</Col>
                    <Col sm={4} className="text-right">
                        <Countdown
                            date={Date.now() + qrTimeout}
                            renderer={rendererCountdown}
                            onComplete={props.onRefresh}
                        />
                    </Col>
                </Row>
            </div>
            <Row className="qr-payment-footer">
                <Col className="text-center">
                    <img src={qrFooter} alt="" width={400} />
                </Col>
            </Row>
        </div>
    );
};

export default QRPaymentForm;
