import React from 'react';
import {HotKeys} from 'react-hotkeys';

const key = {
    upIndex: 'down'
    , downIndex: 'up'
    , editIndex: 'enter'
    , printIndex: 'p'
    , delIndex: ['del', 'space', 'backspace']
}

class CartShortcuts extends React.Component{
    render(){
        const bindings = {
            downIndex: this.props.downIndex
            , upIndex: this.props.upIndex
            , editIndex: this.props.editIndex
            , printIndex: this.props.printIndex
            , delIndex: this.props.delIndex
        }

        return(
            <HotKeys keyMap={ key } handlers={ bindings }>
                <div className='root'>
                    {this.props.children}
                </div>  
            </HotKeys>
        )

    }
}

export default CartShortcuts;