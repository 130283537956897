import { CHECK_OUT
    , CLOSE_CHECK_OUT
    , SET_CART_VALUE
    , CHECKED_OUT
    , CLOSE_CHECKED_OUT
    , TOTAL_DISCOUNT_AMOUNT
    , TOTAL_PAID_AMOUNT
    , COPY_CART_TO_SR
    , FOCUS_SEARCH_AFTER_CLOSE
    , RECEIVE_ABB
    , CHECKOUT_LOADING
    , ON_BLUR_CHECKEDOUT
    , SWITCH_PAYMENT_TYPE
    , ON_BLUR_TOTAL_PAID_AMOUNT
    , OPEN_DANGER_MED_MODAL
    , FOCUS_PAID_AMOUNT
    , ON_BLUR_PAID_AMOUNT
    , PRESCRIPTION_CLEAR_DATA
    , OPEN_NOTI_REFRESH
    , OPEN_PRINT_SALE_RECEIPT
    , CLOSE_PRINT_SALE_RECEIPT
}
from 'Redux/actionTypes'
import { addNotification, removeNotification, notify } from 'reapop'
import moment from 'moment';
import uuidv4 from 'uuid/v4';
import { number } from 'Utilz/currency';
import print from 'Utilz/print';

import axiosHelper from 'Redux/middleware/axios';

import { printSaleReceipt
    , getDgMed
    , getAbbFailed
    , loadQR
    , payWithLinePay
    , payWithAlipayWechat
    , sendSRLogToApi
    , checkCoupon
    , AddMessageLogToCart
} from 'Redux/actions';
import { getPrescription, hasPrescription } from '../selectors/prescription';
import { isCartSatisfyMedcarePrescription, isMedcarePrescription } from '../../bl/prescription';

const helper = new axiosHelper();

export const checkOut = () => (dispatch, getState) => {
    const { cart: { details, payment_types, total_price }, customer, settings } = getState();
    const { payment } = settings;

    if (hasPrescription(getState())) {
        const prescription = getPrescription(getState())
        if (isMedcarePrescription(prescription)
            && ! isCartSatisfyMedcarePrescription(details, prescription.details)) {
            return dispatch(
                addNotification({
                    id: 1,
                    title: 'รายการยาขาย ไม่ตรงกับรายการยาในใบสั่งยา',
                    message: 'ตรวจสอบรายการยา จำนวน และหน่วย ให้ถูกต้อง',
                    status: 'warning',
                    dismissible: true,
                    dismissAfter: 6000,
                    closeButton: true
                })
            );
        }
    }

    if(details.length === 0){
        return dispatch(addNotification({
            id: 1,
            message: 'ยังไม่มีสินค้าในตะกร้า',
            status: 'warning',
            dismissible: true,
            dismissAfter: 3000
        }));
    }

    if (total_price <= 0) {
        return dispatch(addNotification({
            title: 'ผิดพลาด',
            message: 'ไม่สามารถทำบิลขายราคา 0 บาทได้',
            status: 'error',
            dismissAfter: 3000,
            id: 'cartErrorMsg'
        }))
    }

    const hasAllergic = details.filter(detail => detail.is_allergic === true)

    if (hasAllergic.length > 0) {
        const hasConfirm = confirm('มีรายการสินค้าที่ลูกค้าแพ้ คุณต้องการขายหรือไม่')
        if (!hasConfirm) {
            return;
        }
    }

    if (
        (payment_types === 'transfer' && payment.bank_accounts.length === 0) ||
        (payment_types === 'thai_qr' && !payment.thai_qr) ||
        (payment_types === 'alipay' && !payment.alipay) ||
        (payment_types === 'wechat_pay' && !payment.wechat_pay) ||
        (payment_types === 'line_pay' && !payment.line_pay.activate)
    ) {
        dispatch({
            type: SWITCH_PAYMENT_TYPE,
            payload: {
                bank_account_id: 0,
                paid_amount: 0,
                payment_types: 'cash',
                credit_types: null,
                fee: 0,
                credit_include: null,
                change_amount: 0
            }
        });
    }

    const is_dg_med = getDgMed(details);

    if (is_dg_med.length > 0 && customer.id === null) {
        return dispatch({
            type: OPEN_DANGER_MED_MODAL,
            payload: is_dg_med,
        });
    }

    dispatch(checkApiAlive());
}

const checkApiAlive = () => (dispatch, getState) => {
    const { offline_mode } = getState().settings;
    const { reference_code } = getState().cart;

    if (offline_mode) {
        dispatch(dispatchCheckout());
    } else {
        const uuid = uuidv4();
        const client_time = moment().format('YYYY-MM-DD HH:mm:ss');

        dispatch(notify({
            id: 'checkApiAlive',
            message: 'กำลังดำเนินการ...',
            status: 'loading',
            dismissible: true,
            dismissAfter: 10000
        }))

        return helper.get(`/pos/alive/${uuid}/${client_time}`, {}, {}, 3).then(res => {
            if (res.status === 'OK') {
                const { uuid: uuidRes, client_time: client_timeRes } = res

                if (uuidRes === uuid && client_timeRes === client_time) {
                    dispatch(dispatchCheckout());
                } else {
                    console.error(`uuid or client time does not match sent. (${reference_code})`);
                    return dispatch({
                        type: OPEN_NOTI_REFRESH,
                        payload: {
                            type: 'sync_error',
                            code: '#31',
                        }
                    });
                }
            } else {
                console.error(`Failed to check api alive, server may have some problems. (${reference_code})`);
                return dispatch({
                    type: OPEN_NOTI_REFRESH,
                    payload: {
                        type: 'sync_error',
                        code: '#32',
                    }
                });
            }
        }).catch(err => {
            console.error(err);
            return dispatch({
                type: OPEN_NOTI_REFRESH,
                payload: {
                    type: 'sync_error',
                    code: '#33',
                }
            });
        }).finally(() => {
            dispatch(removeNotification('checkApiAlive'));
        })
    }
}

export const dispatchCheckout = () => (dispatch, getState) => {
    const { cart } = getState();

    if (cart.total_price <= 0) {
        // Bypass check out modal
        return dispatch(checkOutSubmit());
    }

    return dispatch({ type: CHECK_OUT });
}

export const closeCheckOut = () => dispatch => {
    return dispatch({ type: CLOSE_CHECK_OUT })
}

const setCartValue = (path, value) => ({
    type: SET_CART_VALUE,
    payload: {
        path,
        value
    }
})

export const calculateTaxAmount = (cart, discountAmount = 0) => {
    const { tax_types, total_amount, tax_percentage, net_amount } = cart

    let result = 0
    const total = number(net_amount).subtract(discountAmount).value;
    if (tax_types === 'vat_included') {
        result = total;
    } else if (tax_types == 'vat_excluded') {
        const tax_amount = number(tax_percentage).divide(100).multiply(total).value;
        result = number(total).add(tax_amount).value;
    } else if (tax_types == 'no_vat') {
        result = total;
    }
    return result
}

export const calculateAmount = type => (dispatch, getState) => {
    const { total_amount, total_discount_amount, total_paid_amount, net_amount, payment_types, credit_include, total_price} = getState().cart
    const totalDiscountAmount = number(total_discount_amount).value;
    if (type === 'total_discount_amount') {
        if (!total_discount_amount) {
            dispatch({
                type: TOTAL_PAID_AMOUNT,
                payload: {
                    total_paid_amount: net_amount,
                    total_discount_amount: 0
                }
            })
        }

        if (totalDiscountAmount > net_amount) {
            return dispatch(setCartValue('total_discount_amount', 0))
        }

        if ((/^(\d+|\d*[.]\d+)%?$/).test(total_discount_amount)) {
            if ((/\d%$/).test(total_discount_amount)) {
                const totalDiscountAmountPercent = number(totalDiscountAmount).divide(100).multiply(net_amount).value;
                const result = calculateTaxAmount(getState().cart, totalDiscountAmountPercent)
                dispatch({
                    type: TOTAL_PAID_AMOUNT,
                    payload: {
                        total_paid_amount: result,
                        total_discount_amount: totalDiscountAmountPercent
                    }
                })
            } else {
                const result = calculateTaxAmount(getState().cart, totalDiscountAmount)
                dispatch({
                    type: TOTAL_PAID_AMOUNT,
                    payload: {
                        total_paid_amount: result,
                        total_discount_amount: totalDiscountAmount
                    }
                })
            }
        } else {
            const result = calculateTaxAmount(getState().cart, totalDiscountAmount)
            dispatch({
                type: TOTAL_PAID_AMOUNT,
                payload: {
                    total_paid_amount: result,
                    total_discount_amount: totalDiscountAmount
                }
            })
        }
    }
    if (type === 'total_paid_amount' ) {
        const totalPaidAmount = number(total_paid_amount).value;
        const discountAmount = number(net_amount).subtract(total_paid_amount).value;

        if (
            (payment_types === 'credit' && credit_include === 'credit_fee_excluded') ||
            (payment_types === 'line_pay' && credit_include === 'credit_fee_excluded')
        ) {
            return dispatch({
                type: ON_BLUR_TOTAL_PAID_AMOUNT
            })
        } else if (discountAmount < 0) {
            dispatch({
                type: TOTAL_PAID_AMOUNT,
                payload: {
                    total_paid_amount: totalPaidAmount,
                    total_discount_amount: 0
                }
            })
        } else {
            dispatch({
                type: TOTAL_PAID_AMOUNT,
                payload: {
                    total_paid_amount: totalPaidAmount,
                    total_discount_amount: discountAmount,
                }
            })
        }

    }
}

export const calculateChangeAmount = () => (dispatch, getState) => {
    const { total_paid_amount, paid_amount, payment_types} = getState().cart
    const paidAmount = number(paid_amount).value;

    if (paidAmount > total_paid_amount && payment_types === 'cash') {
        const changeAmount = number(paidAmount).subtract(total_paid_amount).value;
        return dispatch(setCartValue('change_amount', changeAmount))
    } else if(payment_types === 'cash'){
        return dispatch(setCartValue('change_amount', 0))
    }
}

export const calculateNetAmount = () => (dispatch, getState) => {
    const { total_paid_amount, paid_amount, payment_types, total_price, credit_charge } = getState().cart
    const paidAmount = number(paid_amount).value;
    const totalPaidAmount = number(total_paid_amount).value;
    const totalIncludeCreditCharge = number(total_price).add(credit_charge).value;
    if (totalPaidAmount > totalIncludeCreditCharge) {
        dispatch(addNotification({
            id: 1,
            message: 'คุณใส่จำนวนเงินมากกว่ายอดที่ต้องชำระ!',
            status: 'warning',
            dismissible: true,
            dismissAfter: 3000
        }))
        return dispatch(setCartValue('total_paid_amount', totalIncludeCreditCharge))
    }
    if (paidAmount > 0 && payment_types === 'cash') {
        const changeAmount = number(paidAmount).subtract(totalPaidAmount).value;
        return dispatch(setCartValue('change_amount', changeAmount));
    }
}

export const inputDigit = (digit) => (dispatch, getState) => {
    const { paid_amount } = getState().cart
    return dispatch(setCartValue('paid_amount', paid_amount === 0 ? digit : paid_amount + String(digit)))
}

export const inputDot = (path) => (dispatch, getState) => {
    const value = getState().cart[path]
    if (!(/\./).test(value)) {
        return dispatch(setCartValue(path, value + '.'))
    }
}

export const setCash = (amount) => dispatch => {
    return dispatch(setCartValue('paid_amount', amount))
}

export const checkOutSubmit = (where) => async (dispatch, getState) => {
    const { cart, settings } = getState();
    const { paid_amount, total_amount } = cart

    if(cart.payment_types === 'thai_qr'){
        return dispatch(loadQR());
    }

    if(cart.payment_types === 'alipay'|| cart.payment_types === 'wechat_pay')
    {
        return dispatch(payWithAlipayWechat());
    }

    if (cart.payment_types === 'line_pay') {
        return dispatch(payWithLinePay());
    }

    if (number(paid_amount).value < total_amount) {
        return dispatch(addNotification({
            id: 1,
            message: 'จำนวนเงินที่จ่ายมาน้อยกว่าราคาสินค้า',
            status: 'warning',
            dismissible: true,
            dismissAfter: 3000
        }))
    }

    if (cart.coupon) {
        const isValid = await dispatch(checkCoupon());
        if (!isValid) return;
    }

    if (!settings.offline_mode && process.env.ENABLE_SR_LOG === 'true') {
        const success = await dispatch(sendSRLogToApi());
        if (!success) return;
    }
    return dispatch(checkedOut());
}

export const closeCheckedOut = () => dispatch => {
    return dispatch({ type: CLOSE_CHECKED_OUT })
}

export const afterCloseCheckedOut = () => dispatch =>{
    return dispatch({
        type: FOCUS_SEARCH_AFTER_CLOSE
    })
}

export const changePaymentType = (type) => (dispatch, getState) => {

    const { settings, cart } = getState();
    const { company, payment } = settings;
    const { total_price} = cart;
    const payment_types = type;
    const { line_pay: linePay } = payment

    switch(type){
        case 'cash': {
            return dispatch({
                type: SWITCH_PAYMENT_TYPE,
                payload: {
                    bank_account_id: 0,
                    paid_amount: 0,
                    payment_types,
                    credit_types: null,
                    fee: 0,
                    credit_include: null,
                    change_amount: 0,
                    total_paid_amount: total_price
                }
            })
            break;
        }
        case 'transfer': {
            const bank_account_id = (settings.payment.bank_accounts.length > 0) ? settings.payment.bank_accounts[0].id : null;
            return dispatch({
                type: SWITCH_PAYMENT_TYPE,
                payload: {
                    bank_account_id,
                    payment_types,
                    credit_types: null,
                    fee: 0,
                    credit_include: null,
                    change_amount: 0,
                    paid_amount: total_price,
                    total_paid_amount: total_price
                }
            })
            break;
        }
        case 'credit': {
            return dispatch({
                type: SWITCH_PAYMENT_TYPE,
                payload: {
                    bank_account_id: 0,
                    payment_types,
                    credit_types: 'master_card',
                    fee: company.fee,
                    credit_include: company.credit_include,
                    change_amount: 0
                }
            })
            break;
        }
        case 'thai_qr': {
            return dispatch({
                type: SWITCH_PAYMENT_TYPE,
                payload: {
                    bank_account_id: 0,
                    paid_amount: total_price,
                    payment_types,
                    credit_types: null,
                    fee: 0,
                    credit_include: null,
                    change_amount: 0,
                    total_paid_amount: total_price
                }
            })
            break;
        }
        case 'alipay':
        case 'wechat_pay': {
            return dispatch({
                type: SWITCH_PAYMENT_TYPE,
                payload: {
                    bank_account_id: 0,
                    paid_amount: 0,
                    payment_types,
                    credit_types: null,
                    fee: 3,
                    credit_include: '',
                    change_amount: 0,
                    total_paid_amount: total_price
                }
            })
            break;
        }
        case 'line_pay': {
            return dispatch({
                type: SWITCH_PAYMENT_TYPE,
                payload: {
                    bank_account_id: 0,
                    paid_amount: total_price,
                    payment_types,
                    credit_types: 'line_pay',
                    fee: linePay.fee_percentage,
                    credit_include: `credit_fee_${linePay.fee_type}`,
                    change_amount: 0,
                    total_paid_amount: total_price
                }
            })
            break;
        }
    }
}

export const onBlurCheckedout = () => dispatch => {
    return dispatch({
        type: ON_BLUR_CHECKEDOUT
    })
}

export const focusPaidAmount = () => dispatch => {
    dispatch({ type: FOCUS_PAID_AMOUNT })
}

export const onBlurPaidAmount = () => dispatch => {
    dispatch({ type: ON_BLUR_PAID_AMOUNT })
}

const getAbb = () => (dispatch, getState) => {
    dispatch({ type: CHECKOUT_LOADING })

    const { settings, cart , employee: mainEmp} = getState();
    const {employee} =  mainEmp;
    const { company, branch, tax_types, token } = settings;

    const url ='/companies/'
    + company.id
    + "/branches/"
    + branch.id
    + "/sales-receipts/"
    + cart.reference_code
    + "/abb/"
    + tax_types
    + "/reserve";

    return helper.get(url,{},{
        Authorization : `Bearer ${token}`,
        Accept : 'application/json'
    },2).then((res) => {
        if (res.abb) {
            dispatch({ type: RECEIVE_ABB, payload: res.abb });
            return { abbFailed: false }
        }
    }).catch(err => {
        console.error(err);
        dispatch(getAbbFailed(cart.reference_code))
        return { abbFailed: true }
    })
}

const checkOutAddPrint = (employee) => dispatch => {
    
    dispatch({
        type: CHECKED_OUT,
        payload: {
            employee
        },
        dispatch
    })

    setTimeout(() => dispatch(printSaleReceipt('checkout')), 600);
}

export const checkedOut = () => (dispatch, getState) => {
    const { cart, prescription ,employee: mainEmp, settings } = getState();
    const { employee } = mainEmp;

    if (prescription.data) {
        cart.prescription_id = prescription.data.id;
        cart.prescription_reference_code = prescription.data.reference_code;
        cart.prescription_pickup_note = prescription.pickupNote;
        cart.prescription_category = prescription.data.category;
        cart.prescriber_full_name = prescription.data.prescriber_full_name;

        if (prescription.data.category == 'mc') {
            cart.medcare_order_reference = prescription.data.messenger_phone;
        }

        dispatch({ type: PRESCRIPTION_CLEAR_DATA });

        if (!cart.customer_id) {
            cart.member = {
                name: `${prescription.data.patient_first_name} ${prescription.data.patient_last_name}`
            }
        }
    }

    dispatch({ type: COPY_CART_TO_SR, payload: cart })

    if ((settings.printBill === "1" || settings.printBill === "" || settings.printBill === null) &&
        (!mainEmp.is_using_abb || settings.tax_types === 'no_vat')) {
            dispatch(AddMessageLogToCart(`POS Checkout [c1] with employee_id is ${employee.id}`))
        dispatch(checkOutAddPrint(employee))
    } else if (settings.printBill === "1" &&
        settings.status_online &&
        mainEmp.is_using_abb &&
        !mainEmp.is_abb_offline &&
        !settings.offline_mode) {
            dispatch(getAbb()).then(res => {
                if (!res.abbFailed) {
                    dispatch(AddMessageLogToCart(`POS Checkout [c2] with employee_id is ${employee.id}`))
                    dispatch(checkOutAddPrint(employee))
                }
            })
    } else if (settings.printBill === "1" &&
        settings.status_online &&
        mainEmp.is_using_abb &&
        mainEmp.is_abb_offline &&
        !settings.offline_mode) {
            dispatch(getAbb()).then(res => {
                if (!res.abbFailed) {
                    dispatch(AddMessageLogToCart(`POS Checkout [c3] with employee_id is ${employee.id}`))
                    dispatch(checkOutAddPrint(employee))
                }
            })
    } else if (settings.printBill === "1" && settings.offline_mode) {
        dispatch(AddMessageLogToCart(`POS Checkout [c4] with employee_id is ${employee.id}`))
        dispatch(checkOutAddPrint(employee))
    } else {
        dispatch(AddMessageLogToCart(`POS Checkout [c5] with employee_id is ${employee.id}`))
        dispatch({
            type: CHECKED_OUT,
            payload: {
                employee: employee
            },
            dispatch
        })
    }
}

export const printDeliveryNoteReceipt = () => dispatch => {
    const onBeforePrint = () => {
        dispatch({ type: OPEN_PRINT_SALE_RECEIPT });
    }

    const onAfterPrint = () => {
        dispatch({ type: CLOSE_PRINT_SALE_RECEIPT });
    }

    print({ id: 'deliveryNoteReceipt', onBeforePrint, onAfterPrint });
}
