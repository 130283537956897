import { REQUEST_CHECKIN
    , RECEIVE_CHECKIN
    , CHECKIN_ERROR
    , EMPLOYEE_LOGOUT
    , ON_BLUR_EMPLOYEE_USER 
} from '../actionTypes'
import { setDefaultPrinter } from 'Redux/actions/shared';
import db from '../../utils/db'
import md5 from 'blueimp-md5'
import bcrypt from 'bcryptjs'
import * as Sentry from '@sentry/browser'
import { notify } from 'reapop'
import axiosHelper from '../middleware/axios';
import { initTelepharmacy, disconnectClient } from './telepharmacy';
//import { getNotifyMarketplaceCartExpire } from './notifications'

let helper = new axiosHelper();

const receiveCheckin = employee => dispatch => {
    dispatch({ type: RECEIVE_CHECKIN, payload: employee })
    //dispatch(getNotifyMarketplaceCartExpire())
    dispatch(setDefaultPrinter());
}

export const employeeCheckin = data => (dispatch, getState) => {
    const { company, branch, token, offline_mode, status_online } = getState().settings

    if (offline_mode) {
        dispatch({ type: REQUEST_CHECKIN })
        try {
            return db.table('employees').toArray(employees => {
                const employee = employees.find(x => String(x.reference_code).toLowerCase() === String(data.username).toLowerCase());
                if(employee && (employee.password === md5(data.password) || bcrypt.compareSync(data.password, employee.password))) {
                    dispatch(receiveCheckin(employee))
                }else {
                    dispatch({ type: CHECKIN_ERROR })
                }
            })
        } catch (err) {
            console.error(err);
            dispatch({ type: CHECKIN_ERROR });
        }
    } else {
        if (status_online) {
            dispatch({ type: REQUEST_CHECKIN })

            return helper.post(`/companies/${company.slug}/branches/${branch.slug}/pos/employee/login`, {
                username: data.username,
                password: data.password
            }, {
                'Authorization': `Bearer ${token}`
            }, 3).then(res => {
                if (res.employee) {
                    dispatch(receiveCheckin(res.employee))
                    if (!offline_mode) dispatch(initTelepharmacy());
                    Sentry.setUser(res.employee)
                    Sentry.setTags({
                        'companyId': res.employee.company_id,
                        'branchId': res.employee.pivot.branch_id,
                        'employeeId': res.employee.employee_id,
                    })
                } else {
                    throw new Error('employee is null.')
                }
            }).catch((err) => {
                console.error(err);
                dispatch({ type: CHECKIN_ERROR })
            })
        } else {
            dispatch(notify({
                id: 'offlineMode',
                message: 'กรุณาเชื่อมต่ออินเตอร์เน็ต หรือเปิดใช้งานโหมดออฟไลน์',
                status: 'info',
                dismissible: true,
                dismissAfter: 3000
            }))
        }
    }
}

export const employeeLogout = () => dispatch => {
    disconnectClient();
    Sentry.setUser(null)
    Sentry.setTags({
        employeeId: null
    })
    return dispatch({ type: EMPLOYEE_LOGOUT })
}

export const onBlurEmployeeUser = () => dispatch => {
    return dispatch({ type: ON_BLUR_EMPLOYEE_USER })
}
