import React from 'react';
import { connect } from 'react-redux';
import { Container } from 'reactstrap';
import Currency from 'react-currency-formatter';
import QRPaymentForm from 'Components/Form/QRPaymentForm';
import ThaiQRHeader from 'Components/Shared/ThaiQRHeader';
import { connectQrWindow, inquireQR, refreshQR } from 'Redux/actions';
import logo from 'Images/full-logo.png';

class QRPage extends React.Component {
    render() {
        const { language, focus, refreshQR, cart, inquireQR, loader, qr } = this.props;

        return (
            <div className='qr-page'>
                {
                    (qr.id === null && cart.net_amount > 0) ? (
                        <div className="total-amount-display">
                            <Container>
                                <div><h2>{language.total_price} : </h2></div>
                                <div className='text-center'>
                                    <h2>
                                        <Currency
                                            Currency
                                            quantity={Number(cart.total_price)}
                                            currency='THB'
                                            symbol=""
                                        />
                                        THB
                                    </h2>
                                </div>
                            </Container>
                        </div>
                    ) : qr.id === null ? (
                        <div className='text-center'>
                            <img src={logo} alt="" />
                        </div>
                    ) : (
                        <div>
                            <div className='header'>
                                <ThaiQRHeader width="150" />
                            </div>
                            <Container>
                                <QRPaymentForm
                                    language={language}
                                    focus={focus}
                                    onRefresh={refreshQR}
                                    onInquireQR={inquireQR}
                                    loader={loader}
                                    {...qr}
                                />
                            </Container>
                        </div>
                    )
                }
            </div>
        );
    }

    componentDidUpdate() {
        const { match: { params: { c } } } = this.props;
        this.props.connectQrWindow(c);
    }

    componentDidMount() {
        const { match: { params: { c } } } = this.props;
        this.props.connectQrWindow(c);
    }
}

const mapStateToProps = state => {
    return {
        qr: state.qr,
        focus: state.focus.qrPaymentModal,
        language: state.language,
        loader: state.loader,
        settings: state.settings,
        cart: state.cart,
    };
};

export default connect(mapStateToProps, {
    refreshQR,
    inquireQR,
    connectQrWindow,
})(QRPage);
