import { eleFocus } from "Business";
import _ from "lodash";
import React from "react";
import { number, numberFormat, priceFormat } from 'Utilz/currency';
import {
    Button,
    ButtonGroup,
    Col,
    Form,
    FormGroup,
    FormText,
    Input,
    Label,
    Modal,
    ModalBody,
    ModalHeader,
    Nav,
    NavItem,
    NavLink,
    Row,
    UncontrolledTooltip,
} from "reactstrap";
import { checkPattern } from "Redux/actions";

class AddToCartModal extends React.Component {
    constructor(props) {
        super(props);

        this.onClickSelectUnit = this.onClickSelectUnit.bind(this);
        this._handleChangeInput = this._handleChangeInput.bind(this);

        this._onSubmit = this._onSubmit.bind(this);
        this._getCost = this._getCost.bind(this);
        this._changeTab = this._changeTab.bind(this);
        this._onClickSetUnit = this._onClickSetUnit.bind(this);
        this.computePricePerUnit = this.computePricePerUnit.bind(this);
        this.initializeDetail = this.initializeDetail.bind(this);

        this.state = {
            isPricePerUnitInitialized: false,
            activeTab: 1,
        };
    }

    onClickSelectUnit = (unit) => {
        this.props.setDetailValue("unit", unit);
        this.props.setDetailValue("unit_id", unit.id);
    };

    _handleChangeInput = (evt) => {
        let { value, name } = evt.target;
        if (value == "" || checkPattern(/^[0-9.\b]+$/, value)) {
            var arr = value.split(".");
            if (arr != undefined && arr.length > 1) {
                value = arr[0] + "." + arr[1].substring(0, 2);
            }
            this.props.setDetailValue(name, value);
        }
    };

    _onSubmit = (e) => {
        e.preventDefault();

        const { detail, initialDetail } = this.props;

        const promise = this.props.addToCart(detail);

        if (promise.type !== "ERROR_ADD_TO_CART") {
            initialDetail();
        }
    };

    _changeTab(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab,
            });

            if (tab === 2) {
                const { product } = this.props.detail;
                this.props.setInventoryValue("product_id", product.id);
                if (product.units.length) {
                    this.props.setInventoryValue(
                        "unit_id",
                        product.units[0].id
                    );
                    if (product.units[0].prices.length) {
                        product.units[0].prices.map((price) =>
                            this.props.setInventoryValue(
                                `price${price.price_level_id}`,
                                price.price
                            )
                        );
                    } else {
                        new Array(5)
                            .fill(0)
                            .map((d, i) =>
                                this.props.setInventoryValue(`price${i + 1}`, 0)
                            );
                    }
                }
            }
        }
    }

    _onClickSetUnit(u) {
        this.props.setInventoryValue("unit_id", u.id);

        const { product } = this.props.detail;
        const unit = product.units.find((unit) => unit.id === u.id);
        if (unit.prices.length) {
            unit.prices.map((price) =>
                this.props.setInventoryValue(
                    `price${price.price_level_id}`,
                    price.price
                )
            );
        } else {
            new Array(5)
                .fill(0)
                .map((d, i) =>
                    this.props.setInventoryValue(`price${i + 1}`, 0)
                );
        }
    }

    _getCost() {
        const {
            product: { costs },
            products,
            unit,
            product_set_type,
        } = this.props.detail;

        if (product_set_type === "1") {
            const cost = products.reduce((prev, curr) => {
                return prev + number(curr.costs.first_expire_lot).multiply(curr.unit.pivot.quantity_of_smallest_unit).value;
            }, 0);

            return cost;
        } else {
            return number(costs.first_expire_lot).multiply(unit.pivot.quantity_of_smallest_unit).value;
        }
    }

    _OnlyNumberKey = (e) => {
        const keyCode = e.keyCode ? e.keyCode : e.which;

        if (keyCode === 13) return;
        if (keyCode < 48 || keyCode > 57) {
            e.preventDefault();
        }
    };

    _checkDefaultValue = (e, defaultValue) => {
        const { name, value } = e.target;
        if (String(value).trim() === '') {
            this.props.setDetailValue(name, defaultValue);
        }
    }

    render() {
        const {
            showModal,
            detail,
            employee,
            isAble,
            onBlur,
            initialDetail,
            language,
            settings,
            inventory,
        } = this.props;
        const { unit, product } = detail;

        const units = product.units.map((u) => {
            const key = "btn_unit_" + u.id + product.id;
            return (
                <Button
                    key={key}
                    onClick={() => {
                        this.onClickSelectUnit(u);
                    }}
                    active={unit.id === u.id}
                    disabled={this.epHasSetPrice}
                >
                    {u.name}
                </Button>
            );
        });

        const smallestUnit = _.minBy(
            product.units,
            (unit) => unit.pivot.quantity_of_smallest_unit
        );

        const remaining_quantity = Math.floor(number(product.remaining_quantity).divide(unit.pivot.quantity_of_smallest_unit).value);
        const disabled =
            !isAble("POS_CHANGE_PRICE") &&
            detail.price_per_unit > 0 &&
            this.state.isPricePerUnitInitialized;

        const cost = this._getCost();
        const isNotProductSet = detail.product_id && !detail.product_set_type;
        const isProductSet = !isNotProductSet;
        const isFree = Boolean(detail.product && detail.product.configuration && detail.product.configuration.is_free);
        const checkBranchRole = (ids = []) =>
            [...ids].includes(employee.branch_role_id);
        const checkBranchRoleSlug = (slug = []) =>
            [...slug].includes(employee.role.slug);
        const showIsExpire =
            !settings.offline_mode && isNotProductSet && product.expire_date;
        return (
            <Modal
                toggle={initialDetail}
                isOpen={showModal}
                className="add-to-cart-modal"
            >
                <ModalHeader
                    toggle={initialDetail}
                    className="add-to-cart-header"
                >
                    {product.name}
                    {
                    /*
                    {product.reference_code && !settings.offline_mode ? (
                        product.wishlist_item ? (
                            <span className="float-right">
                                <i
                                    className="fa fa-check-circle fa-lg mr-4 text-primary link"
                                    id="wishlist"
                                    onClick={() =>
                                        this.props.removeWishList(
                                            product,
                                            "search"
                                        )
                                    }
                                ></i>
                                <UncontrolledTooltip
                                    placement="top"
                                    target="wishlist"
                                >
                                    Remove from Wish List
                                </UncontrolledTooltip>
                            </span>
                        ) : (
                            <span className="float-right">
                                <i
                                    className="fa fa-heart fa-lg fa-lg mr-4 text-danger link"
                                    id="wishlist"
                                    onClick={() =>
                                        this.props.addToWishList(
                                            product,
                                            "search"
                                        )
                                    }
                                ></i>
                                <UncontrolledTooltip
                                    placement="top"
                                    target="wishlist"
                                >
                                    Add to Wish List
                                </UncontrolledTooltip>
                            </span>
                        )
                    ) : (
                        ""
                    )} */
                    }
                </ModalHeader>
                <Nav tabs>
                    <NavItem>
                        <NavLink
                            active={this.state.activeTab === 1}
                            onClick={() => this._changeTab(1)}
                            style={{ cursor: "pointer" }}
                        >
                            {language.sale}
                        </NavLink>
                    </NavItem>
                    {!settings.offline_mode &&
                    checkBranchRoleSlug([
                        "OWNER",
                        "ADMINISTRATOR",
                        "MANAGER",
                    ]) &&
                    isNotProductSet ? (
                        <NavItem>
                            <NavLink
                                active={this.state.activeTab === 2}
                                onClick={() => this._changeTab(2)}
                                style={{ cursor: "pointer" }}
                            >
                                {language.product_settings}
                            </NavLink>
                        </NavItem>
                    ) : (
                        ""
                    )}
                </Nav>
                <ModalBody className="px-5">
                    {this.state.activeTab === 1 && (
                        <Form onSubmit={this._onSubmit}>
                            <FormGroup>
                                <Label>{language.unit}</Label>
                                <br />
                                <ButtonGroup>{units}</ButtonGroup>
                            </FormGroup>
                            <FormGroup>
                                <Label>{language.quantity}</Label>
                                <div className="input-group input-group-lg">
                                    <input
                                        type="number"
                                        className="form-control"
                                        ref="txtAmount"
                                        name="sales_quantity"
                                        value={detail.sales_quantity}
                                        onChange={this._handleChangeInput}
                                        onKeyPress={this._OnlyNumberKey}
                                        onBlur={(e) => {
                                            this._checkDefaultValue(e, 1);
                                            this.props.onBlur();
                                        }}
                                        disabled={this.epHasSetPrice}
                                    />
                                    <div className="input-group-append">
                                        <span className="input-group-text">
                                            {unit.name}
                                        </span>
                                    </div>
                                </div>
                                <div>
                                    <FormText>
                                        <span>
                                            {language.remaining_quantity}:{" "}
                                            {numberFormat(remaining_quantity)} {unit.name}
                                        </span>
                                        {showIsExpire && (
                                            <span
                                                style={{ float: "right" }}
                                                className={
                                                    product.is_expire
                                                        ? "text-danger"
                                                        : ""
                                                }
                                            >
                                                <strong>{`EXP: ${product.expire_date}`}</strong>{" "}
                                                <span style={{ marginLeft: 2 }}>
                                                    <i
                                                        className="fa fa-question-circle"
                                                        style={{ fontSize: 17 }}
                                                        id="product-expire-tooltip"
                                                    ></i>
                                                    <UncontrolledTooltip
                                                        placement="top"
                                                        target="product-expire-tooltip"
                                                    >
                                                        {
                                                            language.product_expire_tooltip
                                                        }
                                                    </UncontrolledTooltip>
                                                </span>
                                            </span>
                                        )}
                                    </FormText>
                                </div>
                            </FormGroup>
                            <FormGroup>
                                <Label>{language.price}</Label>
                                {isNotProductSet &&
                                    checkBranchRole([1, 2, 3]) &&
                                    <>
                                        {unit.prices.map((price) => (
                                            <Button
                                                key={price.price_level_id}
                                                type="button"
                                                style={{
                                                    minWidth: 30,
                                                    marginBottom: 8,
                                                    marginLeft: 12,
                                                }}
                                                title={`${language.price} ${price.price_level_id}`}
                                                color="primary"
                                                size="sm"
                                                onClick={() => {
                                                    this.props.setDetailValue(
                                                        "price_per_unit",
                                                        price.price
                                                    )
                                                    this.props.setDetailValue(
                                                        "is_free",
                                                        0
                                                    )
                                                }}
                                                disabled={this.epHasSetPrice}
                                                outline
                                            >
                                                {price.price}
                                            </Button>
                                        ))}
                                    </>
                                }
                                {isNotProductSet &&
                                    checkBranchRole([1, 2, 3]) &&
                                    unit.prices.length === 0 &&
                                    new Array(5).fill(0).map((d, i) => (
                                        <Button
                                            key={i}
                                            type="button"
                                            className="ml-3"
                                            style={{
                                                minWidth: 30,
                                                marginBottom: 8,
                                            }}
                                            title={`${language.price} ${i + 1}`}
                                            color="primary"
                                            size="sm"
                                            disabled={this.epHasSetPrice}
                                            outline
                                        >
                                            0
                                        </Button>
                                    ))}
                                {isNotProductSet &&
                                    !checkBranchRole([1, 2, 3]) &&
                                    unit.prices.map((price) => (
                                        <Button
                                            key={price.price_level_id}
                                            type="button"
                                            style={{
                                                minWidth: 30,
                                                marginBottom: 8,
                                                marginLeft: 12,
                                            }}
                                            title={`${language.price} ${price.price_level_id}`}
                                            color="primary"
                                            size="sm"
                                            disabled={!checkBranchRole([1, 2, 3]) || this.epHasSetPrice}
                                            outline
                                        >
                                            -
                                        </Button>
                                    ))}
                                {isNotProductSet &&
                                    !checkBranchRole([1, 2, 3]) &&
                                    unit.prices.length === 0 &&
                                    new Array(5).fill(0).map((d, i) => (
                                        <Button
                                            key={i}
                                            type="button"
                                            className="ml-3"
                                            style={{
                                                minWidth: 30,
                                                marginBottom: 8,
                                            }}
                                            title={`${language.price} ${i + 1}`}
                                            color="primary"
                                            size="sm"
                                            disabled={!checkBranchRole([1, 2, 3]) || this.epHasSetPrice}
                                            outline
                                        >
                                            -
                                        </Button>
                                    ))}
                                {isFree &&
                                    <Button
                                        type="button"
                                        style={{
                                            width: 42,
                                            marginBottom: 8,
                                            marginLeft: 12,
                                            lineHeight: 1,
                                            fontSize: 10,
                                        }}
                                        title={`${language.is_free}`}
                                        color="warning"
                                        size="sm"
                                        onClick={() => {
                                            this.props.setDetailValue(
                                                "price_per_unit",
                                                0
                                            )
                                            this.props.setDetailValue(
                                                "is_free",
                                                1
                                            )
                                        }}
                                        disabled={this.epHasSetPrice}
                                        outline
                                        dangerouslySetInnerHTML={{ __html: language.is_free_br }}
                                    />
                                }
                                {checkBranchRoleSlug([
                                    "OWNER",
                                    "ADMINISTRATOR",
                                    "MANAGER",
                                ]) && (
                                    <div className="float-right">
                                        <div id="lbl-tooltip-cost-lot">
                                            {language.cost_lot}
                                        </div>
                                        <UncontrolledTooltip
                                            placement="top"
                                            target="lbl-tooltip-cost-lot"
                                        >
                                            {priceFormat(cost)} / {unit.name}
                                        </UncontrolledTooltip>
                                    </div>
                                )}
                                <div className="input-group input-group-lg">
                                    <Input
                                        className="form-control"
                                        name="price_per_unit"
                                        value={detail.price_per_unit}
                                        onChange={this._handleChangeInput}
                                        onBlur={(e) => this._checkDefaultValue(e, 0)}
                                        disabled={disabled || this.epHasSetPrice || detail.is_free || ( isProductSet && detail.product_set_type == 1 )}
                                    />
                                    <div className="input-group-append">
                                        <span className="input-group-text">
                                            {language.baht}
                                        </span>
                                    </div>
                                </div>
                            </FormGroup>
                            {showIsExpire && product.is_expire && (
                                <FormGroup>
                                    <h6 className="text-danger">
                                        {language.product_expire_noti}
                                    </h6>
                                </FormGroup>
                            )}
                            <FormGroup>
                                <Button color="success">
                                    <i className="fa fa-shopping-cart"></i>{" "}
                                    {language.add_to_cart}
                                </Button>
                            </FormGroup>
                        </Form>
                    )}
                    {this.state.activeTab === 2 && (
                        <Form
                            onSubmit={(e) => {
                                e.preventDefault();
                                if (
                                    confirm(
                                        "คุณแน่ใจที่จะบันทึกสินค้านี้หรือไม่"
                                    )
                                ) {
                                    this.props.addInventory();
                                }
                            }}
                        >
                            <FormGroup>
                                <Label>{language.unit}</Label>
                                <br />
                                <ButtonGroup>
                                    {product.units.map((u) => (
                                        <Button
                                            key={`i${u.id}${product.id}`}
                                            onClick={() =>
                                                this._onClickSetUnit(u)
                                            }
                                            active={
                                                u.id ===
                                                this.props.inventory.unit_id
                                            }
                                        >
                                            {u.name}
                                        </Button>
                                    ))}
                                </ButtonGroup>
                            </FormGroup>
                            <Row>
                                <Col md={12}>
                                    <FormGroup>
                                        <Label>
                                            {language.quantity_to_add_to_stock}
                                        </Label>
                                        <Input
                                            type="number"
                                            value={inventory.quantity}
                                            onChange={(e) =>
                                                this.props.setInventoryValue(
                                                    "quantity",
                                                    e.target.value
                                                )
                                            }
                                            onKeyPress={(e) => {
                                                const keyCode = e.keyCode
                                                    ? e.keyCode
                                                    : e.which;
                                                if (
                                                    keyCode < 48 ||
                                                    keyCode > 57
                                                ) {
                                                    e.preventDefault();
                                                }
                                            }}
                                            placeholder={language.quantity}
                                            required
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row className="mb-3">
                                {new Array(5).fill(0).map((d, i) => (
                                    <Col key={i} className="pr-0">
                                        <FormGroup>
                                            <Label>
                                                {language.price} {i + 1}
                                            </Label>
                                            <Input
                                                value={
                                                    inventory[`price${i + 1}`]
                                                }
                                                onChange={(e) =>
                                                    this.props.setInventoryValue(
                                                        `price${i + 1}`,
                                                        e.target.value
                                                    )
                                                }
                                                onKeyPress={(e) => {
                                                    const keyCode = e.keyCode
                                                        ? e.keyCode
                                                        : e.which;
                                                    if (keyCode === 46) {
                                                        const value =
                                                            inventory[
                                                                `price${i + 1}`
                                                            ];
                                                        if (
                                                            String(
                                                                value
                                                            ).indexOf(".") >
                                                                -1 ||
                                                            (value == "" &&
                                                                String(
                                                                    value
                                                                ).indexOf(
                                                                    "."
                                                                ) === -1)
                                                        ) {
                                                            e.preventDefault();
                                                        }
                                                    } else if (
                                                        keyCode < 46 ||
                                                        keyCode > 57
                                                    ) {
                                                        e.preventDefault();
                                                    }
                                                }}
                                                placeholder={`${
                                                    language.price
                                                } ${i + 1}`}
                                                disabled={this.epHasSetPrice}
                                                required
                                            />
                                        </FormGroup>
                                    </Col>
                                ))}
                            </Row>
                            <FormGroup>
                                <Button
                                    color="success"
                                    disabled={this.props.inventory.loading}
                                >
                                    <i className="fa fa-floppy-o"></i>{" "}
                                    {language.save}
                                </Button>
                            </FormGroup>
                        </Form>
                    )}
                </ModalBody>
            </Modal>
        );
    }

    get isLinemanEp() {
        return (
            this.props.prescription.data &&
            this.props.prescription.data.category == "lm"
        );
    }

    get isMedcareEp() {
        return (
            this.props.prescription.data &&
            this.props.prescription.data.category == "mc"
        );
    }

    get isPayInStore() {
        return (
            this.props.prescription.data &&
            this.props.prescription.data.model_no == "PAY_IN_STORE"
        );
    }

    get epHasSetPrice() {
        return this.isLinemanEp || this.isMedcareEp || this.isPayInStore;
    }

    componentDidUpdate(preProps) {
        setTimeout(() => {
            if (
                this.state.activeTab === 1 &&
                !preProps.focus &&
                this.props.focus
            ) {
                eleFocus(this.refs.txtAmount);
            }
        }, 500);

        // Add
        if (this.props.detail.status === "add") {
            if (this.props.detail.product.id != preProps.detail.product.id) {
                if (this.props.detail.product.id) {
                    if (this.props.detail.product.units.length > 0) {
                        this.initializeDetail();
                    } else if (this.props.detail.product.units.length === 0) {
                        this.props.notiUnitProduct(this.props.detail.product);
                    }
                }
            }
            if (
                this.props.detail.unit.id != preProps.detail.unit.id &&
                this.props.detail.unit.prices
            ) {
                this.computePricePerUnit();
            }
            if (
                preProps.detail.sales_quantity !==
                this.props.detail.sales_quantity
            ) {
                this.computeNetAmount();
            }
            if (
                preProps.detail.price_per_unit !==
                this.props.detail.price_per_unit
            ) {
                this.computeNetAmount();
            }
        }

        // Edit
        if (this.props.detail.status === "edit") {
            if (preProps.detail.product_id !== null) {
                if (this.props.detail.price_per_unit > 0 && !this.state.isPricePerUnitInitialized) {
                    this.setState({
                        isPricePerUnitInitialized: true,
                    });
                }
                if (
                    this.props.detail.unit.id != preProps.detail.unit.id &&
                    this.props.detail.unit.prices
                ) {
                    this.computePricePerUnit();
                }
                if (
                    preProps.detail.sales_quantity !==
                    this.props.detail.sales_quantity
                ) {
                    this.computeNetAmount();
                }
                if (
                    preProps.detail.price_per_unit !==
                    this.props.detail.price_per_unit
                ) {
                    this.computeNetAmount();
                }
            }
        }
    }

    componentWillReceiveProps(nextProps) {
        if (!nextProps.showModal) {
            this.setState({ activeTab: 1 });
        } else {
            if (
                this.props.inventory.activeTab === 1 &&
                nextProps.inventory.activeTab === 2
            ) {
                const { product } = nextProps.detail;
                this.props.setInventoryValue("product_id", product.id);
                if (product.units.length) {
                    this.props.setInventoryValue(
                        "unit_id",
                        product.units[0].id
                    );
                    if (product.units[0].prices.length) {
                        product.units[0].prices.map((price) =>
                            this.props.setInventoryValue(
                                `price${price.price_level_id}`,
                                price.price
                            )
                        );
                    } else {
                        new Array(5)
                            .fill(0)
                            .map((d, i) =>
                                this.props.setInventoryValue(`price${i + 1}`, 0)
                            );
                    }
                }
                this.setState({ activeTab: 2 });
            }
        }
    }

    initializeDetail() {
        this.initializeProduct();
        this.initializeUnit();
    }

    initializeProduct() {
        this.props.setDetailValue("product_id", this.props.detail.product.id);
        this.props.setDetailValue("products", this.props.detail.products);
        this.props.setDetailValue(
            "product_set_id",
            this.props.detail.product_set_id
        );
        this.props.setDetailValue(
            "product_set_type",
            this.props.detail.product_set_type
        );
    }

    initializeUnit() {
        // 1) Find unit from barcode
        let barcodeUnit = [];
        if (!isNaN(this.props.query) && this.props.query) {
            barcodeUnit = this.props.detail.product.units.filter(
                (unit) => unit.barcodes.indexOf(this.props.query) > -1
            );
        }
        if (barcodeUnit.length > 0) {
            this.props.setDetailValue("unit", barcodeUnit[0]);
            this.props.setDetailValue("unit_id", barcodeUnit[0].id);
            return;
        }

        // 2) Use data from prescription if exists
        const pUnit = _.find(this.props.detail.product.units, {
            id: this.props.prescription.unitId,
        });
        if (this.epHasSetPrice && pUnit) {
            this.props.setDetailValue("unit", pUnit);
            this.props.setDetailValue(
                "unit_id",
                this.props.prescription.unitId
            );
            return;
        }

        // 3) Fallback to the smallest unit
        const smallestUnit = _.minBy(
            this.props.detail.product.units,
            (unit) => unit.pivot.quantity_of_smallest_unit
        );
        if (smallestUnit) {
            this.props.setDetailValue("unit", smallestUnit);
            this.props.setDetailValue("unit_id", smallestUnit.id);
        }
    }

    computeNetAmount() {
        const { sales_quantity, price_per_unit } = this.props.detail;
        const netAmount = number(price_per_unit).multiply(sales_quantity).value;
        this.props.setDetailValue("net_amount", netAmount);
    }

    computePricePerUnit() {
        // 1) Use a price that matches customer's price_level
        let price = this.props.detail.unit[
            `price${this.props.customer.price_level}`
        ]
            ? this.props.detail.unit[`price${this.props.customer.price_level}`]
            : 0;

        this.setState({
            isPricePerUnitInitialized: price !== 0,
        });

        // 2) Fallback to use the first non-zero price
        if (!price) {
            for (let i = 1; i <= 5; i++) {
                if (this.props.detail.unit["price" + i] > 0) {
                    price = this.props.detail.unit["price" + i];
                    this.setState({
                        isPricePerUnitInitialized: true,
                    });
                    break;
                }
            }
        }

        this.props.setDetailValue("price_per_unit", price);

        // 2) Use data from prescription
        if (this.epHasSetPrice) {
            const pUnit = _.find(this.props.detail.product.units, {
                id: this.props.prescription.unitId,
            })
            const predicate = {
                product_id: this.props.detail.product_id,
                unit_id: this.props.detail.unit_id,
            }

            if (pUnit) {
                predicate.id = this.props.prescription.detailId;
                predicate.unit_id = this.props.prescription.unitId;
            }

            const pDetail = _.find(this.props.prescription.data.details, predicate);
            // check if receipt ep is arincare use pharmacy_price
            this.props.setDetailValue(
                "price_per_unit",
                pDetail
                    ? this.isMedcareEp
                        ? number(pDetail.pharmacy_price).value
                        : number(pDetail.partner_price).value
                    : price
            );
            this.props.setDetailValue(
                "sales_quantity",
                pDetail ? number(pDetail.quantity).value : 1
            );
        }
    }
}

export default AddToCartModal;
