import { Device } from 'twilio-client';
import Chat from './chat';

class TwilioClient extends Chat {
    constructor() {
        super();
        this._device = new Device();
    }

    setupVoiceClient() {
        const { token } = this.state;
        this._device.setup(token, { debug: false, warnings: true, closeProtection: true });
        this.handleDeviceEvents();
    }

    handleDeviceEvents() {
        this._device.on('ready', (device) => {
            this.$device = device;
            this.emit('deviceReady', device);
        });

        this._device.on('incoming', (connection) => {
            this.$connection = connection;
            this.emit('deviceIncoming', connection);
            this.handleConnectionEvents();
        });

        this._device.on('connect', this.emit.bind(this, 'deviceConnect'));
        this._device.on('disconnect', this.emit.bind(this, 'deviceDisconnect'));
        this._device.on('cancel', this.emit.bind(this, 'deviceCancel'));
        this._device.on('offline', this.emit.bind(this, 'deviceOffline'));
        this._device.on('error', this.emit.bind(this, 'deviceError'));
    }

    handleConnectionEvents() {
        this.$connection.on('accept', this.emit.bind(this, 'connectionAccept'));
        this.$connection.on('reject', this.emit.bind(this, 'connectionReject'));
        this.$connection.on('disconnect', this.emit.bind(this, 'connectionDisconnect'));
        this.$connection.on('cancel', this.emit.bind(this, 'connectionCancel'));
        this.$connection.on('error', this.emit.bind(this, 'connectionError'));
        // this.$connection.on('volume', this.emit.bind(this, 'connectionVolume'));
        // this.$connection.on('mute', this.emit.bind(this, 'connectionMute'));
        // this.$connection.on('reconnecting', this.emit.bind(this, 'connectionReconnecting'));
        // this.$connection.on('reconnected', this.emit.bind(this, 'connectionReconnected'));
        // this.$connection.on('ringing', this.emit.bind(this, 'connectionRinging'));
        // this.$connection.on('sample', this.emit.bind(this, 'connectionSample'));
        // this.$connection.on('warning', this.emit.bind(this, 'connectionWarning'));
        // this.$connection.on('warning-cleared', this.emit.bind(this, 'connectionWarningCleared'));
    }

    async updateVoiceClientToken() {
        const token = await this.getClientToken();
        this._device.updateToken(token);
    }
}

export default TwilioClient;
