import React from 'react';
import {  Row
    , Col
 } from 'reactstrap'

const APP_URL = process.env.APP_URL;

const ThaiQRInfoForm = (props) => {
    const {thai_qr} = props.payment;
    return (
        <div className="thaiQRInfoForm">
            <Row>
                <Col><h4>การชำระเงินด้วย THAI QR</h4></Col>
            </Row>
            {
                !thai_qr ?
                <Row><Col className="text-muted">คุณยังไม่ได้ตั้งค่า Merchant สำหรับสาขานี้</Col></Row>
                :
                <div>
                    <Row>
                        <Col sm={12} md={6}>รหัส Merchant : </Col>
                        <Col sm={12} md={6}>{thai_qr.id}</Col>
                    </Row>
                    <Row>
                        <Col sm={12} md={6}>รหัสร้าน : </Col>
                        <Col sm={12} md={6}>{thai_qr.shop.id}</Col>
                    </Row>
                    <Row>
                        <Col sm={12} md={6}>ชื่อร้าน (ไทย) : </Col>
                        <Col sm={12} md={6}>{thai_qr.shop.shop_name_th}</Col>
                    </Row>
                    <Row>
                        <Col sm={12} md={6}>ชื่อร้าน (English) : </Col>
                        <Col sm={12} md={6}>{thai_qr.shop.shop_name_en}</Col>
                    </Row>
                    <Row>
                        <Col sm={12} md={6}>รหัสการลงทะเบียนกสิกรไทย : </Col>
                        <Col sm={12} md={6}>{thai_qr.shop.kbank_registration_id}</Col>
                    </Row>
                </div>
            }
            <br/>
            <Row>
                <Col className='text-danger'>สามารถเปลียนแปลงการตั้งค่านี้ได้ที่ <a href={APP_URL} target="_blank">app.arincare.com</a></Col>
            </Row>
        </div>
    )
}

export default ThaiQRInfoForm;

