import { 
    SELECT_PROFILE_PANEL,
 } from 'Redux/actionTypes';

 import { setDailySummaryReport} from 'Redux/actions'; 


 export const selectProfilePanel = (index) => dispatch => {

    if(index === 0){
        dispatch(setDailySummaryReport());
    }

     return dispatch({
         type: SELECT_PROFILE_PANEL,
         payload:{
            panel: index,
            title: (index === 0) ? 'สรุปยอดขายรายวัน' : (index === 1) ? 'เลือกเครื่องพิมพ์ใบเสร็จ' : (index === 2) ? 'Thai QR' : 'ตั้งค่าการใช้งาน'
         }
     })
 }