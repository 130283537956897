import { Button, Card, CardBody, Col, CustomInput, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import InputFormGroup from 'Components/Input/InputFormGroup';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import { notify } from 'reapop';
import GoogleMapReact from 'google-map-react';
import Autosuggest from 'react-autosuggest';
import validate from 'validate.js';
import MapMarkerImage from '../../../images/map_marker.png';
import { 
    ServiceDeliveryStatus, 
    ServicesModalScreen, 
    ServiceDeliveryPaymentStatus 
} from './constants';
import { ADD_TO_CART } from 'Redux/actionTypes';
import { loadQR, openServicesModal, AddMessageLogToCart } from 'Redux/actions';
import { latLongManhattanDistance } from 'Business/distanceCalculator';
import DeliveryPrice from './DeliveryPrice';

const API3_URL = process.env.API3_URL;
const GOOGLE_MAP_API_KEY = process.env.GOOGLE_MAP_API_KEY;

const PACKAGE_TYPE = {
    MEDICINE: 'MEDICINE',
    FOOD: 'FOOD',
    DOCUMENT: 'DOCUMENT',
};

const formSchema = {
    package_weight: { numericality: { greaterThanOrEqualTo: 0 } },
    sender_name: { presence: { allowEmpty: false, message: 'is required' } },
    sender_address: { presence: { allowEmpty: false, message: 'is required' } },
    sender_street: { presence: { allowEmpty: false, message: 'is required' } },
    sender_zipcode: { numericality: { onlyInteger: true, greaterThanOrEqualTo: 10000 }, length: { minimum: 5 } },
    sender_latitude: { presence: { allowEmpty: false, message: 'is required' } },
    sender_longitude: { presence: { allowEmpty: false, message: 'is required' } },
    sender_phone: { numericality: { onlyInteger: true }, length: { minimum: 9 } },
    receiver_name: { presence: { allowEmpty: false, message: 'is required' } },
    receiver_address: { presence: { allowEmpty: false, message: 'is required' } },
    receiver_street: { presence: { allowEmpty: false, message: 'is required' } },
    receiver_zipcode: { numericality: { onlyInteger: true, greaterThanOrEqualTo: 10000 }, length: { minimum: 5 } },
    receiver_phone: { numericality: { onlyInteger: true }, length: { minimum: 9 } },
    receiver_latitude: { presence: { allowEmpty: false, message: 'is required' } },
    receiver_longitude: { presence: { allowEmpty: false, message: 'is required' } },
};

const defaultMapCenter = {
    lat: 13.745,
    lng: 100.535,
};

// Google Map Api
let GoogleMapApi, PlacesService;

const DeliveryForm = (props) => {
    const { dispatch, settings, cart, providerId, language, setBackWarning } = props;

    const defaultForm = {
        status: null,
        provider_id: providerId,
        package_type: PACKAGE_TYPE.MEDICINE,
        package_weight: 0,
        package_price: cart.total_amount || 0,
        sender_name: `${settings.company.name} ${settings.branch.name}`,
        sender_address: '',
        sender_phone: '',
        sender_latitude: null,
        sender_longitude: null,
        sender_note: '',
        receiver_name: '',
        receiver_address: '',
        receiver_phone: '',
        receiver_latitude: null,
        receiver_longitude: null,
        distance: null,
        total_fee: '-',
        include_fee_in_sr: true,
    };

    const endpoint = `${API3_URL}/companies/${settings.company.slug}/branches/${settings.branch.slug}/service-delivery`;
    const [loading, setLoading] = useState(false);
    const [form, setForm] = useState(defaultForm);
    const [errors, setErrors] = useState(undefined);
    const [mapModal, setMapModal] = useState({
        open: false,
        selector: null, // sender, receiver
        center: defaultMapCenter,
        marker: defaultMapCenter,
        geocode: null,
        input: '',
        suggestions: [],
    });
    const [priceModal, setPriceModal] = useState(false);

    const disableForm = Boolean(loading || !!form.id);

    useEffect(() => {
        setBackWarning(!!form.id);
    }, [form]);

    useEffect(() => {
        if (form.id || !form.sender_latitude || !form.receiver_latitude) return;
        const distance = latLongManhattanDistance(
            form.sender_latitude,
            form.sender_longitude,
            form.receiver_latitude,
            form.receiver_longitude,
        ).toFixed(2);
        setForm({
            ...form,
            distance,
        });
    }, [form.sender_latitude, form.receiver_latitude]);

    const handleChange = (event) => {
        const { type, name, checked, value } = event.target;
        setForm({
            ...form,
            [name]: type === 'checkbox' ? checked : value,
        });
    };

    const handleChangePackageType = (package_type) => () => {
        if (disableForm) return;
        setForm({
            ...form,
            package_type,
        });
    };

    const handleOpenMap = (selector) => () => {
        const selected = !!form[`${selector}_latitude`];
        const address = form[`${selector}_address`];
        const lat = form[`${selector}_latitude`] || defaultMapCenter.lat;
        const lng = form[`${selector}_longitude`] || defaultMapCenter.lng;
        setMapModal({
            ...mapModal,
            open: true,
            selector,
            address,
            input: '',
            center: { lat, lng },
            marker: { lat, lng },
        });

        if (!selected && navigator.geolocation) {
            navigator.geolocation.getCurrentPosition((position) => {
                const lat = position.coords.latitude;
                const lng = position.coords.longitude;

                setTimeout(() => {
                    setMapModal((old) => ({
                        ...old,
                        center: { lat, lng },
                        marker: { lat, lng },
                    }));
                    getMapMarkerAddress({ lat, lng });
                }, 100);
            });
        }
    };

    const onGoogleApiLoaded = ({ map, maps }) => {
        const { lat, lng } = mapModal.marker;

        GoogleMapApi = maps;
        PlacesService = new maps.places.PlacesService(map);
        getMapMarkerAddress({ lat, lng });
    };

    const handleChangeMapSearch = () => {
        const { value } = event.target;

        setMapModal({
            ...mapModal,
            input: value || '',
        });
    };

    const requestMapSearch = ({ value }) => {
        const request = {
            query: value || '',
            fields: ['name', 'formatted_address', 'geometry'],
        };

        PlacesService.textSearch(
            request,
            (results, status) => {
                if (status === 'OK') {
                    setMapModal((old) => ({
                        ...old,
                        suggestions: results.slice(0, 5).map((result) => ({
                            address: result.formatted_address,
                            lat: result.geometry.location.lat(),
                            lng: result.geometry.location.lng(),
                        })),
                    }));
                }
            },
        );
    };

    const handleSelectMapSearch = (event, { suggestion }) => {
        const { address, lat, lng } = suggestion;

        setMapModal({
            ...mapModal,
            address,
            input: address,
            center: { lat, lng },
            marker: { lat, lng },
            disableChangeAddress: true,
        });
    };

    const handleMapChange = ({ center }) => {
        setMapModal((old) => {
            if (mapModal.disableChangeAddress) {
                return {
                    ...old,
                    disableChangeAddress: undefined,
                };
            }

            getMapMarkerAddress(center);

            return {
                ...old,
                center,
                marker: center,
            };
        });
    };

    const handleDragMap = ({ center }) => {
        const lat = center.lat();
        const lng = center.lng();
        setMapModal({
            ...mapModal,
            marker: { lat, lng },
        });
    };

    const handleCloseMap = () => {
        setMapModal({
            ...mapModal,
            open: false,
        });
    };

    const getMapMarkerAddress = async (center) => {
        const { lat, lng } = center;

        try {
            const geocoder = new GoogleMapApi.Geocoder();
            geocoder.geocode({ location: { lat, lng } }, (results, status) => {
                if (status !== 'OK') return;
                if (results[0]) {
                    setMapModal((old) => ({
                        ...old,
                        address: results[0].formatted_address,
                        geocode: results[0],
                        input: '',
                    }));
                }
            });
        } catch (error) {
            // console.error(error);
        }
    };

    const handleConfirmMap = () => {
        const { selector } = mapModal;
        const { geocode, marker } = mapModal;

        setMapModal({
            ...mapModal,
            open: false,
        });

        const addToForm = {
            [`${selector}_latitude`]: marker.lat,
            [`${selector}_longitude`]: marker.lng,
        };

        if (geocode) {
            addToForm[`${selector}_address`] = mapModal.address;
            addToForm[`${selector}_street`] = mapModal.address;

            const postal_code = geocode.address_components.find((comp) => comp.types.includes('postal_code'));
            addToForm[`${selector}_zipcode`] = postal_code ? postal_code.long_name : form[`${selector}_zipcode`];
        }

        setForm({
            ...form,
            ...addToForm,
        });
    };

    const setFormFromOrder = (order) => {
        setForm({
            ...order,
            receiver_name: `${order.receiver_first_name} ${order.receiver_last_name} `,
        });
    };

    const handleCreateOrder = async () => {
        const newError = validate(form, formSchema);
        setErrors(newError);
        if (newError) {
            dispatch(notify({
                id: 'service-delivery',
                title: 'Service Delivery',
                message: 'กรุณากรอกข้อมูลให้ครบถ้วน',
                status: 'warning',
                dismissible: true,
                dismissAfter: 5000,
            }));
            return;
        }

        if (form.distance > 10) {
            dispatch(notify({
                id: 'delivery',
                title: 'Service Delivery',
                message: `ไม่สามารถให้บริการในระยะทางเกิน 10 km. ได้`,
                status: 'error',
                dismissible: true,
                dismissAfter: 5000,
            }));
            return;
        }

        setLoading(true);

        dispatch(notify({
            id: 'delivery',
            title: 'Service Delivery',
            message: 'กำลังคำนวนค่าบริการ กรุณารอซักครู่...',
            status: 'info',
            dismissAfter: 300000,
        }));

        try {
            const data = {
                ...form,
                receiver_name: undefined,
                receiver_first_name: form.receiver_name.split(' ')[0],
                receiver_last_name: form.receiver_name.split(' ')[1] || ' ',
            };

            const response = await axios({
                url: endpoint,
                method: 'POST',
                headers: { 'Authorization': `Bearer ${settings.token}` },
                data,
            });

            const order = response.data.data;

            setFormFromOrder(order);

            dispatch(notify({
                id: 'delivery',
                title: 'Service Delivery',
                message: 'คำนวนค่าบริการเรียบร้อย!',
                status: 'success',
                dismissible: true,
                dismissAfter: 5000,
            }));
        } catch (error) {
            console.error(error);
            dispatch(notify({
                id: 'delivery',
                title: 'Service Delivery',
                message: 'บันทึกไม่สำเร็จ',
                status: 'error',
                dismissible: true,
                dismissAfter: 5000,
            }));
        }

        setLoading(false);
    };

    const handleEdit = () => {
        setForm({
            ...form,
            id: null,
            status: null,
        });
    };

    const handleConfirm = async () => {
        setLoading(true);

        dispatch(notify({
            id: 'delivery',
            title: 'Service Delivery',
            message: 'กำลังส่งไปยังผู้ให้บริการ กรุณารอซักครู่...',
            status: 'info',
            dismissAfter: 300000,
        }));

        try {
            const response = await axios({
                url: `${endpoint}/${form.id}/confirm`,
                method: 'POST',
                headers: { 'Authorization': `Bearer ${settings.token}` },
            });

            const { data: order, addToCart } = response.data;

            setFormFromOrder(order);

            if (order.status !== 'CONFIRMED') {
                throw new Error('Order is not confirmed.');
            }

            // Add service fee to cart
            if (addToCart) {
                dispatch(AddMessageLogToCart(`POS Add to cart [df1] with employee_id is ${addToCart.employee_id}`))
                dispatch({
                    type: ADD_TO_CART,
                    payload: addToCart,
                });
            }

            dispatch(notify({
                id: 'delivery',
                title: 'Service Delivery',
                message: 'ยืนยันออเดอร์เรียบร้อย!',
                status: 'success',
                dismissible: true,
                dismissAfter: 5000,
            }));

            setBackWarning(false);
            dispatch(openServicesModal(ServicesModalScreen.FOODPANDA, order.id));
        } catch (error) {
            console.error(error);
            dispatch(notify({
                id: 'delivery',
                title: 'Service Delivery',
                message: 'ยืนยันออเดอร์ไม่สำเร็จ',
                status: 'error',
                dismissible: true,
                dismissAfter: 5000,
            }));
        }

        setLoading(false);
    };

    const handlePayment = async () => {
        setLoading(true);

        try {
            dispatch(loadQR({
                qrType: '3',
                reference1: form.reference_code,
                payFor: 'service_delivery',
            }));

            const response = await axios({
                url: `${endpoint}/${form.id}/wait-payment`,
                method: 'POST',
                headers: { 'Authorization': `Bearer ${settings.token}` },
            });

            const { data: order, addToCart } = response.data;

            setFormFromOrder(order);

            if (order.status !== ServiceDeliveryStatus.WAITING_FOR_PAYMENT) {
                throw new Error('Order is not change status.');
            }

            // Add service fee to cart
            if (addToCart) {
                dispatch(AddMessageLogToCart(`POS Add to cart [df2] with employee_id is ${addToCart.employee_id}`))
                dispatch({
                    type: ADD_TO_CART,
                    payload: addToCart,
                });
            }

            setBackWarning(false);
            dispatch(openServicesModal(ServicesModalScreen.FOODPANDA, order.id));
        } catch (error) {
            console.error(error);
            dispatch(notify({
                id: 'delivery',
                title: 'Service Delivery',
                message: 'ยืนยันออเดอร์ไม่สำเร็จ',
                status: 'error',
                dismissible: true,
                dismissAfter: 5000,
            }));
        }

        setLoading(false);
    };

    const hasError = (field) => !!errors && !!errors[field];
    const errorMessage = (field) => (hasError(field) ? errors[field][0] : null);

    return (
        <>
            <Card className="mb-2">
                <CardBody className="py-2">
                    <h5 className="font-weight-bold text-grey mb-0">รายละเอียดการส่งสินค้า</h5>
                </CardBody>
            </Card>
            <Row>
                <Col xs={8}>
                    <Card>
                        <CardBody>
                            <div
                                style={{
                                    maxHeight: 'calc(100vh - 400px)',
                                    overflow: 'scroll',
                                }}
                            >
                                <h5 className="font-weight-bold" style={{ color: '#55c2eb' }}>ผู้ส่ง</h5>

                                <InputFormGroup
                                    label="ชื่อผู้ส่ง"
                                    placeholder="ชื่อ-นามสกุล"
                                    type="text"
                                    name="sender_name"
                                    error={errorMessage('sender_name')}
                                    value={form.sender_name}
                                    disabled={disableForm}
                                    onChange={handleChange}
                                />

                                <InputFormGroup
                                    label="หมายเลขโทรศัพท์มือถือ"
                                    placeholder="หมายเลขโทรศัพท์มือถือ"
                                    type="text"
                                    name="sender_phone"
                                    error={errorMessage('sender_phone')}
                                    value={form.sender_phone}
                                    disabled={disableForm}
                                    onChange={handleChange}
                                />

                                <InputFormGroup
                                    label="ที่อยู่"
                                    placeholder="รับของที่ไหน?"
                                    type="text"
                                    name="sender_address"
                                    value={form.sender_address}
                                    error={errorMessage('sender_address')}
                                    disabled={true}
                                    onChange={handleChange}
                                />

                                <Button
                                    className="mb-2"
                                    color="white"
                                    block
                                    disabled={disableForm}
                                    onClick={handleOpenMap('sender')}
                                >
                                    <img
                                        className="mr-2"
                                        src={MapMarkerImage}
                                        width={20}
                                        height={20}
                                    />
                                    ปักหมุดบนแผนที่
                                </Button>
                                {hasError('sender_latitude') && (
                                    <div className="small" style={{ color: 'red' }}>
                                        *กรุญาเลือกพิกัดสถานที่
                                    </div>
                                )}

                                <hr style={{ borderTop: '2px dashed #55c2eb' }} />

                                <h5 className="font-weight-bold" style={{ color: '#55c2eb' }}>ผู้รับ</h5>

                                <InputFormGroup
                                    label="ชื่อผู้รับ"
                                    placeholder="ชื่อ-นามสกุล"
                                    type="text"
                                    name="receiver_name"
                                    value={form.receiver_name}
                                    error={errorMessage('receiver_name')}
                                    disabled={disableForm}
                                    onChange={handleChange}
                                />

                                <InputFormGroup
                                    label="หมายเลขโทรศัพท์มือถือ"
                                    placeholder="หมายเลขโทรศัพท์มือถือ"
                                    type="text"
                                    name="receiver_phone"
                                    value={form.receiver_phone}
                                    error={errorMessage('receiver_phone')}
                                    disabled={disableForm}
                                    onChange={handleChange}
                                />

                                <InputFormGroup
                                    label="ที่อยู่"
                                    placeholder="ส่งของที่ไหน?"
                                    type="text"
                                    name="receiver_address"
                                    value={form.receiver_address}
                                    error={errorMessage('receiver_address')}
                                    disabled={true}
                                    onChange={handleChange}
                                />

                                <Button
                                    className="mb-2"
                                    color="white"
                                    block
                                    disabled={disableForm}
                                    onClick={handleOpenMap('receiver')}
                                >
                                    <img
                                        className="mr-2"
                                        src={MapMarkerImage}
                                        width={20}
                                        height={20}
                                    />
                                    ปักหมุดบนแผนที่
                                </Button>
                                {hasError('receiver_latitude') && (
                                    <div className="small" style={{ color: 'red' }}>
                                        *กรุญาเลือกพิกัดสถานที่
                                    </div>
                                )}

                                <hr style={{ borderTop: '2px dashed #55c2eb' }} />

                                <h5 className="font-weight-bold" style={{ color: '#55c2eb' }}>พัสดุ</h5>

                                <InputFormGroup
                                    label="หมายเหตุการจัดส่ง"
                                    placeholder="เช่น ฝากไว้ที่เคาร์เตอร์"
                                    type="text"
                                    name="sender_note"
                                    value={form.sender_note}
                                    error={errorMessage('sender_note')}
                                    disabled={disableForm}
                                    onChange={handleChange}
                                />

                                <InputFormGroup
                                    label="น้ำหนักพัสดุ (kg)"
                                    hint="*น้ำหนักไม่เกิน 15 กิโลกรัม และ ขนาดต้องไม่เกินกล่อง สูง 14 นิ้ว กว้าง 16.75 นิ้ว"
                                    type="number"
                                    step={0.5}
                                    name="package_weight"
                                    value={form.package_weight}
                                    error={errorMessage('package_weight')}
                                    disabled={disableForm}
                                    onChange={handleChange}
                                />

                                <label>ประเภทของพัสดุ:</label>
                                <br />
                                {Object.values(PACKAGE_TYPE).map((type) => (
                                    <Button
                                        key={type}
                                        className="mr-1"
                                        size="sm"
                                        outline
                                        onClick={handleChangePackageType(type)}
                                        style={{
                                            minWidth: 70,
                                            background: form.package_type === type ? '#55C2EB' : 'white',
                                            border: '#55C2EB solid 1px',
                                            color: form.package_type === type ? 'white' : '#55C2EB',
                                            boxShadow: 'none',
                                        }}
                                    >
                                        {language.PACKAGE_TYPE[type]}
                                    </Button>
                                ))}

                                <div className="mt-3">
                                    <CustomInput
                                        id={1}
                                        className="custom-control-inline"
                                        type="checkbox"
                                        label="เพิ่มค่าบริการจัดส่งลงในบิลขาปัจจุบัน"
                                        name="include_fee_in_sr"
                                        checked={form.include_fee_in_sr}
                                        disabled={disableForm}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>

                            <div className="mt-4">
                                <Row>
                                    <Col xs={7} className="pr-0">
                                        {!!errors && (
                                            <div className="small" style={{ color: 'red' }}>
                                                *กรุณาใส่รายละเอียดให้ครบถ้วน
                                            </div>
                                        )}
                                        {!!form.id && (
                                            <div>
                                                <div className="small" style={{ color: 'red', fontSize: '70%' }}>
                                                    *กรุณาตรวจรายละเอียดในการจัดส่งให้เรียบร้อย
                                                    <br />
                                                    หากยืนยันออเดอร์แล้วจะไม่สามารถทำการยกเลิกออเดอร์ได้
                                                </div>
                                                <div className="small" style={{ color: 'red', fontSize: '80%' }}>
                                                    **กรุณาเตรียมสินค้าให้พร้อมภายในเวลา 7 นาที
                                                    <br />
                                                    ก่อนที่ไรเดอร์จะมารับสินค้า
                                                </div>
                                            </div>
                                        )}
                                    </Col>
                                    <Col xs={5} className="text-right pl-0">
                                        {!form.id ? (
                                            <Button
                                                color="warning"
                                                disabled={loading}
                                                style={{ background: '#ffc107', color: 'white' }}
                                                onClick={handleCreateOrder}
                                            >
                                                คำนวนค่าบริการ
                                            </Button>
                                        ) : (
                                            <>
                                                <Button
                                                    className="mr-2"
                                                    disabled={loading || form.status === 'CONFIRMED'}
                                                    color="danger"
                                                    onClick={handleEdit}
                                                >
                                                    แก้ไข
                                                </Button>
                                                {form.payment_status == ServiceDeliveryPaymentStatus.FREE ? (
                                                    <Button
                                                        disabled={loading || form.status === 'CONFIRMED'}
                                                        color="success"
                                                        onClick={handleConfirm}
                                                    >
                                                        **ส่งฟรี
                                                    </Button>
                                                ):(
                                                    <Button
                                                        disabled={loading || form.status === 'CONFIRMED'}
                                                        color="success"
                                                        onClick={handlePayment}
                                                    >
                                                        ชำระค่าส่ง
                                                    </Button>
                                                )}
                                            </>
                                        )}</Col>
                                </Row>
                            </div>
                        </CardBody>
                    </Card>
                </Col>

                <Col xs={4}>
                    <Card className="mb-2">
                        <CardBody className="pb-2">
                            <div className="my-2">
                                <strong style={{
                                    color: '#0184c0',
                                    fontSize: '80%',
                                }}>
                                    ORDER#: {form.reference_code || '-'}
                                </strong>
                            </div>
                            <div
                                className="py-2 mb-2"
                                style={{
                                    borderTop: '1px dashed grey',
                                    borderBottom: '1px dashed grey',
                                }}
                            >
                                <Row>
                                    <Col xs={6}>
                                        <strong>ระยะทาง:</strong>
                                    </Col>
                                    <Col xs={6} className="text-right">
                                        <strong style={{ color: '#0184c0' }}>{form.distance || 'N/A'}</strong>
                                        <strong className="d-inline-block" style={{ width: 50 }}>km.</strong>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={6}>
                                        <stxrong>ค่าบริการ:</stxrong>
                                    </Col>
                                    <Col xs={6} className="text-right">
                                        <strong style={{ color: '#0184c0' }}>{form.total_fee}</strong>
                                        <strong className="d-inline-block" style={{ width: 50 }}>THB</strong>
                                    </Col>
                                </Row>
                            </div>
                            <div
                                className="text-right"
                                style={{ cursor: 'pointer', fontSize: '70%' }}
                                onClick={() => setPriceModal(true)}
                            >
                                <i className="fa fa-info-circle mr-2" />
                                เปรียบเทียบราคาค่าบริการ
                            </div>
                        </CardBody>
                    </Card>
                </Col>
            </Row>

            <Modal
                isOpen={priceModal}
                toggle={() => setPriceModal(false)}
                size="lg"
            >
                <ModalHeader
                    className="modal-title-center pb-2"
                    toggle={() => setPriceModal(false)}
                >
                    ค่าบริการ
                </ModalHeader>
                <DeliveryPrice />
            </Modal>

            <Modal
                isOpen={mapModal.open}
                toggle={handleCloseMap}
                size="lg"
            >
                <ModalBody>
                    <Autosuggest
                        suggestions={mapModal.suggestions}
                        onSuggestionsFetchRequested={requestMapSearch}
                        onSuggestionsClearRequested={() => null}
                        getSuggestionValue={item => item.value}
                        renderSuggestion={suggestion => (
                            <div
                                className="px-3 py-1"
                                style={{ borderBottom: '1px solid #ccc', cursor: 'pointer' }}
                            >
                                {suggestion.address}
                            </div>
                        )}
                        onSuggestionSelected={handleSelectMapSearch}
                        inputProps={{
                            value: mapModal.input,
                            onChange: handleChangeMapSearch,
                            type: 'search',
                            placeholder: 'Search location...',
                        }}
                        theme={{
                            container: 'autosuggest',
                            input: 'form-control mb-2',
                            suggestionsContainer: 'dropdown',
                            suggestionsList: `dropdown-menu py-0 ${mapModal.suggestions.length ? 'show' : ''}`,
                            suggestion: 'dropdown-item px-0 py-0 my-0',
                            suggestionFocused: 'active',
                        }}
                    />

                    <div style={{ height: 500 }}>
                        <GoogleMapReact
                            bootstrapURLKeys={{
                                key: GOOGLE_MAP_API_KEY,
                                libraries: ['places'],
                                region: 'TH',
                                language: 'th',
                            }}
                            defaultZoom={16}
                            defaultCenter={defaultMapCenter}
                            center={[mapModal.center.lat, mapModal.center.lng]}
                            onChange={handleMapChange}
                            onDrag={handleDragMap}
                            onGoogleApiLoaded={onGoogleApiLoaded}
                            yesIWantToUseGoogleMapApiInternals
                        >
                            <div
                                className="position-relative"
                                lat={mapModal.marker.lat}
                                lng={mapModal.marker.lng}
                            >
                                <Card
                                    className="position-absolute"
                                    style={{
                                        width: 300,
                                        left: -150,
                                        bottom: 40,
                                    }}
                                >
                                    <CardBody className="py-1">
                                        {(mapModal.address) || 'Unknown location'}
                                    </CardBody>
                                </Card>
                                <img
                                    className="position-absolute"
                                    src={MapMarkerImage}
                                    width={32}
                                    height={32}
                                    style={{
                                        bottom: 0,
                                        left: -16,
                                    }}
                                />
                            </div>
                        </GoogleMapReact>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <div className="text-danger font-italic">
                        *ลากแผนที่เพื่อให้หมุดอยู่ตรงกับสถานที่ที่ต้องการ
                    </div>
                    <div className="flex-grow-1" />
                    <Button
                        className="mr-2"
                        color="danger"
                        onClick={handleCloseMap}
                    >
                        ยกเลิก
                    </Button>
                    <Button
                        color="success"
                        onClick={handleConfirmMap}
                        style={{ width: 100 }}
                    >
                        ยืนยันพิกัด
                    </Button>
                </ModalFooter>
            </Modal>
        </>
    );
};

const mapStateToProps = state => ({
    settings: state.settings,
    language: state.language,
    cart: state.cart,
});


export default connect(mapStateToProps)(DeliveryForm);
