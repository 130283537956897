export const SEARCH_CUSTOMERS = 'SEARCH_CUSTOMERS'
export const SET_CURRENT_CUSTOMER = 'SET_CURRENT_CUSTOMER'
export const CLOSE_SEARCH_CUSTOMERS = 'CLOSE_SEARCH_CUSTOMERS'
export const REMOVE_CUSTOMER = 'REMOVE_CUSTOMER'
export const OPEN_CUSTOMER_REGISTER = 'OPEN_CUSTOMER_REGISTER'
export const CLOSE_CUSTOMER_REGISTER = 'CLOSE_CUSTOMER_REGISTER'
export const SET_CUSTOMER_VALUE = 'SET_CUSTOMER_VALUE'
export const SET_CUSTOMER_INFO = 'SET_CUSTOMER_INFO' 
export const CLOSE_CUSTOMER_INFO = 'CLOSE_CUSTOMER_INFO'
export const ADD_ALLERGY_INFO = 'ADD_ALLERGY_INFO'
export const REMOVE_ALLERGY_INFO = 'REMOVE_ALLERGY_INFO'
export const EDIT_INFO_CUSTOMER = 'EDIT_INFO_CUSTOMER'
export const ALLERGY_CHANGE = 'ALLERGY_CHANGE'
export const FOCUS_SEARCH_CUSTOMER = 'FOCUS_SEARCH_CUSTOMER'
export const SHOW_NOTI_USER_AS_CUSTOMER = 'SHOW_NOTI_USER_AS_CUSTOMER'
export const CLOSE_NOTI_USER_AS_CUSTOMER = 'CLOSE_NOTI_USER_AS_CUSTOMER'