import React from 'react';
import { Modal, ModalHeader, ModalBody, Row, Col, Button, Input } from 'reactstrap';

export default class PatientModal extends React.Component {

    render() {

        const {isOpen, onSubmit, language, patient} = this.props;

        return (
            <Modal isOpen={isOpen}>
                <ModalHeader>Customer</ModalHeader>
                <ModalBody className="px-4 text-center">
                    {patient ? (
                        <div>
                            <Row>
                                <Col sm={4}>Name:</Col>
                                <Col sm={8}>
                                    <Input value={patient.name} disabled={true}/>
                                </Col>
                            </Row>
                            <div style={{height: '15px', width: '20px'}}></div>
                            <Row>
                                <Col sm={4}>Phone:</Col>
                                <Col sm={8}>
                                    <Input value={patient.phone_number} disabled={true}/>
                                </Col>
                            </Row>
                        </div>
                    ) : ('')}
                    <Button
                        color="danger"
                        style={{ marginTop: '15px' }}
                        onClick={onSubmit}
                    >
                        {language.close}
                    </Button>
                </ModalBody>
            </Modal>
        );
    }
}