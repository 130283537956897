export const QR_PAYMENT_SUCCESS = 'QR_PAYMENT_SUCCESS';
export const QR_RESULT_CLOSE = 'QR_RESULT_CLOSE';
export const QR_PAYMENT_OPEN = 'QR_PAYMENT_OPEN';
export const QR_PAYMENT_CLOSE = 'QR_PAYMENT_CLOSE';
export const QR_PAYMENT_ERROR = 'QR_PAYMENT_ERROR';
export const QR_REQUEST = 'QR_REQUEST';
export const QR_REFRESH = 'QR_REFRESH';
export const QR_INQUIRE = 'QR_INQUIRE';
export const QR_INQUIRE_ERROR = 'QR_INQUIRE_ERROR';
export const QR_CANCEL_ERROR = 'QR_CANCEL_ERROR';
export const QR_LOADING = 'QR_LOADING';
export const SET_UUID_FOR_QR = 'SET_UUID_FOR_QR';
export const QR_CONNECTED = 'QR_CONNECTED';
export const QR_CONNECTING = 'QR_CONNECTING';
export const CLOSE_QR_CONNECTED = 'CLOSE_QR_CONNECTED';
export const QR_PAID = 'QR_PAID';
export const QR_CHECKOUT = 'QR_CHECKOUT';
export const QR_TRIGGER_CHECKOUT = 'QR_TRIGGER_CHECKOUT';
export const QR_TRIGGER_PAID = 'QR_TRIGGER_PAID';


