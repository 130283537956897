import { SEARCH_PRODUCTS
    , CLEAR_PRODUCTS
    , SEARCH_CUSTOMERS
    , SEARCH_RECEIPTS
    , CLOSE_SALE_RETURN
    , SYNC_SALE_RETURN 
    , CLOSE_SEARCH_CUSTOMERS
    , UPDATE_SEARCH_WISHLIST
    , SELECT_PRODUCT
    , ADD_TO_CART
    , SET_FIND_ALL_PRODUCTS
    , UPDATE_PRODUCT_PAGINATE
    , SET_PRODUCT_LOADING 
    , OPEN_PRODUCT_ERROR_MODAL
    , CLOSE_PRODUCT_ERROR_MODAL
    } from '../actionTypes'

const initialState = {
    products: [],
    product_query: '',
    customers: [],
    receipts: [],
    findAllProducts: false,
    productPage: 1,
    productTotal: 0,
    productPerPage: 30,
    productLoading: false,
    productErrorModal: false,
    productErrorModalMessages: [],
}

export default (state=initialState, { type, payload }) => {
    switch (type) {
        case SELECT_PRODUCT:
            return Object.assign({}, state,{ product_query: payload.query});
        case ADD_TO_CART :
            return initialState;
        case SEARCH_PRODUCTS:
            return Object.assign({}, state, {
                products: payload.products,
                product_query: payload.query,
                productPage: payload.paginate ? payload.paginate.current_page : 1,
                productTotal: payload.paginate ? payload.paginate.total : 0,
            });
        case CLEAR_PRODUCTS:
            return Object.assign(state, { products: [], product_query: '', productPage: 1, productTotal: 0 });
        case SEARCH_CUSTOMERS:
            return Object.assign(state, { customers: payload });
        case CLOSE_SEARCH_CUSTOMERS:
            return Object.assign(state, { customers: [] })
        case SEARCH_RECEIPTS:
            return Object.assign({}, state , { receipts: payload })
        case SYNC_SALE_RETURN:
        case CLOSE_SALE_RETURN:
            return Object.assign(state, { receipts: [] })
        case UPDATE_SEARCH_WISHLIST: {
            const updateProducts = state.products.map(product => {
                if (product.id === payload.id) {
                    return Object.assign({}, product, { wishlist_item: payload.wishlist_item })
                }
                return product
            })
            return Object.assign({}, state, { products: updateProducts })
        }
        case SET_FIND_ALL_PRODUCTS: 
            return Object.assign({}, state, { findAllProducts: payload })
        case UPDATE_PRODUCT_PAGINATE:
            return Object.assign({}, state, { productPage: payload })
        case SET_PRODUCT_LOADING:
            return Object.assign({}, state, { productLoading: payload })
        case OPEN_PRODUCT_ERROR_MODAL:
            return Object.assign({},state,{ productErrorModal: true, productErrorModalMessages: payload})
        case CLOSE_PRODUCT_ERROR_MODAL:
            return Object.assign({},state,{ productErrorModal: false })
        default:
            return state
    }
}