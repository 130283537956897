import { PRINT_DRUG_LABEL
    , SET_DRUG_LABEL_VALUE
    , RESET_PRINT_DRUG_LABEL
    , CLOSE_PRINT_DRUG_LABEL 
    , IN_PROCESSING_DRUG_LABEL
} from '../actionTypes'

import moment from 'moment'

const initialState = {
    id: null,
    display_store: true,
    show_pharmacist: true,
    save_label: false,
    include_print_drug_info: false,
    is_other: false,
    other: false,
    amount: '',
    amount_unit: 'หน่วย',
    product: {
        labels: []
    },
    name: '',
    date: '',
    eat: '',
    drug_name: '',
    exp_date: '',
    timeSelected: [],
    remark: '',
    volume_intake: '',
    unit_intake: '',
    daily_frequency_intake: '',
    is_taken_other: false,
    taken_other: '',
    taken_time: 0,
    taken_morning: false,
    taken_noon: false,
    taken_evening: false,
    taken_before_bed: false,
    taken_symptom_occur: false,
    symptom_occur: '',
    drug_label_information: '',
    note: '',
    dosage_instruction: '',
    created_at: moment().format('YYYY-MM-DD HH:mm:ss'),
    updated_at: moment().format('YYYY-MM-DD HH:mm:ss'),
    deleted_at: null,
    patient_name: '',
    is_over_limit: [],
    how_to_use: '',
}

export default (state=initialState, { type, payload }) => {
    switch (type) {
        case PRINT_DRUG_LABEL:
            return Object.assign({}, state, payload.customer, { product: payload.detail})
        case SET_DRUG_LABEL_VALUE:
            return Object.assign({},state,{ [payload.path] : payload.value })
        case CLOSE_PRINT_DRUG_LABEL:
            return Object.assign({}, initialState, { timeSelected: [] })
        case RESET_PRINT_DRUG_LABEL:
            return Object.assign({}, initialState, payload.customer, payload.detail,  { processing_product: payload.processing} )
        case IN_PROCESSING_DRUG_LABEL:
            return Object.assign({}, initialState, payload.detail, { processing_product: payload.detail})            
        default:
            return state
    }
}