import {
    PAY_WITH_ALIPAY_WECHAT,
    CLOSE_ALIPAY_WECHAT_MODAL,
    ON_OPEN_ALIPAY_WECHAT_MODAL,
    INQUIRE_ALIPAY_WECHAT
} from 'Redux/actionTypes';


export const payWithAlipayWechat = () => (dispatch, getState) => {
    return dispatch({
        type: PAY_WITH_ALIPAY_WECHAT
    });
}

export const closeAlipayWechatModal = () => dispatch => {
    return dispatch({
        type: CLOSE_ALIPAY_WECHAT_MODAL
    })
}

export const onOpenAlipayWechatModal = () => (dispatch, getState) => {
    return dispatch({
        type: ON_OPEN_ALIPAY_WECHAT_MODAL
    });
}

export const inquiryAlipayWechat = () => (dispatch, getState) => {
    return dispatch({
        type: INQUIRE_ALIPAY_WECHAT
    })
}