import React, { Fragment } from 'react'
import { Row, Alert, Button } from 'reactstrap'

const APP_URL = process.env.APP_URL;

class LoginForm extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            email: '',
            password: ''
        }
        this._onChange = this._onChange.bind(this)
        this._onClickBranchCode = this._onClickBranchCode.bind(this)
        this._onSubmit = this._onSubmit.bind(this)
    }

    _onChange(e) {
        this.setState({ [e.target.name]: e.target.value })
    }

    _onClickBranchCode(e) {
        this.setState({ email: 'BR' })
        this.refs.emailInput.focus()
    }

    _onSubmit(e) {
        e.preventDefault()
        if (this.state.email.trim() && this.state.password.trim()) {
            this.state.email.match(/^BR/) ?
            this.props.getBranchLogin(this.state) :
            this.props.getUserLogin(this.state)
        }
    }

    render() {
        const { email, password } = this.state
        const { login, language } = this.props
        const permission = localStorage.getItem('permission')
        return (
            <Fragment>
                <div className="text-center my-4">
                    <h3 className="mb-2">{language.login_title}</h3>
                    <span>{language.login_subtitle}</span>
                </div>
                <Row className="justify-content-center mx-0">
                    {login.error && <Alert color="danger">{language.login_error}</Alert>}
                    <form className="col-lg-8 text-left" onSubmit={this._onSubmit}>
                        <div className="form-group">
                            <label>{language.email} / {language.branch_code} (
                                <span
                                    className="branch_code"
                                    onClick={this._onClickBranchCode}>
                                    BRxxxx
                                </span>)
                            </label>
                            <div className="input-group input-group-lg">
                                <div className="input-group-prepend">
                                    <span className="input-group-text">
                                        <i className="fa fa-user"></i>
                                    </span>
                                </div>
                                <input
                                    type="text"
                                    className="form-control"
                                    name="email"
                                    value={email}
                                    onChange={this._onChange}
                                    placeholder={language.email}
                                    ref="emailInput"
                                />
                            </div>
                        </div>
                        <div className="form-group">
                            <label>{language.password}</label>
                            <div className="input-group input-group-lg">
                                <div className="input-group-prepend">
                                    <span className="input-group-text">
                                        <i className="fa fa-lock"></i>
                                    </span>
                                </div>
                                <input
                                    type="password"
                                    className="form-control"
                                    name="password"
                                    value={password}
                                    onChange={this._onChange}
                                    placeholder={language.password}
                                />
                            </div>
                        </div>
                        <div className="form-group my-4">
                            <Button
                                color="primary"
                                disabled={login.loading}>
                                {login.loading ? `${language.checking}...` : language.login}
                            </Button>
                            {permission &&
                            <Button
                                type="button"
                                color="link"
                                className="float-right"
                                onClick={this.props.backToPos}>
                                {language.back} <i className="fa fa-angle-right fa-lg ml-1"></i>
                            </Button>}
                            <a
                                className="float-right"
                                href={APP_URL + 'password-forgot'}
                                target="_blank"
                            >
                                <Button
                                    type="button"
                                    color="link"
                                >
                                    {language.forgot_password}
                                </Button>
                            </a>
                        </div>
                    </form>
                </Row>
            </Fragment>
        )
    }
}

export default LoginForm
