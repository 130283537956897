import React from 'react';

import { ModalBody,
    ModalFooter,
    Button
} from 'reactstrap';

import ModalTemplate from 'Components/Shared/ModalTemplate';
import ConfirmBtn from 'Components/Shared/ConfirmBtn';

import Arinhelp_Cartoon from 'Images/Arinhelp_Cartoon.png';

const NotiRefreshModal = (props) => {

    return(
        <ModalTemplate 
            onCancel={props.onCancel}
            show={props.show}
            title={props.type === 'sync_error' ? props.language.sync_error_title : props.type === 'indexeddb_error' ? props.language.indexeddb_error_title : props.language.noti_refresh_title}
            language={props.language}
            size="lg"
            backdrop="static"
            closeButton={false}
        >
            <ModalBody>
                <div className="row">
                    <div className="col-sm-12 col-md-5 text-center">
                        <img src={Arinhelp_Cartoon} alt="" width="200"/>
                    </div>
                    <div className="col-sm-12 col-md-6">
                        <h4 style={{color}}>REFRESH YOUR BROWSER</h4><br/>
                        {
                            props.type === 'confirm' ?
                            <span>{props.language.noti_refresh}</span>
                            :
                            props.type === 'sync_error' ?
                            <span>{props.language.exception_sync_error}</span>
                            :
                            props.type === 'indexeddb_error' ?
                            <span>{props.language.exception_indexeddb_error}</span>
                            :
                            <span>{props.language.exception_add_to_cart}</span>
                        } {props.code ? props.code : ''}
                    </div>
                </div>
            </ModalBody>
            <ModalFooter className="confirm-modal-footer">
                {
                    props.type === 'confirm' ?
                    <ConfirmBtn
                        onCancel={props.onCancel}
                        onSubmit={props.onSubmit}
                        txtCancel={props.language.cancel_refresh_btn}
                        txtConfirm={props.language.submit_refresh_btn}
                        size={"180px"}
                    />
                    :
                    <Button onClick={ props.onSubmit} color="success" autoFocus>{ props.language.submit_refresh_btn}</Button>
                }

            </ModalFooter>
        </ModalTemplate>
    )
}

const color = "#ff4968";

export default NotiRefreshModal;