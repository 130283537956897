import React from 'react';
import {
    Button,
    Col,
    ListGroup,
    ListGroupItem,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Nav,
    NavItem,
    NavLink,
    Row,
} from 'reactstrap';
import _ from 'lodash';
import { connect } from 'react-redux';
import { closePrescriptionListModal, getPrescriptionInboxCount, addPrescriptionToCart } from 'Redux/actions';
import axios from 'axios';
import classnames from 'classnames';
import PrescriptionInfo from 'Components/Form/PrescriptionInfo';
import Pagination from 'react-js-pagination';
import { notify } from 'reapop';
import PrescriptionCancelModal from 'Components/Modal/PrescriptionCancelModal';
import PatientModal from 'Components/Modal/PatientModal';

import axiosHelper from 'Redux/middleware/axios';

const helper = new axiosHelper();

const API3_URL = process.env.API3_URL;

class PrescriptionListModal extends React.Component {
    state = {
        loading: false,
        dropdownOpen: false,
        listType: 'inbox', // inbox, done
        listBy: '',
        list: null,
        page: 1,
        prescription: null,
        showCancelModal: false,
        showPatientModal: false,
        patient: null
    };

    componentWillReceiveProps(nextProps) {
        const { isOpen } = nextProps;

        if (isOpen) {
            this.setListType('inbox')();
        } 
    }

    setListType = (type) => () => {
        this.setState({
            listType: type,
            listBy: '',
            page: 1,
            prescription: null,
        });
        setTimeout(() => {
            this.getList();
        });
    };

    setListBy = (e) => {
        const { value } = e.target;
        this.setState({
            listBy: value,
            page: 1,
            prescription: null,
        });
        setTimeout(() => {
            this.getList();
        });
    };

    changePage = (page) => {
        this.setState({
            page,
        });
        setTimeout(() => {
            this.getList();
        });
    };

    getList() {
        const { settings } = this.props;
        const { listType, listBy, page } = this.state;

        return axios({
            url: `${API3_URL}/companies/${settings.company.slug}/branches/${settings.branch.slug}/pos/eps/list/${listType}`,
            headers: { 'Authorization': `Bearer ${settings.token}` },
            params: {
                status: listBy,
                page,
                limit: 9,
            },
        }).then((response) => {
            this.setState({
                list: response.data,
            });
            return response.data
        });
    }

    isItemActive(item) {
        const { prescription } = this.state;
        return Boolean(prescription && prescription.id === item.id);
    }

    handleSelectItem = (item) => () => {
        this.setState({
            prescription: item,
        });
    };

    addPrescriptionToCart = () => {
        const { prescription } = this.state;
        this.props.addPrescriptionToCart(prescription);
    }

    showPatientName = async () => {

        const {settings} = this.props; 
        const {prescription} = this.state;

        const url = `/companies/${settings.company.slug}/branches/${settings.branch.slug}/lineman/patient?line_order_id=${prescription.reference_code}`
        try {
            const res = await helper.get(url, {}, {
                'Authorization': `Bearer ${settings.token}`
            }, 3);
    
            this.setState({
                showPatientModal: true,
                patient: {
                    name: res.name,
                    phone_number: res.contactNumber
                }
            });
        }catch(err) {
            console.error(err);

            notify({
                id: 'linemanEps',
                title: 'ผิดพลาด',
                message: 'ไม่สามารถแสดงชื่อผู้ซื้อได้',
                status: 'danger',
            });
        }
        
    }

    receiveCurrentEps = async () => {
        const { settings, employee, notify} = this.props;
        const { list, prescription } = this.state;

        this.setState({ loading: true });

        const url = `${API3_URL}/companies/${settings.company.slug}/branches/${settings.branch.slug}/pos/eps/receive`
        const response = await axios({
            method: 'post',
            url,
            headers: { 'Authorization': `Bearer ${settings.token}` },
            data: {
                reference_code: prescription.reference_code,
                employee_id: employee.id,
            },
        }).then((response) => {
            return response
        }).finally(() => {
            this.setState({ loading: false });
        });

        if (response.data.code === '30000') {
            return notify({
                id: 'eps',
                title: 'เกิดข้อผิดพลาด',
                message: 'ไม่สามารถรับใบสั่งยาได้ เพราะถูกยกเลิกไปแล้ว',
                status: 'warning',
            });
        } else if (response.data.code !== '00') {
            return notify({
                id: 'eps',
                title: 'เกิดข้อผิดพลาด',
                message: 'ไม่สามารถรับใบสั่งยาได้',
                status: 'warning',
            });
        }

        if(prescription.prescriber_company_name == 'lineman' || prescription.category.toUpperCase() == 'LM') {
            try {
                await this.confirmToLineman(prescription)
                this.afterConfirm();
            }catch (err) {
                console.error(err);

                notify({
                    id: 'eps',
                    title: 'เกิดข้อผิดพลาด',
                    message: 'EP lineman หมดอายุการยืนยัน',
                    status: 'warning',
                });
                await this.getList()
                this.setState({
                    prescription: null
                })
            }

        }else {    
            notify({
                id: 'eps',
                title: 'เรียบร้อย',
                message: 'รับใบสั่งยาแล้ว',
                status: 'success',
            });

            this.afterConfirm()
        }
        
    };

    afterConfirm = () => {
        const { listBy, prescription } = this.state;

        if (listBy !== '') {
            this.setState({
                listBy: '',
                page: 1,
                prescription: null
            });
        }

        setTimeout(() => {
            this.getList().then(res => {
                const newEps = _.find(res.data, { id: prescription.id });
                if (newEps) this.handleSelectItem(newEps)();   
            });
        });
    };

    confirmToLineman = async (data) => {
        
        const {settings, employee} = this.props;
        const obj = {
            ...data,
            employee_id: employee.id,
            employee_name: employee.first_name
        };

        const url = `/companies/${settings.company.slug}/branches/${settings.branch.slug}/lineman/confirmation`
        await helper.post(url, obj, {
            'Authorization': `Bearer ${settings.token}`
        }, 3);

        notify({
            id: 'eps',
            title: 'เรียบร้อย',
            message: 'รับใบสั่งยาแล้ว',
            status: 'success',
        });
    };

    cancelToLineman = async data => {
        const { settings } = this.props;

        const url = `/companies/${settings.company.slug}/branches/${settings.branch.slug}/lineman/cancellation`
        return helper.post(url, data, {
            'Authorization': `Bearer ${settings.token}`
        }, 3);

    };

    rejectToLineman = async data => {
        const { settings } = this.props;

        const url = `/companies/${settings.company.slug}/branches/${settings.branch.slug}/lineman/reject`
        return helper.post(url, data, {
            'Authorization': `Bearer ${settings.token}`
        }, 3);

    };


    rejectCurrentEps = (data) => {
        const { settings, employee, notify, reloadInboxCount } = this.props;
        const { prescription, listType } = this.state;

        this.setState({ loading: true });

        return axios({
            method: 'post',
            url: `${API3_URL}/companies/${settings.company.slug}/branches/${settings.branch.slug}/pos/eps/reject`,
            headers: { 'Authorization': `Bearer ${settings.token}` },
            data: {
                ...data,
                reference_code: prescription.reference_code,
                employee_id: employee.id,
            },
        }).then((response) => {
            notify({
                id: 'eps',
                title: 'เรียบร้อย',
                message: 'ปฏิเสธใบสั่งยาแล้ว',
                status: 'info',
            });
            
            this.getList();
            this.setState({
                prescription: null,
                showCancelModal: false,
            });
            reloadInboxCount();
        }).finally(() => {
            this.setState({ loading: false });
        });
    };

    onCancelEP = async (data) => {
        const { prescription, listType } = this.state;
        const { notify, employee} = this.props;
        if (prescription.prescriber_company_name == 'lineman' || prescription.category.toUpperCase() == 'LM') {

            if (listType.toLocaleLowerCase() === 'received') {
                try {
                    await this.cancelToLineman({
                        ...prescription,
                        ...data,
                        employee_id: employee.id
                    });
                } catch (err) {
                    console.error(err);
                    notify({
                        id: 'eps',
                        title: 'เกิดข้อผิดพลาด',
                        message: 'ไม่สามารถยกเลิกใบสั่งยาได้(Delivery)',
                        status: 'warning',
                    });
                    return;
                }
            }else {
                try {
                    await this.rejectToLineman({
                        ...prescription,
                        ...data,
                        employee_id: employee.id,
                        employee_name: employee.first_name
                    });
                } catch (err) {
                    console.error(err);
                    notify({
                        id: 'eps',
                        title: 'เกิดข้อผิดพลาด',
                        message: 'ไม่สามารถยกเลิกใบสั่งยาได้(Delivery)',
                        status: 'warning',
                    });
                    return;
                }
            }

            this.rejectCurrentEps(data);

        } else {
            this.rejectCurrentEps(data)
        }
    }

    render() {
        const { isOpen, language, onClose } = this.props;
        const { loading, listType, listBy, list, prescription, showCancelModal, showPatientModal, patient} = this.state;

        return (
            <>
                <Modal
                    className="new-modal"
                    isOpen={isOpen}
                    toggle={onClose}
                    size="lg"
                    style={{ maxWidth: '80%', minWidth: 800 }}
                >
                    <ModalHeader toggle={onClose} className="bg-primary">
                        e-Prescriptions list
                    </ModalHeader>
                    <Nav className="bg-primary" tabs>
                        <NavItem>
                            <NavLink
                                className={classnames({ active: listType === 'inbox' })}
                                onClick={this.setListType('inbox')}
                                style={{ cursor: 'pointer' }}
                            >
                                Inbox
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                className={classnames({ active: listType === 'done' })}
                                onClick={this.setListType('done')}
                                style={{ cursor: 'pointer' }}
                            >
                                Done
                            </NavLink>
                        </NavItem>
                    </Nav>
                    <ModalBody style={{ minHeight: 600 }}>
                        <Row>
                            <Col xs={4}>
                                {listType === 'inbox' && (
                                    <div
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            marginBottom: 10,
                                        }}
                                    >
                                        <svg
                                            className="ml-1 mr-3"
                                            width="28"
                                            height="28"
                                            viewBox="0 0 28 28"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                d="M5.66776 3.99999H22.3344L13.9844 14.5L5.66776 3.99999ZM1.08442 3.34999C4.45109 7.66666 10.6678 15.6667 10.6678 15.6667V25.6667C10.6678 26.5833 11.4178 27.3333 12.3344 27.3333H15.6678C16.5844 27.3333 17.3344 26.5833 17.3344 25.6667V15.6667C17.3344 15.6667 23.5344 7.66666 26.9011 3.34999C27.7511 2.24999 26.9678 0.666656 25.5844 0.666656H2.40109C1.01776 0.666656 0.234424 2.24999 1.08442 3.34999Z"
                                                fill="#007BFF"
                                            />
                                        </svg>
                                        <select className="form-control w-50" value={listBy} onChange={this.setListBy}>
                                            <option value="">รายการทั้งหมด</option>
                                            <option value="PICK_UP_LOCATION_SELECTED">{language.prescriptionStatus.PICK_UP_LOCATION_SELECTED}</option>
                                            <option value="READY_FOR_PICK_UP">{language.prescriptionStatus.READY_FOR_PICK_UP}</option>
                                        </select>
                                    </div>
                                )}
                                <ListGroup>
                                    {Boolean(list) && list.data.map((item) => {
                                        const backgroundColor = item.status === 'DISPENSED' ? '#67d786' : item.status === 'READY_FOR_PICK_UP' ? '#efab46' : '#ff0000'
                                        return (
                                            <ListGroupItem
                                                key={item.id}
                                                active={this.isItemActive(item)}
                                                tag="button"
                                                action
                                                onClick={this.handleSelectItem(item)}
                                                style={{ padding: 0 }}
                                            >
                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                    <div
                                                        style={{
                                                            backgroundColor,
                                                            marginRight: 10,
                                                            display: 'flex',
                                                            flexDirection: 'column',
                                                            justifyContent: 'center',
                                                            alignItems: 'center',
                                                            padding: '5px 10px',
                                                            minWidth: 83,
                                                            maxWidth: 83,
                                                        }}
                                                    >
                                                        {item.status === 'DISPENSED' ? (
                                                            <svg
                                                                width="25"
                                                                height="24"
                                                                viewBox="0 0 25 24"
                                                                fill="none"
                                                                xmlns="http://www.w3.org/2000/svg"
                                                            >
                                                                <path
                                                                    fillRule="evenodd"
                                                                    clipRule="evenodd"
                                                                    d="M15.32 3H18.5C19.6 3 20.5 3.9 20.5 5V10.68C19.88 10.39 19.21 10.18 18.5 10.08V5H16.5V8H8.5V5H6.5V20H11.19C11.54 20.75 12.02 21.43 12.61 22H6.5C5.4 22 4.5 21.1 4.5 20V5C4.5 3.9 5.4 3 6.5 3H9.68C10.1 1.84 11.2 1 12.5 1C13.8 1 14.9 1.84 15.32 3ZM11.5 4C11.5 4.55 11.95 5 12.5 5C13.05 5 13.5 4.55 13.5 4C13.5 3.45 13.05 3 12.5 3C11.95 3 11.5 3.45 11.5 4Z"
                                                                    fill="white"
                                                                />
                                                                <path
                                                                    fillRule="evenodd"
                                                                    clipRule="evenodd"
                                                                    d="M17.5 21C15.295 21 13.5 19.205 13.5 17C13.5 14.795 15.295 13 17.5 13C19.705 13 21.5 14.795 21.5 17C21.5 19.205 19.705 21 17.5 21ZM16.5 18.085L19.795 14.79L20.5 15.5L16.5 19.5L14.5 17.5L15.205 16.795L16.5 18.085Z"
                                                                    fill="white"
                                                                />
                                                                <path
                                                                    fillRule="evenodd"
                                                                    clipRule="evenodd"
                                                                    d="M17.5 12C14.74 12 12.5 14.24 12.5 17C12.5 19.76 14.74 22 17.5 22C20.26 22 22.5 19.76 22.5 17C22.5 14.24 20.26 12 17.5 12ZM13.5 17C13.5 19.205 15.295 21 17.5 21C19.705 21 21.5 19.205 21.5 17C21.5 14.795 19.705 13 17.5 13C15.295 13 13.5 14.795 13.5 17Z"
                                                                    fill="white"
                                                                />
                                                            </svg>
                                                        ) : item.status === 'READY_FOR_PICK_UP' ? (
                                                            <svg
                                                                width="25"
                                                                height="24"
                                                                viewBox="0 0 25 24"
                                                                fill="none"
                                                                xmlns="http://www.w3.org/2000/svg"
                                                            >
                                                                <path
                                                                    d="M17.5 12C14.74 12 12.5 14.24 12.5 17C12.5 19.76 14.74 22 17.5 22C20.26 22 22.5 19.76 22.5 17C22.5 14.24 20.26 12 17.5 12ZM19.15 19.35L17 17.2V14H18V16.79L19.85 18.64L19.15 19.35ZM18.5 3H15.32C14.9 1.84 13.8 1 12.5 1C11.2 1 10.1 1.84 9.68 3H6.5C5.4 3 4.5 3.9 4.5 5V20C4.5 21.1 5.4 22 6.5 22H12.61C12.02 21.43 11.54 20.75 11.19 20H6.5V5H8.5V8H16.5V5H18.5V10.08C19.21 10.18 19.88 10.39 20.5 10.68V5C20.5 3.9 19.6 3 18.5 3ZM12.5 5C11.95 5 11.5 4.55 11.5 4C11.5 3.45 11.95 3 12.5 3C13.05 3 13.5 3.45 13.5 4C13.5 4.55 13.05 5 12.5 5Z"
                                                                    fill="white"
                                                                />
                                                            </svg>
                                                        ) : (
                                                            <svg
                                                                width="25"
                                                                height="24"
                                                                viewBox="0 0 25 24"
                                                                fill="none"
                                                                xmlns="http://www.w3.org/2000/svg"
                                                            >
                                                                <path
                                                                    fillRule="evenodd"
                                                                    clipRule="evenodd"
                                                                    d="M15.32 3H18.5C19.6 3 20.5 3.9 20.5 5V10.68C19.88 10.39 19.21 10.18 18.5 10.08V5H16.5V8H8.5V5H6.5V20H11.19C11.54 20.75 12.02 21.43 12.61 22H6.5C5.4 22 4.5 21.1 4.5 20V5C4.5 3.9 5.4 3 6.5 3H9.68C10.1 1.84 11.2 1 12.5 1C13.8 1 14.9 1.84 15.32 3ZM11.5 4C11.5 4.55 11.95 5 12.5 5C13.05 5 13.5 4.55 13.5 4C13.5 3.45 13.05 3 12.5 3C11.95 3 11.5 3.45 11.5 4Z"
                                                                    fill="white"
                                                                />
                                                                <path
                                                                    fillRule="evenodd"
                                                                    clipRule="evenodd"
                                                                    d="M17.5 21C15.295 21 13.5 19.205 13.5 17C13.5 14.795 15.295 13 17.5 13C19.705 13 21.5 14.795 21.5 17C21.5 19.205 19.705 21 17.5 21ZM17 14.5H18V16.5H20V17.5H18V19.5H17V17.5H15V16.5H17V14.5Z"
                                                                    fill="white"
                                                                />
                                                                <path
                                                                    fillRule="evenodd"
                                                                    clipRule="evenodd"
                                                                    d="M12.5 17C12.5 14.24 14.74 12 17.5 12C20.26 12 22.5 14.24 22.5 17C22.5 19.76 20.26 22 17.5 22C14.74 22 12.5 19.76 12.5 17ZM13.5 17C13.5 19.205 15.295 21 17.5 21C19.705 21 21.5 19.205 21.5 17C21.5 14.795 19.705 13 17.5 13C15.295 13 13.5 14.795 13.5 17Z"
                                                                    fill="white"
                                                                />
                                                            </svg>
                                                        )}
                                                        <span style={{ fontSize: 12, color: '#fff' }}>
                                                            {language.prescriptionStatus[item.status]}
                                                        </span>
                                                    </div>
                                                    <span>{item.reference_code}</span>
                                                </div>
                                            </ListGroupItem>
                                        );
                                    })}
                                    {Boolean(list) && list.data.length === 0 && (
                                        <ListGroupItem disabled action className="text-center">No data.</ListGroupItem>
                                    )}
                                    {!list && (
                                        <ListGroupItem disabled action
                                                       className="text-center">Loading...</ListGroupItem>
                                    )}
                                </ListGroup>
                                {Boolean(list) && (
                                    <Pagination
                                        activePage={list.current_page || 1}
                                        pageRangeDisplayed={5}
                                        itemsCountPerPage={list.per_page || 1}
                                        totalItemsCount={list.total || 1}
                                        onChange={this.changePage}
                                        innerClass="pagination"
                                        itemClass="page-item"
                                        linkClass="page-link"
                                    />
                                )}
                            </Col>
                            <Col xs={8}>
                                <Row>
                                    <Col style={{ height: 500, overflow: 'auto', position: 'relative' }}>
                                        {prescription ? (
                                            <PrescriptionInfo
                                                prescription={prescription}
                                                hideData={listType === 'inbox'}
                                                showPatientName={this.showPatientName}
                                            />
                                        ) : (
                                            <div
                                                style={{
                                                    position: 'absolute',
                                                    top: '50%',
                                                    left: '50%',
                                                    transform: 'translate(-50%, -50%)',
                                                    fontWeight: 600,
                                                }}
                                            >
                                                กรุณาเลือกใบสั่งจากเเถบรายการด้านซ้าย
                                            </div>
                                        )}
                                    </Col>
                                </Row>
                                {listType === 'inbox' && prescription && (
                                    <div
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'row-reverse',
                                            alignItems: 'center',
                                            marginTop: 18,
                                        }}
                                    >
                                        {prescription.status === 'PICK_UP_LOCATION_SELECTED' ? (
                                            <Button
                                                color="success"
                                                style={{ minWidth: 120, marginLeft: 15 }}
                                                onClick={this.receiveCurrentEps}
                                                disabled={loading}
                                            >
                                                รับใบสั่งยา
                                            </Button>
                                        ) : prescription.status === 'READY_FOR_PICK_UP' ? (
                                            <Button
                                                color="info"
                                                style={{ minWidth: 120, marginLeft: 15 }}
                                                onClick={this.addPrescriptionToCart}
                                                disabled={loading}
                                            >
                                                หยิบรายการลงหน้าขาย
                                            </Button>
                                        ) : ''
                                        }
                                        <Button
                                            color="danger"
                                            style={{ minWidth: 120, marginLeft: 20 }}
                                            onClick={() => this.setState({ showCancelModal: true })}
                                            disabled={loading}
                                            outline
                                        >
                                            ไม่รับใบสั่งยา
                                        </Button>
                                        {prescription.status === 'PICK_UP_LOCATION_SELECTED' && (
                                            <span style={{ fontSize: 14, color: '#9b9b9b' }}>
                                                ***หากร้านยากด "รับใบสั่งยา" ระบบจะทำการเรียก Rider ทันที
                                                <br />
                                                กรุณาทำรายการเเละเตรียมสินค้าให้พร้อมก่อนที่ Rider จะมาถึง
                                            </span>
                                        )}
                                    </div>
                                )}
                            </Col>
                        </Row>
                    </ModalBody>
                </Modal>

                <PatientModal
                    isOpen={showPatientModal}
                    patient={patient}
                    language={language}
                    onSubmit={() => this.setState({showPatientModal: false})}
                />

                <PrescriptionCancelModal
                    isOpen={showCancelModal}
                    prescription={prescription}
                    onClose={() => this.setState({ showCancelModal: false })}
                    onSubmit={this.onCancelEP}
                />
            </>
        );
    }
}

const mapStateToProps = state => ({
    language: state.language,
    settings: state.settings,
    employee: state.employee.employee,
    isOpen: state.prescription.listModalOpen,
});

const mapDispatchToProps = {
    reloadInboxCount: getPrescriptionInboxCount,
    onClose: closePrescriptionListModal,
    notify,
    addPrescriptionToCart,
};

export default connect(mapStateToProps, mapDispatchToProps)(PrescriptionListModal);
