import React from 'react';
import { Row, Col } from 'reactstrap';
import Currency from 'react-currency-formatter';

import SelectFormGroup from 'Components/Input/SelectFormGroup';

class LinePayPage extends React.Component {
    constructor() {
        super();
        this._displayPrice = this._displayPrice.bind(this);
        this._onChange = this._onChange.bind(this);
        this._handleKeyDown = this._handleKeyDown.bind(this);
        this.oneTimeKey = React.createRef();
    }

    _displayPrice(price) {
        return Number(price)
            .toFixed(2)
            .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
    }

    _onChange(e) {
        const { name, value } = e.target;
        this.props.setLinePayValue(name, value);
        this.oneTimeKey.current.focus();
    }

    _handleKeyDown(e) {
        const keyCode = e.keyCode;
        if (keyCode === 13) {
            this.props.checkOutSubmit();
        }
    }

    render() {
        const { language, setLinePayValue, cart, linePay } = this.props;

        const linePayFeeType = [
            { id: 1, name: 'คิดรวมในยอดชำระสุทธิ', value: 'included' },
            { id: 2, name: 'คิดแยกจากยอดชำระสุทธิ', value: 'excluded' }
        ];

        return (
            <div className="line-pay-container">
                <div className="highlight">
                    <Row className="mb-2 text-left">
                        <Col sm="4" className="text-right">
                            <span style={{ lineHeight: '40px' }}>
                                ค่าบริการ Rabbit LINE Pay:
                            </span>
                        </Col>
                        <Col sm="8">
                            <SelectFormGroup
                                md="12"
                                name="fee_type"
                                value={linePay.fee_type}
                                onChange={this._onChange}
                                placeholder={language.credit_include}
                                options={linePayFeeType}
                                style={{
                                    marginBottom: 0,
                                    paddingLeft: 0
                                }}
                            />
                        </Col>
                    </Row>
                    <Row className="mb-3">
                        <Col sm="4" className="text-right">
                            Fee rate {linePay.fee_percentage}
                            %:
                        </Col>
                        <Col sm="8" className="text-left">
                            <Currency
                                quantity={Number(cart.credit_charge)}
                                currency="THB"
                            />
                        </Col>
                    </Row>
                    <h4 className="highlight-header">
                        {language.net_payment_summary}
                    </h4>
                    <h1 className="mb-3">
                        {this._displayPrice(cart.total_paid_amount)} {language.baht}
                    </h1>
                    <h6 className="highlight-header">
                        กรุณาสแกนหรือใส่รหัส 12 หลักจาก Rabbit LINE Pay
                        ของลูกค้า
                    </h6>
                    <input
                        autoFocus
                        className="form-control form-control-lg"
                        name="oneTimeKey"
                        value={linePay.oneTimeKey}
                        onChange={({ target }) =>
                            setLinePayValue(target.name, target.value)
                        }
                        onKeyDown={this._handleKeyDown}
                        maxLength="12"
                        placeholder="Scan or Enter 12 Digit One Time Key"
                        style={{
                            width: 400,
                            margin: 'auto',
                            textAlign: 'center'
                        }}
                        ref={this.oneTimeKey}
                    />
                </div>
                <div className="footer-shop">
                    <strong className="text-danger">
                        กรุณาตรวจสอบข้อมูลการชำระเงินและ Rabbit LINE Pay
                        จากลูกค้าให้ถูกต้อง
                    </strong>
                </div>
            </div>
        );
    }
}

export default LinePayPage;
