import { 
    SET_VALUE_IN_DG_MED,
    SUBMIT_DANGER_MED,
    CLOSE_DANGER_MED_MODAL,
    OPEN_DANGER_MED_MODAL
 } from 'Redux/actionTypes'

const initialState = {
    dg_med: []
}

export default (state=initialState, { type, payload }) => {
    switch (type) {
        case OPEN_DANGER_MED_MODAL:
            return Object.assign({}, state, { dg_med: payload})
        case CLOSE_DANGER_MED_MODAL:
        case SUBMIT_DANGER_MED:
            return Object.assign({}, initialState);
        case SET_VALUE_IN_DG_MED:
            return Object.assign({},state, { [payload.path]: payload.value})
        default:
            return state
    }
}