import { connect } from 'react-redux';
import { Button, Card, CardBody, CardFooter, Col, Row } from 'reactstrap';
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { notify } from 'reapop';
import {
    ServiceDeliveryPaymentStatus,
    ServiceDeliveryPaymentStatuses,
    ServiceDeliveryStatus,
    ServiceDeliveryStatuses,
    ServicesModalScreen,
} from './constants';
import moment from 'moment';
import {
    openServicesModal,
    AddMessageLogToCart,
    getServiceDeliveryCancellation
} from 'Redux/actions';
import serviceDeliveryDriverLogo from '../../../images/service-delivery-driver-logo.png';
import serviceDeliveryDriverWaiting from '../../../images/service-delivery-driver-waiting.png';
import serviceDeliveryStatus1 from '../../../images/service-delivery-status-1.png';
import serviceDeliveryStatus2 from '../../../images/service-delivery-status-2.png';
import serviceDeliveryStatus3 from '../../../images/service-delivery-status-3.png';
import serviceDeliveryStatus4 from '../../../images/service-delivery-status-4.png';
import { loadQR } from '../../../redux/actions';
import { ADD_TO_CART, REMOVE_TO_CART } from 'Redux/actionTypes';

import DeliveryUserCancelOrderModal from './DeliveryUserCancelOrderModal';

const API3_URL = process.env.API3_URL;

const DeliveryDetail = (props) => {
    const { dispatch, language, settings, cart, orderId } = props;

    const [timeout, setTimeoutInstance] = useState();
    const [order, setOrder] = useState(null);
    const [loading, setLoading] = useState(false);
    const [userCancelOrderModal, setUserCancelOrderModal] = useState(false);

    useEffect(() => {
        if (orderId) {
            getOrder();
        }
    }, [orderId]);

    useEffect(() => {
        return function cleanup() {
            if (timeout) {
                clearTimeout(timeout);
            }
        };
    });

    const getOrder = async () => {
        try {
            const response = await axios({
                method: 'GET',
                url: `${API3_URL}/companies/${settings.company.slug}/branches/${settings.branch.slug}/service-delivery/${orderId}`,
                headers: { 'Authorization': `Bearer ${settings.token}` },
            });

            setOrder(response.data.data);

            setTimeoutInstance(setTimeout(getOrder, 5000));
        } catch (error) {
            console.error(error);
            dispatch(notify({
                id: 'delivery',
                title: 'Service Delivery',
                message: 'โหลดออเดอร์ไม่สำเร็จ',
                status: 'error',
                dismissible: true,
                dismissAfter: 5000,
            }));
        }
    };

    const removeFromCart = () => {
        const detailToRemove = cart.details.find((detail) => !!detail.service_delivery_order_id);
        dispatch({
            type: REMOVE_TO_CART,
            payload: detailToRemove,
        });
    };

    const handleCancelOrder = async (reason = '') => {
        setLoading(true);

        dispatch(notify({
            id: 'delivery',
            title: 'Service Delivery',
            message: 'กำลังยกเลิก กรุณารอซักครู่...',
            status: 'info',
            dismissAfter: 300000,
        }));

        try {
            const response = await axios({
                method: 'POST',
                url: `${API3_URL}/companies/${settings.company.slug}/branches/${settings.branch.slug}/service-delivery/${orderId}/cancel`,
                headers: { 'Authorization': `Bearer ${settings.token}` },
                data: { reason },
            });

            setOrder(response.data.data);
            dispatch(getServiceDeliveryCancellation());
            setUserCancelOrderModal(false);

            removeFromCart();

            dispatch(notify({
                id: 'delivery',
                title: 'Service Delivery',
                message: 'ยกเลิกออเดอร์เรียบร้อย!',
                status: 'success',
                dismissible: true,
                dismissAfter: 5000,
            }));
        } catch (error) {
            console.error(error);
            dispatch(notify({
                id: 'delivery',
                title: 'Service Delivery',
                message: 'ยกเลิกออเดอร์ไม่สำเร็จ',
                status: 'error',
                dismissible: true,
                dismissAfter: 5000,
            }));
        }

        setLoading(false);
    };

    const handleRetryOrder = async () => {
        setLoading(true);

        dispatch(notify({
            id: 'delivery',
            title: 'Service Delivery',
            message: 'กำลังส่งไปยังผู้ให้บริการ กรุณารอซักครู่...',
            status: 'info',
            dismissAfter: 300000,
        }));

        try {
            const response = await axios({
                method: 'POST',
                url: `${API3_URL}/companies/${settings.company.slug}/branches/${settings.branch.slug}/service-delivery/${orderId}/retry`,
                headers: { 'Authorization': `Bearer ${settings.token}` },
            });

            const { data: newOrder, addToCart } = response.data;

            removeFromCart();

            // Add service fee to cart
            if (addToCart) {
                dispatch(AddMessageLogToCart(`POS Add to cart [d1] with employee_id is ${addToCart.employee_id}`))
                dispatch({
                    type: ADD_TO_CART,
                    payload: addToCart,
                });
            }

            dispatch(notify({
                id: 'delivery',
                title: 'Service Delivery',
                message: 'ยืนยันออเดอร์เรียบร้อย!',
                status: 'success',
                dismissible: true,
                dismissAfter: 5000,
            }));

            dispatch(openServicesModal(ServicesModalScreen.FOODPANDA, newOrder.id));
        } catch (error) {
            console.error(error);
            dispatch(notify({
                id: 'delivery',
                title: 'Service Delivery',
                message: 'ลองใหม่ไม่สำเร็จ',
                status: 'error',
                dismissible: true,
                dismissAfter: 5000,
            }));
        }

        setLoading(false);
    };

    const handlePayment = async () => {
        dispatch(loadQR({
            qrType: '3',
            reference1: order.reference_code,
            payFor: 'service_delivery',
        }));
    };

    const handleClickBack = () => {
        dispatch(openServicesModal(ServicesModalScreen.FOODPANDA));
    };

    if (!order) {
        return (
            <Card>
                <CardBody>
                    กำลังโหลด...
                </CardBody>
            </Card>
        );
    }

    const status = ServiceDeliveryStatuses.find((status) => status.id === order.status);
    const paymentStatus = ServiceDeliveryPaymentStatuses.find((status) => status.id === order.payment_status);

    return (
        <Card style={{ overflow: 'hidden', fontSize: '80%' }}>
            {[ServiceDeliveryStatus.CONFIRMED, ServiceDeliveryStatus.WAITING_FOR_TRANSPORT].includes(order.status) ? (
                <>
                    <img src={serviceDeliveryDriverWaiting} width="100%" />
                    <Row className="pt-5 px-3">
                        <Col xs={6}>
                            <h4 className="mb-1">กำลังหาคนขับเพื่อไปรับสินค้า...</h4>
                            <div style={{ fontSize: 14, color: '#0083c0' }}>*หากรอนานเกิน 10  นาที กรุณาลองใหม่อีกครั้ง</div>
                        </Col>
                        <Col xs={6} className="text-right" style={{ color: '#0184c0' }}>
                            <h4>{order.reference_code || '-'}</h4>
                        </Col>
                    </Row>
                </>
            ) : (
                <Row>
                    <Col xs={3} className="pr-0" style={{ background: '#54c1eb' }}>
                        <img src={serviceDeliveryDriverLogo} width="100%" />
                    </Col>

                    <Col xs={5} className="py-3 px-4">
                        <h4>รายละเอียดคนขับ</h4>

                        <Row>
                            <Col xs={5}>
                                <strong>ชื่อผู้ส่ง</strong>
                                <br />
                                {order.driver_name || '-'}
                            </Col>

                            <Col xs={7}>
                                <strong>รหัสการทำรายการ</strong>
                                <br />
                                {order.reference_code || '-'}
                            </Col>
                        </Row>

                        <Row className="mt-3">
                            <Col xs={12}>
                                <strong>วันที่สร้างออเดอร์</strong>
                                <br />
                                <td>{moment(order.created_at).format('DD/MM/YYYY HH:mm:ss')}</td>
                            </Col>
                        </Row>
                    </Col>

                    <Col
                        xs={4}
                        className="my-5"
                        style={{
                            borderLeft: '1px solid #ccc',
                        }}
                    >
                        <div className="text-center" style={{ marginTop: 10 }}>
                            <Button
                                className="font-weight-bold"
                                size="sm"
                                outline
                                disabled
                                color={status ? status.color : 'success'}
                                style={{
                                    borderWidth: 5,
                                    borderColor: status.forceColor,
                                    color: status.forceColor,
                                    opacity: 1,
                                }}
                            >
                                {status ? status.name : 'N/A'}
                            </Button>
                        </div>

                        <Row style={{ marginTop: 16 }}>
                            <Col xs={6}>
                                <strong>เวลารับสินค้า</strong>
                                <br />
                                {order.picked_up_at ? moment(order.picked_up_at).format('hh:mm:ss') : '-'}
                            </Col>

                            <Col xs={6}>
                                <strong>เวลาจัดส่งสินค้า</strong>
                                <br />
                                {order.delivered_at ? moment(order.delivered_at).format('hh:mm:ss') : '-'}
                            </Col>
                            <Col xs={12}>
                                <strong>หมายเหตุ</strong>
                                <br />
                                {!order.system_remark && !order.user_remark ? (
                                    '-'
                                ) : (
                                    <React.Fragment>
                                        {order.user_remark ? order.user_remark : '-'}
                                        {order.system_remark && (
                                            <React.Fragment>
                                                <br />
                                                <span
                                                    style={{ color: '#9b9b9b' }}
                                                >
                                                    {order.system_remark}
                                                </span>
                                            </React.Fragment>
                                        )}
                                    </React.Fragment>
                                )}
                            </Col>
                        </Row>
                    </Col>

                    <Col xs={12} className="pt-4 pb-1 text-center">
                        {[ServiceDeliveryStatus.ASSIGNED_TO_TRANSPORT, ServiceDeliveryStatus.NEAR_SENDER].includes(order.status) && (
                            <img src={serviceDeliveryStatus1} />
                        )}
                        {[ServiceDeliveryStatus.WAITING_TIMEOUT, ServiceDeliveryStatus.PROVIDER_CANCELLED].includes(order.status) && (
                            <>
                                <Button
                                    color="danger"
                                    disabled={loading}
                                    onClick={() => setUserCancelOrderModal(true)}
                                >
                                    ยกเลิกออเดอร์
                                </Button>
                                <Button
                                    color="warning"
                                    className="ml-2"
                                    disabled={loading}
                                    onClick={handleRetryOrder}
                                >
                                    ลองใหม่อีกครั้ง
                                </Button>
                                <DeliveryUserCancelOrderModal
                                    isOpen={userCancelOrderModal}
                                    onConfirm={handleCancelOrder}
                                    onClose={() => setUserCancelOrderModal(false)}
                                />
                            </>
                        )}
                        {[ServiceDeliveryStatus.PICKED_UP, ServiceDeliveryStatus.NEAR_RECEIVER].includes(order.status) && (
                            <img src={serviceDeliveryStatus2} />
                        )}
                        {order.status === ServiceDeliveryStatus.DELIVERED && (
                            <img src={serviceDeliveryStatus3} />
                        )}
                        {order.status === ServiceDeliveryStatus.CANCELLED && (
                            <img src={serviceDeliveryStatus4} />
                        )}
                    </Col>
                </Row>
            )}

            <CardBody>
                <Row>
                    <Col xs={6}>
                        <Row className="mt-2">
                            <Col xs={3}>
                                <strong>ชื่อผู้ส่ง</strong>
                            </Col>
                            <Col xs={7}>
                                {order.sender_name}
                            </Col>
                        </Row>

                        <Row className="mt-2">
                            <Col xs={3}>
                                <strong>ที่อยู่</strong>
                            </Col>
                            <Col xs={7}>
                                {order.sender_address}
                            </Col>
                        </Row>

                        <Row className="mt-2">
                            <Col xs={3}>
                                <strong>โทร.</strong>
                            </Col>
                            <Col xs={7}>
                                {order.sender_phone}
                            </Col>
                        </Row>
                    </Col>

                    <Col xs={6}>
                        <Row className="mt-2">
                            <Col xs={3}>
                                <strong>ชื่อผู้รับ</strong>
                            </Col>
                            <Col xs={7}>
                                {order.receiver_name}
                            </Col>
                        </Row>

                        <Row className="mt-2">
                            <Col xs={3}>
                                <strong>ที่อยู่</strong>
                            </Col>
                            <Col xs={7}>
                                {order.receiver_address}
                            </Col>
                        </Row>

                        <Row className="mt-2">
                            <Col xs={3}>
                                <strong>โทร.</strong>
                            </Col>
                            <Col xs={7}>
                                {order.receiver_phone}
                            </Col>
                        </Row>
                    </Col>
                </Row>

                <hr style={{ borderTop: '2px dashed #999' }} />

                <Row>
                    <Col xs={6}>
                        {[ServiceDeliveryStatus.CONFIRMED, ServiceDeliveryStatus.WAITING_FOR_TRANSPORT].includes(order.status) && (
                            <div className="py-2 mb-3">
                                <Row className="mt-2">
                                    <Col xs={3} className="pr-0">
                                        <strong>เวลาเรียกรถ</strong>
                                    </Col>
                                    <Col xs={9}>
                                        {moment(order.order_sent_at || order.created_at).format('hh:mm:ss')}
                                    </Col>
                                </Row>
                            </div>
                        )}
                    </Col>
                    <Col xs={6}>
                        <div
                            className="py-2 mb-3"
                        >
                            <Row className="mt-2">
                                <Col xs={6}>
                                    <strong>ระยะทาง:</strong>
                                </Col>
                                <Col xs={6} className="text-right">
                                    <strong style={{ color: '#0184c0' }}>{order.distance || 'N/A'}</strong>
                                    <strong className="d-inline-block" style={{ width: 50 }}>km.</strong>
                                </Col>
                            </Row>
                            <Row className="mt-2">
                                <Col xs={6}>
                                    <strong>ค่าบริการ:</strong>
                                </Col>
                                <Col xs={6} className="text-right">
                                    <strong style={{ color: '#0184c0' }}>{order.total_fee}</strong>
                                    <strong className="d-inline-block" style={{ width: 50 }}>THB</strong>
                                </Col>
                            </Row>
                            <Row className="mt-2">
                                <Col xs={6}>
                                    <strong>สถานะการชำระเงิน:</strong>
                                </Col>
                                <Col xs={6} className="text-right">
                                    <strong style={{ color: '#0184c0' }}>
                                        {paymentStatus ? paymentStatus.name : 'N/A'}
                                    </strong>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>
            </CardBody>
            <CardFooter className="d-flex">
                {order.payment_status === ServiceDeliveryPaymentStatus.WAITING && (
                    <Button
                        color="info"
                        onClick={handlePayment}
                    >
                        ชำระเงินอีกครั้ง
                    </Button>
                )}
                <div className="flex-grow-1" />
                <Button
                    color="success"
                    onClick={handleClickBack}
                >
                    กลับไปที่หน้าทำรายการ
                </Button>
            </CardFooter>
        </Card>
    );
};

const mapStateToProps = state => ({
    settings: state.settings,
    language: state.language,
    cart: state.cart,
});

export default connect(mapStateToProps)(DeliveryDetail);
