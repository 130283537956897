import React, { Fragment } from 'react'
import { Row, Col, Button } from 'reactstrap'

class SetupForm extends React.Component {
    constructor(props) {
        super(props)
        this.state ={
            companyId: props.companies[0].id || 0,
            branchId: 0,
            branches: [],
            loading: false
        }
        this._onChangeSelect = this._onChangeSelect.bind(this)
        this._onSubmit = this._onSubmit.bind(this)
    }

    componentDidMount() {
        const { companyId, branches } = this.state
        if (branches.length === 0) {
            const company = this.props.companies.find(company => company.id == companyId)
            this.setState({ branches: company.branches })
        }
    }

    _onChangeSelect(e) {
        const { name, value } = e.target
        this.setState({ [name]: value })
        if (value != 0 && name === 'companyId') {
            const company = this.props.companies.find(company => company.id == value)
            this.setState({ branches: company.branches })
        }
    }
    
    _onSubmit(e) {
        e.preventDefault()
        this.setState({ loading: true })
        const { companyId, branchId, branches } = this.state
        const company = this.props.companies.find(company => company.id == companyId)
        const branch = branches.find(branch => branch.id == branchId)
        this.props.setupCompanyBranch(company, branch)
        this.setState({ loading: false })
    }

    render() {
        const { companyId, branchId, branches, loading } = this.state
        const { language, companies } = this.props
        const companyList = companies.map(item => <option key={item.id} value={item.id}>{item.name}</option>)
        const branchList = branches.map(item => <option key={item.id} value={item.id}>{item.name}</option>)
        return (
            <Fragment>
                <div className="text-center my-4">
                    <h3 className="mb-2">{language.setup_title}</h3>
                    <span>{language.login_subtitle}</span>
                </div>
                <Row className="justify-content-center mx-0">
                    <form className="col-lg-8 text-left">
                        <div className="form-group">
                            <label>{language.select_company}</label>
                            <div className="input-group input-group-lg">
                                <div className="input-group-prepend">
                                    <span className="input-group-text">
                                        <i className="fa fa-building"></i>
                                    </span>
                                </div>
                                <select
                                    className="custom-select form-control-lg"
                                    name="companyId"
                                    value={companyId}
                                    onChange={this._onChangeSelect}>
                                    <option value={0}>-- {language.select_company} --</option>
                                    {companyList}
                                </select>
                            </div>
                        </div>
                        {companyId != 0 &&
                        <div className="form-group">
                            <label>{language.select_branch}</label>
                            <div className="input-group input-group-lg">
                                <div className="input-group-prepend">
                                    <span className="input-group-text">
                                        <i className="fa fa-map-marker"></i>
                                    </span>
                                </div>
                                <select
                                    className="custom-select form-control-lg"
                                    name="branchId"
                                    value={branchId}
                                    onChange={this._onChangeSelect}>
                                    <option value={0}>-- {language.select_branch} --</option>
                                    {branchList}
                                </select>
                            </div>
                        </div>}
                        <Row className="my-4">
                            <Col sm="2">
                                <Button
                                    color="danger"
                                    onClick={() => this.props.setCurrentPage(1)}
                                    block>
                                    <i className="fa fa-chevron-left"></i>
                                </Button>
                            </Col>
                            <Col sm="10">
                                <Button
                                    color="primary"
                                    onClick={this._onSubmit}
                                    disabled={companyId == 0 || branchId == 0 || loading}
                                    block>
                                    {loading ? `${language.saving}...` : language.save_change}
                                </Button>
                            </Col>
                        </Row>
                    </form>
                </Row>
            </Fragment>
        )
    }
}

export default SetupForm