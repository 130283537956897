import {
    PRESCRIPTION_CLEAR_DATA,
    PRESCRIPTION_CLOSE_CANCEL_MODAL,
    PRESCRIPTION_CLOSE_INFO_MODAL,
    PRESCRIPTION_CLOSE_LIST_MODAL,
    PRESCRIPTION_CLOSE_PICKUP_MODAL,
    PRESCRIPTION_OPEN_CANCEL_MODAL,
    PRESCRIPTION_OPEN_INFO_MODAL,
    PRESCRIPTION_OPEN_LIST_MODAL,
    PRESCRIPTION_OPEN_PICKUP_MODAL,
    PRESCRIPTION_SET_DATA,
    PRESCRIPTION_SET_INBOX_COUNT_MODAL,
    PRESCRIPTION_SET_PICKUP_NOTE,
    PRESCRIPTION_SET_UNIT,
    PRESCRIPTION_SET_DETAIL_STATUS_TYPE,
    INITIAL_CART_DETAIL,
} from 'Redux/actionTypes';

const initialState = {
    pickupModalOpen: false,
    cancelModalOpen: false,
    listModalOpen: false,
    infoModalOpen: false,
    qrCode: null,
    inboxCount: 0,
    data: null,
    pickupNote: '',
    ignoreCalculatePrice: false,
    unitId: null,
    detailId: null,
};

export default (state = initialState, { type, payload }) => {
    switch (type) {
        case PRESCRIPTION_OPEN_PICKUP_MODAL:
            return Object.assign({}, state, { pickupModalOpen: true, qrCode: payload });
        case PRESCRIPTION_CLOSE_PICKUP_MODAL:
            return Object.assign({}, state, { pickupModalOpen: false });
        case PRESCRIPTION_SET_DATA:
            window.prescriptionData = payload;
            window.ignoreCalculatePrice = ['1', '2'].includes(payload.model_no);
            return Object.assign({}, state, { data: payload, ignoreCalculatePrice: window.ignoreCalculatePrice });
        case PRESCRIPTION_CLEAR_DATA:
            delete window.prescriptionData;
            delete window.ignoreCalculatePrice;
            return Object.assign({}, state, { data: null, pickupNote: '', ignoreCalculatePrice: window.ignoreCalculatePrice });
        case PRESCRIPTION_OPEN_CANCEL_MODAL:
            return Object.assign({}, state, { cancelModalOpen: true });
        case PRESCRIPTION_CLOSE_CANCEL_MODAL:
            return Object.assign({}, state, { cancelModalOpen: false });
        case PRESCRIPTION_OPEN_LIST_MODAL:
            return Object.assign({}, state, { listModalOpen: true });
        case PRESCRIPTION_CLOSE_LIST_MODAL:
            return Object.assign({}, state, { listModalOpen: false });
        case PRESCRIPTION_OPEN_INFO_MODAL:
            return Object.assign({}, state, { infoModalOpen: true });
        case PRESCRIPTION_CLOSE_INFO_MODAL:
            return Object.assign({}, state, { infoModalOpen: false });
        case PRESCRIPTION_SET_INBOX_COUNT_MODAL:
            return Object.assign({}, state, { inboxCount: payload });
        case PRESCRIPTION_SET_PICKUP_NOTE:
            return Object.assign({}, state, { pickupNote: payload });
        case PRESCRIPTION_SET_UNIT:
            return Object.assign({}, state, { unitId: payload.unitId, detailId: payload.detailId });
        case INITIAL_CART_DETAIL:
            return Object.assign({}, state, { unitId: null, detailId: null });
        case PRESCRIPTION_SET_DETAIL_STATUS_TYPE:
            return Object.assign({}, state, {
                data: {
                    ...state.data,
                    details: state.data.details.map((detail) => {
                        if (detail.id === payload.detailId) {
                            return {
                                ...detail,
                                statusType: payload.statusType,
                            };
                        }
                        return detail;
                    }),
                },
            });
        default:
            return state;
    }
}
