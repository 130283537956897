import React from 'react'
import Loadable from 'react-loading-overlay';
import {  ModalBody,
    ModalFooter,
    Button,
    FormGroup,
    Input,
    Label
} from 'reactstrap';

import _ from 'lodash';

import {isBarcode} from 'Business';

import ModalTemplate from 'Components/Shared/ModalTemplate';

let arrUnit = [];
let choosed;


class AddRetailUnitModal extends React.Component{
    constructor(props){
        super(props);

        this.state = {
            units: arrUnit
        };

        this._renderUnits = this._renderUnits.bind(this);
        this._onChangeCheckout = this._onChangeCheckout.bind(this);
    }
    _renderUnits(unit,index){
        return (
            <FormGroup key={`${unit.name}_${index}`}>
                <Label>
                    <Input type="checkbox" onChange={() => this._onChangeCheckout(unit.unit_id)}  checked={this.state.units.includes(unit.unit_id)}/> {unit.name}
                </Label>
            </FormGroup>
        )
    }
    _onChangeCheckout(unit_id){
        if(arrUnit.includes(unit_id)){
            arrUnit.splice(arrUnit.indexOf(unit_id),1);
        }else{
            arrUnit.push(unit_id);
        }
        this.setState({units: arrUnit});
    }
    render(){
        const {props} = this;
        const units = props.product.units.map(this._renderUnits);
        return(
            <ModalTemplate
                onCancel={props.onCancel}
                show={props.show}
                size="md"
                title={props.product.name}
            >
                <ModalBody>
                    <span>{props.language.add_retail_unit_txt}</span>
                    {props.loader ? 
                        <Loadable active={props.loader} spinner color='black' style={{marginBottom: "55px"}}/>
                        :
                        <div style={{padding: '10px 25px 0'}}>{units}</div>
                    }
                </ModalBody>
                <ModalFooter style={{justifyContent: "initial"}}>
                    <Button color='success' onClick={() => props.onSubmit(this.state.units)}>{props.language.save}</Button>
                </ModalFooter>    
            </ModalTemplate>
        )
    }
    componentDidUpdate(preProps, preState){
        const {props, state} = this;
        if(state.units !== preState.units || preProps.product.units !== props.product.units){
            if(isBarcode(props.query)){
                choosed = props.product.units.find(unit => {return unit.barcode === props.query;});
                this._onChangeCheckout(choosed.unit_id);
            }else if(props.product.units.length > 0){
                choosed = _.minBy(props.product.units, unit => unit.quantity_of_smallest_unit);
                this._onChangeCheckout(choosed.unit_id)
            }
        }

    }
}


export default AddRetailUnitModal;