import { FOCUS_SEARCH_PRODUCT
, FOCUS_EMPLOYEE_USER
, FOCUS_AMOUNT_PRODUCT
, SELECT_PRODUCT
, EDIT_CART_DETAIL
, SEARCH_PRODUCTS
, FOCUS_PAID_AMOUNT
, CHECK_OUT
, SELECT_PRODUCT_SET
, ON_BLUR_SEARCH
, ERROR_ADD_TO_CART
, ON_BLUR_AMOUNT_PRODUCT
, CLOSE_CHECKED_OUT
, FOCUS_CART
, ON_BLUR_CART
, INITIAL_CART_DETAIL
, CHECKED_OUT
, OPEN_PRINT_SALE_RECEIPT
, CLOSE_PRINT_SALE_RECEIPT
, CLEAR_PRODUCTS
, FOCUS_SEARCH_AFTER_CLOSE
, CHECKIN_ERROR
, ON_BLUR_EMPLOYEE_USER
, ON_BLUR_CHECKEDOUT
, FOCUS_SEARCH_CUSTOMER
, SET_CURRENT_CUSTOMER
, PRINT_DRUG_LABEL
, CLOSE_PRINT_DRUG_LABEL
, OPEN_PROFILE_MODAL
, CLOSE_PROFILE_MODAL
, CLOSE_DANGER_MED_MODAL
, OPEN_DANGER_MED_MODAL
, FOCUS_DANGER_MED
, SUBMIT_DANGER_MED
, QR_PAYMENT_ERROR
, QR_PAYMENT_SUCCESS
, QR_PAYMENT_CLOSE
, OPEN_SALE_RETURN
, CLOSE_SALE_RETURN
, SEARCH_RECEIPTS
, ON_BLUR_PAID_AMOUNT
, CONTINUE_CART
, RECEIVE_CHECKIN
, CLOSE_ADS_POPUP
, SET_FIND_ALL_PRODUCTS
, CLOSE_CHECK_OUT
, CLOSE_VOICE_CALL_MODAL } from '../actionTypes'

const initialState = {
    searchProduct: false
    , employeeUser: false
    , amountProduct: false
    , searchProductModal: false
    , paidAmountInput: false
    , cart: false
    , checkedOutModal: false
    , searchCustomer: false
    , printDrungLabel: false
    , profileModal: false
    , dgMedModal: false
    , qrResultModal: false
    , qrPaymentModal: false
    , searchSaleReturn: false
}

export default (state=initialState, { type, payload }) => {

    switch (type) {
        case QR_PAYMENT_SUCCESS:
            return Object.assign({}, initialState, { qrResultModal: true});
        case OPEN_DANGER_MED_MODAL:
            return Object.assign({}, initialState, { dgMedModal: true});
        case OPEN_PROFILE_MODAL:
            return Object.assign({},initialState, { profileModal: true});
        case PRINT_DRUG_LABEL:
            return Object.assign({},initialState,{ printDrungLabel: true});
        case ON_BLUR_CHECKEDOUT: 
        case OPEN_PRINT_SALE_RECEIPT:
            return Object.assign({},initialState);
        case CLOSE_PRINT_SALE_RECEIPT:
        case CHECKED_OUT:
            return Object.assign({}, initialState,{ checkedOutModal: true })
        case ON_BLUR_CART:
            return Object.assign({}, state, { cart: false })
        case FOCUS_CART:
            return Object.assign({},initialState,{ cart: true });
        case ON_BLUR_AMOUNT_PRODUCT:
            return Object.assign({},state,{ amountProduct: false })
        case ON_BLUR_SEARCH: 
            return Object.assign({},state,{ searchProduct: false });
        case CLOSE_ADS_POPUP:
        case CLOSE_VOICE_CALL_MODAL:
        case RECEIVE_CHECKIN:
        case CLOSE_PROFILE_MODAL:
        case CLOSE_PRINT_DRUG_LABEL:
        case INITIAL_CART_DETAIL:
        case FOCUS_SEARCH_PRODUCT:
        case CLEAR_PRODUCTS:
        case FOCUS_SEARCH_AFTER_CLOSE:
        case SET_CURRENT_CUSTOMER:
        case CLOSE_DANGER_MED_MODAL:
        case QR_PAYMENT_CLOSE:
        case SET_FIND_ALL_PRODUCTS:
        case CONTINUE_CART:
        case CLOSE_CHECK_OUT:
            return  Object.assign({},initialState,{ searchProduct: true });
        case SELECT_PRODUCT:
        case SELECT_PRODUCT_SET:
        case EDIT_CART_DETAIL:
        case FOCUS_AMOUNT_PRODUCT: 
        case ERROR_ADD_TO_CART:
            return Object.assign({},initialState,{ amountProduct: true })
        case CHECKIN_ERROR: 
        case FOCUS_EMPLOYEE_USER:
            return Object.assign({},initialState,{ employeeUser: true })
        case ON_BLUR_EMPLOYEE_USER:
            return Object.assign({},state, { employeeUser: false })
        case SEARCH_PRODUCTS:
            return Object.assign({},initialState,{ searchProductModal: true })
        case CHECK_OUT:
        case SUBMIT_DANGER_MED:
        case FOCUS_PAID_AMOUNT:
            return Object.assign({},initialState,{ paidAmountInput: true })
        case ON_BLUR_PAID_AMOUNT:
            return Object.assign({},initialState,{ paidAmountInput: false })
        case FOCUS_SEARCH_CUSTOMER:
            return Object.assign({}, state, { searchCustomer: true })
        case OPEN_SALE_RETURN:
            return Object.assign({}, state, { searchSaleReturn: true })
        case SEARCH_RECEIPTS:
        case CLOSE_SALE_RETURN:
            return Object.assign({}, state, { searchSaleReturn: false })
        default:
            return state
    }
}