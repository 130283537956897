import { INITIAL_CART_DETAIL
, EDIT_CART_DETAIL
, SET_DETAIL_VALUE
, SELECT_PRODUCT
, SELECT_PRODUCT_SET
, UPDATE_SEARCH_WISHLIST
, UPDATE_PRODUCT_UNITS
, UPDATE_PRODUCT_QUANTITY } from '../actionTypes'
import { number } from 'Utilz/currency';

const initialState = {
    product: {
        units:[
            {
                name: 'ชุด'
                , retailPrice : { price: 0 } 
                , remaining_quantity: 0
                , id: 0
                , pivot : {
                  quantity_of_smallest_unit : 1
                }
            }
        ]
        , costs: {
            first_expire_lot: 0
        }
    }
    , unit: {
        name: 'ชุด'
        , id: 0
        , pivot: {}
        , prices: []
    }
    , product_set_id: null
    , product_id: null
    , unit_id: null
    , sales_quantity: 1
    , price_per_unit: 0
    , net_amount: 0
    , product_set_type: null
    , products: []
    , status: 'add'
    , sales_receipt_id: 0
    , id: 0
}

export default (state=initialState, { type, payload }) => {
    switch (type) {
        case SELECT_PRODUCT:
            return Object.assign({},state,{ product: payload.product, sales_quantity: payload.quantity })
        case SELECT_PRODUCT_SET: {
            return Object.assign({},state, payload )
        }
        case SET_DETAIL_VALUE:
            return Object.assign({},state,{[payload.path] : payload.value})
        case EDIT_CART_DETAIL:
            return Object.assign({},payload,{ status:'edit' });
        case INITIAL_CART_DETAIL: 
            return Object.assign({},initialState);
        case UPDATE_SEARCH_WISHLIST:
            return Object.assign({}, state, { product: { ...state.product, wishlist_item: payload.wishlist_item } })
        case UPDATE_PRODUCT_UNITS:
            return Object.assign({}, state, { product: { ...state.product, units: payload } });
        case UPDATE_PRODUCT_QUANTITY:
            return Object.assign({}, state, {
                product: {
                    ...state.product,
                    remaining_quantity: number(state.product.remaining_quantity).add(payload).value,
                },
            });
        default:
            return state
    }
}