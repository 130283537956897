import React from 'react';
import { Modal, ModalHeader, ModalBody, Row, Col, FormGroup, Button } from 'reactstrap';

import alipayLogo from '../../images/alipay_logo.png'
import wechatPayLogo from '../../images/wechat_pay_logo.png'

const logo = {
    alipay: alipayLogo,
    wechat_pay: wechatPayLogo
};

const btnStyle = {
    width: '210px',
    margin: '0 5px'
};
class AlipayWechatPaymentModal extends React.Component
{
    render(){
        const {props, state} = this;
        const {payment_types, language, show, close, onOpen, refresh} = props;
        return(
            <Modal
                isOpen={show} 
                size="md"
                style={{ minWidth: '600px', maxWidth: '1200px', width: '80%' }}
                toggle={close}
                onOpened={onOpen}
            >
                <ModalHeader toggle={close}></ModalHeader>
                <ModalBody>
                    <Row>
                        <Col className='text-center'><img src={logo[payment_types]} width={'85px'}/></Col>
                    </Row>
                    <Row>
                        <Col className="text-center">
                            ...Loading
                        </Col>
                    </Row>
                    <Row>
                        <Col className="text-center">
                            Processing Payment, Please Wait...
                        </Col>
                    </Row>
                    <Row>
                        <Col className="text-center">
                            <Button color={'success'} onClick={refresh} style={btnStyle}>{language.refresh}</Button>
                            <Button color={'danger'} onClick={close} style={btnStyle}>{language.cancel}</Button>
                        </Col>
                    </Row>
                </ModalBody>
            </Modal>
        )
    }
}

export default AlipayWechatPaymentModal;