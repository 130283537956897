import {
    SET_VOICE_CALL_ON,
    VOICE_CALL_INCOMING,
    VOICE_CALL_HANG_UP,
    VOICE_CALL_MISSED,
    SET_VOICE_CALL_STATUS,
    RECEIVE_VOICE_RECORDING_URL,
    POPUP_VOICE_CALL_WIDGET,
    MINIMIZE_VOICE_CALL_WIDGET,
    CLOSE_VOICE_CALL_WIDGET,
    POPUP_CHAT_WIDGET,
    MINIMIZE_CHAT_WIDGET,
    SET_CURRENT_USER_CHAT,
    REMOVE_USER_CHAT,
    RECEIVE_MESSAGE_CHAT,
    SET_CHAT_ON,
    SET_CHAT_LOADING,
    USER_LEAVE_CHAT,
} from "../actionTypes";

import Twilio, { getUserMedia } from "../../utils/twilio";
import { notify } from "reapop";
import moment from "moment";
import _ from "lodash";
import { getCurrentUser, getCurrentMessages } from "../reducers/chat";

const client = new Twilio();

export const acceptCall = () => {
    client.$connection.accept();
};

export const rejectCall = () => {
    client.$connection.reject();
};

export const hangUpCall = () => {
    client.$device.disconnectAll();
};

export const initTelepharmacy = () => (dispatch, getState) => {
    const { branch } = getState().settings;
    const { employee } = getState().employee;
    const branchSlug = branch.slug;
    const voice = branch.telepharmacy.voice;
    const chat = branch.telepharmacy.chat;

    client.setIsAvailable("voice", voice);
    client.setIsAvailable("chat", chat);
    client.setPharmacist(employee);

    if (client.state.token) {
        if (voice && client._device._status === "ready") {
            client.setClientOnline("voice");
            dispatch({ type: SET_VOICE_CALL_ON, payload: true });
        }
        if (
            chat &&
            client._chat &&
            client._chat.connectionState === "connected"
        ) {
            client.setClientOnline("chat");
            dispatch({ type: SET_CHAT_ON, payload: true });
        }
        return;
    }

    if (voice || (voice && chat)) {
        getUserMedia({ audio: true }, (error) => {
            if (error) {
                return dispatch(handlePermissionDenied(error));
            }
            client.connectSocket(branchSlug);
            if (voice) dispatch(handleVoiceCallEvents());
            if (chat) dispatch(handleChatEvents());
        });
    } else if (chat) {
        client.connectSocket(branchSlug);
        dispatch(handleChatEvents());
    }
};

export const disconnectClient = () => {
    if (!client.state.token) return;
    client.setClientOffline("voice");
    client.setClientOffline("chat");
};

const handleVoiceCallEvents = () => (dispatch) => {
    // Device Events
    client.on("deviceReady", (device) => {
        dispatch({ type: SET_VOICE_CALL_ON, payload: true });
    });

    client.on("deviceIncoming", (connection) => {
        const { parameters, customParameters, _status } = connection;
        const { CallSid } = parameters;
        const patientId = customParameters.get("patientId");
        const patientName = customParameters.get("patientName");
        const patientPhone = customParameters.get("patientPhone");
        client.setClientOffline("voice");
        dispatch({
            type: VOICE_CALL_INCOMING,
            payload: {
                callSid: CallSid,
                status: _status,
                patient: {
                    id: patientId,
                    name: patientName,
                    phone: patientPhone,
                },
            },
        });
    });

    client.on("deviceConnect", (connection) => {
        const { _status } = connection;
        dispatch(setVoiceCallStatus(_status));
    });

    client.on("deviceCancel", (connection) => {
        const { parameters, customParameters } = connection;
        const { CallSid } = parameters;
        const patientId = customParameters.get("patientId");
        const patientName = customParameters.get("patientName");
        client.setClientOnline("voice");
        dispatch({
            type: VOICE_CALL_MISSED,
            payload: {
                callSid: CallSid,
                patient: { id: patientId, name: patientName },
                timestamp: +new Date(),
            },
        });
    });

    client.on("deviceError", (error) => {
        const { code, message } = error;
        console.log(code, message);
        client.setClientOffline("voice");
        switch (code) {
            case 31205:
                client.updateVoiceClientToken();
                break;
        }
    });

    client.on("deviceOffline", (device) => {
        client.setClientOffline("voice");
        dispatch({ type: SET_VOICE_CALL_ON, payload: false });
    });

    // Connection Events
    client.on("connectionReject", () => {
        client.setClientOnline("voice");
        dispatch(closeVoiceCallWidget());
    });

    client.on("connectionDisconnect", (connection) => {
        const { _status } = connection;
        client.setClientOnline("voice");
        dispatch({
            type: VOICE_CALL_HANG_UP,
            payload: {
                status: _status,
            },
        });
    });

    client.on("connectionError", (error) => {
        const { code, message } = error;
        console.log(code, message);
    });

    // Socket Events
    client.on("socketSendVoiceRecordingUrl", (recordingUrl) => {
        dispatch({ type: RECEIVE_VOICE_RECORDING_URL, payload: recordingUrl });
    });

    client.on("socketDisconnect", () => {
        console.log("socket disconnect");
    });
};

const handleChatEvents = () => (dispatch) => {
    client.on("chatConnectedState", () => {
        dispatch({ type: SET_CHAT_ON, payload: true });
    });

    client.on("chatDisconnectedState", () => {
        client.setClientOffline("chat");
        dispatch({ type: SET_CHAT_ON, payload: false });
    });

    client.on("chatConnectionError", () => {
        client.setClientOffline("chat");
        dispatch({ type: SET_CHAT_ON, payload: false });
    });

    client.on("userLeaveChat", (userId) => {
        dispatch({ type: USER_LEAVE_CHAT, payload: userId})
    })

    client.on("chatMessageAdded", async (message) => {
        const { identity } = client.state;
        let { sid, index, type, body, dateCreated, channel, author } = message;
        const { patientId, patientName, patientPhone } = channel.attributes;
        const channelSid = channel.sid;
        const lastCreated = +dateCreated;

        const isOwned = author === identity;
        const isMedia = type === "media";

        if (isMedia) {
            body = await message.media.getContentTemporaryUrl();
            body = await convertImageToBase64(body, message.media.contentType);
        }

        dispatch({
            type: RECEIVE_MESSAGE_CHAT,
            payload: {
                sid,
                index,
                type,
                body,
                incoming: isOwned ? false : true,
                created: lastCreated,
                kind: "message",
                user: {
                    id: patientId,
                    name: patientName,
                    phone: patientPhone,
                    lastMessage: isMedia ? "รูปภาพ" : message.body,
                    lastCreated,
                    channelSid,
                },
            },
        });
    });
};

const handlePermissionDenied = (error) => (dispatch) => {
    switch (error.name) {
        case "NotAllowedError":
            return dispatch(
                notify({
                    id: "voiceCall",
                    title: "ไมโครโฟนถูกบล็อก จะไม่สามารถรับสายได้",
                    message: `ระบบต้องการเข้าถึงไมโครโฟน<br>- คลิกไอคอน <img style="vertical-align: middle;" src="img/blocked_camera.svg"> ในแถบ URL<br>- อนุญาตการเข้าถึง จากนั้นรีเฟรชหน้าเว็บ`,
                    status: "error",
                    allowHTML: true,
                    dismissible: true,
                    dismissAfter: 0,
                })
            );
        case "NotFoundError":
            return dispatch(
                notify({
                    id: "voiceCall",
                    title: "ไม่พบไมโครโฟน",
                    message: `เพื่อให้บริการปรึกษาทางไกลด้วยเสียง กรุณาเชื่อมต่อไมโครโฟนและรีเฟรชหน้าเว็บอีกครั้ง`,
                    status: "error",
                    allowHTML: true,
                    dismissible: true,
                    dismissAfter: 0,
                })
            );
        default:
            return dispatch(
                notify({
                    id: "voiceCall",
                    title: "เกิดข้อผิดพลาด",
                    message: `มีปัญหาบางอย่างในการเข้าถึงไมโครโฟน จะไม่สามารถให้บริการปรึกษาทางไกลด้วยเสียงได้`,
                    status: "error",
                    allowHTML: true,
                    dismissible: true,
                    dismissAfter: 0,
                })
            );
    }
};

export const closeVoiceCallWidget = () => (dispatch) => {
    return dispatch({ type: CLOSE_VOICE_CALL_WIDGET });
};

const setVoiceCallStatus = (status) => (dispatch) => {
    return dispatch({ type: SET_VOICE_CALL_STATUS, payload: status });
};

export const toggleVoiceBusy = () => (dispatch, getState) => {
    if (!client.state.token) return;
    const { isOn } = getState().voice;

    if (!isOn) {
        client.setClientOnline("voice");
    } else {
        client.setClientOffline("voice");
    }
    return dispatch({ type: SET_VOICE_CALL_ON, payload: !isOn });
};

export const toggleChatBusy = () => (dispatch, getState) => {
    if (!client.state.token) return;
    const { isOn } = getState().chat;

    if (!isOn) {
        client.setClientOnline("chat");
    } else {
        client.setClientOffline("chat");
    }
    return dispatch({ type: SET_CHAT_ON, payload: !isOn });
};

export const popupVoiceCallWidget = () => (dispatch) => {
    return dispatch({ type: POPUP_VOICE_CALL_WIDGET });
};

export const minimizeVoiceCallWidget = () => (dispatch) => {
    return dispatch({ type: MINIMIZE_VOICE_CALL_WIDGET });
};

export const popupChatWidget = () => (dispatch) => {
    return dispatch({ type: POPUP_CHAT_WIDGET });
};

export const minimizeChatWidget = () => (dispatch) => {
    return dispatch({ type: MINIMIZE_CHAT_WIDGET });
};

export const typing = () => (dispatch, getState) => {
    const channelSid = getChannelSid(getState());
    if (channelSid) {
        client.typing(channelSid);
    }
};

export const sendMessage = (data) => (dispatch, getState) => {
    const channelSid = getChannelSid(getState());
    if (channelSid) {
        dispatch({ type: SET_CHAT_LOADING, payload: true });
        client
            .sendMessage(data, channelSid)
            .finally(() =>
                dispatch({ type: SET_CHAT_LOADING, payload: false })
            );
    }
};

const getChannelSid = (state) => {
    const { currentUser, users } = state.chat;
    const user = users.find((user) => user.id === currentUser);
    return user ? user.channelSid : "";
};

export const setCurrentUser = (userId) => (dispatch) => {
    return dispatch({ type: SET_CURRENT_USER_CHAT, payload: userId });
};

export const removeUser = (userId, channelSid) => async dispatch => {
    if (confirm("คุณต้องการจะลบการสนทนานี้หรือไม่?")) {
        await client.leaveAndClearChannel(channelSid);
        return dispatch({ type: REMOVE_USER_CHAT, payload: userId });
    }
};

const getCurrentDateTime = (format = "YYYY-MM-DD HH:mm:ss") => {
    return moment().format(format);
};

export const dateTimeFormat = (timestamp) => {
    if (!timestamp) return "";
    return moment(timestamp).format("DD/MM/YYYY HH:mm");
};

export const timeFormat = (timestamp) => {
    if (!timestamp) return "";
    return moment(timestamp).format("HH:mm");
};

const saveAs = (blob, fileName) => {
    const link = document.createElement("a");
    link.href = blob;
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
};

export const downloadChatHistory = () => (dispatch, getState) => {
    const { chat, employee } = getState();
    const user = getCurrentUser(chat);
    let messages = getCurrentMessages(chat);
    if (!messages.length) return;
    const patientName = user && user.name ? user.name : "ไม่ทราบชื่อ";
    const createdAt = getCurrentDateTime("DD/MM/YYYY HH:mm");
    const title = `ประวัติการสนทนาของ ${patientName}\nบันทึกเมื่อ ${createdAt}`;
    messages = messages
        .map((message) => {
            if (message.kind === "action") {
                if (!["newSession", "endSession"].includes(message.type))
                    return;
                if (message.type === "newSession") {
                    return `เริ่มการสนทนาใหม่ ${dateTimeFormat(
                        message.created
                    )}`;
                }
                if (message.type === "endSession")
                    return "====== สิ้นสุดการสนทนา ======\n";
            }
            const created = dateTimeFormat(message.created);
            const pharmacist = `${employee.employee.first_name} ${employee.employee.last_name}`;
            const name = !message.incoming ? pharmacist : message.user.name;
            const body = message.type === "media" ? `[รูป]` : message.body;
            return `${created}  ${name}  ${body}`;
        })
        .join("\n");
    const text = `${title}\n\n${messages}`;
    const fileName = `${user.channelSid}.txt`;
    const blob = new Blob([text], {
        type: "text/plain;charset=utf-8",
        endings: "native",
    });
    const blobUrl = window.URL.createObjectURL(blob);
    saveAs(blobUrl, fileName);
};

export const downloadChatImage = async (url, pathName) => {
    const extensionName = url.split(";")[0].split("/")[1];
    const fileName = `${pathName}.${extensionName}`;
    saveAs(url, fileName);
};

const convertImageToBase64 = (url, outputFormat) => {
    return new Promise((resovle, reject) => {
        const image = new Image();
        image.crossOrigin = "Anonymous";
        image.onload = () => {
            let canvas = document.createElement("canvas");
            let ctx = canvas.getContext("2d");
            canvas.width = image.width;
            canvas.height = image.height;
            ctx.drawImage(image, 0, 0);
            const dataURL = canvas.toDataURL(outputFormat);
            resovle(dataURL);
            canvas = null;
        };
        image.onerror = () => reject(url);
        image.src = url;
    });
};

export const uniqBy = (array, key) => _.uniqBy(array, key);
export const getLastOfArray = (array) => _.last(array);
export const removeObjectKey = (object, key) => _.omit(object, key);
