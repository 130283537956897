import React from 'react';
import db from '../utils/db'

class ServicePage extends React.Component {
    constructor(props) {
        super(props);
        this.state = { carts: [] };
    }

    componentDidMount() {
        this.getCartsFromIndexDB();
    }

    
    getCartsFromIndexDB(){
        const carts =   new Promise((resovle) => {
                            const carts = db.table('carts').toArray();
                            resovle(carts);
                        });
        
        if(carts) {
            carts.then(carts => {
                this.setState({ carts: carts });
            });
        }
    }

    getStatus(index){
        const statusList = [
            '',
            'กำลังดำเนินการ',
            'รอการชำระเงิน',
            'ชำระเงินเสร็จสิ้น',
            'ยกเลิก',
            'ผิดพลาด'
        ];
        return statusList[index];
    }
  
    render() {
        return (
            <div>
                Hello
                <table width="100%">
                    <tr>
                        <th width="10%">วันที่สร้าง</th>
                        <th width="10%">หมายเลขอ้างอิง</th>
                        <th width="10%">สถานะล่าสุด</th>
                        <th width="40%">รายการ</th>
                        <th width="10%">Logs</th>
                        <th width="10%">Histories</th>
                        <th width="10%">Message</th>
                    </tr>
                {
                    this.state.carts.map((cart) => {
                        return  <tr>
                                    <td>{cart.created_at}</td>
                                    <td>{cart.reference_code}</td>
                                    <td>{this.getStatus(cart.current_status_id)}</td>
                                    <td>
                                        <table>
                                            <tr>
                                                <th width="10%">รหัสสินค้า</th>
                                                <th width="40%">ชื่อสินค้า</th>
                                                <th width="10%">จำนวนซื้อ</th>
                                                <th width="10%">หน่วย</th>
                                                <th width="10%">ราคาขาย/หน่วย</th>
                                                <th width="10%">ราคาขายรายการนี้</th>
                                            </tr>
                                            {
                                                cart.details.map(item => {
                                                    return  <tr>
                                                                <td>{item.product.reference_code}</td>
                                                                <td>{item.product.name}</td>
                                                                <td>{item.sales_quantity}</td>
                                                                <td>{item.unit.name}</td>
                                                                <td>{item.price_per_unit}</td>
                                                                <td>{item.net_amount}</td>
                                                            </tr>
                                                })
                                            }
                                        </table>
                                    </td>
                                    <td>
                                        {JSON.stringify(cart.pos_logs)}
                                    </td>
                                    <td>{JSON.stringify(cart.histories)}</td>
                                    <td>{cart.message}</td>
                                </tr>
                    })
                }
                </table>

                <hr />
                { 
                    // JSON.stringify(this.state.carts, null, 2)
                }


<style>
{`
table, th, td {
    border: 1px solid black;
}

table {
    width: 100%;
}
`}
</style>

            </div>
        );
    }
}

export default ServicePage;