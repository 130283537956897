import React from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { connect } from 'react-redux';
import { closePrescriptionInfoModal } from 'Redux/actions';
import PrescriptionInfo from 'Components/Form/PrescriptionInfo';

const API3_URL = process.env.API3_URL;

class PrescriptionInfoModal extends React.Component {
    render() {
        const { prescription, isOpen, language, onClose } = this.props;

        return (
            <>
                <Modal
                    className="new-modal"
                    isOpen={isOpen}
                    toggle={onClose}
                    size="lg"
                >
                    <ModalHeader toggle={onClose} className="bg-primary">
                        e-Prescription information
                    </ModalHeader>
                    <ModalBody>
                        <PrescriptionInfo prescription={prescription} />
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            color="success"
                            onClick={onClose}
                        >
                            ดำเนินการต่อ
                        </Button>
                    </ModalFooter>
                </Modal>
            </>
        );
    }
}

const mapStateToProps = state => ({
    language: state.language,
    prescription: state.prescription.data,
    isOpen: state.prescription.infoModalOpen,
});

const mapDispatchToProps = {
    onClose: closePrescriptionInfoModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(PrescriptionInfoModal);
