import {
    SET_LINE_PAY_VALUE,
    LINE_PAY_REQUEST,
    LINE_PAY_RECEIVE,
    UPDATE_LINE_PAY_FEE
} from 'Redux/actionTypes';
import { checkedOut } from 'Redux/actions';

import axiosHelper from 'Redux/middleware/axios';
import { addNotification } from 'reapop';

const helper = new axiosHelper();

export const setLinePayValue = (path, value) => dispatch => {
    return dispatch({
        type: SET_LINE_PAY_VALUE,
        payload: {
            path,
            value
        }
    });
};

export const changeLinePayFee = feePercentage => dispatch => {
    dispatch({
        type: UPDATE_LINE_PAY_FEE,
        payload: {
            feePercentage
        }
    });
};

export const payWithLinePay = () => (dispatch, getState) => {
    const { settings, cart, employee: mainEmp, linePay } = getState();

    const {
        token,
        company: { slug: company_slug },
        branch: { slug: branch_slug }
    } = settings;

    const { employee } = mainEmp;

    const url = `/companies/${company_slug}/branches/${branch_slug}/pos/line-pay/pay`;

    const data = {
        oneTimeKey: linePay.oneTimeKey,
        amount: Number(cart.total_paid_amount),
        original_amount: Number(cart.total_price),
        fee_type: linePay.fee_type,
        fee_percentage: linePay.fee_percentage,
        reference1: cart.reference_code,
        employee_id: employee.id
    };

    if (!data.oneTimeKey) {
        dispatch(
            addNotification({
                title: 'คำเตือน',
                message: 'กรุณาสแกนหรือใส่รหัสจากลูกค้า',
                status: 'warning',
                dismissAfter: 3000,
                id: 'linePayNotification'
            })
        );
        return;
    } else if (data.oneTimeKey.length !== 12) {
        dispatch(
            addNotification({
                title: 'คำเตือน',
                message: 'กรุณาใส่ One Time Key ให้ครบ 12 หลัก',
                status: 'warning',
                dismissAfter: 3000,
                id: 'linePayNotification'
            })
        );
        return;
    }

    dispatch({ type: LINE_PAY_REQUEST });

    helper
        .post(url, data, { Authorization: `Bearer ${token}` }, 3)
        .then(res => {
            if (res.returnCode === '0000') {
                dispatch(checkedOut());
            } else if (res.returnCode === '3003') {
                dispatch(
                    addNotification({
                        title: 'ไม่สามารถชำระเงินได้',
                        message:
                            `ค่าบริการ Rabbit LINE Pay มีการเปลี่ยนแปลงเป็น ${res.value}% กรุณาตรวจสอบยอดชำระและลองใหม่อีกครั้ง`,
                        status: 'warning',
                        dismissAfter: 30000,
                        id: 'linePayNotification'
                    })
                );
                dispatch(changeLinePayFee(res.value));
            } else {
                dispatch(
                    addNotification({
                        title: 'ไม่สามารถชำระเงินได้',
                        message:
                            res.returnMessage +
                            ' (Error Code: ' +
                            res.returnCode +
                            ' )',
                        status: 'warning',
                        dismissAfter: 10000,
                        id: 'linePayNotification'
                    })
                );
            }
        })
        .catch((err) => {
            console.error(err);
            dispatch(
                addNotification({
                    title: 'ไม่สามารถชำระเงินได้',
                    message: 'ไม่สามารถใช้ Rabbit LINE Pay ได้ในขณะนี้',
                    status: 'error',
                    dismissAfter: 10000,
                    id: 'linePayNotification'
                })
            );
        })
        .finally(() => {
            dispatch({ type: LINE_PAY_RECEIVE });
        });
};
