import { SEARCH_PRODUCTS
    , CLEAR_PRODUCTS
    , OPEN_ADD_TO_CART
    , CLOSE_ADD_TO_CART
    , SELECT_PRODUCT
    , RECEIVE_CHECKIN
    , EMPLOYEE_LOGOUT
    , NOT_FOUND_PRODUCT
    , CHECK_OUT
    , CLOSE_CHECK_OUT
    , EDIT_CART_DETAIL
    , OPEN_DANGER_MED_MODAL
    , SELECT_PRODUCT_SET
    , EDIT_PRODUCT_SET
    , CHECKED_OUT
    , CLOSE_CHECKED_OUT
    , PRINT_DRUG_LABEL
    , CLOSE_PRINT_DRUG_LABEL
    , INITIAL_CART_DETAIL
    , OPEN_AWAITING_CART
    , CLOSE_AWAITING_CART
    , OPEN_SALE_RETURN
    , CLOSE_SALE_RETURN
    , OPEN_CONFIRM_TAX_INVOICE
    , CLOSE_CONFIRM_TAX_INVOICE
    , OPEN_CANCEL_SALE_RETURN
    , CLOSE_CANCEL_SALE_RETURN
    , SYNC_SALE_RETURN
    , SEARCH_CUSTOMERS
    , CLOSE_SEARCH_CUSTOMERS
    , SET_CURRENT_CUSTOMER
    , OPEN_CUSTOMER_REGISTER
    , CLOSE_CUSTOMER_REGISTER
    , SET_CUSTOMER_INFO
    , CLOSE_CUSTOMER_INFO
    , EDIT_INFO_CUSTOMER
    , DELETE_AWAITING_CART
    , ADD_TO_CART
    , OPEN_PROFILE_MODAL
    , CLOSE_PROFILE_MODAL
    , ADD_STOCK_SET
    , CLOSE_ADD_STOCK_SET
    , SELECT_PROFILE_PANEL
    , EDIT_TO_CART_AND_PLUS
    , CLOSE_DANGER_MED_MODAL
    , SUBMIT_DANGER_MED
    , FAILURE_ABB
    , QR_PAYMENT_SUCCESS
    , QR_PAYMENT_ERROR
    , QR_PAYMENT_CLOSE
    , QR_PAYMENT_OPEN
    , QR_RESULT_CLOSE
    , QR_LOADING
    , CLOSE_NOTI_REFRESH
    , OPEN_NOTI_REFRESH
    , ADD_RETAIL_UNIT
    , SUBMIT_RETAIL_UNIT
    , CANCEL_RETAIL_UNIT
    , NOTI_SYNC_DATA
    , CLOSE_NOTI_SYNC_DATA
    , SHOW_ADS_POPUP
    , CLOSE_ADS_POPUP
    , PAY_WITH_ALIPAY_WECHAT
    , SHOW_NOTI_WRONG_TIME
    , SHOW_NOTI_PRESCRIPTION
    , CLOSE_NOTI_PRESCRIPTION
    , PRESCRIPTION_OPEN_LIST_MODAL
    , CHECKOUT_FAILURE
    , SHOW_NOTI_NEW_VERSION
    , SHOW_NOTI_REMOVE_COUPON
    , CLOSE_NOTI_REMOVE_COUPON
    , SHOW_NOTI_USER_AS_CUSTOMER
    , CLOSE_NOTI_USER_AS_CUSTOMER
    , RECEIVE_SETTINGS
} from '../actionTypes'

const initialState = {
    showSearchProducts: false,
    showAddToCart: false,
    showEmployeeModal: true,
    showCheckOutModal: false,
    showCheckedOutModal: false,
    showPrintDrugLabel: false,
    showAwaitingCart: false,
    showSaleReturn: false,
    confirmSendTaxInvoice: {
        showModal: false,
        reference_code: null
    },
    cancelSaleReturn: {
        showModal: false,
        reference_code: null
    },
    showSearchCustomers: false,
    showCustomerRegister: false,
    showCustomerInfo: false,
    profile: {
        show: false,
        title: null,
        panel: 0
    },
    addStockSet: {
        showModal: false,
        productName: null
    },
    dgMedModal: false,
    qrResultModal: false,
    qrPaymentModal: false,
    notiRefresh: {
        showModal: false,
        type: 'confirm',
        code: '',
    },
    addRetailUnit: false,
    notiSyncData: false,
    adsPopup: {
        showModal: false,
        data: []
    },
    alipayWechat: false,
    notiWrongTime: {
        showModal: false,
        serverTime: null,
    },
    notiPrescription: false,
    coupon: false,
    notiNewVersion: false,
    notiRemoveCoupon: false,
    notiUserAsCustomer: {
        showModal: false,
        type: '',
        userId: null,
        customer: {
            id: null,
            name: '',
        }
    }
}

export default (state=initialState, { type, payload }) => {
    switch (type) {
        case PAY_WITH_ALIPAY_WECHAT:
            return Object.assign({}, state, {alipayWechat: true});
        case ADD_RETAIL_UNIT:
            return Object.assign({}, state, { addRetailUnit: true});
        case CANCEL_RETAIL_UNIT:
        case SUBMIT_RETAIL_UNIT:
            return Object.assign({}, state, { addRetailUnit: false})
        case OPEN_NOTI_REFRESH:
            return Object.assign({},state, { 
                notiRefresh: Object.assign({}, {
                    showModal: true,
                    type: payload.type,
                    code: payload.code ? payload.code : '',
                })
            });
        case CLOSE_NOTI_REFRESH:
        return Object.assign({},state, { 
            notiRefresh: Object.assign({}, initialState.notiRefresh) 
        });
        case CHECKOUT_FAILURE:
        return Object.assign({},state, { 
            notiRefresh: Object.assign({}, {
                showModal: true,
                type: 'sync_error',
                code: '#30',
            })
        });
        case QR_PAYMENT_OPEN:
            return Object.assign({}, state, { qrPaymentModal: true});
        case QR_LOADING:
        case QR_PAYMENT_CLOSE:
            return Object.assign({}, state, { qrPaymentModal:false});
        case QR_RESULT_CLOSE:
            return Object.assign({}, state, { qrResultModal: false});
        case QR_PAYMENT_SUCCESS:
            return Object.assign({},state, { qrResultModal: true, qrPaymentModal: false});
        case SUBMIT_DANGER_MED:
            return Object.assign({}, state, { dgMedModal: false});
        case CLOSE_DANGER_MED_MODAL:
            return Object.assign({}, state, { dgMedModal: false});
        case OPEN_DANGER_MED_MODAL:
            return Object.assign({}, state, { dgMedModal: true});
        case SELECT_PROFILE_PANEL:
            return Object.assign({},state,{
                profile: Object.assign({}, state.profile, { panel: payload.panel, title: payload.title})
            });
        case OPEN_PROFILE_MODAL:
            return Object.assign({}
                , state
                , { profile: Object.assign({}
                    , initialState.profile
                    , { show: true})
                });
        case CLOSE_PROFILE_MODAL:
            return Object.assign({}
                , state
                , { profile: Object.assign({}
                    , initialState.profile
                    , { show: false})
                });
        case SEARCH_PRODUCTS:
            return Object.assign({},state,{ showSearchProducts: true })
        case ADD_TO_CART:
        case NOT_FOUND_PRODUCT:
        case EDIT_TO_CART_AND_PLUS:
        case CLEAR_PRODUCTS:
            return Object.assign({},state,{ showSearchProducts: false })
        case SELECT_PRODUCT_SET:
        case SELECT_PRODUCT:
        case EDIT_CART_DETAIL:
            return Object.assign({},state,{ showAddToCart: true, showSearchProducts: false })
        case OPEN_ADD_TO_CART :
            return Object.assign({},state,{ showAddToCart: true })
        case EDIT_PRODUCT_SET:
        case CLOSE_ADD_TO_CART :
        case INITIAL_CART_DETAIL :
            return Object.assign({},state,{ showAddToCart: false })
        case RECEIVE_CHECKIN :
            return Object.assign({},state,{ showEmployeeModal: false })
        case EMPLOYEE_LOGOUT:
            return Object.assign({},state,{ showEmployeeModal: true, showCheckedOutModal: false })
        case CHECK_OUT:
            return Object.assign({},state,{ showCheckOutModal: true })
        case FAILURE_ABB:
        case CLOSE_CHECK_OUT:
            return Object.assign({},state,{ showCheckOutModal: false })
        case CHECKED_OUT:
            return Object.assign({},state,{ showCheckOutModal: false, showCheckedOutModal: true })
        case CLOSE_CHECKED_OUT:
            return Object.assign({},state,{ showCheckedOutModal: false })
        case PRINT_DRUG_LABEL:
            return Object.assign({},state,{ showPrintDrugLabel: true })
        case CLOSE_PRINT_DRUG_LABEL:
            return Object.assign({},state,{ showPrintDrugLabel: false })
        case OPEN_AWAITING_CART:
            return Object.assign({},state,{ showAwaitingCart: true })
        case DELETE_AWAITING_CART:
        case CLOSE_AWAITING_CART:
            return Object.assign({},state,{ showAwaitingCart: false })
        case OPEN_SALE_RETURN:
            return Object.assign({},state,{ showSaleReturn: true })
        case CLOSE_SALE_RETURN:
            return Object.assign({},state,{ showSaleReturn: false })
        case OPEN_CANCEL_SALE_RETURN:
            return Object.assign({},state,{
                cancelSaleReturn: {
                    showModal: true,
                    reference_code: payload
                }
            })
        case OPEN_CONFIRM_TAX_INVOICE:
            return Object.assign({},state,{
                confirmSendTaxInvoice: {
                    showModal: true,
                    reference_code: payload
                }
                
            })
        case SYNC_SALE_RETURN:
            return Object.assign({},state,{
                cancelSaleReturn: {
                    showModal: false
                },
                confirmSendTaxInvoice: {
                    showModal: false
                },
                showSaleReturn: false
            })
        case CLOSE_CANCEL_SALE_RETURN:
            return Object.assign({},state,{ cancelSaleReturn: { showModal: false } })
        case CLOSE_CONFIRM_TAX_INVOICE:
            return Object.assign({},state,{ confirmSendTaxInvoice: { showModal: false } })
        case SEARCH_CUSTOMERS:
            return Object.assign({},state,{ showSearchCustomers: true })
        case CLOSE_SEARCH_CUSTOMERS:
            return Object.assign({},state,{ showSearchCustomers: false })
        case OPEN_CUSTOMER_REGISTER:
            return Object.assign({},state,{ showCustomerRegister: true })
        case CLOSE_CUSTOMER_REGISTER:
            return Object.assign({},state,{ showCustomerRegister: false })
        case SET_CUSTOMER_INFO:
            return Object.assign({},state,{ showCustomerInfo: true })
        case CLOSE_CUSTOMER_INFO:
            return Object.assign({},state,{ showCustomerInfo: false })
        case EDIT_INFO_CUSTOMER:
            return Object.assign({},state,{ showCustomerInfo: true })
        case SET_CURRENT_CUSTOMER:
            return Object.assign({}, state, {
                showSearchCustomers: false,
                showCustomerRegister: false,
                notiUserAsCustomer: initialState.notiUserAsCustomer,
            })
        case ADD_STOCK_SET:
            return Object.assign({},state,{
                addStockSet: {
                    showModal: true,
                    productName: payload.name
                },
                showSearchProducts: false
            })
        case CLOSE_ADD_STOCK_SET:
            return Object.assign({},state,{
                addStockSet: {
                    showModal: false
                }
            })
        case NOTI_SYNC_DATA:
            return Object.assign({},state,{ showEmployeeModal: false, notiSyncData: true })
        case CLOSE_NOTI_SYNC_DATA:
        case RECEIVE_SETTINGS:
            return Object.assign({},state,{ showEmployeeModal: true, notiSyncData: false })
        case SHOW_ADS_POPUP:
            return Object.assign({},state,{
                adsPopup: {
                    showModal: true,
                    data: payload
                }
            })
        case CLOSE_ADS_POPUP:
            return Object.assign({},state,{
                adsPopup: {
                    showModal: false
                }
            })
        case SHOW_NOTI_WRONG_TIME:
            return Object.assign({}, state, {
                notiWrongTime: {
                    showModal: true,
                    serverTime: payload
                }
            })
        case SHOW_NOTI_PRESCRIPTION:
            return Object.assign({}, state, {
                notiPrescription: true,
            })
        case PRESCRIPTION_OPEN_LIST_MODAL:
        case CLOSE_NOTI_PRESCRIPTION:
            return Object.assign({}, state, {
                notiPrescription: false,
            })
        case SHOW_NOTI_NEW_VERSION:
            return { ...state, notiNewVersion: true }
        case SHOW_NOTI_REMOVE_COUPON:
            return { ...state, notiRemoveCoupon: true }
        case CLOSE_NOTI_REMOVE_COUPON:
            return { ...state, notiRemoveCoupon: false }
        case SHOW_NOTI_USER_AS_CUSTOMER:
            return {
                ...state,
                notiUserAsCustomer: {
                    ...payload,
                    showModal: true,
                }
            }
        case CLOSE_NOTI_USER_AS_CUSTOMER:
            return { ...state, notiUserAsCustomer: initialState.notiUserAsCustomer }
        default:
            return state
    }
}
