import React, { useEffect, useState } from 'react';
import { Button, Card, CardBody, Col, Modal, ModalBody, Row } from 'reactstrap';
import { connect } from 'react-redux';
import SelectFormGroup from '../../Input/SelectFormGroup';
import InputFormGroup from '../../Input/InputFormGroup';
import DeliveryForm from './DeliveryForm';
import WarningIcon from '../../../images/warning-icon.png';
import moment from 'moment';
import DeliveryList from './DeliveryList';
import DeliveryDetail from './DeliveryDetail';
import { openServicesModal } from 'Redux/actions';
import { ServicesModalScreen } from 'Components/Page/Services/constants';

const SCREEN = {
    FORM: 'FORM',
    LIST: 'LIST',
    DETAIL: 'DETAIL',
};

const DeliveryPage = (props) => {
    const { dispatch, language, providerId, orderId, onClickBack } = props;

    const [screen, setScreen] = useState(SCREEN.FORM);
    const [form, setForm] = useState({
        from_date: '',
        to_date: '',
        provider_id: providerId,
        requested_at: null,
    });
    const [submittedForm, setSubmittedForm] = useState(form);
    const [backWarning, setBackWarning] = useState(false);
    const [backWarningModal, setBackWarningModal] = useState(false);

    useEffect(() => {
        if (orderId) {
            setScreen(SCREEN.DETAIL);
        } else if (screen === SCREEN.DETAIL) {
            setScreen(SCREEN.LIST);
        }
    }, [orderId]);

    const handleClickNew = () => {
        setScreen(SCREEN.FORM);
    };

    const handleChange = (event) => {
        setForm({
            ...form,
            [event.target.name]: event.target.value,
        });
    };

    const handleSearch = () => {
        setScreen(SCREEN.LIST);
        dispatch(openServicesModal(ServicesModalScreen.FOODPANDA, null));
        setSubmittedForm({
            ...form,
            status: form.status !== 'all' ? [form.status] : [],
            requested_at: moment().toISOString(),
        });
    };

    const handleClickBack = () => {
        if (backWarning) {
            setBackWarningModal(true);
            return;
        }
        onClickBack();
    };

    const handleCancelClickBack = () => {
        setBackWarningModal(false);
    };

    const handleConfirmClickBack = () => {
        onClickBack();
    };

    return (
        <>
            <Row>
                <Col xs={3}>
                    <Card style={{ height: '100%', minHeight: 550 }}>
                        <CardBody>
                            <Button
                                color="warning"
                                block
                                style={{ background: '#ffc107', color: 'white' }}
                                onClick={handleClickNew}
                            >
                                + ทำรายการใหม่
                            </Button>
                            <hr />
                            <div className="mb-2 font-weight-bold" style={{ color: '#0184c0' }}>
                                ตัวกรองหารายการ
                            </div>
                            <InputFormGroup
                                label="ตั้งแต่วันที่"
                                type="date"
                                name="from_date"
                                value={form.from_date}
                                onChange={handleChange}
                            />
                            <InputFormGroup
                                label="ถึงวันที่"
                                type="date"
                                name="to_date"
                                value={form.to_date}
                                onChange={handleChange}
                            />
                            <SelectFormGroup
                                label="สถานะรายการสินค้า"
                                placeholder="เลือกสถานะ"
                                name="status"
                                value={form.status}
                                onChange={handleChange}
                                options={[
                                    {
                                        id: 0,
                                        value: 'all',
                                        name: 'All',
                                    },
                                    {
                                        id: 'CONFIRMED',
                                        value: 'CONFIRMED',
                                        name: 'CONFIRMED',
                                    },
                                ]}
                            />

                            <Button
                                color="warning"
                                block
                                outline
                                onClick={handleSearch}
                            >
                                ประวัติการทำรายการ
                            </Button>

                            <Button
                                className="position-absolute"
                                color="info"
                                block
                                onClick={handleClickBack}
                                style={{
                                    bottom: 20, width: 'calc(100% - 40px)',
                                    background: '#55C2EB',
                                    border: '#55C2EB solid 1px',
                                }}
                            >
                                กลับไปหน้าเมนู
                            </Button>
                        </CardBody>
                    </Card>
                </Col>
                <Col xs={9}>
                    {screen === SCREEN.FORM && (
                        <DeliveryForm providerId={providerId} setBackWarning={setBackWarning} />
                    )}
                    {screen === SCREEN.LIST && (
                        <>
                            <Card className="mb-2">
                                <CardBody className="py-3">
                                    <h5 className="font-weight-bold text-grey mb-0">ประวัติการทำรายการ: {providerId}</h5>
                                </CardBody>
                            </Card>
                            <DeliveryList filter={submittedForm} />
                        </>
                    )}
                    {screen === SCREEN.DETAIL && (
                        <DeliveryDetail orderId={orderId} />
                    )}
                </Col>
            </Row>

            <Modal isOpen={backWarningModal}>
                <ModalBody className="text-center text-warning">
                    <div className="pt-2 pb-4">
                        <img className="mb-2" src={WarningIcon} height={100} />
                        <br />
                        กดกลับไปหน้าบริการอื่นๆ ระหว่างทำ
                        <br />
                        รายการปัจจุบัน จะถูกยกเลิกทันที
                    </div>
                    <Button
                        className="mr-2"
                        color="default"
                        onClick={handleCancelClickBack}
                    >
                        ยกเลิก
                    </Button>
                    <Button
                        className="mr-2"
                        color="warning"
                        onClick={handleConfirmClickBack}
                    >
                        ยืนยัน
                    </Button>
                </ModalBody>
            </Modal>
        </>
    );
};

const mapStateToProps = state => ({
    language: state.language,
});

export default connect(mapStateToProps)(DeliveryPage);
