import React from "react";
import Barcode from "react-barcode";
import { numberFormat } from "Utilz/currency";

const DeliveryNoteReceipt = (props) => {
    const { saleReceipt, employee: employeeState } = props;
    const { employee } = employeeState;

    const productList = saleReceipt.details.map((detail, i) => {
        const { product, sales_quantity = 0, unit, product_set_type } = detail;
        if (!product_set_type) {
            return (
                <tr key={i}>
                    <td style={{ width: 20, textAlign: "left" }}>{i + 1}</td>
                    <td style={{ textAlign: "left" }}>{product.name || "-"}</td>
                    <td style={{ width: 100, textAlign: "right" }}>
                        {numberFormat(sales_quantity)} {unit.name || "-"}
                    </td>
                </tr>
            );
        }
    });

    return (
        <div id="deliveryNoteReceipt">
            <div>
                <div style={{ textAlign: "center" }}>
                    <h4
                        style={{
                            fontFamily,
                            fontSize: fontSize.xl,
                            margin: 0,
                            padding: 0,
                            paddingBottom: 5,
                        }}
                    >
                        Medcare
                    </h4>
                    <h6
                        style={{
                            fontSize: fontSize.xs,
                            margin: 0,
                            padding: 0,
                            paddingBottom: 20,
                        }}
                    >
                        Believe in a better pharmacy
                    </h6>
                    <h6
                        style={{
                            fontSize: fontSize.xs,
                            margin: 0,
                            padding: 0,
                        }}
                    >
                        ใบส่งสินค้า / Delivery Note
                    </h6>
                </div>
                <hr />
                <div style={{ fontSize: fontSize.sm }}>
                    <div>
                        Order #: {saleReceipt.medcare_order_reference || "-"}
                    </div>
                    <div>
                        Prescription #:{" "}
                        {saleReceipt.prescription_reference_code || "-"}
                    </div>
                    <div>Receipt #: {saleReceipt.reference_code || "-"}</div>
                    <div>Date: {saleReceipt.created_at || "-"}</div>
                    <div style={{ marginBottom: 20 }}></div>
                    <div>
                        Consulting pharmacist:{" "}
                        {saleReceipt.prescriber_full_name || "-"}
                    </div>
                    <div>
                        Dispensing pharmacist: {employee.first_name || "-"}
                    </div>
                </div>
            </div>
            <hr style={{ marginTop: 17, marginBottom: 5 }} />
            <div>
                <div>
                    <div
                        style={{
                            textAlign: "center",
                            fontSize: fontSize.xs,
                            marginBottom: 8,
                        }}
                    >
                        Medication details
                    </div>
                    <table style={{ width: "100%", fontSize: fontSize.sm }}>
                        <tbody>{productList}</tbody>
                    </table>
                </div>
            </div>
            <hr style={{ marginBottom: 5 }} />
            <div style={{ textAlign: "center" }}>
                <div style={{ marginBottom: 15 }}>
                    <h6
                        style={{
                            fontSize: fontSize.md,
                            margin: 0,
                            padding: 0,
                        }}
                    >
                        ขอให้มีสุขภาพแข็งแรง
                    </h6>
                    <h6
                        style={{
                            fontSize: fontSize.md,
                            margin: 0,
                            padding: 0,
                        }}
                    >
                        Wish you get well soon
                    </h6>
                </div>
                <div
                    style={{
                        textAlign: "left",
                        fontSize: fontSize.xs,
                        marginBottom: 5,
                    }}
                >
                    <div>Please visit our website: www.medicine.in.th</div>
                    <div>Or reach us via LINE: @medcareapp</div>
                </div>
                {saleReceipt.reference_code && (
                    <Barcode
                        value={saleReceipt.reference_code}
                        height={50}
                    ></Barcode>
                )}
                <div style={{ fontFamily, marginTop: 5 }}>
                    Powered by Arincare.com
                </div>
            </div>
        </div>
    );
};

const fontFamily = "Tahoma";
const fontSize = {
    xs: 14,
    sm: 15,
    md: 16,
    lg: 17,
    xl: 18,
};

export default DeliveryNoteReceipt;
