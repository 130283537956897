import React from 'react'
import { connect } from 'react-redux'
import { Button } from 'reactstrap'
import NotificationsSystem from 'reapop'
import theme from 'reapop-theme-wybo'
import {
    searchReceipts
    , employeeCheckin
    , switchLanguage
    , changeSettings
    , onBlurEmployeeUser
    , openAwaitingCart
    , closeAwaitingCart
    , deleteAwaitingCart
    , checkCouponBeforeSaveCart
    , saveCart
    , continueCart
    , openSaleReturn
    , closeSaleReturn
    , selectReceipt
    , confirmTaxInvoice
    , cancelSaleReceipt
    , closeCancelSaleReturn
    , confirmTaxInvoiceSaleReceipt
    , closeConfirmTaxInvoice
    , confirmCancelSaleReceipt
    , clearReceipt
    , setReturnValue
    , convertToSaleReceipt
    , printSaleReceipt
    , handleDetailChange
    , confirmReturnProduct
    , isAble
    , toggleOffline
    , setOfflineMode
    , closeNotiSyncData
    , closeAds
    , openPickupPrescriptionModal
    , closeNotiPrescription
    , openPrescriptionListModal
    , closeNotiRemoveCouponModal
    , registerUserAsCustomer
    , updateCustomerFromUser
} from 'Redux/actions'

import SaleReturnModal from 'Components/Modal/SaleReturnModal'
import AwaitingCartModal from 'Components/Modal/AwaitingCartModal'
import EmployeeModal from 'Components/Modal/EmployeeModal'
import ConfirmSendTaxInvoice from 'Components/Modal/ConfirmSendTaxInvoice'
import CancelSaleReturn from 'Components/Modal/CancelSaleReturn'
import NotiSyncDataModal from 'Components/Modal/NotiSyncDataModal'
import AdsPopupModal from 'Components/Modal/AdsPopupModal'
import NotiPrescriptionModal from 'Components/Modal/NotiPrescriptionModal'
import VoiceCallWidget from 'Components/Form/Telepharmacy/Voice/VoiceCallWidget'
import ChatWidget from 'Components/Form/Telepharmacy/Chat/ChatWidget'
import NotiNewVersionModal from 'Components/Modal/NotiNewVersionModal'
import NotiRemoveCouponModal from 'Components/Modal/NotiRemoveCouponModal'
import NotiUserAsCustomerModal from 'Components/Modal/NotiUserAsCustomerModal'
import ServicesButton from 'Components/Page/Services/ServicesButton';
import NotiWindowWillClosePlatform from 'Components/Modal/NotiWindowWillClosePlatform';
import NotiWindowUpdatePlatform from 'Components/Modal/NotiWindowUpdatePlatform';
import FoodPanDaModal from 'Components/Modal/FoodPanDaModal';
class Footer extends React.Component {
    render() {
        const { language, settings, adsPopup, employee } = this.props
        const awaitingCart = this.props.awaitingCart.filter(cart => cart.current_status_id === 1)
        const updatePlatform = process.env.UPDATE_PLATFORM;
        const foodPandaModal = process.env.FOOD_PANDA_MODAL;
        const targetDateFoodPanDaDate = new Date('2023-09-01');
        const currentDate = new Date();
        const shouldShowFoodPanDaModal = currentDate.getTime() <= targetDateFoodPanDaDate.getTime();
        return (
            <div className="footer-container">
            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                {this.props.isAble('CREATE_SALES_RETURN') ?
                    !settings.offline_mode ?
                        <SaleReturnModal
                            receipts={this.props.receipts}
                            searchReceipts={this.props.searchReceipts}
                            language={this.props.language}
                            showSaleReturn={this.props.showSaleReturn}
                            openSaleReturn={this.props.openSaleReturn}
                            closeSaleReturn={this.props.closeSaleReturn}
                            selectReceipt={this.props.selectReceipt}
                            selectedSaleReceipt={this.props.selectedSaleReceipt}
                            confirmTaxInvoice={this.props.confirmTaxInvoice}
                            closeConfirmTaxInvoice={this.props.closeConfirmTaxInvoice}
                            cancelSaleReceipt={this.props.cancelSaleReceipt}
                            showCancelSaleReturn={this.props.showCancelSaleReturn}
                            closeCancelSaleReturn={this.props.closeCancelSaleReturn}
                            clearReceipt={this.props.clearReceipt}
                            setReturnValue={this.props.setReturnValue}
                            convertToSaleReceipt={this.props.convertToSaleReceipt}
                            printSaleReceipt={this.props.printSaleReceipt}
                            handleDetailChange={this.props.handleDetailChange}
                            confirmReturnProduct={this.props.confirmReturnProduct}
                            loader={this.props.loader.saleReturn}
                            focus={this.props.focus.searchSaleReturn}
                        />
                    : ''
                : ''}
                {this.props.cart.reference_code &&
                    <Button
                        color="warning"
                        className="text-white mb-2 mr-2"
                        disabled={!!this.props.prescription}
                        onClick={this.props.checkCouponBeforeSaveCart}
                    >
                        <i className="fa fa-pause"></i> {language.pause_sale}
                    </Button>
                }
                {awaitingCart.length > 0 &&
                    <AwaitingCartModal
                        language={this.props.language}
                        openAwaitingCart={this.props.openAwaitingCart}
                        closeAwaitingCart={this.props.closeAwaitingCart}
                        showAwaitingCart={this.props.showAwaitingCart}
                        awaitingCart={awaitingCart}
                        deleteAwaitingCart={this.props.deleteAwaitingCart}
                        continueCart={this.props.continueCart}
                    />
                }
                </div>
                <div style={{ flexGrow: 1 }} />
                <ServicesButton />
                {/*
                    27/1/2022
                    From: Thanapon
                    To: The Next Generation Developer

                    This function has been moved to PrescriptionInfoModal
                    If not used, Please delete.

                    <div>
                        {settings.branch.enable_prescription && !this.props.prescription && !this.props.cart.bodc12 &&
                            <Button
                                color="info"
                                className="mb-2 ml-2"
                                onClick={this.props.openPickupPrescriptionModal}
                                style={{ minWidth: 110 }}
                            >
                                {language.prescription}
                            </Button>
                        }
                    </div>
                */}
                <EmployeeModal
                    showEmployeeModal={this.props.showEmployeeModal}
                    employeeCheckin={this.props.employeeCheckin}
                    employee={this.props.employee}
                    focus={this.props.focus.employeeUser}
                    language={this.props.language}
                    switchLanguage={this.props.switchLanguage}
                    changeSettings={this.props.changeSettings}
                    onBlur={this.props.onBlurEmployeeUser}
                    settings={this.props.settings}
                    toggleOffline={this.props.toggleOffline} />
                <NotificationsSystem theme={theme} />
                <CancelSaleReturn
                    cancelSaleReturn={this.props.cancelSaleReturn}
                    language={this.props.language}
                    closeCancelSaleReturn={this.props.closeCancelSaleReturn}
                    confirmCancelSaleReceipt={this.props.confirmCancelSaleReceipt} />
                <ConfirmSendTaxInvoice
                    confirmSendTaxInvoice={this.props.confirmSendTaxInvoice}
                    language={this.props.language}
                    closeConfirmTaxInvoice={this.props.closeConfirmTaxInvoice}
                    confirmTaxInvoiceSaleReceipt={this.props.confirmTaxInvoiceSaleReceipt} />
                <NotiSyncDataModal
                    language={this.props.language}
                    showNotiSyncData={this.props.showNotiSyncData}
                    closeNotiSyncData={this.props.closeNotiSyncData}
                    setOfflineMode={this.props.setOfflineMode} />
                {adsPopup.showModal &&
                    <AdsPopupModal
                        language={this.props.language}
                        adsPopup={this.props.adsPopup}
                        closeAds={this.props.closeAds}
                    />}
                {!!employee.employee.id && <NotiPrescriptionModal
                    language={this.props.language}
                    showNotiPrescription={this.props.showNotiPrescription}
                    closeNotiPrescription={this.props.closeNotiPrescription}
                    openPrescriptionListModal={this.props.openPrescriptionListModal} />}
                {
                    !!employee.employee.id && process.env.CLOSE_PLATFORM === 'WILL' 
                    && <NotiWindowWillClosePlatform />
                }
                {
                    !!employee.employee.id && updatePlatform && updatePlatform != '' 
                    && <NotiWindowUpdatePlatform />
                }
                <VoiceCallWidget />
                <ChatWidget />
                <NotiNewVersionModal
                    language={this.props.language}
                    showModal={this.props.showNotiNewVersion} />
                <NotiRemoveCouponModal
                    language={this.props.language}
                    showModal={this.props.showNotiRemoveCoupon}
                    confirm={this.props.saveCart}
                    closeModal={this.props.closeNotiRemoveCouponModal}
                />
                <NotiUserAsCustomerModal
                    language={this.props.language}
                    notiUserAsCustomer={this.props.notiUserAsCustomer}
                    registerCustomer={this.props.registerUserAsCustomer}
                    updateCustomer={this.props.updateCustomerFromUser}
                />
                {
                    foodPandaModal && shouldShowFoodPanDaModal ? <FoodPanDaModal/> : ''
                }
            </div>
        )
    }
}

const mapStateToProps = state => {
    let receipts = Object.assign([], state.search.receipts)
    let awaitingCart = Object.assign([], state.awaitingCart.carts)
    return {
        receipts,
        awaitingCart,
        showEmployeeModal: state.modal.showEmployeeModal,
        employee: state.employee,
        focus: state.focus,
        language : state.language,
        cart: {...state.cart},
        prescription: state.prescription.data,
        showAwaitingCart: state.modal.showAwaitingCart,
        showSaleReturn: state.modal.showSaleReturn,
        selectedSaleReceipt: state.saleReturn,
        confirmSendTaxInvoice: state.modal.confirmSendTaxInvoice,
        cancelSaleReturn: state.modal.cancelSaleReturn,
        loader: state.loader,
        settings: state.settings,
        showNotiSyncData: state.modal.notiSyncData,
        adsPopup: state.modal.adsPopup,
        showNotiPrescription: state.modal.notiPrescription,
        showNotiNewVersion: state.modal.notiNewVersion,
        showNotiRemoveCoupon: state.modal.notiRemoveCoupon,
        notiUserAsCustomer: state.modal.notiUserAsCustomer,
    }
}

export default connect(mapStateToProps, {
    searchReceipts
    , employeeCheckin
    , switchLanguage
    , changeSettings
    , onBlurEmployeeUser
    , openAwaitingCart
    , closeAwaitingCart
    , deleteAwaitingCart
    , checkCouponBeforeSaveCart
    , saveCart
    , continueCart
    , openSaleReturn
    , closeSaleReturn
    , selectReceipt
    , confirmTaxInvoice
    , cancelSaleReceipt
    , closeCancelSaleReturn
    , confirmTaxInvoiceSaleReceipt
    , closeConfirmTaxInvoice
    , confirmCancelSaleReceipt
    , clearReceipt
    , setReturnValue
    , convertToSaleReceipt
    , printSaleReceipt
    , handleDetailChange
    , confirmReturnProduct
    , isAble
    , toggleOffline
    , setOfflineMode
    , closeNotiSyncData
    , closeAds
    , openPickupPrescriptionModal
    , closeNotiPrescription
    , openPrescriptionListModal
    , closeNotiRemoveCouponModal
    , registerUserAsCustomer
    , updateCustomerFromUser
})(Footer)
