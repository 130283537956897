import uuidv4 from 'uuid/v4';

(function() {
    const DuplicateTab = () => {
        const timeout = 5 * 1000;
        const resetInterval = 1000;
        const tabCookieName = 'tabKey';

        const ItemType = {
            Session: 1,
            Local: 2,
        };

        const setCookie = (name, value, days) => {
            let expires = '';

            if (days) {
                let date = new Date();
                date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
                expires = '; expires=' + date.toUTCString();
            }

            document.cookie = name + '=' + (value || '') + expires + '; path=/';
        };

        const getCookie = name => {
            name = name + '=';
            let ca = document.cookie.split(';');

            for (let i = 0; i < ca.length; i++) {
                let c = ca[i];

                while (c.charAt(0) == ' ') c = c.substring(1, c.length);
                if (c.indexOf(name) == 0) return c.substring(name.length, c.length);
            }

            return null;
        };

        const getItem = type => {
            let value = '';

            switch (type) {
                case ItemType.Session:
                    value = window.name;
                    break;
                case ItemType.Local:
                    value = decodeURIComponent(getCookie(tabCookieName));
                    if (value == undefined) value = '';
                    break;
            }

            return value;
        };

        const setItem = (type, value) => {
            switch (type) {
                case ItemType.Session:
                    window.name = value;
                    break;
                case ItemType.Local:
                    setCookie(tabCookieName, value);
                    break;
            }
        };

        const checkDuplicate = () => {
            const sessionUuid = getItem(ItemType.Session) || uuidv4();

            setItem(ItemType.Session, sessionUuid);

            const value = getItem(ItemType.Local);
            const tabObj = (value === '' ? null : JSON.parse(value)) || null;

            if (
                tabObj === null ||
                tabObj.timestamp < new Date().getTime() - timeout ||
                tabObj.uuid === sessionUuid
            ) {
                function setTabObj() {
                    const newTabObj = {
                        uuid: sessionUuid,
                        timestamp: new Date().getTime(),
                    };

                    setItem(ItemType.Local, JSON.stringify(newTabObj));
                }

                setTabObj();
                setInterval(setTabObj, resetInterval);

                return false;
            } else {
                return true;
            }
        };

        window.IsDuplicate = () => {
            const duplicate = checkDuplicate();
            return duplicate;
        };

        window.addEventListener('beforeunload', () => {
            if (checkDuplicate() === false) {
                setItem(ItemType.Local, '');
            }
        });
    };

    DuplicateTab();
})();
