import { combineReducers } from 'redux';
import search from './search';
import modal from './modal';
import product from './product';
import cart from './cart';
import customer from './customer';
import services from './services';
import prescription from './prescription';
import login from './login';
import employee from './employee';
import focus from './focus';
import settings from './settings';
import detail from './detail';
import drugLabel from './drugLabel';
import language from './language';
import saleReceipt from './saleReceipt';
import awaitingCart from './awaitingCart';
import saleReturn from './saleReturn';
import syncErrorMessage from './syncErrorMessage';
import customerInfo from './customerInfo';
import loader from './loader';
import printer from './printer';
import daily from './daily';
import dangerMed from './dangerMed';
import qr from './qr';
import linePay from './linePay';
import confirm from './confirm';
import alipayWechat from './alipayWechat';
import inventory from './inventory';
import coupon from './coupon';
import voice from './voice';
import chat from './chat';

import { routerReducer as router } from 'react-router-redux';
import { reducer as notificationsReducer } from 'reapop';

export default combineReducers({
    search,
    modal,
    product,
    cart,
    customer,
    services,
    prescription,
    login,
    employee,
    focus,
    settings,
    detail,
    drugLabel,
    language,
    router,
    saleReceipt,
    awaitingCart,
    saleReturn,
    syncErrorMessage,
    customerInfo,
    loader,
    printer,
    daily,
    dangerMed,
    qr,
    linePay,
    confirm,
    alipayWechat,
    inventory,
    coupon,
    voice,
    chat,
    notifications: notificationsReducer(),
});
