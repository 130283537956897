import React from 'react';
import { Modal, ModalHeader, ModalBody, Row, Col, FormGroup, Button } from 'reactstrap';
import Loadable from 'react-loading-overlay';
import { priceFormat } from 'Utilz/currency';

import thaiQRLogo from '../../images/thai_qr_logo.png'
import alipayLogo from '../../images/alipay_logo.png'
import wechatPayLogo from '../../images/wechat_pay_logo.png'
import linePayLogo from '../../images/rabbit_line_pay_logo.png'

import CashPage from '../Page/PaymentPage/CashPage';
import TransferPage from '../Page/PaymentPage/TransferPage';
import CreditPage from '../Page/PaymentPage/CreditPage';
import QrPaymentPage from '../Page/PaymentPage/QrPaymentPage';
import LinePayPage from '../Page/PaymentPage/LinePayPage';
import AlipayWechatPage from '../Page/PaymentPage/AlipayWechatPage';

class CheckOutModal extends React.Component {
    constructor(props) {
        super(props)
        this._onChangeTotalDiscountAmount = this._onChangeTotalDiscountAmount.bind(this)
        this._onChangeTotalPaidAmount = this._onChangeTotalPaidAmount.bind(this)
        this._calculateAmount = this._calculateAmount.bind(this)
        this._handleFocus = this._handleFocus.bind(this)
        this._handleKeyDown = this._handleKeyDown.bind(this)
        this._handleSubmit = this._handleSubmit.bind(this)
    }

    _showPayment() {
        const { language
            , cart
            , setCartValue
            , focus
            , calculateChangeAmount
            , inputDigit
            , inputDot
            , setCash
            , settings
            , onBlurPaidAmount
            , linePay
            , setLinePayValue
            , onCreditChange } = this.props
        switch (cart.payment_types) {
            case 'cash':
                return <CashPage language={language}
                cart={cart}
                setCartValue={setCartValue}
                focus={focus}
                calculateChangeAmount={calculateChangeAmount}
                inputDigit={inputDigit}
                inputDot={inputDot}
                setCash={setCash}
                checkOutSubmit={this._handleSubmit}
                onBlurPaidAmount={onBlurPaidAmount}
            />
            case 'transfer':
                return <TransferPage 
                    bankAccounts={settings.payment.bank_accounts}
                    language={language}
                    setCartValue={setCartValue}
                    cart={cart}
                />
            case 'credit':
                return <CreditPage 
                    language={language} 
                    setCartValue={setCartValue}
                    cart={cart}
                />
            case 'thai_qr':
                return <QrPaymentPage
                    language={language}
                    cart={cart}
                    merchant={settings.payment.thai_qr}
                />
            case 'alipay':
            case 'wechat_pay':
                return <AlipayWechatPage
                    language={language}
                    cart={cart}
                    merchant={settings.payment.wechat_pay}
                    setCartValue={setCartValue}
                    onCreditChange={(e) => onCreditChange(e.target.value)}
                />
            case 'line_pay':
                return <LinePayPage
                    language={language}
                    cart={cart}
                    linePay={linePay}
                    setLinePayValue={setLinePayValue}
                    checkOutSubmit={this._handleSubmit}
                />
        }
    }

    _onChangeTotalDiscountAmount(e) {
        const value = e.target.value
        if (value == '' || (/^[0-9.%\b]+$/).test(value)) {
            this.props.setCartValue('total_discount_amount', value)
        }
    }

    _onChangeTotalPaidAmount(e) {
        const value = e.target.value
        if (value == '' || (/^[0-9.\b]+$/).test(value)) {
            this.props.setCartValue('total_paid_amount', value || 0)
        }
    }

    _calculateAmount(type) {
        this.props.calculateAmount(type)
        this.props.calculateChangeAmount()
        type === 'total_paid_amount' && this.props.calculateNetAmount()
    }

    _handleFocus(e) {
        e.target.select()
    }

    _handleKeyDown(e) {
        const { name } = e.target
        const keyCode = e.keyCode
        if (keyCode === 13 || keyCode === 9) {
            e.preventDefault()
            this.props.focusPaidAmount()
        } else if (keyCode === 190 || keyCode === 110) {
            e.preventDefault()
            this.props.inputDot(name)
        }
    }

    _handleSubmit() {
        if (!this.props.loader) {
            this.props.checkOutSubmit();
        }
    }

    render() {
        const { checkOut
            , showCheckOutModal
            , closeCheckOut
            , language
            , cart
            , prescription
            , loader
            , changePaymentType
            , settings } = this.props
        const paymentTypeName = {
            thai_qr: 'THAI QR',
            alipay: 'Alipay',
            wechat_pay: 'WeChat Pay',
            line_pay: 'Rabbit LINE Pay',
        };
        const hasCoupon = Boolean(cart.coupon);
        return (
            <div>
                <Button color={prescription.ignoreCalculatePrice ? 'success' : 'info'} className="checkout-button" onClick={checkOut}>
                    {prescription.ignoreCalculatePrice ? language.checkout_zero : language.payment}
                </Button>
                <Modal isOpen={showCheckOutModal} 
                size="lg"
                style={{ minWidth: '600px', maxWidth: '1200px', width: '80%' }}
                toggle={closeCheckOut}
                centered>
                    <Loadable active={loader} spinner>
                        <ModalHeader toggle={closeCheckOut}>{language.summary_price}</ModalHeader>
                        <ModalBody>
                            <Row>
                                <Col md="4">
                                    <FormGroup>
                                        <label>{language.total_amount}</label>
                                        <h2 className="total-amount">{priceFormat(cart.net_amount)} ฿</h2>
                                    </FormGroup>
                                </Col>
                                <Col md="4">
                                    <FormGroup>
                                        <label>{language.discount_end_bill}</label>
                                        <div className="input-group input-group-xl">
                                            <input
                                                className="form-control"
                                                name="total_discount_amount"
                                                value={cart.total_discount_amount}
                                                onChange={this._onChangeTotalDiscountAmount}
                                                onBlur={(e) => this._calculateAmount(e.target.name)}
                                                onFocus={this._handleFocus}
                                                onKeyDown={this._handleKeyDown}
                                                maxLength="6"
                                                style={{ backgroundColor: '#f6c0cb' }}
                                                disabled={hasCoupon}
                                            />
                                            <div className="input-group-append">
                                                <span className="input-group-text">฿</span>
                                            </div>
                                        </div>
                                    </FormGroup>
                                </Col>
                                <Col md="4">
                                    <FormGroup>
                                        <label>{language.net_price}</label>
                                        <div className="input-group input-group-xl">
                                            <input
                                                className="form-control"
                                                name="total_paid_amount"
                                                value={cart.total_paid_amount}
                                                onChange={this._onChangeTotalPaidAmount}
                                                onBlur={(e) => this._calculateAmount(e.target.name)}
                                                onFocus={this._handleFocus}
                                                onKeyDown={this._handleKeyDown}
                                                maxLength="10"
                                                disabled={cart.tax_types === 'vat_excluded' ||
                                                    cart.payment_types === 'credit' ||
                                                    cart.payment_types === 'line_pay'
                                                }
                                                style={{ backgroundColor: '#66d750' }}
                                                disabled={hasCoupon}
                                            />
                                            <div className="input-group-append">
                                                <span className="input-group-text">฿</span>
                                            </div>
                                        </div>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <hr className="mb-4" />
                            <Row className="payment-button">
                                <Col sm="4">
                                    <FormGroup>
                                        <label>วิธีการชำระเงิน</label>
                                        <Button
                                            size="lg"
                                            color="light"
                                            onClick={() => changePaymentType('cash')}
                                            active={cart.payment_types === 'cash'}
                                            outline
                                            block>
                                            <i className="fa fa-money fa-lg"></i> {language.cash}
                                        </Button>
                                        <Row className="payment-second-button mt-3">
                                            <Col xl="6">
                                                <Button
                                                    size="lg"
                                                    color="light"
                                                    onClick={() => changePaymentType('transfer')}
                                                    active={cart.payment_types === 'transfer'}
                                                    outline
                                                    block
                                                    disabled={settings.payment.bank_accounts.length === 0}>
                                                    <i className="pf pf-bank-transfer pf-lg"></i> {language.transfer}
                                                </Button>
                                            </Col>
                                            <Col xl="6">
                                                <Button
                                                    size="lg"
                                                    className="px-2"
                                                    color="light"
                                                    onClick={() => changePaymentType('credit')}
                                                    active={cart.payment_types === 'credit'}
                                                    outline
                                                    block>
                                                    <i className="fa fa-credit-card fa-lg"></i> {language.credit}
                                                </Button>
                                            </Col>
                                        </Row>
                                        <hr className="mt-0 mb-4" />
                                        <Row>
                                            <Col xl="4">
                                                <Button
                                                    color="light"
                                                    onClick={() => changePaymentType('thai_qr')}
                                                    active={cart.payment_types === 'thai_qr'}
                                                    disabled={!settings.payment.thai_qr || settings.offline_mode}
                                                    style={{ backgroundColor: '#0f3f67' }}
                                                    title="THAI QR"
                                                    outline>
                                                    <img src={thaiQRLogo} alt="" width="100%" />
                                                </Button>
                                            </Col>
                                            {/* <Col xl="4">
                                                <Button
                                                    color="light"
                                                    onClick={() => changePaymentType('alipay')}
                                                    active={cart.payment_types === 'alipay'}
                                                    disabled={!settings.payment.alipay || settings.offline_mode}
                                                    style={{ backgroundColor: 'inherit' }}
                                                    title="Alipay"
                                                    outline>
                                                    <img src={alipayLogo} alt="" width="100%" />
                                                </Button>
                                            </Col> */}
                                            {/* <Col xl="4">
                                                <Button
                                                    color="light"
                                                    onClick={() => changePaymentType('wechat_pay')}
                                                    active={cart.payment_types === 'wechat_pay'}
                                                    disabled={!settings.payment.wechat_pay || settings.offline_mode}
                                                    style={{ backgroundColor: 'inherit' }}
                                                    title="WeChat Pay"
                                                    outline>
                                                    <img src={wechatPayLogo} alt="" width="100%" />
                                                </Button>
                                            </Col> */}
                                            {/* <Col xl="4">
                                                <Button
                                                    color="light"
                                                    onClick={() => changePaymentType('line_pay')}
                                                    active={cart.payment_types === 'line_pay'}
                                                    disabled={!settings.payment.line_pay.activate || settings.offline_mode}
                                                    style={{ backgroundColor: '#00c54b' }}
                                                    title="Rabbit LINE Pay"
                                                    outline>
                                                    <img src={linePayLogo} alt="" width="100%" />
                                                </Button>
                                            </Col> */}
                                        </Row>
                                    </FormGroup>
                                </Col>
                                <Col sm="8">
                                    {this._showPayment()}
                                    <Row>
                                        <Col>
                                            <div className="final-checkout-button">
                                                {cart.payment_types === 'thai_qr' ||
                                                cart.payment_types === 'alipay' ||
                                                cart.payment_types === 'wechat_pay' ||
                                                cart.payment_types === 'line_pay' ?
                                                <Button
                                                    color="success"
                                                    size="lg"
                                                    onClick={this._handleSubmit}
                                                    block>
                                                    ชำระด้วย {paymentTypeName[cart.payment_types]}
                                                </Button>
                                                : <Button
                                                    color="primary"
                                                    size="lg"
                                                    onClick={this._handleSubmit}
                                                    block>
                                                    {language.payment}
                                                </Button>}
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </ModalBody>
                    </Loadable>
                </Modal>
            </div>
        )
    }
}

export default CheckOutModal
