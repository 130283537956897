import React, { useState } from 'react'
import { Modal, ModalHeader, ModalBody, Button } from 'reactstrap'

class CancelSaleReturn extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            isProcessing: false
        }
    }

    handleConfirmCancelSaleReceipt = () => {
        this.setState({ isProcessing: true })
        const { confirmCancelSaleReceipt, closeCancelSaleReturn, cancelSaleReturn } = this.props
        const referenceCode = cancelSaleReturn.reference_code

        confirmCancelSaleReceipt(referenceCode).then(() => {
            this.setState({ isProcessing: false })
            closeCancelSaleReturn()
        }).catch(() => {
            this.setState({ isProcessing: false })
            closeCancelSaleReturn()
        })
    }

    render() {
        const { cancelSaleReturn, closeCancelSaleReturn, language } = this.props
        const referenceCode = cancelSaleReturn.reference_code

        return (
            <Modal isOpen={cancelSaleReturn.showModal}
                toggle={closeCancelSaleReturn}
                style={{ maxWidth: '40%' }}>
                <ModalHeader toggle={closeCancelSaleReturn}>{language.confirm_cancel_bill}: {referenceCode}</ModalHeader>
                <ModalBody>
                    <div className="warning-img"></div>
                    <h5 className="text-center" style={{ color: '#ff6eaf', marginBottom: '10px' }}>{language.void_bill_no}: {referenceCode}</h5>
                    <div className="text-danger text-center" style={{ fontSize: '16px' }}>{language.void_bill_not_change}</div>
                    <div className="text-center mt-4">
                        <Button
                            color="danger"
                            className="mr-2"
                            onClick={closeCancelSaleReturn}>
                            {language.close}
                        </Button>
                        <Button
                            color="primary"
                            onClick={() => this.handleConfirmCancelSaleReceipt()}
                            disabled={this.state.isProcessing}
                            >
                            {language.confirm}
                        </Button>
                    </div>
                </ModalBody>
            </Modal>
        )
    }
}

export default CancelSaleReturn