import {
    POPUP_CHAT_WIDGET,
    MINIMIZE_CHAT_WIDGET,
    SET_CURRENT_USER_CHAT,
    REMOVE_USER_CHAT,
    RECEIVE_MESSAGE_CHAT,
    USER_LEAVE_CHAT,
    SET_CHAT_ON,
    SET_CHAT_LOADING,
    EMPLOYEE_LOGOUT,
} from '../actionTypes';
import { getLastOfArray, removeObjectKey } from '../actions/telepharmacy';

const initialState = {
    isOn: false,
    popup: false,
    loading: false,
    currentUser: null,
    users: [],
    messagesByUser: {},
};

export default (state = initialState, { type, payload }) => {
    switch (type) {
        case SET_CHAT_ON:
            return { ...state, isOn: payload };
        case POPUP_CHAT_WIDGET:
            return {
                ...state,
                popup: true,
                users: state.users.map((user) => {
                    if (user.id === state.currentUser) {
                        return {
                            ...user,
                            unRead: false,
                        };
                    }
                    return user;
                }),
            };
        case MINIMIZE_CHAT_WIDGET:
            return { ...state, popup: false };
        case SET_CHAT_LOADING:
            return { ...state, loading: payload };
        case RECEIVE_MESSAGE_CHAT:
            const { id: userId } = payload.user;
            const hasUser = state.users.some((user) => user.id === userId);
            let message = {};
            let beforeNewSession = [];
            if (hasUser) {
                const lastMessage = getLastOfArray(state.messagesByUser[userId]);
                if (
                    lastMessage &&
                    lastMessage.kind === 'action' &&
                    lastMessage.type === 'endSession'
                ) {
                    beforeNewSession = [
                        ...beforeNewSession,
                        { kind: 'action', type: 'newSession', created: +new Date() },
                    ];
                } else {
                    beforeNewSession = [];
                }
                message[userId] = [...state.messagesByUser[userId], ...beforeNewSession, payload];
                return {
                    ...state,
                    currentUser: !state.currentUser ? userId : state.currentUser,
                    users: state.users.map((user) => {
                        if (user.id === userId) {
                            return {
                                ...user,
                                ...payload.user,
                                unRead: !state.currentUser ? true : !state.popup ? true : false,
                                status: 'open',
                            };
                        }
                        return user;
                    }),
                    messagesByUser: { ...state.messagesByUser, ...message },
                };
            } else {
                message[userId] = [payload];
                return {
                    ...state,
                    popup: true,
                    currentUser: !state.currentUser ? userId : state.currentUser,
                    messagesByUser: { ...state.messagesByUser, ...message },
                    users: [
                        ...state.users,
                        {
                            ...payload.user,
                            unRead: !state.currentUser ? false : true,
                            status: 'open',
                        },
                    ],
                };
            }
        case USER_LEAVE_CHAT: {
            const hasUser = state.users.some((user) => user.id === payload);
            let message = {};
            if (hasUser) {
                message[payload] = [
                    ...state.messagesByUser[payload],
                    { kind: 'action', type: 'endSession', created: +new Date() },
                ];
                return {
                    ...state,
                    users: state.users.map((user) => {
                        if (user.id === payload) {
                            return {
                                ...user,
                                status: 'closed',
                            };
                        }
                        return user;
                    }),
                    messagesByUser: { ...state.messagesByUser, ...message },
                };
            } else {
                return state;
            }
        }
        case SET_CURRENT_USER_CHAT:
            return {
                ...state,
                currentUser: payload,
                users: state.users.map((user) => {
                    if (user.id === payload) return { ...user, unRead: false };
                    return user;
                }),
            };
        case REMOVE_USER_CHAT:
            return {
                ...state,
                currentUser: null,
                users: state.users.filter((user) => user.id !== payload),
                messagesByUser: removeObjectKey(state.messagesByUser, payload),
            };
        case EMPLOYEE_LOGOUT:
            return initialState;
        default:
            return state;
    }
};

export const getCurrentMessages = (state) => {
    return state.messagesByUser[state.currentUser] || [];
};

export const getCurrentUser = (state) => {
    const { currentUser, users } = state;
    const user = users.find((user) => user.id === currentUser);
    return user ? user : { id: null, name: '', phone: '', status: '' };
};
