import io from 'socket.io-client';
import Client from './client';

const TWILIO_SOCKET_URL = process.env.TWILIO_SOCKET_URL;

class TwilioSocket extends Client {
    constructor() {
        super();
        this.options = {
            reconnectionAttempts: 3,
            query: {
                branchSlug: '',
            },
        };
    }

    connectSocket(branchSlug) {
        this.options.query.branchSlug = branchSlug;
        this.state.branchSlug = branchSlug;

        const { voice, chat } = this.isAvailable;
        if (voice) this.options.query.voice = 1;
        if (chat) this.options.query.chat = 1;

        const macId = this.getClientMacId();
        if (macId) this.options.query.macId = macId;

        this.socket = io(TWILIO_SOCKET_URL, this.options);

        this.socket.on('connect', () => {
            this.handleSocketEvents();
        });

        this.socket.on('disconnect', (reason) => {
            console.log(reason);
            switch (reason) {
                case 'io client disconnect':
                    this.emit('socketDisconnect');
                    break;
                case 'io server disconnect':
                    break;
                case 'transport close':
                    // this.disconnect();
                    // ขาดการเชื่อมต่อ
                    break;
                default:
                    break;
            }
        });

        this.socket.on('reconnect_failed', () => {
            // เตือนไม่สามารถเชื่่อม socket ได้ กรุณารีเฟรชเพื่อลองใหม่อีกครั้ง
            console.log('socket connect failed');
        });
    }

    async handleClientSetup() {
        const { branchSlug, macId } = this.options.query;
        this.setClientIdentity(branchSlug, macId);
        await this.getClientToken(branchSlug);

        const { voice, chat } = this.isAvailable;
        if (voice) this.setupVoiceClient();
        if (chat) this.setupChatClient();
    }

    handleSocketEvents() {
        this.socket.on('ready', () => {
            this.handleClientSetup();
        });

        this.socket.on('setClientMacId', (id) => {
            this.setClientMacId(id);
            this.options.query.macId = id;
            this.handleClientSetup();
        });

        this.socket.on('sendVoiceRecordingUrl', (data) => {
            const { macId, recordingUrl } = data;
            if (macId === this.options.query.macId) {
                this.emit('socketSendVoiceRecordingUrl', recordingUrl);
            }
        });
    }

    setClientOnline(type) {
        this.socket.emit('setClientOnline', type);
    }

    setClientOffline(type) {
        this.socket.emit('setClientOffline', type);
    }

    disconnectSocket() {
        this.socket.disconnect();
    }
}

export default TwilioSocket;
