export const OPEN_SALE_RETURN = 'OPEN_SALE_RETURN'
export const CLOSE_SALE_RETURN = 'CLOSE_SALE_RETURN'
export const CLEAR_RECEIPT = 'CLEAR_RECEIPT'
export const SELECT_RECEIPT = 'SELECT_RECEIPT'
export const OPEN_CONFIRM_TAX_INVOICE = 'OPEN_CONFIRM_TAX_INVOICE'
export const CLOSE_CONFIRM_TAX_INVOICE = 'CLOSE_CONFIRM_TAX_INVOICE'
export const OPEN_CANCEL_SALE_RETURN = 'OPEN_CANCEL_SALE_RETURN'
export const CLOSE_CANCEL_SALE_RETURN = 'CLOSE_CANCEL_SALE_RETURN'
export const SYNC_SALE_RETURN = 'SYNC_SALE_RETURN'
export const SET_RETURN_VALUE = 'SET_RETURN_VALUE'
export const CHANGE_DETAILS = 'CHANGE_DETAILS'
export const CLICK_CHECKBOX_DETAIL = 'CLICK_CHECKBOX_DETAIL'