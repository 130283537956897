import React from 'react';
import { 
    Row
    , Col
    , Button
} from 'reactstrap';

import SelectFromGroup from 'Components/Input/SelectFormGroup';

const AlipayWechatSettingForm = (props) => {
    const {language, onSelectAccount, onSelectTerminal, onRefreshAccount, onSubmit, onCancel} = props;
    return(
        <React.Fragment>
            <Row>
                <Col md={5}>{language.alipay_wechat_account}</Col>
                <Col md={5}>
                    <SelectFromGroup md="12"                            
                        name="account"
                        value={1}
                        onChange={onSelectAccount}
                        placeholder={language.choose_alipay_wechat_account}
                        options={[]}
                    />
                </Col>
                <Col md={2}><Button onClick={onRefreshAccount}>Refesh</Button></Col>
            </Row>
            <Row>
                <Col md={5}>{language.terminal}</Col>
                <Col md={5}>
                    <SelectFromGroup md="12"                            
                        name="terminal"
                        value={1}
                        onChange={onSelectTerminal}
                        placeholder={language.terminal}
                        options={[]}
                    />
                </Col>
            </Row>
            <Row>
                <Col className="text-center">
                    <Button onClick={onCancel} color="danger">{ language.close}</Button>
                    <Button onClick={onSubmit} color="primary">{ language.save}</Button>
                </Col>
            </Row>
        </React.Fragment>

    )
}

export default AlipayWechatSettingForm;