import { PRINT_DRUG_LABEL
    , CLOSE_PRINT_DRUG_LABEL
    , RESET_PRINT_DRUG_LABEL
    , SET_DRUG_LABEL_VALUE
    , IN_PROCESSING_DRUG_LABEL
 } from '../actionTypes'

import { print } from './shared'
import axiosHelper from '../middleware/axios'
import { notify, addNotification} from 'reapop'

let helper = new axiosHelper()

export const printDrugLabel = (detail, where) => (dispatch, getState) => {

    const { id } = getState().customer
    const customer = {
        id,
        name
    }
    dispatch({
        type: PRINT_DRUG_LABEL,
        payload: {
            detail,
            customer
        }
    })
    
    const drugLabelProcess = {...getState().drugLabel}

    return dispatch({
        type: IN_PROCESSING_DRUG_LABEL,
        payload: {
            detail: drugLabelProcess,
            customer
        }
    })

}

export const setProcessingLabel = (detail, where) => (dispatch, getState) => {

    const { id } = getState().customer
    const customer = {
        id,
        name
    }
    
    return  dispatch({
        type: IN_PROCESSING_DRUG_LABEL,
        payload: {
            detail,
            customer
        }
    })
}

export const closePrintDrugLabel = () => dispatch => {

    return dispatch({
        type: CLOSE_PRINT_DRUG_LABEL
    });
}

export const resetPrintDrugLabel = () => (dispatch, getState) => {
    const { id } = getState().customer
    const drugLabel = getState().drugLabel
    const processingProduct = {...drugLabel.processing_product}
    const customer = {
        id,
        name
    }

    return dispatch({
        type: RESET_PRINT_DRUG_LABEL,
        payload: {
            detail: processingProduct,
            processing: processingProduct,
            customer
        }
    })
}

export const submitPrintDrungLabel = () => (dispatch, getState) => {

    var beforePrint = function() {
        return dispatch({
            type: CLOSE_PRINT_DRUG_LABEL
        });
    };

    var afterPrint = function() {
        setTimeout(() => {
            return dispatch({
                type: CLOSE_PRINT_DRUG_LABEL
            });
        }, 1000)
    };
    const states = getState()
    const { id, name } = getState().customer
    const { drugLabel } = states
    const { employee } = states
    const employee_id = employee.employee.id
    const { product } = drugLabel
    const { reference_code } = product
    const { company, branch, token } = getState().settings
    const company_slug = company.slug

    const data = {
        id: drugLabel.id,
        employee_id: employee_id,
        product_id: product.id,
        company_id: company.id,
        volume_intake: drugLabel.volume_intake,
        unit_intake: drugLabel.unit_intake,
        daily_frequency_intake: drugLabel.daily_frequency_intake,
        is_taken_other: drugLabel.is_taken_other,
        taken_other: drugLabel.taken_other.toString(),
        taken_time: drugLabel.taken_time,
        taken_morning: drugLabel.taken_morning,
        taken_noon: drugLabel.taken_noon,
        taken_evening: drugLabel.taken_evening,
        taken_before_bed: drugLabel.taken_before_bed,
        taken_symptom_occur: drugLabel.taken_symptom_occur,
        symptom_occur: drugLabel.symptom_occur,
        note: drugLabel.note,
        is_note: drugLabel.is_note ? true : false,
        dosage_instruction: drugLabel.dosage_instruction,
        created_at: drugLabel.created_at,
        updated_at: drugLabel.updated_at,
        drug_label_information: drugLabel.drug_label_information,
        deleted_at: null
    }


    const endpoint = `/companies/${company_slug}/products/${reference_code}/drug_label`
    if (drugLabel.is_over_limit.length == 0) {
        if (drugLabel.save_label) {
            helper.put(endpoint, data, {
                'Authorization': `Bearer ${token}`
            }, 3).then(res => {
                if (res.message) {
                    dispatch(notify({
                        id: 'addDummy',
                        title: 'สำเร็จ',
                        message: 'เพิ่มสต๊อคสินค้าเรียบร้อยแล้ว',
                        status: 'success',
                        dismissible: true,
                        dismissAfter: 3000
                    }))
                }
            }).catch(err => {
                console.error(err);
                dispatch(notify({
                    id: 'saveDrugLabel',
                    title: 'ล้มเหลว',
                    message: 'บันทึกสลากยาไม่สำเร็จ',
                    status: 'error',
                    dismissible: true,
                    dismissAfter: 3000
                }))
            })
        }
    } else {
        dispatch(notify({
            id: 'saveDrugLabel',
            title: 'ล้มเหลว',
            message: 'บันทึกสลากยาไม่สำเร็จ',
            status: 'error',
            dismissible: true,
            dismissAfter: 10000
        }))
    }

    dispatch(print('print-drug-label', beforePrint, afterPrint))
}

export const setDrugLabelValue = (path,value) => dispatch => {
    return dispatch({
        type: SET_DRUG_LABEL_VALUE,
        payload: {
            path,
            value
        }
    })
}

export const selectTimeToEat = (value) => (dispatch, getState) => {

    const { morning, noon, evening, before_bed, when_symptoms_occur } = getState().language;
    const { timeSelected } = getState().drugLabel
    const index = timeSelected.indexOf(value)
    if (index < 0) {
        timeSelected.push(value)
    } else {
        timeSelected.splice(index, 1)
    }

    let res = [];
    timeSelected.map((time) => {
        
        if(time === morning){ 
            res[0] = time 
        }
        if(time === noon){ 
            res[1] = time 
        }
        if(time === evening){
            res[2] = time 
        }
        if(time === before_bed){
            res[3] = time 
        }
        if(time === when_symptoms_occur){
            res[4] = time 
        }
    })

    dispatch(setDrugLabelValue('timeSelected', [...res]))
}