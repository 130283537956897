import React from 'react';
import { Modal
    , ModalHeader
    , ModalBody
    , Row
    , Col
 } from 'reactstrap'

import PrinterSelectionForm from 'Components/Form/PrinterSelectionForm'; 
import DailySummaryForm from 'Components/Form/DailySummaryForm';
import QRForm from 'Components/Form/QRForm';
import SettingsForm from 'Components/Form/SettingsForm';

 const ProfileModal = (props) => {
     const {language} = props;
     return (
         <Modal
            toggle={props.onClose}
            isOpen={props.show}
            size="lg"  
            style={{
                maxWidth: '1000px'
            }}
         >
            <ModalHeader toggle={props.onClose}>{props.title}</ModalHeader>
            <ModalBody>
                <Row className='profileModal-content'>
                    <Col sm={12} md={3}>
                        <div className="side-tab">
                            {!props.settings.offline_mode && <div className={ props.panel === 0 ? 'text-center active': 'text-center' } onClick={() => { props.showPanel(0)}}>{language.daily_summary}</div>}
                            <div className={ props.panel === 1 ? 'text-center active': 'text-center' } onClick={() => { props.showPanel(1)}}>{language.select_printer_slip}</div>
                            {!props.settings.offline_mode && <div className={ props.panel === 2 ? 'text-center active': 'text-center' } onClick={() => { props.showPanel(2)}}>{language.qr_payment_setting}</div>}
                            <div className={ props.panel === 3 ? 'text-center active': 'text-center' } onClick={() => { props.showPanel(3)}}>{language.settings}</div>
                        </div>
                    </Col>
                    <Col sm={12} md={9} className='profileModal-content-r'>
                        { props.panel === 0 ?
                            <DailySummaryForm 
                                daily={ props.daily}
                                language={ props.language}
                                onClose={ props.onClose}
                                focus={ props.focus}
                                settings={ props.settings}/>
                            :
                          props.panel === 1 ?
                          <PrinterSelectionForm 
                                printers={ props.printers}
                                headers={ props.headers}
                                language={ props.language}
                                onSave={ props.onClose}
                                onClose={ props.onClose}
                                focus={ props.focus}
                            />
                            :
                          props.panel === 2 ?
                          <QRForm 
                            settings={props.settings}
                            language={props.language}
                            />
                            :
                          props.panel === 3 &&
                          <SettingsForm 
                            language={props.language}
                            isOpen={props.show}
                            settings={props.settings}
                          />
                        }
           
                    </Col>
                </Row>
            </ModalBody>
         </Modal>
     )
 }

 export default ProfileModal;