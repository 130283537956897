import openSocket from 'socket.io-client';


const API_SOCKET_URL = process.env.API_SOCKET_URL;
let socket;

export const connectSocket = () => {

    socket = openSocket(API_SOCKET_URL,{
        reconnectionAttempts : 10
    });

    socket.on('connected',() => {
        console.log('socket connect success');
    });

    socket.on('connecting',() => {
        console.log('socket connecting...');
    });

    socket.on('connect',() => {
        console.log('socket connecting...');
    });

    return new Promise((resolve,reject) => {
        socket.on('reconnect_failed', () => {
            reject('connect failed');
        })
    })
}

export const disconnectSocket = () => {
    if(socket) socket.disconnect();
}

export const qrPaymentSocket = (qrId) => {

    return new Promise((resolve) => {
        if(socket){
            socket.on(qrId, data => {
                resolve(data);
            });
        }
    });
}

export const qrWindowSocket = (uuid) => {
    return new Promise((resolve) => {
        if(socket){
            socket.on(`${uuid}/connected`, data => {
                let res = Object.assign({},data,{type: "connected"});
                resolve(res);
            });
            socket.on(`${uuid}/paid`, data => {
                let res = Object.assign({},data,{type: "paid"});
                resolve(res);
            });
            socket.on(`${uuid}/checkout`, data => {
                let res = Object.assign({},data,{type: "checkout"});
                resolve(res);
            });
            socket.on(`${uuid}/refresh`, data => {
                let res = Object.assign({},data,{type: "refresh"});
                resolve(res);
            });
            socket.on(`${uuid}/close`, data => {
                let res = Object.assign({},data,{type: "close"});
                resolve(res);
            });
        }
    });
}




