import React from 'react';
import { Route } from 'react-router-dom';

import PrivateRoute from '../routes/PrivateRoute';

import PosPage from '../pages/PosPage';
import LoginPage from '../pages/LoginPage';
import PrintSalesReceiptPage from '../pages/PrintSalesReceiptPage'
import QRPage from '../pages/QRPage';
import ServicePage from '../pages/ServicePage';

const App = () => (
  <div>
    <PrivateRoute exact path="/" component={PosPage} />
    <Route exact path="/login" component={LoginPage} />
    <Route exact path="/print/sales-receipt" component={PrintSalesReceiptPage} />
    <Route exact path="/qr/:c" component={QRPage} />
    <PrivateRoute exact path="/service" component={ServicePage} />
  </div>
)

export default App;
