import { CLOSE_SEARCH_CUSTOMERS
    , SET_CURRENT_CUSTOMER
    , REMOVE_CUSTOMER
    , OPEN_CUSTOMER_REGISTER
    , CLOSE_CUSTOMER_REGISTER
    , SET_CUSTOMER_VALUE
    , SET_CUSTOMER_INFO
    , CLOSE_CUSTOMER_INFO
    , ADD_ALLERGY_INFO
    , REMOVE_ALLERGY_INFO
    , EDIT_INFO_CUSTOMER
    , ALLERGY_CHANGE
    , SHOW_NOTI_USER_AS_CUSTOMER
    , CLOSE_NOTI_USER_AS_CUSTOMER
} from '../actionTypes'

import moment from 'moment'
import axiosHelper from '../middleware/axios'
import { notify, removeNotification } from 'reapop'
import { closeVoiceCallWidget } from './telepharmacy'
import axios from 'axios'

let helper = new axiosHelper()
const API2_URL = process.env.API2_URL;

export const closeSearchCustomers = () => dispatch => {
    return dispatch({ type: CLOSE_SEARCH_CUSTOMERS })
}

export const selectCustomer = customer => dispatch => {
    return dispatch({ type: SET_CURRENT_CUSTOMER, payload: customer })
}

export const unSelectCustomer = () => dispatch => {
    return dispatch({ type: REMOVE_CUSTOMER })
}

export const openCustomerRegister = () => dispatch => {
    return dispatch({ type: OPEN_CUSTOMER_REGISTER })
}

export const closeCustomerRegister = () => dispatch => {
    return dispatch({ type: CLOSE_CUSTOMER_REGISTER })
}

export const setCustomerValue = (path, value) => dispatch => {
    return dispatch({
        type: SET_CUSTOMER_VALUE,
        payload: {
            path,
            value
        }
    })
}

export const getCustomerInfo = (customerId) => (dispatch, getState) => {
    const { company, branch, token } = getState().settings

    return helper.get(`/customers/companies/${company.id}/customer/${customerId}?company_id=${company.id}&branch_id=${branch.id}&id=${customerId}`, {}, {
        'Authorization': `Bearer ${token}`
    }, 2).then(res => {
        const customer = res.customer
        return dispatch({
            type: SET_CUSTOMER_INFO,
            payload: {
                customer
            }
        })
    }).catch((err) => {
        console.error(err);
        return { customerFailed: true }
    })
}

export const getSalesOrders = (duration=1) => (dispatch, getState) => {
    const { company, branch, token } = getState().settings
    const { employee } = getState().employee
    const customer = getState().customer

    const startedAt = moment().subtract(duration, 'months').format('YYYY-M-D')
    const finishedAt = moment().format('YYYY-M-D')

    return helper.get(`/customers/companies/${company.id}/customer/${customer.id}/sales-history?company_id=${company.id}&branch_id=${branch.id}&employee_id=${employee.id}&member_id=${customer.id}&customer_id=${customer.id}&started_at=${startedAt}&finished_at=${finishedAt}`, {}, {
        'Authorization': `Bearer ${token}`
    }, 2).then(res => {
        const sales_orders = res.sales_orders
        return dispatch({
            type: SET_CUSTOMER_INFO,
            payload: {
                sales_orders
            }
        })
    })
}

export const closeCustomerInfo = () => dispatch => {
    return dispatch({ type: CLOSE_CUSTOMER_INFO })
}

export const addAllergyInfo = (path) => (dispatch, getState) => {
    const { company, branch } = getState().settings
    const { employee } = getState().employee
    const customer = getState().customer
  
    let allergic = Object.assign({}, {
        user_id: customer.id === null ? 0 : customer.id,
        employee_id: employee.id,
        company_id: company.id,
        branch_id: branch.id,
        suggestions: [],
        state: 'add'
    })
    
    switch(path){
        case 'allergic_to_products':{ 
            allergic = Object.assign({}, allergic, {
                product_id: '',
                product_name: ''
            });
            break;
        }
        case 'allergic_to_ingredients':{ 
            allergic = Object.assign({}, allergic, {
                ingredient_id: '',
                ingredient_name: ''
            })
            break;
        }
        case 'customer_diseases':{ 
            allergic = Object.assign({}, allergic, {
                disease_id: '',
                disease_name: ''
            })
            break;
        }
    }

    return dispatch({
        type: ADD_ALLERGY_INFO,
        payload: {
            path,
            allergic
        }
    })
}

const setAllergyChange = payload => ({
    type: ALLERGY_CHANGE,
    payload
})

export const removeAllergyInfo = (path, index) => (dispatch, getState) => {
    const { data } = getState().customer
    
    if (data[path][index].created_at) {
        dispatch(setAllergyChange({
            path,
            key: 'state',
            value: 'delete',
            index
        }))
    } else {
        dispatch({
            type: REMOVE_ALLERGY_INFO,
            payload: {
                path,
                index
            }
        })
    }
}

export const getAllergy = ({ value }, path, index) => (dispatch, getState) => {
    const { company } = getState().settings
    const name = (path === 'allergic_to_products') ? 'products' : (path === 'allergic_to_ingredients') ? 'ingredients' : 'diseases'

    return helper.get(`/${name}?q=${value}&company_id=${company.id}`, {}, {}, 1).then(res => {
        dispatch(setAllergyChange({
            path,
            key: 'suggestions',
            value: res[name],
            index
        }))
    })
}

export const clearAllergy = (path, index) => dispatch => {
    dispatch(setAllergyChange({
        path,
        key: 'suggestions',
        value: [],
        index
    }))
}

export const registerCustomer = () => (dispatch, getState) => {
    const { company, branch, token } = getState().settings
    const { employee } = getState().employee
    const { data } = getState().customer
    const newData = {
        ...data,
        company_id: (data.company_id == null) ? company.id : data.company_id,
        branch_id: (data.branch_id == null) ? branch.id : data.branch_id,
        employee_id: employee.id,
        retail_price_level: data.price_level
    }

    return helper.put(`/members${data.id ? `/${data.id}/update` : `/new`}`, newData, {
        'Authorization': `Bearer ${token}`
    }, 2).then(res => {
        const attributes = res.data.attributes
        if (!attributes.error) {
            const customer = {
                id: attributes.id,
                ingredient_allergies: attributes.ingredient_allergies,
                name: attributes.name,
                price_level: attributes.retail_price_level,
                product_allergies: attributes.product_allergies,
                reference_code: attributes.reference_code,
                retail_price_level: attributes.retail_price_level,
                wholesale_price_level: attributes.wholesale_price_level
            }
            
            dispatch(notify({
                id: 'customer',
                title: 'สำเร็จ',
                message: 'บันทึกข้อมูลเรียบร้อยแล้ว',
                status: 'success',
                dismissible: true,
                dismissAfter: 3000
            }))
            dispatch({ type: SET_CURRENT_CUSTOMER, payload: customer })
        } else {
            dispatch(notify({
                id: 'customer',
                title: 'เกิดข้อผิดพลาด',
                message: 'เลขบัตรประชาชนหรือเบอร์โทรศัพท์นี้เคยลงทะเบียนไว้แล้ว',
                status: 'warning',
                dismissible: true,
                dismissAfter: 3000
            }))
        }
    }).catch(err => {
        console.error(err);
        dispatch(notify({
            id: 'customer',
            title: 'ล้มเหลว',
            message: 'บันทึกข้อมูลไม่สำเร็จ',
            status: 'warning',
            dismissible: true,
            dismissAfter: 3000
        }))
    })
}

export const editInfoCustomer = (customer) => dispatch => {
    for (let i in customer.allergic_to_products) {
        let product = customer.allergic_to_products[i]
        product.suggestions = []
    }
    
    for (let i in customer.allergic_to_ingredients) {
        let ingredient = customer.allergic_to_ingredients[i]
        ingredient.suggestions = []
    }

    for (let i in customer.customer_diseases) {
        let diseases = customer.customer_diseases[i]
        diseases.suggestions = []
    }

    dispatch({ type: EDIT_INFO_CUSTOMER, payload: customer })
}

export const handleAllergyChange = (index, input, value) => dispatch => {
    const { id, name } = input

    dispatch(setAllergyChange({
        path: id,
        key: name,
        value,
        index
    }))
}

export const closeNotiUserAsCustomerModal = () => dispatch => {
    return dispatch({ type: CLOSE_NOTI_USER_AS_CUSTOMER });
}

const CancelToken = axios.CancelToken;
let cancel;

export const checkUserAsCustomer = (userId) => async (dispatch, getState) => {
    if (cancel !== undefined) {
        cancel();
    }

    const { company, token } = getState().settings;
    try {
        const res = await axios(`${API2_URL}/members`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
            params: {
                company_id: company.id,
                filters: {
                    user_id: userId,
                },
            },
            cancelToken: new CancelToken(function executor(c) {
                cancel = c;
            }),
        });
        return res.data.customers.length > 0;
    } catch (err) {
        console.error(err);
        return false;
    }
}

export const checkCustomerBeforeRegister = (userMobilePhone, userId, type) => async (dispatch, getState) => {
    dispatch(notify({
        id: 'customer',
        message: 'กำลังดำเนินการ...',
        status: 'loading',
        dismissible: true,
        dismissAfter: 10000
    }));

    const { company, token } = getState().settings;
    try {
        const filters = encodeURIComponent(`{"user_id":null,"mobile_number":"${userMobilePhone}"}`);
        const res = await helper.get(`/members?company_id=${company.id}&filters=${filters}`, {}, {
            'Authorization': `Bearer ${token}`,
        }, 2);
        if (!res.customers.length) {
            dispatch(registerUserAsCustomer(userId, type));
        } else {
            const customerId = res.customers[0] && res.customers[0].id ? res.customers[0].id : '';
            const customerName = res.customers[0] && res.customers[0].name ? res.customers[0].name : '';
            dispatch({
                type: SHOW_NOTI_USER_AS_CUSTOMER,
                payload: {
                    type,
                    userId,
                    customer: {
                        id: customerId,
                        name: customerName,
                    }
                }
            });
            dispatch(removeNotification('customer'));
        }
    } catch (err) {
        console.error(err);
        dispatch(removeNotification('customer'));
    }
}

export const registerUserAsCustomer = (userId, type) => async (dispatch, getState) => {
    const { company, token } = getState().settings;
    try {
        const res = await helper.post(`/companies/${company.slug}/users/${userId}/customers`, null, {
            Authorization: `Bearer ${token}`,
        }, 3);
        if (res.statusCode === '200') {
            dispatch(notify({
                id: 'customer',
                message: 'บันทึกข้อมูลสมาชิกเรียบร้อยแล้ว',
                status: 'success',
                dismissible: true,
                dismissAfter: 3000
            }));
            dispatch(selectCustomer(res.customer));
            if (type === 'voice') dispatch(closeVoiceCallWidget());
        } else {
            throw Error('Failed to register customer.');
        }
    } catch (err) {
        console.error(err);
        dispatch(notify({
            id: 'customer',
            title: 'ล้มเหลว',
            message: 'บันทึกข้อมูลสมาชิกไม่สำเร็จ',
            status: 'warning',
            dismissible: true,
            dismissAfter: 3000,
        }));
        dispatch(closeNotiUserAsCustomerModal());
    }
};

export const updateCustomerFromUser = (userId, customerId, type) => async (dispatch, getState) => {
    dispatch(notify({
        id: 'customer',
        message: 'กำลังดำเนินการ...',
        status: 'loading',
        dismissible: true,
        dismissAfter: 10000
    }));

    const { company, token } = getState().settings;
    try {
        const res = await helper.put(`/companies/${company.slug}/users/${userId}/customers${customerId ? `/${customerId}` : ''}`, null, {
            Authorization: `Bearer ${token}`,
        }, 3);
        if (res.statusCode === '200') {
            dispatch(notify({
                id: 'customer',
                message: 'อัพเดทข้อมูลสมาชิกเรียบร้อยแล้ว',
                status: 'success',
                dismissible: true,
                dismissAfter: 3000
            }));
            dispatch(selectCustomer(res.customer));
            if (type === 'voice') dispatch(closeVoiceCallWidget());
        } else {
            throw Error('Failed to update customer info.');
        }
    } catch (err) {
        console.error(err);
        dispatch(notify({
            id: 'customer',
            title: 'ล้มเหลว',
            message: 'อัพเดทข้อมูลสมาชิกไม่สำเร็จ',
            status: 'warning',
            dismissible: true,
            dismissAfter: 3000,
        }));
        dispatch(closeNotiUserAsCustomerModal());
    }
}
