import React from 'react'
import { Modal
    , ModalHeader
    , ModalBody
    , Row
    , Col
    , Button
    , Input
    , Form
 } from 'reactstrap'




class DangerMedModal extends React.Component{
    constructor(props){
        super(props);
        this._submit = this._submit.bind(this);
        this._change = this._change.bind(this);
    }

    _change(evt){
        this.props.onChange ? this.props.onChange(evt.target.value) : null;
    }

    _submit(evt){
        evt.preventDefault();
        this.props.onSubmit();
    }

    render(){
        return(
            <Modal
                isOpen={this.props.show}
                size="lg"  
            >
                <ModalHeader>{this.props.language.warning}</ModalHeader>
                <ModalBody>
                    <Form onSubmit={this._submit} className="dangerMedModal-content">
                        <div >
                            <Row>
                                <Col className='text-danger'>ยาอันตราย / ยาควบคุมพิเศษ { this.props.dg_med.join(',')}</Col>
                            </Row>
                            <Row>
                                <Col className='text-danger'>กรุณาใส่ชื่อ - นามสกุล ผู้ซื้อ ยาอันตราย / ยาควบคุมพิเศษ</Col>
                            </Row>
                            <Row>
                                <input
                                    className="form-control"  
                                    type='text'
                                    onChange={ this._change}
                                    ref="name"
                                    value={this.props.value || ''}
                                />
                            </Row>
                        </div>
                        <div className="text-center">
                            <Button color="danger" onClick={ this.props.onClose}>{ this.props.language.cancel}</Button>
                            <Button color="primary" onClick={ this._submit}>{ this.props.language.save}</Button>
                        </div>
                    </Form>
                </ModalBody>
            </Modal>
        )
    }

    componentDidUpdate(preProps){

        const {focus} = this.props;
        const {focus: wasFocus} = preProps;

        setTimeout(() => {
            if( !wasFocus && focus){
                this.refs.name.focus();
                this.refs.name.select();
            }
        })
    }
}

export default DangerMedModal;
