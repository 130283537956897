import React from 'react';
import { Container
    , Row
    , Col } from 'reactstrap';

import ACHeader from './Header';
import ACRow from './Row';

const notFoundTxtStyle = {
    "backgroundColor": "#faf2cc"
    ,"padding":"5px"
}

class ACTable extends React.Component{

    render(){

        const { currentRow, onClickRow, isOfflineMode } = this.props;

        let rowEle = this.props.datas.map((data, index) => {
            return <ACRow data={data} 
            headers={ this.props.headers }
            childHeaders={ this.props.childHeaders }
            hightLight={ index === this.props.currentRow }
            index={ index+1 }
            childField={this.props.childField}
            key={index}
            isCurrentRow={ currentRow === index }
            onClick={ onClickRow }
            isOfflineMode={isOfflineMode}
            ></ACRow>
        })
        return (
            <div>
                <ACHeader headers={ this.props.headers }></ACHeader>
                { this.props.datas.length > 0 ?
                    rowEle :
                    <Row className='text-center' style={ notFoundTxtStyle }>
                        <Col sm={ 12 } md={ 12 } lg={ 12 }>
                            { this.props.notFoundTxt }
                        </Col>
                    </Row>
                }
            </div>
        )
    }
}

export default ACTable