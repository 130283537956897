import { 
    SET_PRINTER_TABLE_HEADER
    , SELECT_PRINTER
 } from 'Redux/actionTypes';



 export const setPrinterTableHeader = (headers) => dispatch => {

    return dispatch({
        type: SET_PRINTER_TABLE_HEADER
        , payload: headers
    })
 }

 export const selectPrinter = (printer) => dispatch => {
    return dispatch({
        type: SELECT_PRINTER
        , payload: printer
    })
 }