import { SET_CUSTOMER_INFO, CLOSE_CUSTOMER_INFO } from '../actionTypes'

const initialState = {
    customer: {
        allergic_to_products: [],
        allergic_to_ingredients: [],
        top_5_products: [],
        customer_diseases: []
    },
    sales_orders: []
}

export default (state=initialState, { type, payload }) => {
    switch (type) {
        case SET_CUSTOMER_INFO:
            return Object.assign({}, state, payload)
        case CLOSE_CUSTOMER_INFO:
            return initialState
        default:
            return state
    }
}