import { COPY_CART_TO_SR
    , CLOSE_PRINT_SALE_RECEIPT
    , OPEN_PRINT_SALE_RECEIPT
    , CHECKOUT_LOADING
    , CHECKOUT_SUCCESS
    , CHECKOUT_FAILURE
    , ADD_MESSAGE_LOG_TO_CART
} from '../actionTypes'

import { printDeliveryNoteReceipt, addLogToCart } from 'Redux/actions';
import db from '../../utils/db'
import axios from 'axios';
import moment from 'moment'
import { number } from 'Utilz/currency';
import { notify } from 'reapop';

const API3_URL = process.env.API3_URL;

export const printSaleReceipt = (where) => (dispatch, getState) => {
    const { settings, employee, saleReceipt } = getState();
    const { prescription_category } = saleReceipt;

    if (prescription_category && prescription_category == 'mc') {
        return dispatch(printDeliveryNoteReceipt());
    }

    try {
        localStorage.setItem('printSalesReceipt', JSON.stringify({ settings, employee, saleReceipt }));
        window.open('/print/sales-receipt', '_blank', 'width=600,height=900,noopener');
    } catch (err) {
        console.error(err);
        dispatch(notify({
            id: 'printSalesReceipt',
            title: 'เกิดข้อผิดพลาด',
            message: 'ไม่สามารถดำเนินการพิมพ์ใบเสร็จได้',
            status: 'error',
            dismissAfter: 3000,
        }));
    }
}


const initABB = {reference_code: null}
const initMember = {   
    reference_code : "ลูกค้าทั่วไป"
    , name: 'ลูกค้าทั่วไป'
}


export const convertToSaleReceipt = (sale_return) => (dispatch, getState) =>{

    const { attributes: saleReturnAttr, relationships } = sale_return;
    const { abb, customer, prescription, details, employee, member, coupons } = relationships;
    const { attributes: employeeAttr} = employee;

    const initSR = getState().saleReceipt;
    const initDetail = getState().detail;
  
    let sr = Object.assign({}, initSR,{
        reference_code: saleReturnAttr.reference_code
        , details: _groupDetails(details, initDetail)
        , company_id: saleReturnAttr.company_id
        , branch_id: saleReturnAttr.branch_id
        , employee: employeeAttr
        , employee_id: employeeAttr.id
        , current_status_id: saleReturnAttr.current_status_id
        , created_at: saleReturnAttr.created_at
        , tax_types: saleReturnAttr.tax_types
        , tax_percentage: saleReturnAttr.tax_percentage 
        , total_discount_amount: saleReturnAttr.total_discount_amount
        , tax_amount: saleReturnAttr.tax_amount
        , total_amount: saleReturnAttr.total_amount
        , total_paid_amount: saleReturnAttr.total_amount
        , paid_amount: saleReturnAttr.paid_amount
        , change_amount: saleReturnAttr.change_amount
        , credit_include: saleReturnAttr.credit_include
        , abb: (abb === null) ? initABB : abb.attributes
        , customer_id: (customer === null) ? null : customer.id
        , member: (customer === null) ? initMember : {
            name: `${customer.attributes.first_name} ${customer.attributes.last_name}`
        }
        , member_id:(member === null) ?  null : member.id
        , prescription_reference_code: prescription ? prescription.attributes.reference_code : null
        , prescription_category: prescription ? prescription.attributes.category : ''
        , prescriber_full_name: prescription ? prescription.attributes.prescriber_full_name : ''
        , medcare_order_reference:
            prescription &&
            prescription.attributes.category == 'mc' &&
            prescription.relationships.messenger_details
                ? prescription.relationships.messenger_details.attributes.phone_number
                : ''
        , bank_account_id: (saleReturnAttr.bank_account_id === "")? null : Number(saleReturnAttr.bank_account_id)
        , payment_types: saleReturnAttr.payment_types
        , printer_license: saleReturnAttr.printer_license
        , credit_charge: saleReturnAttr.credit_charge
        , coupon: coupons && coupons.length ? coupons[0].attributes : null
    });

    return dispatch({
        type: COPY_CART_TO_SR
        , payload: sr
    })
}

export const groupDetails = _groupDetails;

function _groupDetails (details, initDetail) {

    let res = []

    let detailGroupProducts = details.filter((detail) => { return detail.attributes.product_set_type === '1' });
    let detailProducts = details.filter((detail) => { return detail.attributes.product_set_type !== '1' });

    detailGroupProducts.map((detailGroupProduct) => {
        const { attributes, relationships} = detailGroupProduct;
        const { product, unit, sales_return_details } = relationships;
        const { attributes: productAttr } = product
        const { attributes: unitAttr } = unit;

        let groupIndex = res.findIndex((res)=>{ return res.product_set_id === attributes.product_set_id });

        const sales_quantity = number(attributes.sales_quantity).divide(attributes.product_quantity_in_set).value;
        const remainingQuantity = _getRemainingQuautity(sales_return_details, sales_quantity)

        if (groupIndex === -1) {
            res.push(Object.assign({},initDetail,{
                product_set_id: attributes.product_set_id
                , product_set_type: attributes.product_set_type
                , product: Object.assign({},initDetail.product,{
                    name: attributes.product_set_name
                })
                , price_per_unit: attributes.product_set_amount
                , sales_quantity: sales_quantity
                , products: [_addProductInProductGroup(productAttr, unitAttr, attributes)]
                , sales_receipt_id: attributes.sales_receipt_id
                , net_amount: number(attributes.product_set_amount).multiply(sales_quantity).value
                , sales_price_per_unit: attributes.product_set_amount
                , remaining_quantity: remainingQuantity < 0 ? 0 : remainingQuantity
                , product_id: attributes.product_id
                , id: attributes.id
            }))
        } else {
            res[groupIndex].products.push(_addProductInProductGroup(productAttr, unitAttr, attributes))
            if (res[groupIndex].remaining_quantity === 0) {
                res[groupIndex].remaining_quantity = remainingQuantity < 0 ? 0 : remainingQuantity
            }
        }
    })

    detailProducts.map((detailProduct) => {

        const { attributes, relationships} = detailProduct;
        const { product, unit, sales_return_details } = relationships;
        const { attributes: productAttr } = product
        const { attributes: unitAttr } = unit;

        const remainingQuantity = _getRemainingQuautity(sales_return_details,attributes.sales_quantity)

        res.push(Object.assign({},initDetail,{
            product: productAttr
            , unit: unitAttr
            , product_set_id: attributes.product_set_id
            , product_id: attributes.product_id
            , unit_id: attributes.unit_id
            , sales_quantity: attributes.sales_quantity
            , price_per_unit: attributes.price_per_unit
            , net_amount: attributes.net_amount
            , product_set_type: attributes.product_set_type
            , sales_receipt_id: attributes.sales_receipt_id
            , id: attributes.id
            , sales_price_per_unit: attributes.price_per_unit
            , remaining_quantity: remainingQuantity < 0 ? 0 : remainingQuantity
            , sales_receipt_detail_id: attributes.id
        }));
    })

    return res;
}

function _addProductInProductGroup(productAttr, unitAttr, attributes){
    return Object.assign({}, productAttr,{
        quantity: attributes.sales_quantity
        , unit: unitAttr
        , product_is_free: attributes.product_is_free
        , price_per_unit: attributes.product_set_amount
        , net_amount: number(attributes.sales_quantity).divide(attributes.product_quantity_in_set).multiply(attributes.product_set_amount).value
        , sales_receipt_detail_id: attributes.id
        , product_quantity_in_set: attributes.product_quantity_in_set
    })
}

function _getRemainingQuautity(salesReturnDetails, salesQuantity = 0) {
    const reduceQuantity = salesReturnDetails.reduce((prev, curr) => prev + number(curr.attributes.quantity).value, 0);
    return number(salesQuantity).subtract(reduceQuantity).value;
}


export const sendSRLogToApi = () => (dispatch, getState, context) => {
    const { company, branch, token } = getState().settings;
    const cart = {...getState().cart};
    const { employee } = getState().employee;

    return new Promise(async (resolve, reject) => {
        dispatch({ type: CHECKOUT_LOADING })

        // DEBUG: Clickup TaskID: 45q1ub
        // cart.redux_actions_log = context.getActionLog();

        try {
            const res = await axios.post(`${API3_URL}/companies/${company.slug}/branches/${branch.slug}/pos/sales-receipts/log`, cart, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            }).then(res => res.data)

            context.clearActionLog();

            if (res.status === 'success') {
                dispatch({ type: CHECKOUT_SUCCESS });
                resolve(true)
            } else {
                throw Error('sendSRLogToApi has error.');
            }
        } catch (err) {
            console.error(err);
            addLogToCart(cart, `POS Change histories [s1] with employee_id is ${employee.id}`, false);
            const updateCart = {
                current_status_id: 1,
                employee: employee,
                histories: [...cart.histories, {
                    employee_id: employee.id,
                    sales_receipt_status_id: 1,
                    created_at: moment().format('YYYY-MM-DD HH:mm:ss')
                }],
                pos_logs: [...cart.pos_logs || [], `[${moment().format('YYYY-MM-DD HH:mm:ss')}] send SRLog has error.`]
            }
    
            db.table('carts').update(cart.reference_code, updateCart).finally(() => {
                dispatch({ type: CHECKOUT_FAILURE });
                reject(false);
            });
        }
    })
}

export const AddMessageLogToCart = message => dispatch => {
    return dispatch({
        type: ADD_MESSAGE_LOG_TO_CART,
        payload: message
    })
}