import React from 'react'
import {
    Row
    , Col
    , Button
 } from 'reactstrap'

 import ACTable from 'Components/DataGrid/Table';

 const PrinterSelectionForm = ({ printers, headers, language, onClose, onSave, focus}) => {
     

    return(
        <div className='printerSelectionForm'>
            <div>
                <ACTable 
                    datas={ printers } 
                    headers={ headers }
                    notFoundTxt={language.not_found_printer}
                />
            </div>
            <div className='text-center'>
                <Button onClick={ onClose} color="danger" autoFocus={ focus}>{ language.close}</Button>
                <Button onClick={ onSave} color="primary">{ language.save}</Button>
            </div>
        </div>
    )
 }

 export default PrinterSelectionForm;