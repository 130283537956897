import React from 'react'
import { connect } from 'react-redux'
import { Navbar, Nav, NavItem, NavLink, Badge } from 'reactstrap'
import Logo from '../images/logo.png'

import ProfileModal from 'Components/Modal/ProfileModal';
import SyncErrorNotification from 'Components/Form/SyncErrorNotification'
import ServicesModal from 'Components/Page/Services/ServicesModal';
import PrescriptionListModal from 'Components/Modal/PrescriptionListModal';
import CallDropdownPanel from 'Components/Form/Telepharmacy/Voice/CallDropdownPanel';
import VoiceSetting from 'Components/Form/VoiceSetting';

import { employeeLogout
    , statusOnline
    , statusOffline
    , setPrinterTableHeader
    , openProfileModal
    , closeProfileModal
    , getTableHeader
    , selectPrinter
    , selectProfilePanel
    , syncAgain
    , continueSyncError
    , deleteSyncError
    , newQRWindow
    , closeQRWindow
    , openPrescriptionListModal
    , sendSaleReceipts
    , toggleVoiceBusy
    , toggleChatBusy
    , openServiceDeliverySearchHistory
    , setNotiEpSoundLoop
    , setNotiEpSoundPlay
} from 'Redux/actions'

import { Howl, Howler } from 'howler';
import newNotificationSound from '../sounds/notification.wav';

class Header extends React.Component {
    constructor(props) {
        super(props)
        this._setPrinterHeader = this._setPrinterHeader.bind(this);
        this._renderPrinterRow1 = this._renderPrinterRow1.bind(this);
        this.state = {
            playingLoopCount : 0
        }
    }

    componentDidMount() {
        window.addEventListener('online', () => {
            navigator.onLine && this.props.statusOnline()
        })

        window.addEventListener('offline', () => {
            !navigator.onLine && this.props.statusOffline()
        })

        const { language, setPrinterTableHeader, closeQRWindow } = this.props;
        setPrinterTableHeader(this._setPrinterHeader(language));

        window.onbeforeunload = function (evt) {
            closeQRWindow();
        }
    }

    componentDidUpdate(preProps){
        const { language: preLang, isPlaying : preStatusPlaying, isLooping : preStatusLooping } = preProps;
        const { language, setPrinterTableHeader, isPlaying, isLooping } = this.props;

        if(language != preLang){
            setPrinterTableHeader(this._setPrinterHeader(language));
        }

        if(preStatusPlaying != isPlaying){
            this.playNotiSound();
        }
    }

    _renderPrinterRow1(data){

        return(
            <span>
                <input type="radio"
                name='printer'
                onChange={() => { this.props.selectPrinter(data) }}
                checked={ this.props.printer.selected.id === data.id}
                />
            </span>
        )
    }

    _setPrinterHeader(language){
        let headers = [];

        headers.push(this.props.getTableHeader('',undefined,'custom',1, this._renderPrinterRow1));
        headers.push(this.props.getTableHeader(`${language.printer_header}`,'printer_name','text', 5));
        headers.push(this.props.getTableHeader(`${language.printer_license_header}`,'printer_license','text', 6));

        return headers;
    }

    togglePlay = () => {
        this.props.setNotiEpSoundPlay(!this.props.isPlaying);
    };

    playNotiSound = async () => {
        const sound = new Howl({
            src: [newNotificationSound],
            html5: true,
            volume: 1,
        });

        if(this.props.isPlaying && !this.props.isLooping) {
            sound.play();
            sound.on('end', () => {
                this.props.setNotiEpSoundPlay(false)
                sound.unload();
            });
        } 

        if(this.props.isPlaying && this.props.isLooping) {
            sound.play();
            sound.on('end', () => {
                this.props.setNotiEpSoundPlay(true)
                this.playNotiSound();
            });
        }
        
        if(!this.props.isPlaying) {
            this.setState({ playingLoopCount : 0 })
            sound.stop();
            sound.unload();
        }
    }

    render() {
        const { employee
            , employeeLogout
            , language
            , settings
            , errorMessages
            , modal
            , openProfileModal
            , closeProfileModal
            , printer
            , printerList
            , focus
            , selectProfilePanel
            , daily
            , newQRWindow
            , closeQRWindow
            , prescriptionInboxCount
            , openPrescriptionListModal
            , isPlaying
        } = this.props

        const APP_VERSION = process.env.APP_VERSION || '1.0.0'
        const voiceCallIsAvailable = settings.branch.telepharmacy.voice;
        const chatIsAvailable = settings.branch.telepharmacy.chat;
        const hasTelepharmacyFeature = voiceCallIsAvailable || chatIsAvailable

        return (
            <Navbar color={settings.offline_mode ? 'dark' : 'primary'} dark expand="md">
                 {/* for debug sound */}
                 {/* <div> 
                    <button onClick={this.togglePlay}>
                        {isPlaying ? 'Pause' : 'Play'}
                    </button>
                </div>  */}
                {/* for debug sound */}
                <a href="/">
                    <img src={Logo} className="navbar-brand-logo" alt="" />
                </a>
                <Nav navbar>
                    <NavItem>
                        <NavLink>
                            <Badge color="danger">BETA {APP_VERSION}</Badge>
                        </NavLink>
                    </NavItem>
                    {settings.company.name ? <span className="navbar-text text-white">
                        <b>{settings.company.name}</b>{' '}
                        <small>({settings.branch.name})</small>
                    </span> : ''}
                </Nav>
                <Nav className="ml-auto" navbar>
                    {!settings.offline_mode && !!employee.employee.id && hasTelepharmacyFeature && <CallDropdownPanel
                        language={language}
                        voice={this.props.voice}
                        chat={this.props.chat}
                        toggleVoiceBusy={this.props.toggleVoiceBusy}
                        toggleChatBusy={this.props.toggleChatBusy}
                        settings={settings}
                    />}
                    {settings.branch.enable_prescription && (
                        <NavItem >
                            <NavLink
                                className="link mr-2 shadow-box"
                                title="e-Prescription"
                                onClick={openPrescriptionListModal}
                            >
                                <div>
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g clipPath="url(#clip0_10911_51)">
                                    <path className={!!prescriptionInboxCount ? 'flashing-svg flashing' : ''} d="M19 3H14.815C14.405 1.84 13.305 1 12 1C10.695 1 9.595 1.84 9.185 3H5C3.895 3 3 3.895 3 5V19C3 20.105 3.895 21 5 21H19C20.105 21 21 20.105 21 19V5C21 3.895 20.105 3 19 3ZM13 18H11V16H13V18ZM13 14H11V8H13V14ZM12 5C11.45 5 11 4.555 11 4C11 3.445 11.45 3 12 3C12.55 3 13 3.445 13 4C13 4.555 12.55 5 12 5Z" fill="#454545"/>
                                    <rect x="5" y="7" width="14" height="12" rx="1" fill="white"/>
                                    </g>
                                    <defs>
                                    <clipPath id="clip0_10911_51">
                                    <rect width="24" height="24" fill="white"/>
                                    </clipPath>
                                    </defs>
                                </svg>
                                </div>
                                {!!prescriptionInboxCount && (
                                    <div className='notify'>
                                        <span 
                                       className={`prescription-inbox-count flash-text ${!!prescriptionInboxCount ? 'flashing-text' : ''}`}>
                                        {prescriptionInboxCount}
                                    </span>
                                    </div>
                                )}
                            </NavLink>
                        </NavItem>
                    )}
                    {!settings.offline_mode && <NavItem>
                        <NavLink
                            className="link mr-2 shadow-box"
                            onClick={newQRWindow}>
                            <div>
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M20 3H4C2.9 3 2 3.9 2 5V16C2 17.1 2.9 18 4 18H7L6 19V21H18V19L17 18H20C21.1 18 22 17.1 22 16V5C22 3.9 21.1 3 20 3ZM20 16H4V5H20V16Z" fill="#454545"/>
                                </svg>
                            </div>
                        </NavLink>
                    </NavItem>}
                    {errorMessages.length > 0 && <SyncErrorNotification
                        language={language}
                        errors={errorMessages}
                        syncAgain={this.props.syncAgain}
                        continueSyncError={this.props.continueSyncError}
                        deleteSyncError={this.props.deleteSyncError}
                        openServiceDeliverySearchHistory={this.props.openServiceDeliverySearchHistory}
                    />}
                    {settings.offline_mode && employee.employee.id && <NavItem>
                        <NavLink
                            className="link mr-2 shadow-box"
                            title={language.sync_sale_receipts}
                            onClick={this.props.sendSaleReceipts}>
                            <div>
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M12.0469 5.64708C12.3789 5.64708 13.1677 5.85703 13.1677 5.85703C15.3047 6.34673 16.8089 8.31511 16.8089 10.5375V16.5193L17.2458 16.9583L17.6108 17.325H6.38916L6.75422 16.9583L7.19114 16.5193V10.5375C7.19114 8.31516 8.69531 6.34673 10.8323 5.85703C10.8323 5.85703 11.6781 5.64708 11.9531 5.64708M12 2.25C11.1749 2.25 10.5441 2.88366 10.5441 3.7125V4.39495C7.77778 5.02884 5.69114 7.56375 5.69114 10.5375V15.9L3.75 17.85V18.825H20.25V17.85L18.3089 15.9V10.5375C18.3089 7.56375 16.2222 5.02884 13.4559 4.39495V3.7125C13.4559 2.88366 12.8251 2.25 12 2.25ZM13.9411 19.8H10.0588C10.0588 20.8725 10.9322 21.75 12 21.75C13.0678 21.75 13.9411 20.8725 13.9411 19.8Z" fill="#454545"/>
                                </svg>
                            </div>
                        </NavLink>
                    </NavItem>}
                    {
                        <VoiceSetting 
                            loopSetting = { settings.notiSoundIsLoop } 
                            setNotiEpSoundLoop = { this.props.setNotiEpSoundLoop }
                        />
                    }
                    {employee.employee.first_name && <NavItem>
                        <NavLink
                            className="link mr-2 shadow-box"
                            style={{ color : 'black'}}
                            title={`${employee.employee.first_name} ${employee.employee.last_name}`}
                            onClick={openProfileModal}>
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M16.5 6.60001C16.5 4.20001 14.5 2.20001 12 2.20001C9.49999 2.20001 7.49999 4.20001 7.49999 6.60001C7.49999 9.00001 9.49999 11 12 11C14.5 11 16.5 9.00001 16.5 6.60001ZM9.49999 6.60001C9.49999 5.30001 10.6 4.20001 12 4.20001C13.4 4.20001 14.5 5.30001 14.5 6.60001C14.5 7.90001 13.4 9.00001 12 9.00001C10.6 9.00001 9.49999 7.90001 9.49999 6.60001ZM4.59999 16.3L4.09999 18.2C3.79999 19.1 3.99999 20.1 4.59999 20.9C5.19999 21.6 5.99999 22 6.99999 22H17C17.9 22 18.8 21.6 19.3 20.8C19.9 20 20.1 19 19.8 18.1L19.3 16.2C18.7 13.7 16.5 12 14 12H9.99999C7.49999 12 5.29999 13.7 4.59999 16.3ZM5.99999 18.7L6.49999 16.8C6.99999 15.1 8.39999 14 9.99999 14H14C15.6 14 17 15.1 17.5 16.8L18 18.7C18.1 19 18 19.4 17.8 19.7C17.7 19.8 17.4 20 17 20H6.99999C6.59999 20 6.39999 19.8 6.29999 19.6C5.99999 19.4 5.89999 19 5.99999 18.7Z" fill="#454545"/>
                            </svg>
                            {employee.employee.first_name}
                        </NavLink>
                    </NavItem>}
                    {!!employee.employee.id && <NavItem>
                        <NavLink
                            className="link shadow-box"
                            style={{ color : 'black'}}
                            onClick={employeeLogout}>
                           <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M21.9 10.6C21.8 10.5 21.8 10.4 21.7 10.3L19.7 8.3C19.3 7.9 18.7 7.9 18.3 8.3C17.9 8.7 17.9 9.3 18.3 9.7L18.6 10H16C15.4 10 15 10.4 15 11C15 11.6 15.4 12 16 12H18.6L18.3 12.3C17.9 12.7 17.9 13.3 18.3 13.7C18.5 13.9 18.8 14 19 14C19.2 14 19.5 13.9 19.7 13.7L21.7 11.7C21.8 11.6 21.9 11.5 21.9 11.4C22 11.1 22 10.9 21.9 10.6Z" fill="#454545"/>
                            <path d="M17 14C16.4 14 16 14.4 16 15V16C16 16.6 15.6 17 15 17H14V8.4C14 7.1 13.2 6 12.1 5.6L10.5 5H15C15.6 5 16 5.4 16 6V7C16 7.6 16.4 8 17 8C17.6 8 18 7.6 18 7V6C18 4.3 16.7 3 15 3H5C4.9 3 4.8 3 4.7 3.1C4.6 3.1 4.5 3.2 4.5 3.2L4.4 3.3C4.3 3.3 4.2 3.4 4.2 3.5V3.6C4.1 3.6 4 3.7 4 3.8V18C4 18.4 4.3 18.8 4.6 18.9L11.2 21.4C11.4 21.5 11.7 21.5 11.9 21.5C12.3 21.5 12.7 21.4 13 21.1C13.5 20.7 13.9 20.1 13.9 19.5V19H14.9C16.6 19 17.9 17.7 17.9 16V15C18 14.5 17.6 14 17 14ZM6 17.3V5.4L11.3 7.4C11.7 7.6 12 8 12 8.4V19.5L6 17.3Z" fill="#454545"/>
                            </svg>
                            {language.logout}
                        </NavLink>
                    </NavItem>}
                    <ProfileModal
                        printers={printerList}
                        seletedPrinter={printer.selected}
                        headers={printer.headers}
                        language={language}
                        onClose={closeProfileModal}
                        showPanel={selectProfilePanel}
                        focus={focus.profileModal}
                        daily={ daily}
                        settings={ settings}
                        {...modal.profile}
                    />
                    <ServicesModal />
                    <PrescriptionListModal />
                </Nav>
            </Navbar>
        )
    }
}

const mapStateToProps = state => {
    let errorMessages = Object.assign([], state.syncErrorMessage.carts)
    let isPlaying = state.settings.notiSoundIsPlay;
    let isLooping = state.settings.notiSoundIsLoop;

    return {
        errorMessages,
        employee: state.employee,
        language: state.language,
        settings: state.settings,
        notifications: state.notifications,
        prescriptionInboxCount: state.prescription.inboxCount,
        modal: state.modal,
        printer: state.printer,
        printerList: state.settings.printers,
        focus: state.focus,
        daily: state.daily,
        voice: state.voice,
        chat: state.chat,
        isPlaying,
        isLooping
    }
}

export default connect(mapStateToProps, {
    employeeLogout
    , statusOnline
    , statusOffline
    , setPrinterTableHeader
    , openProfileModal
    , closeProfileModal
    , getTableHeader
    , selectPrinter
    , selectProfilePanel
    , syncAgain
    , continueSyncError
    , deleteSyncError
    , newQRWindow
    , closeQRWindow
    , openPrescriptionListModal
    , sendSaleReceipts
    , toggleVoiceBusy
    , toggleChatBusy
    , openServiceDeliverySearchHistory
    , setNotiEpSoundLoop
    , setNotiEpSoundPlay
})(Header)
