import React from 'react';
import { connect } from 'react-redux';
import classnames from 'classnames';

import { getCurrentMessages, getCurrentUser } from 'Redux/reducers/chat';

import {
    popupChatWidget,
    minimizeChatWidget,
    sendMessage,
    setCurrentUser,
    removeUser,
    downloadChatHistory,
    downloadChatImage,
    dateTimeFormat,
    timeFormat,
    uniqBy,
    checkUserAsCustomer,
    checkCustomerBeforeRegister,
    updateCustomerFromUser,
    typing
} from 'Redux/actions';

import ImageContainer from './ImageContainer';
import ImageLightBox from './ImageLightBox';

import '../../../../style/chat-widget.css';

class ChatWidget extends React.Component {
    state = {
        text: '',
        lightBox: {
            open: false,
            src: null,
        },
        isCustomer: false,
    };

    _onChangeInput = (e) => {
        const value = e.target.value;
        this.setState({ text: value });
        this.props.typing();
    };

    _sendMessage = (e) => {
        if (e.keyCode === 13) {
            if (!e.shiftKey) {
                e.preventDefault();
                if (!this.state.text.trim() || this.props.chat.loading) return;
                const text = this.state.text;
                this.props.sendMessage(text);
                this.setState({ text: '' });
            }
        }
    };

    _onChangeSendImage = (e) => {
        e.preventDefault();
        const formData = new FormData();
        formData.append('file', e.target.files[0]);
        this.props.sendMessage(formData);
        e.target.value = null;
    };

    _endConsultation = (e, user) => {
        e.stopPropagation();
        this.props.removeUser(user.id, user.channelSid);
    };

    _scrollToButtom = () => {
        const { chat } = this.props;
        if (!chat.popup || !this.chatHistoryEl) return;
        const scrollHeight = this.chatHistoryEl.scrollHeight;
        const height = this.chatHistoryEl.clientHeight;
        const maxScrollTop = scrollHeight - height;
        this.chatHistoryEl.scrollTop = maxScrollTop > 0 ? maxScrollTop : 0;
    };

    _openImageLightBox = (src) => {
        if (!src) return;
        this.setState({ lightBox: { open: true, src } });
    };

    _closeImageLightBox = () => {
        this.setState({ lightBox: { open: false, src: null } });
    };

    _checkIsCustomer = async (userId) => {
        const isCustomer = await this.props.checkUserAsCustomer(userId);
        this.setState({ isCustomer });
    };

    _checkRegisterOrUpdateCustomer = () => {
        const { currentUser } = this.props;
        if (!this.state.isCustomer) {
            this.props.checkCustomerBeforeRegister(currentUser.phone, currentUser.id, 'voice');
        } else {
            this.props.updateCustomerFromUser(currentUser.id, null, 'chat');
        }
    };

    render() {
        const { chat, employee, currentMessages, currentUser } = this.props;
        const { popup, loading, currentUser: userId, users } = chat;

        const hasUser = users.length > 0;

        if (!popup) {
            const hasEmployee = !!employee.employee.id;

            if (!hasEmployee || !hasUser) return null;
            const hasUnReadMessage = users.some((user) => user.unRead);

            return (
                <div className="chat-widget-tray" onClick={this.props.popupChatWidget}>
                    {hasUnReadMessage ? (
                        <React.Fragment>
                            <i
                                className="fa fa-circle flash"
                                style={{
                                    fontSize: 10,
                                    color: '#31a24c',
                                    marginTop: 5,
                                    marginRight: 6,
                                }}
                            ></i>{' '}
                            มีข้อความใหม่
                        </React.Fragment>
                    ) : (
                        <React.Fragment>
                            <i
                                className="fa fa-inbox fa-lg"
                                style={{
                                    marginTop: 3,
                                    marginRight: 6,
                                }}
                            ></i>{' '}
                            กล่องข้อความ
                        </React.Fragment>
                    )}
                </div>
            );
        }
        return (
            <div className="chat-widget">
                <div className="chat-widget-header">
                    <div>
                        <h6 className="chat-widget_title">
                            {!userId ? (
                                ''
                            ) : (
                                <React.Fragment>
                                    {currentUser.name}{' '}
                                    <i
                                        className="fa fa-floppy-o chat-widget_header__icon"
                                        title="บันทึกประวัติการสนทนา"
                                        onClick={this.props.downloadChatHistory}
                                    ></i>{' '}
                                    <i
                                        className="fa fa-user-plus chat-widget_header__icon"
                                        title={this.state.isCustomer ? 'อัพเดทข้อมูลสมาชิก' : 'บันทึกคนไข้เป็นสมาชิก'}
                                        onClick={this._checkRegisterOrUpdateCustomer}
                                    ></i>
                                </React.Fragment>
                            )}
                        </h6>
                    </div>
                    <div className="chat-widget_close" onClick={this.props.minimizeChatWidget}>
                        <i className="fa fa-minus" title="ย่อหน้าต่าง"></i>
                    </div>
                </div>
                <div className="chat-widget-body">
                    <div className="chat-widget_users">
                        {hasUser ? (
                            <ul className="chat-widget_users__list">
                                {uniqBy(users, 'id').map((user) => (
                                    <li
                                        key={user.id}
                                        className={`chat-widget_users__item ${
                                            userId === user.id ? 'active' : ''
                                        }`}
                                        onClick={() => this.props.setCurrentUser(user.id)}
                                    >
                                        <div className="chat-widget_user">
                                            <div className="chat-widget_user__avatar">
                                                <img
                                                    src="https://ptetutorials.com/images/user-profile.png"
                                                    style={{ maxWidth: '100%' }}
                                                />
                                            </div>
                                            <div className="chat-widget_user__ib">
                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        justifyContent: 'space-between',
                                                    }}
                                                >
                                                    <div
                                                        style={{
                                                            maxWidth: '75%',
                                                            whiteSpace: 'nowrap',
                                                            overflow: 'hidden',
                                                            textOverflow: 'ellipsis',
                                                        }}
                                                    >
                                                        {user.unRead && (
                                                            <i
                                                                className="fa fa-circle"
                                                                style={{
                                                                    fontSize: 9,
                                                                    color: '#09f',
                                                                    marginRight: 4,
                                                                    position: 'relative',
                                                                    top: -1,
                                                                }}
                                                            ></i>
                                                        )}
                                                        {user.name ? user.name : 'ไม่ทราบชื่อ'}
                                                    </div>
                                                    <time className="text-muted">
                                                        {timeFormat(user.lastCreated)}
                                                    </time>
                                                </div>
                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        justifyContent: 'space-between',
                                                    }}
                                                >
                                                    <div
                                                        className="text-muted"
                                                        style={{
                                                            width: '90%',
                                                            whiteSpace: 'nowrap',
                                                            overflow: 'hidden',
                                                            textOverflow: 'ellipsis',
                                                        }}
                                                    >
                                                        {user.lastMessage}
                                                    </div>
                                                    <i
                                                        className="fa fa-times chat-widget_remove__icon text-danger"
                                                        style={{ display: 'none' }}
                                                        onClick={(e) =>
                                                            this._endConsultation(e, user)
                                                        }
                                                        title="จบการปรึกษา"
                                                    ></i>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                ))}
                            </ul>
                        ) : (
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    height: '100%',
                                }}
                            >
                                <h6>ไม่พบข้อความ</h6>
                            </div>
                        )}
                    </div>
                    <div className="chat-widget_messages">
                        <div
                            className="chat-widget_history"
                            style={{
                                height: currentUser.status === 'closed' ? '100%' : '',
                            }}
                            ref={(el) => (this.chatHistoryEl = el)}
                        >
                            {currentMessages.map((message) => {
                                switch (message.kind) {
                                    case 'message':
                                        if (!message.incoming) {
                                            return (
                                                <div
                                                    key={message.created}
                                                    className="chat-widget_outgoing__message"
                                                >
                                                    <div className="chat-widget_message">
                                                        <div
                                                            className={classnames(
                                                                'chat-widget_message__background',
                                                                {
                                                                    outgoing_message__background:
                                                                        message.type === 'text',
                                                                },
                                                                {
                                                                    'p-0 w-100':
                                                                        message.type === 'media',
                                                                }
                                                            )}
                                                        >
                                                            {message.type === 'media' ? (
                                                                <ImageContainer
                                                                    src={message.body}
                                                                    className="chat-widget_message__image"
                                                                    title="คลิกเพื่อดูรูปภาพ"
                                                                    onClick={() =>
                                                                        this._openImageLightBox(
                                                                            message.body
                                                                        )
                                                                    }
                                                                />
                                                            ) : (
                                                                message.body
                                                            )}
                                                        </div>
                                                        <time className="chat-widget_message__time">
                                                            {timeFormat(message.created)}
                                                        </time>
                                                    </div>
                                                </div>
                                            );
                                        }
                                        return (
                                            <div
                                                key={message.created}
                                                className="chat-widget_incoming__message"
                                            >
                                                <div className="chat-widget_message">
                                                    <div
                                                        className={classnames(
                                                            'chat-widget_message__background',
                                                            {
                                                                'p-0 w-100':
                                                                    message.type === 'media',
                                                            }
                                                        )}
                                                    >
                                                        {message.type === 'media' ? (
                                                            <ImageContainer
                                                                src={message.body}
                                                                className="chat-widget_message__image"
                                                                title="คลิกเพื่อดูรูปภาพ"
                                                                onClick={() =>
                                                                    this._openImageLightBox(
                                                                        message.body
                                                                    )
                                                                }
                                                            />
                                                        ) : (
                                                            message.body
                                                        )}
                                                    </div>
                                                    {message.type === 'media' ? (
                                                        <div
                                                            style={{
                                                                display: 'flex',
                                                                justifyContent: 'space-between',
                                                            }}
                                                        >
                                                            <span
                                                                className="chat-widget_download__image"
                                                                onClick={() =>
                                                                    downloadChatImage(
                                                                        message.body,
                                                                        message.sid
                                                                    )
                                                                }
                                                            >
                                                                บันทึก
                                                            </span>
                                                            <time className="chat-widget_message__time">
                                                                {timeFormat(message.created)}
                                                            </time>
                                                        </div>
                                                    ) : (
                                                        <time className="chat-widget_message__time">
                                                            {timeFormat(message.created)}
                                                        </time>
                                                    )}
                                                </div>
                                            </div>
                                        );
                                    case 'action':
                                        if (message.type === 'newSession') {
                                            return (
                                                <div
                                                    key={message.created}
                                                    className="chat-widget_divider__horizontal"
                                                >
                                                    เริ่มการสนทนาใหม่{' '}
                                                    {dateTimeFormat(message.created)}
                                                </div>
                                            );
                                        }
                                        if (message.type === 'endSession') {
                                            return (
                                                <React.Fragment key={message.created}>
                                                    <div className="chat-widget_divider__horizontal">
                                                        สิ้นสุดการสนทนา
                                                    </div>
                                                    <div className="chat-widget_outgoing__message">
                                                        <div className="chat-widget_message">
                                                            {this.state.isCustomer
                                                                ? 'ต้องการอัพเดทข้อมูลสมาชิก ไว้ในระบบหรือไม่?'
                                                                : 'ต้องการบันทึกข้อมูลคนไข้ ไว้ในระบบสมาชิกหรือไม่?'}
                                                            <button
                                                                type="button"
                                                                className="btn btn-link btn-sm chat-widget_history__button"
                                                                style={{ fontSize: 16 }}
                                                                onClick={this._checkRegisterOrUpdateCustomer}
                                                            >
                                                                ตกลง
                                                            </button>
                                                        </div>
                                                    </div>
                                                </React.Fragment>
                                            );
                                        }
                                }
                            })}
                        </div>
                        {currentUser.status === 'open' && (
                            <div className="chat-widget_input">
                                <textarea
                                    className="chat-widget_input__field"
                                    value={this.state.text}
                                    onChange={this._onChangeInput}
                                    onKeyDown={this._sendMessage}
                                    placeholder="ส่งข้อความ..."
                                ></textarea>
                                <input
                                    type="file"
                                    accept="image/*"
                                    style={{ display: 'none' }}
                                    onChange={this._onChangeSendImage}
                                    ref="sendImageInput"
                                    disabled={loading}
                                />
                                <i
                                    className={classnames(
                                        'fa chat-widget_upload__icon',
                                        loading ? 'fa-spinner fa-spin' : 'fa-file-image-o'
                                    )}
                                    title="ส่งรูปภาพ"
                                    onClick={() => this.refs.sendImageInput.click()}
                                ></i>
                            </div>
                        )}
                        <ImageLightBox {...this.state.lightBox} close={this._closeImageLightBox} />
                    </div>
                </div>
            </div>
        );
    }

    componentWillReceiveProps(nextProps) {
        const { currentUser, popup } = nextProps.chat;
        const newCurrentUser = currentUser !== this.props.chat.currentUser;
        const isCurrentUserClosed = currentUser && currentUser.status === 'closed';
        if (newCurrentUser || isCurrentUserClosed) this.setState({ text: '' });
        if (!popup) this.setState({ isCustomer: false });
        if (newCurrentUser) {
            this._checkIsCustomer(currentUser);
        }
    }

    componentDidUpdate(prevProps) {
        const { currentMessages, chat } = this.props;
        if (
            currentMessages.length > prevProps.currentMessages.length ||
            chat.popup !== prevProps.chat.popup ||
            chat.currentUser !== prevProps.chat.currentUser
        ) {
            this._scrollToButtom();
        }
    }
}

const mapStateToProps = (state) => {
    return {
        chat: state.chat,
        employee: state.employee,
        currentMessages: getCurrentMessages(state.chat),
        currentUser: getCurrentUser(state.chat),
    };
};

export default connect(mapStateToProps, {
    popupChatWidget,
    minimizeChatWidget,
    sendMessage,
    setCurrentUser,
    removeUser,
    downloadChatHistory,
    checkUserAsCustomer,
    checkCustomerBeforeRegister,
    updateCustomerFromUser,
    typing,
})(ChatWidget);
