import React from 'react';

import { Button} from 'reactstrap'

const ConfirmBtn = (props) => {

    const { className, txtCancel, txtConfirm, onCancel, onSubmit, size} = props;    
    return(
        <div className={className}>
            <Button onClick={onCancel} color="danger" autoFocus style={{width: size}}>{txtCancel}</Button>
            <Button onClick={onSubmit} color="success" style={{width: size}}>{txtConfirm}</Button>
        </div>
    )
}

export default ConfirmBtn;