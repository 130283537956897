import React from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import { Button, Col, CustomInput, Form, FormGroup, Input, Label, ListGroup, ListGroupItem, Modal, ModalBody, ModalFooter, ModalHeader, Row, Table } from 'reactstrap';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import { notify } from 'reapop';
import _ from 'lodash';

const API3_URL = process.env.API3_URL;

class CustomerEncounter extends React.Component {
    state = {
        encounters: null,
        encounter: null,
        originEncounter: null,
        referralModelOpen: false,
        referral: null,
        hospitals: null,
        loading: false,
    };

    static reasonTexts = {
        FURTHER_INVESTIGATION: 'Further Investigation',
        DRUG_RELATED_PROBLEMS: 'Drug Related Problems',
        LOSS_FOLLOW_UP: 'Loss of Follow-up',
    };

    componentWillMount() {
        this.handleCreateNewEncounter();
    }

    componentWillReceiveProps(nextProps) {
        const { active, customer } = nextProps;

        // Reload encounter when tab is active and customer has defined
        if (active && customer.id) {
            this.getEncounters();
            this.getHospitals();
        }
    }

    getNewEncounter() {
        const { customer } = this.props;

        return {
            id: null,
            customer_id: customer.id,
            reference_code: '-',
            cc: '',
            symptoms: '',
            recommendation: '',
            status: 'CREATED',
            created_at: moment().startOf('minute').format('YYYY-MM-DD HH:mm:ss'),
            referral: null,
        };
    }

    getNewReferral() {
        const { customer } = this.props;
        const { hospitals } = this.state;

        return {
            id: null,
            hospital_ids: hospitals.map(hospital => hospital.id),
            first_name: customer.first_name,
            last_name: customer.last_name,
            mobile_number: customer.mobile_number || customer.phone_number,
            citizen_id: customer.citizen_id,
            email: customer.email,
            reason: [],
            note: '',
            show_purchase_history: false,
        };
    }

    getEncounters() {
        const { settings, customer } = this.props;

        axios({
            url: `${API3_URL}/companies/${settings.company.slug}/encounters`,
            headers: { 'Authorization': `Bearer ${settings.token}` },
            params: { customer_id: customer.id },
        }).then((response) => {
            this.setState({
                encounters: response.data.data,
            });
        });
    }

    getHospitals() {
        const { hospitals } = this.state;

        if (hospitals) {
            return;
        }

        axios({
            url: `${API3_URL}/hospitals`,
        }).then((response) => {
            this.setState({
                hospitals: response.data.data,
            });
        });
    }

    isListGroupItemActive(encounter) {
        const { encounter: currentEncounter } = this.state;
        return Boolean(currentEncounter && currentEncounter.id === encounter.id);
    }

    relateReceiptCount() {
        const { encounter } = this.state;

        if (!encounter || !encounter.relateReceipts) {
            return 0;
        }

        let count = 0;

        encounter.relateReceipts.forEach(receipt => receipt.details.forEach(() => count++));

        return count;
    }

    handleCreateNewEncounter = () => {
        this.setState({
            encounter: this.getNewEncounter(),
            originEncounter: this.getNewEncounter(),
        });
    };

    handleSelectEncounter = (encounter) => () => {
        const { settings } = this.props;

        this.setState({
            encounter,
            originEncounter: JSON.parse(JSON.stringify(encounter)),
        });

        axios({
            url: `${API3_URL}/companies/${settings.company.slug}/encounters/${encounter.id}`,
            headers: { 'Authorization': `Bearer ${settings.token}` },
        }).then((response) => {
            this.setState({
                encounter: response.data.data,
            });
        });
    };

    handleReset = () => {
        const { originEncounter } = this.state;
        this.setState({
            encounter: JSON.parse(JSON.stringify(originEncounter)),
        });
    };

    handleChange = (key) => (event) => {
        const { encounter } = this.state;

        let value;

        switch (key) {
            case 'created_at':
                value = event.startOf('minute').format('YYYY-MM-DD HH:mm:ss');
                break;
            default:
                value = event.target.value;
                break;
        }

        this.setState({
            encounter: {
                ...encounter,
                [key]: value,
            },
        });
    };

    onDatePickerBlur = (event) => {
        const { encounter } = this.state;

        const temp = encounter.created_at;

        this.setState({
            encounter: {
                ...encounter,
                created_at: '',
            },
        });

        const date = moment(event.target.value);

        setTimeout(() => {
            this.setState({
                encounter: {
                    ...encounter,
                    created_at: date.isValid() ? date.startOf('minute').format('YYYY-MM-DD HH:mm:ss') : temp,
                },
            });
        });

    };

    handleCreateReferral = () => {
        this.setState({
            referralModelOpen: true,
            referral: this.getNewReferral(),
        });
    };

    handleEditReferral = () => {
        const { encounter } = this.state;
        const { referral } = encounter;

        this.setState({
            referralModelOpen: true,
            referral: {
                ...JSON.parse(JSON.stringify(referral)),
                hospital_ids: referral.hospitals.map(hospital => hospital.id),
            },
        });
    };

    handleReferralModalOpen = (open) => () => {
        this.setState({
            referralModelOpen: open,
        });
    };

    handleChangeReferral = (key) => (event) => {
        const { referral } = this.state;

        const value = event.target.type === 'checkbox'
            ? event.target.checked
            : event.target.value;


        this.setState({
            referral: {
                ...referral,
                [key]: value,
            },
        });
    };

    handleChangeReferralHospital = (event) => {
        const { referral } = this.state;
        const { hospital_ids } = referral;

        const value = +event.target.value;

        if (event.target.checked) {
            hospital_ids.push(value);
        } else {
            const index = hospital_ids.indexOf(value);
            hospital_ids.splice(index, 1);
        }

        this.setState({
            referral: {
                ...referral,
                hospital_ids,
            },
        });
    };

    handleChangeReferralReason = (event) => {
        const { referral } = this.state;
        const { reason } = referral;

        if (event.target.checked) {
            reason.push(event.target.value);
        } else {
            const index = reason.indexOf(event.target.value);
            reason.splice(index, 1);
        }

        this.setState({
            referral: {
                ...referral,
                reason,
            },
        });
    };

    handleSaveReferral = () => {
        const { dispatch } = this.props;
        const { encounter, referral, hospitals } = this.state;

        if (!referral.hospital_ids.length) {
            dispatch(notify({
                id: 'encounterValidation',
                message: `กรุณาเลือกโรงพยาบาล`,
                status: 'warning',
                dismissible: true,
                dismissAfter: 3000,
            }));
            return;
        }

        if (!referral.first_name
            || !referral.last_name
            || !referral.mobile_number
            || !referral.citizen_id
        ) {
            dispatch(notify({
                id: 'encounterValidation',
                message: `กรุณากรอกข้อมูลให้ครบถ้วน`,
                status: 'warning',
                dismissible: true,
                dismissAfter: 3000,
            }));
            return;
        }

        referral.hospitals = referral.hospital_ids.map(id => _.find(hospitals, { id }));

        this.setState({
            referralModelOpen: false,
            encounter: {
                ...encounter,
                referral: JSON.parse(JSON.stringify(referral)),
            },
        });
    };

    handleSave = () => {
        const { dispatch, settings } = this.props;
        const { encounter } = this.state;

        const data = JSON.parse(JSON.stringify(encounter));

        if (!data.cc || !data.symptoms || !data.recommendation) {
            dispatch(notify({
                id: 'encounterValidation',
                message: `กรุณากรอกข้อมูลให้ครบถ้วน`,
                status: 'warning',
                dismissible: true,
                dismissAfter: 3000,
            }));
            return;
        }

        if (data.referral) {
            data.referral.hospital_ids = data.referral.hospitals.map(hospital => hospital.id);
            delete data.referral.hospitals;
        }

        let option = {
            url: `${API3_URL}/companies/${settings.company.slug}/encounters`,
            headers: { 'Authorization': `Bearer ${settings.token}` },
            data,
        };

        if (encounter.id) {
            option.url += `/${encounter.id}`;
            option.method = 'put';
        } else {
            option.method = 'post';
        }

        this.setState({
            loading: true,
        });

        axios(option).then((response) => {
            dispatch(notify({
                id: 'encounterSave',
                title: 'สำเร็จ',
                message: `บันทึกข้อมูลเรียบร้อยแล้ว`,
                status: 'success',
                dismissible: true,
                dismissAfter: 3000,
            }));
            this.handleSelectEncounter(response.data.data)();
            this.getEncounters();
        }).catch((err) => {
            console.error(err);
            dispatch(notify({
                id: 'encounterSave',
                title: 'สำเร็จ',
                message: `บันทึกไม่สำเร็จ`,
                status: 'error',
                dismissible: true,
                dismissAfter: 3000,
            }));
        }).finally(() => {
            this.setState({
                loading: false,
            });
        });

    };

    render() {
        const { language, settings } = this.props;

        const {
            loading,
            encounters,
            encounter,
            referralModelOpen,
            referral,
            hospitals,
        } = this.state;

        return (
            <>
                <Row>
                    <Col md={3}>
                        <Button color="info" block onClick={this.handleCreateNewEncounter}>New Encounter</Button>

                        <ListGroup style={{
                            marginTop: 20,
                            maxHeight: 484,
                            overflow: 'auto',
                        }}>
                            {Boolean(encounters) && encounters.map((item) => (
                                <ListGroupItem
                                    key={item.id}
                                    active={this.isListGroupItemActive(item)}
                                    tag="button"
                                    action
                                    onClick={this.handleSelectEncounter(item)}
                                >
                                    {item.created_at}
                                    <br />
                                    cc: {item.cc}
                                </ListGroupItem>
                            ))}
                            {Boolean(encounters) && encounters.length === 0 && (
                                <ListGroupItem disabled action className="text-center">No data.</ListGroupItem>
                            )}
                            {!encounters && (
                                <ListGroupItem disabled action className="text-center">Loading...</ListGroupItem>
                            )}
                        </ListGroup>
                    </Col>

                    <Col md={8}>
                        {Boolean(encounter) && (
                            <Form style={{ marginTop: 10 }}>
                                <FormGroup row>
                                    <Col md={3}>
                                        <strong>Encounter Code:</strong>
                                    </Col>
                                    <Col md={9}>
                                        {encounter.reference_code}
                                    </Col>
                                </FormGroup>

                                <FormGroup row>
                                    <Label for="date" md={3}>
                                        <strong>Date & Time:</strong>
                                    </Label>
                                    <Col md={9}>
                                        <DatePicker
                                            className="form-control"
                                            id="date"
                                            selected={moment(encounter.created_at)}
                                            onChange={this.handleChange('created_at')}
                                            onBlur={this.onDatePickerBlur}
                                            dateFormat='YYYY-MM-DD HH:mm:ss'
                                            disabledKeyboardNavigation
                                            showTimeSelect
                                            timeFormat="HH:mm"
                                            timeIntervals={10}
                                            maxDate={moment()}
                                        />
                                    </Col>
                                </FormGroup>

                                <FormGroup row>
                                    <Label for="cc" md={3}>
                                        <strong>CC:</strong>
                                    </Label>
                                    <Col md={9}>
                                        <Input
                                            name="cc"
                                            id="cc"
                                            placeholder="หัวข้อ"
                                            value={encounter.cc}
                                            onChange={this.handleChange('cc')}
                                        />
                                    </Col>
                                </FormGroup>

                                <Row>
                                    <Col md={6}>
                                        <FormGroup>
                                            <Label for="symptoms">
                                                <strong>Symptoms:</strong>
                                            </Label>
                                            <Input
                                                type="textarea"
                                                name="symptoms"
                                                id="symptoms"
                                                placeholder="อาการป่วย"
                                                value={encounter.symptoms}
                                                onChange={this.handleChange('symptoms')}
                                            />
                                        </FormGroup>

                                        <FormGroup>
                                            <Label for="recommendation">
                                                <strong>Recommendation:</strong>
                                            </Label>
                                            <Input
                                                type="textarea"
                                                name="recommendation"
                                                id="recommendation"
                                                placeholder="คำแนะนำจากเภสัชกร"
                                                value={encounter.recommendation}
                                                onChange={this.handleChange('recommendation')}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col md={6}>
                                        <Label>
                                            <strong>Prescribed {this.relateReceiptCount()} items:</strong>
                                        </Label>

                                        <div style={{ overflowY: 'auto', height: 180 }}>
                                            <Table>
                                                <thead>
                                                    <tr>
                                                        <th>{language.reference_code}</th>
                                                        <th>{language.name}</th>
                                                        <th className="text-right">{language.quantity}</th>
                                                        <th className="text-center">{language.unit}</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {Boolean(encounter.relateReceipts && encounter.relateReceipts.length)
                                                        ? encounter.relateReceipts.map(receipt => receipt.details.map(detail => (
                                                            <tr key={detail.id}>
                                                                <th>{detail.product.reference_code}</th>
                                                                <td>{detail.product.name}</td>
                                                                <td className="text-right">{detail.sales_quantity}</td>
                                                                <td className="text-center">{detail.unit.name}</td>
                                                            </tr>
                                                        )))
                                                        : (
                                                            <tr>
                                                                <th className="text-center" colSpan={4}>-</th>
                                                            </tr>
                                                        )}

                                                </tbody>
                                            </Table>
                                        </div>
                                    </Col>
                                </Row>

                                <FormGroup>
                                    {Boolean(encounter.referral || settings.company.enableReferral) && (
                                        <Label style={{ marginRight: 10 }}>
                                            <strong>e-Referral:</strong>
                                        </Label>
                                    )}

                                    {encounter.referral
                                        ? (
                                            <div style={{ display: 'inline-block' }}>
                                                {encounter.referral.id
                                                    ? (
                                                        <Button
                                                            color="success"
                                                            size="sm"
                                                            onClick={this.handleEditReferral}
                                                        >
                                                            SENT
                                                        </Button>
                                                    )
                                                    : (
                                                        <Button
                                                            color="warning"
                                                            size="sm"
                                                            onClick={this.handleEditReferral}
                                                        >
                                                            WAITING
                                                        </Button>
                                                    )
                                                }
                                                {' '}
                                                <span style={{ margin: '0 10px' }}>
                                                    {moment(encounter.referral.created_at).format('YYYY-MM-DD')}
                                                </span>
                                                {' '}
                                                {encounter.referral.hospitals.map(hospital => hospital.name).join(', ')}
                                                {' | '}
                                                {encounter.referral.reason.map(reason => this.constructor.reasonTexts[reason]).join(', ')}
                                            </div>
                                        )
                                        : (
                                            settings.company.enableReferral && (
                                                <Button
                                                    color="info"
                                                    size="sm"
                                                    onClick={this.handleCreateReferral}
                                                    disabled={!settings.company.enableReferral}
                                                >
                                                    New Referral
                                                </Button>
                                            )
                                        )
                                    }
                                </FormGroup>


                                <FormGroup className="text-center">
                                    <Button
                                        color="success"
                                        type="button"
                                        onClick={this.handleSave}
                                        disabled={loading}
                                    >
                                        {loading ? language.loading + '...' : language.save}
                                    </Button>
                                    <Button
                                        color="danger"
                                        type="button"
                                        onClick={this.handleReset}
                                        style={{ marginLeft: 10 }}
                                    >
                                        {language.reset}
                                    </Button>
                                </FormGroup>
                            </Form>
                        )}
                    </Col>
                </Row>

                <Modal isOpen={referralModelOpen} size="lg">
                    <ModalHeader>Create New Referral</ModalHeader>
                    <ModalBody>
                        {Boolean(referral) && (
                            <>
                                <FormGroup>
                                    <Label>
                                        <strong>Select Hospital:</strong>
                                    </Label>

                                    <div>
                                        {Boolean(hospitals) && hospitals.map((hospital) => (
                                            <label className="text-center" key={hospital.id} style={{ marginLeft: 15 }}>
                                                <div
                                                    className="profile-picture"
                                                    style={{
                                                        backgroundImage: hospital.image ? 'url(' + hospital.image.normal.url + ')' : 'none',
                                                    }}
                                                />
                                                <CustomInput
                                                    type="checkbox"
                                                    id={'hospital-' + hospital.id}
                                                    value={hospital.id}
                                                    checked={referral.hospital_ids.indexOf(hospital.id) !== -1}
                                                    label={hospital.name}
                                                    onChange={this.handleChangeReferralHospital}
                                                    disabled={referral && referral.id}
                                                />
                                            </label>
                                        ))}
                                    </div>
                                </FormGroup>

                                <Label>
                                    <strong>Required Patient Info:</strong>
                                </Label>
                                <Row>
                                    <Col md={6}>
                                        <FormGroup row>
                                            <Label for="first_name" md={4}>
                                                First Name:
                                            </Label>
                                            <Col md={8}>
                                                <Input
                                                    name="first_name"
                                                    id="first_name"
                                                    placeholder="ชื่อ"
                                                    value={referral.first_name}
                                                    onChange={this.handleChangeReferral('first_name')}
                                                    disabled={referral && referral.id}
                                                />
                                            </Col>
                                        </FormGroup>
                                    </Col>
                                    <Col md={6}>
                                        <FormGroup row>
                                            <Label for="last_name" md={4}>
                                                Last Name:
                                            </Label>
                                            <Col md={8}>
                                                <Input
                                                    name="last_name"
                                                    id="last_name"
                                                    placeholder="นามสกุล"
                                                    value={referral.last_name}
                                                    onChange={this.handleChangeReferral('last_name')}
                                                    disabled={referral && referral.id}
                                                />
                                            </Col>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={6}>
                                        <FormGroup row>
                                            <Label for="mobile_number" md={4}>
                                                Mobile Phone:
                                            </Label>
                                            <Col md={8}>
                                                <Input
                                                    name="mobile_number"
                                                    id="mobile_number"
                                                    placeholder="หมายเลขโทรศัพท์"
                                                    value={referral.mobile_number}
                                                    onChange={this.handleChangeReferral('mobile_number')}
                                                    disabled={referral && referral.id}
                                                />
                                            </Col>
                                        </FormGroup>
                                    </Col>
                                    <Col md={6}>
                                        <FormGroup row>
                                            <Label for="citizen_id" md={4}>
                                                ID / Passport:
                                            </Label>
                                            <Col md={8}>
                                                <Input
                                                    name="citizen_id"
                                                    id="citizen_id"
                                                    placeholder="รหัสประจำตัวประขาชน"
                                                    value={referral.citizen_id}
                                                    onChange={this.handleChangeReferral('citizen_id')}
                                                    disabled={referral && referral.id}
                                                />
                                            </Col>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={6} />
                                    <Col md={6}>
                                        <FormGroup row>
                                            <Label for="citizen_id" md={4}>
                                                Email
                                            </Label>
                                            <Col md={8}>
                                                <Input
                                                    name="email"
                                                    id="email"
                                                    placeholder="Email"
                                                    value={referral.email}
                                                    onChange={this.handleChangeReferral('email')}
                                                    disabled={referral && referral.id}
                                                />
                                            </Col>
                                        </FormGroup>
                                    </Col>
                                </Row>

                                <FormGroup>
                                    <Label>
                                        <strong>Reason For Referral:</strong>
                                    </Label>
                                    <div>
                                        {Object.entries(this.constructor.reasonTexts).map((item) => (
                                            <CustomInput
                                                className="custom-control-inline"
                                                type="checkbox"
                                                key={item[0]}
                                                id={item[0]}
                                                value={item[0]}
                                                label={item[1]}
                                                checked={referral.reason.indexOf(item[0]) !== -1}
                                                onChange={this.handleChangeReferralReason}
                                                disabled={referral && referral.id}
                                            />
                                        ))}
                                    </div>
                                </FormGroup>

                                <FormGroup>
                                    <Label for="note">
                                        <strong>Note for Physician:</strong>
                                    </Label>
                                    <Input
                                        type="textarea"
                                        name="note"
                                        id="note"
                                        placeholder="หมายเหตุสำหรับแพทย์"
                                        value={referral.note}
                                        onChange={this.handleChangeReferral('note')}
                                        disabled={referral && referral.id}
                                    />
                                </FormGroup>

                                <FormGroup>
                                    <CustomInput
                                        className="custom-control-inline"
                                        type="checkbox"
                                        id="show_purchase_history"
                                        label="แนบประวัติการใช้ยา 3 เดือนล่าสุดของคนไข้ไปให้โรงพยาบาลพร้อมกับ Referral"
                                        checked={referral.show_purchase_history}
                                        onChange={this.handleChangeReferral('show_purchase_history')}
                                        disabled={referral && referral.id}
                                    />
                                </FormGroup>
                            </>
                        )}
                    </ModalBody>
                    <ModalFooter>
                        {Boolean(referral && !referral.id) && (
                            <Button
                                color="success"
                                type="button"
                                onClick={this.handleSaveReferral}
                            >
                                {language.save}
                            </Button>
                        )}
                        <Button
                            color="danger"
                            type="button"
                            onClick={this.handleReferralModalOpen(false)}
                            style={{ marginLeft: 10 }}
                        >
                            {language.cancel}
                        </Button>
                    </ModalFooter>
                </Modal>
            </>
        );
    }
}

const mapStateToProps = ({ customerInfo, settings, language }) => ({ customer: customerInfo.customer, settings, language });

export default connect(mapStateToProps)(CustomerEncounter);
