import React from 'react'
import { Button, Modal, ModalHeader, ModalBody, Table } from 'reactstrap'
import { priceFormat } from 'Utilz/currency';

class AwaitingCartModal extends React.Component {
    render() {
        const { openAwaitingCart, closeAwaitingCart, showAwaitingCart, language } = this.props

        const awaitingCartList = this.props.awaitingCart.map((cart,index) => {
            const productsList = cart.details.map((item, index) => <li key={index}>{item.product.name}</li>)
            return(
                <tr key={cart.reference_code}>
                    <td>{index + 1}</td>
                    <td>{cart.reference_code}</td>
                    <td>{cart.employee.first_name}</td>
                    <td>
                        <ul className="list-unstyled mb-0">{productsList}</ul>
                    </td>
                    <td>{priceFormat(cart.net_amount)}</td>
                    <td className="text-center">
                        <Button
                            color="success"
                            size="sm"
                            onClick={() => this.props.continueCart(cart.reference_code)}>
                            {language.continue}
                        </Button>{' '}
                        <Button
                            color="danger"
                            size="sm"
                            onClick={() => this.props.deleteAwaitingCart(cart.reference_code)}>
                            {language.delete}
                        </Button>
                    </td>
                </tr>
            ) 
        })

        return (
            <React.Fragment>
                <Button color="primary" className="mb-2" onClick={openAwaitingCart}><i className="fa fa-list-alt"></i> {language.have_awaiting} {awaitingCartList.length}</Button>
                <Modal
                    isOpen={showAwaitingCart}
                    toggle={closeAwaitingCart}
                    size="lg"
                    style={{ maxWidth: '70%' }}>
                    <ModalHeader toggle={closeAwaitingCart}>{language.awaiting_list}</ModalHeader>
                    <ModalBody>
                        <Table bordered hover>
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>{language.reference_number}</th>
                                    <th>{language.seller_name}</th>
                                    <th>{language.product_list}</th>
                                    <th>{language.product_price}</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {awaitingCartList}
                            </tbody>
                        </Table>
                    </ModalBody>
                </Modal>
            </React.Fragment>
        )
    }
}

export default AwaitingCartModal