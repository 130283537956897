export const REQUEST_SETTINGS = 'REQUEST_SETTINGS'
export const RECEIVE_SETTINGS = 'RECEIVE_SETTINGS'
export const EMPTY_SETTINGS = 'EMPTY_SETTINGS'
export const SWITCH_LANGUAGE = ' SWITCH_LANGUAGE'
export const SET_STATUS_ONLINE = 'SET_STATUS_ONLINE'
export const OPEN_PROFILE_MODAL = 'OPEN_PROFILE_MODAL';
export const CLOSE_PROFILE_MODAL = 'CLOSE_PROFILE_MODAL';
export const OPEN_NOTI_REFRESH = 'OPEN_NOTI_REFRESH';
export const CLOSE_NOTI_REFRESH = 'CLOSE_NOTI_REFRESH';
export const NOTI_SYNC_DATA = 'NOTI_SYNC_DATA'
export const CLOSE_NOTI_SYNC_DATA = 'CLOSE_NOTI_SYNC_DATA'
export const SHOW_ADS_POPUP = 'SHOW_ADS_POPUP'
export const CLOSE_ADS_POPUP = 'CLOSE_ADS_POPUP'
export const SETTING_QUICK_POS = 'SETTING_QUICK_POS';
export const SET_PRINTERS = 'SET_PRINTERS';
export const SHOW_NOTI_WRONG_TIME = 'SHOW_NOTI_WRONG_TIME';
export const SHOW_NOTI_NEW_VERSION = 'SHOW_NOTI_NEW_VERSION';
export const SETTING_NOTI_SOUND_LOOP = 'SETTING_NOTI_SOUND_LOOP';
export const SETTING_NOTI_SOUND_PLAY = 'SETTING_NOTI_SOUND_PLAY';