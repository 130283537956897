import React from 'react'
import { Modal, ModalHeader, ModalBody, Button, ModalFooter } from 'reactstrap'
import FoodPanDaImage from 'Images/food_panda.png'
class FoodPanDaModal extends React.Component {
    constructor(props) {
        super(props);
        this.handleModal = this.handleModal.bind(this);
        this.state = {
            modal: true,
        }
    }

    handleModal() {
        this.setState({
            modal: !this.state.modal,
        });
    }

    render() {
        return (
            <>
                <link href="https://fonts.googleapis.com/css?family=Kanit" rel="stylesheet"/>
                <div>
                    <Modal isOpen={ this.state.modal }>
                        <ModalHeader className='close-platform-header modal-header-close-platform'>
                            <span>ประกาศ ยุติการให้บริการ Delivery Service กับ Foodpanda</span>
                        </ModalHeader>
                        <ModalBody className="px-5">
                            <div className='img-body-close-platform'>
                                <img width={200} src={ FoodPanDaImage } />
                            </div>
                            <div className='modal-body-close-platform-0-orange'>ร้านยาสามารถใช้บริการ Delivery Service กับ Foodpanda ได้ถึง วันที่ 31 สิงหาคม 2566 เวลา 23.59 น.</div>
                            <br/>
                            <div className='modal-body-close-platform-1'>Arincare ต้องขออภัยในความไม่สะดวกที่เกิดขึ้น</div>
                            <div className='modal-body-close-platform-1'>และหวังเป็นอย่างยิ่งว่าจะได้ส่งมอบบริการอื่นๆ</div>
                            <div className='modal-body-close-platform-1'>ให้แก่ท่านในโอกาสต่อไป</div>
                            <br/>
                            <div className='modal-body-close-platform-1'>หากมีคำถามหรือข้อสงสัยเพิ่มเติม</div>
                            <div className='modal-body-close-platform-1'> ติดต่อทาง Line ID : @arincare</div>
                        </ModalBody>
                        <ModalFooter>
                            <Button color="primary" onClick={ this.handleModal } >ปิด</Button>
                        </ModalFooter>
                    </Modal>
                </div>
            </>
        );
    }
}

export default FoodPanDaModal