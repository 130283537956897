import { SET_STATUS_ONLINE
    , INITIAL_CART_DETAIL
    , OPEN_NOTI_REFRESH
    , CLOSE_NOTI_REFRESH
    , ADD_RETAIL_UNIT
    , SET_ALL_UNITS
    , CLOSE_NOTI_SYNC_DATA
    , SHOW_ADS_POPUP
    , CLOSE_ADS_POPUP
    , ADD_TO_CART
    , SHOW_NOTI_NEW_VERSION
} from 'Redux/actionTypes';

import axios from 'axios'
import moment from 'moment'
import { notify, removeNotification } from 'reapop'
import { setOfflineMode } from 'Redux/actions'

import {getAllUnits} from 'Business';

const API3_URL = process.env.API3_URL
const APP_URL = process.env.APP_URL
const WIKI_URL = process.env.WIKI_URL

export const statusOnline = () => (dispatch, getState) => {
    const notifications = getState().notifications
    
    dispatch({ type: SET_STATUS_ONLINE, payload: true })

    notifications.find(notification => notification.id === 'statusOffline') &&
    dispatch(removeNotification('statusOffline'))
}

export const statusOffline = () => (dispatch, getState) => {
    const { offline_mode } = getState().settings
    const { employee } = getState().employee

    dispatch({ type: SET_STATUS_ONLINE, payload: false })

    if (!offline_mode && employee.id) {
        dispatch(notify({
            id: 'statusOffline',
            title: 'ออฟไลน์',
            message: 'คุณขาดการเชื่อมต่อกับอินเทอร์เน็ต กรุณาเชื่อมต่อใหม่อีกครั้ง หรือเปิดใช้งานโหมดออฟไลน์',
            status: 'error',
            dismissible: false,
            dismissAfter: 0,
            position: 't',
            buttons: [
                {
                    name: 'เปิดโหมดออฟไลน์',
                    primary: true,
                    onClick: () => setOfflineMode(true)
                }
            ]
        }))
    }
}

export const notiUnitProduct = (product) => async (dispatch, getState) => {
    dispatch({type: INITIAL_CART_DETAIL});
    dispatch({type: ADD_RETAIL_UNIT, payload: product});

    const {settings: {company: {slug: company_slug}, token}} = getState();
    const units = await getAllUnits(product.id, company_slug, token);
    
    return dispatch({type: SET_ALL_UNITS, payload: units.result});
}

export const openNotiRefreshModal = (where = null) => (dispatch, getState) => {
    
    const {language, modal:{ notiRefresh: notiRefreshShow}, cart:{ reference_code: sr_ref_code}, employee:{ employee:{ id: emp_id}}} = getState();
    
    if(!notiRefreshShow && sr_ref_code === null && emp_id !== 0){

        return dispatch({
            type: OPEN_NOTI_REFRESH,
            payload: {
                type: 'confirm'
            }
        });
    }

    if(where === ADD_TO_CART) {
        return dispatch({
            type: OPEN_NOTI_REFRESH,
            payload: {
                type: 'alert',
                code: '#25'
            }
        });
    }

}

export const closeNotiRefreshModal = () => dispatch => {
    return dispatch({type: CLOSE_NOTI_REFRESH});
}

export const submitNotiRefreshModal = () => dispatch => {
    location.reload();

    return dispatch(closeNotiRefreshModal());
}

export const closeNotiSyncData = () => dispatch => {
    return dispatch({ type: CLOSE_NOTI_SYNC_DATA })
}

export const getAds = () => (dispatch, getState) => {
    const { company, branch, offline_mode } = getState().settings
    const { employee } = getState().employee
    const { adsPopup } = getState().modal
    const { reference_code } = getState().cart
    
    if (!adsPopup.showModal && employee.id && !reference_code && !offline_mode) {
        axios({
            url: `${WIKI_URL}/api/ads`,
            params: {
                ref: 'pos-idle-popup',
                company: company.id,
                branch: branch.id,
                employee: employee.id,
            },
            withCredentials: true,
        }).then(res => {
            const data = res.data.data

            if (data.length) {
                dispatch({ type: SHOW_ADS_POPUP, payload: data }) 
            }
        }).catch(function (error) {
            console.log('api/ads');
            console.log(error);
            console.log('----------');

        });
    }
}

export const closeAds = () => dispatch => {
    return dispatch({ type: CLOSE_ADS_POPUP })
}

export const showNotiNewVersionModal = () => dispatch => {
    return dispatch({ type: SHOW_NOTI_NEW_VERSION });
}

export const getNotifyMarketplaceCartExpire = () => (dispatch, getState) => {
    const currentDate = moment().format('YYYY-MM-DD')

    if (localStorage.getItem('MARKETPLACE_CART_EXPIRE_NOTIFY') !== currentDate) {
        const { company, token } = getState().settings

        axios(`${API3_URL}/companies/${company.slug}/arinlink/orders/notify-expire`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        }).then(res => {
            localStorage.setItem('MARKETPLACE_CART_EXPIRE_NOTIFY', currentDate)
            if (res.data.have_least_one) {
                dispatch(notify({
                    id: 'marketplaceCartExpire',
                    title: 'แจ้งเตือน',
                    message: `คุณมีตะกร้าสินค้าใน Arincare Marketplace ที่ใกล้หมดอายุ <a href="${APP_URL}/companies/arinlink/order?intent=view_pending_payment_order" target="_blank">คลิกที่นี่เพื่อชำระเงิน</a>`,
                    allowHTML: true,
                    status: 'warning',
                    dismissible: true,
                    dismissAfter: 0,
                    closeButton: true,
                }))
            }
        })
    }
}
