import { 
    SET_DAILY_SUMMARY_REPORT
 } from 'Redux/actionTypes'

const initialState = {
    date: null,
    cash:{ transactions: 0, total_amount: 0},
    credit:{ transactions: 0, total_amount: 0},
    transfer:{ transactions: 0, total_amount: 0},
    thai_qr:{ transactions: 0, total_amount: 0},
    alipay:{ transactions: 0, total_amount: 0},
    wechat_pay:{ transactions: 0, total_amount: 0},
    line_pay:{ transactions: 0, total_amount: 0},
    total:{ transactions: 0, total_amount: 0},
}

export default (state=initialState, { type, payload }) => {
    switch (type) {
        case SET_DAILY_SUMMARY_REPORT: 
            return Object.assign({},state, payload);
        default:
            return state
    }
}