import { ADD_TO_CART
    , REMOVE_TO_CART
    , INITIAL_CART_DETAIL
    , EDIT_CART_DETAIL
    , EDIT_TO_CART
    , SET_CART_VALUE
    , EDIT_PRODUCT_SET
    , CLOSE_ADD_TO_CART
    , ERROR_ADD_TO_CART
    , FOCUS_CART
    , UP_INDEX_CART
    , DOWN_INDEX_CART
    , ON_BLUR_CART
    , EDIT_TO_CART_AND_PLUS
    , FEE_PAYMENT_CHANGE
    , CHANGE_UNIT_IN_CART
    , SET_BODC12_DATA
    , CLEAR_BODC12_DATA
} from 'Redux/actionTypes'

import { AddMessageLogToCart } from 'Redux/actions'
import { addNotification } from 'reapop';
import { number } from 'Utilz/currency';

export const checkCertRemainingQuantity = (detail, cart) => {
    const { edit_detail } = cart;
    const { product } = detail;

    const remaining_quantity = number(product.remaining_quantity).value;
    const in_cart_quantity = cart.details
        .filter((d) =>
                (
                    detail.product_id &&
                    d.product_id === detail.product_id &&
                    d.unit_id !== edit_detail.unit_id
                ) || (
                    detail.product_set_id &&
                    d.product_set_id === detail.product_set_id &&
                    d.product_set_id !== edit_detail.product_set_id &&
                    d.product_set_type === "1"
                )
        )
        .reduce((total , detail) => total + number(detail.sales_quantity).multiply(detail.unit.pivot.quantity_of_smallest_unit).value, 0);
    const sales_quantity = number(detail.sales_quantity).multiply(detail.unit.pivot.quantity_of_smallest_unit).value;
    const total_quantity = in_cart_quantity + sales_quantity;

    /*
    console.log('remaining_quantity', remaining_quantity);
    console.log('sales_quantity', sales_quantity);
    console.log('in_cart_quantity', in_cart_quantity);
    console.log('total_quantity', total_quantity);
     */

    return remaining_quantity < total_quantity
}


export const addToCart = (detail, where = 'addProductModal') => (dispatch,getState) => {
    const { cart, settings, employee, customer} = getState();
    const { edit_detail } = cart;
    const { data: prescription } = getState().prescription;
    const { product, unit} = detail;
    const isNotProductSet = detail.product_id && !detail.product_set_type;

    const customerInfo = {
        id: customer.id,
        ingredient_allergies: customer.ingredient_allergies,
        name: customer.name,
        price_level: customer.price_level,
        product_allergies: customer.product_allergies,
        reference_code: customer.reference_code,
        retail_price_level: customer.retail_price_level,
        wholesale_price_level: customer.wholesale_price_level
    }

    const least_price = number(product.costs.first_expire_lot).multiply(detail.unit.pivot.quantity_of_smallest_unit).value;

    const errRemainingQuantity = checkCertRemainingQuantity(detail, cart);
    const errSaleQuantity = detail.sales_quantity <= 0;
    const errPriceLessThenZero = detail.price_per_unit < 0 ;
    const errPriceZero = detail.price_per_unit == 0 && customer.price_level != 5 && !detail.is_free;
    const errPriceLessThenLot = employee.employee.branch_role_id > 2 && !detail.is_free && detail.price_per_unit < least_price
    const errPriceType = detail.price_per_unit === '';
    const ignorePrice = prescription ? ['1', '2'].includes(prescription.model_no) : false;
    const errProductId = detail.product_id === null

    if(errRemainingQuantity || errSaleQuantity || errPriceLessThenZero || errPriceLessThenLot || errPriceType || errPriceZero){
        const msg = (errRemainingQuantity) ? 'ไม่สามารถเพิ่มสินค้าได้มากเกินจำนวนที่คงเหลือ.'
        : (errSaleQuantity) ?  'ไม่สามารถเพิ่มสินค้าได้.'
        : (errPriceLessThenZero) ? 'ไม่สามารถขายสินค้าโดยราคาน้อยกว่า 0 บาทได้.'
        : (errPriceZero) ? 'ไม่สามารถขายสินค้าราคา 0 บาทได้'
        : (errPriceLessThenLot) ? 'ไม่สามารถขายสินค้าโดยราคาที่น้อยกว่าราคาต่ำสุดของ LOT นั้นได้.'
        : (errPriceType) && 'กรุณาใส่ราคาขาย.'

        if (!ignorePrice || errRemainingQuantity || errSaleQuantity) {
            dispatch(addNotification({
                title: 'ผิดพลาด',
                message: msg,
                status: 'error',
                dismissAfter: 3000,
                id: 'cartErrorMsg'
            }))
            return dispatch({
                type: ERROR_ADD_TO_CART
            })
        }
    }

    const sameInCart = cart.details.find((d) => {
        return (d.product_id === detail.product_id && d.unit_id === detail.unit_id && detail.product_id)
    })

    if( detail.status === 'add'){

        let res = Object.assign({},detail);

        if(detail.products && detail.product_set_type === '1'){
            res.products = detail.products.map((p) => {
                const quantity = number(p.quantity).multiply(detail.sales_quantity).value;
                return Object.assign({}, p,{ quantity })
            });
            const { details: prevDetails } = cart

            let sameProductSet = prevDetails.find((d) => { return d.product_set_id === detail.product_set_id });

            if(typeof(sameProductSet) !== 'undefined'){

                let updateDetails = prevDetails.map((d) => {
                    if(d.product_set_id === detail.product_set_id){
                        const sales_quantity = number(d.sales_quantity).add(detail.sales_quantity).value;
                        const net_amount = number(d.price_per_unit).multiply(sales_quantity).value;
                        return Object.assign({},d,{
                            sales_quantity,
                            net_amount,
                            products: d.products.map((p) => {
                                const quantity = !!p.quantity_per_set ? number(p.quantity_per_set).multiply(sales_quantity).value : number(p.quantity).divide(detail.sales_quantity).multiply(sales_quantity).value;
                                return Object.assign({},p,{ quantity });
                            })
                        })
                    }
                    return d;
                })

                return dispatch({
                    type: EDIT_PRODUCT_SET,
                    payload: updateDetails
                })
            }
        }

        if(typeof(sameInCart) !== 'undefined'){
            return dispatch({
                type: EDIT_TO_CART_AND_PLUS,
                payload: Object.assign({}, sameInCart, detail)
            })
        }

        dispatch(AddMessageLogToCart(`POS Add to cart [ca1] with employee_id is ${employee.employee.id}`))
        return dispatch({
            type: ADD_TO_CART,
            payload: {
                detail: res
                , sales_receipt_status_id: cart.current_status_id
                , employee_id: employee.employee.id
                , customer_id: customer.id
                , customer: customerInfo
            },
        })

    }else if( detail.status === 'edit' ){

        if(detail.products && detail.product_set_type === '1'){
            return dispatch({
                type: EDIT_TO_CART,
                payload: detail
            })
        }

        if(edit_detail.unit_id === detail.unit_id){
            return dispatch({
                type: EDIT_TO_CART,
                payload: detail
            })
        }

        const sameProduct = cart.details.filter(d => d.product_id === detail.product_id && d.product_set_type !== '1');

        if(sameProduct.length === 1){
            return dispatch({
                type: CHANGE_UNIT_IN_CART,
                payload: detail
            });
        }else {
            const sameUnit = sameProduct.find(d => d.unit_id === detail.unit_id );

            if(!sameUnit){
                return dispatch({
                    type: CHANGE_UNIT_IN_CART,
                    payload: detail
                });
            }else{
                dispatch(removeToCart(edit_detail));
                return dispatch({
                    type: EDIT_TO_CART_AND_PLUS,
                    payload: detail
                })
            }
        }


    }

}

export const addMultipleToCart = (details) => (dispatch, getState) => {
    const { cart } = getState();

    if (details.some(detail => checkCertRemainingQuantity(detail, cart))) {
        dispatch(addNotification({
            title: 'ผิดพลาด',
            message: 'ไม่สามารถเพิ่มสินค้าได้มากเกินจำนวนที่คงเหลือ',
            status: 'error',
            dismissAfter: 3000,
            id: 'cartErrorMsg'
        }))
        return dispatch({
            type: ERROR_ADD_TO_CART
        })
    }

    if (details.some(detail => detail.price_per_unit === 0)) {
        // Error some product in sets is 0 price
        return dispatch(addNotification({
            title: 'ผิดพลาด',
            message: 'ไม่สามารถขายสินค้าราคา 0 บาทได้',
            status: 'error',
            dismissAfter: 3000,
            id: 'cartErrorMsg'
        }))
    }

    details.forEach(detail => dispatch(addToCart(detail)));
}

export const removeToCart = (detail) => dispatch => {
    return dispatch({
        type: REMOVE_TO_CART,
        payload: detail
    })
}

export const editCartDetail = (detail) => dispatch => {

    // const payload = Object.assign({},detail,{
    //     product: Object.assign({},detail.product,{
    //         units: detail.product.units.filter((u) => {
    //             return u.id === detail.unit_id
    //         })
    //     })
    // });

    const payload = detail;

    return dispatch({
        type: EDIT_CART_DETAIL,
        payload: payload
    })
}

export const setCartValue = (path,value) => dispatch =>{
    return dispatch({
        type: SET_CART_VALUE
        , payload: {
            path
            , value
        }
    })
}

export const focusCart = () => dispatch => {
    return dispatch({
        type: FOCUS_CART
    })
}

export const upIndexCart = () => dispatch => {
    return dispatch({
        type: UP_INDEX_CART
    })
}

export const downIndexCart = () => dispatch => {
    return dispatch({
        type: DOWN_INDEX_CART
    })
}

export const onBlurCart = () => dispatch => {
    return dispatch({
        type: ON_BLUR_CART
    })
}

export const onCreditChange = (val) => (dispatch) => {

    return dispatch({
        type: FEE_PAYMENT_CHANGE,
        payload: val
    })

}

export const setBodc12Data = (data) => dispatch => {
    return dispatch({
        type: SET_BODC12_DATA,
        payload: data,
    });
};

export const clearBodc12Data = () => dispatch => {
    return dispatch({ type: CLEAR_BODC12_DATA });
};
