import { LOAD_SYNC_ERROR
    , DELETE_SYNC_ERROR
    , ADD_TO_SYNC_ERROR } from '../actionTypes'

const initialState = {
    carts: []
}

export default (state=initialState, { type, payload }) => {
    switch (type) {
        case LOAD_SYNC_ERROR:
            return Object.assign(state, { carts: payload })
        case DELETE_SYNC_ERROR:
            return {
                carts: state.carts.filter(cart => cart.reference_code !== payload)
            }
        case ADD_TO_SYNC_ERROR:
            return {
                carts: [...state.carts, payload]
            }
        default:
            return state
    }
}