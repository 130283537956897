import {
    SET_VOICE_CALL_ON,
    SET_VOICE_CALL_STATUS,
    SET_VOICE_CALL_SCREEN,
    POPUP_VOICE_CALL_WIDGET,
    MINIMIZE_VOICE_CALL_WIDGET,
    CLOSE_VOICE_CALL_WIDGET,
    VOICE_CALL_INCOMING,
    VOICE_CALL_HANG_UP,
    VOICE_CALL_MISSED,
    RECEIVE_VOICE_RECORDING_URL,
} from '../actionTypes';

const initialState = {
    isOn: false,
    popup: false,
    screen: 'call',
    callSid: null,
    status: '',
    patient: {
        id: null,
        name: 'ไม่ทราบชื่อ',
        phone: '',
    },
    calls: [],
    recordingUrl: '',
};

export default (state = initialState, { type, payload }) => {
    switch (type) {
        case POPUP_VOICE_CALL_WIDGET:
            return { ...state, popup: true };
        case MINIMIZE_VOICE_CALL_WIDGET:
            return { ...state, popup: false };
        case SET_VOICE_CALL_ON:
            return { ...state, isOn: payload };
        case SET_VOICE_CALL_STATUS:
            return { ...state, status: payload };
        case SET_VOICE_CALL_SCREEN:
            return { ...state, screen: payload };
        case VOICE_CALL_INCOMING:
            return {
                ...state,
                popup: true,
                screen: 'call',
                callSid: payload.callSid,
                status: payload.status,
                patient: payload.patient,
                recordingUrl: '',
            };
        case VOICE_CALL_HANG_UP:
            return { ...state, screen: 'completed', status: payload.status };
        case VOICE_CALL_MISSED:
            return { ...initialState, isOn: true, calls: [...state.calls, payload] };
        case RECEIVE_VOICE_RECORDING_URL:
            return { ...state, recordingUrl: payload };
        case CLOSE_VOICE_CALL_WIDGET:
            if (state.status !== 'open') {
                return { ...initialState, isOn: true, calls: state.calls };
            } else {
                return state;
            }
        default:
            return state;
    }
};
