function deg2rad(degrees) {
    const pi = Math.PI;
    return degrees * (pi / 180);
}

function rad2deg(radians) {
    const pi = Math.PI;
    return radians * (180 / pi);
}

export function latLongDistance(lat1, lon1, lat2, lon2) {
    const theta = lon1 - lon2;
    let dist = Math.sin(deg2rad(lat1)) * Math.sin(deg2rad(lat2)) + Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) * Math.cos(deg2rad(theta));
    dist = Math.acos(dist);
    dist = rad2deg(dist);
    return dist * 60 * 1.853159616;
}

export function latLongManhattanDistance(lat1, lon1, lat2, lon2) {
    const dLat = latLongDistance(lat1, lon1, lat2, lon1);
    const dLon = latLongDistance(lat1, lon1, lat1, lon2);
    return dLat + dLon;
}
