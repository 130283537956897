import {
    SERVICES_CLOSE_MODAL,
    SERVICES_OPEN_MODAL,
    SERVICES_SEARCH_HISTORY_CANCELLATION,
    ADD_TO_SYNC_ERROR,
    DELETE_SYNC_ERROR,
} from '../actionTypes';
import { ServicesModalScreen } from 'Components/Page/Services/constants';
import foodPandaServiceAreas from 'Utilz/foodPandaServiceAreas';

import io from 'socket.io-client';
import { notify } from 'reapop';
import axios from 'axios';
import moment from 'moment';

const API3_URL = process.env.API3_URL;

export const openServicesModal = (modalScreen = null, modalScreenOrderId = null) => dispatch => {
    dispatch({
        type: SERVICES_OPEN_MODAL,
        payload: {
            modalScreen,
            modalScreenOrderId,
        },
    });
};

export const closeServicesModal = () => dispatch => {
    dispatch({ type: SERVICES_CLOSE_MODAL });
};

export const isEnableServices = settings => {
    const isBranchInBangkok =
        settings.branch.province_id &&
        settings.branch.province_id === 1 &&
        settings.branch.city_id &&
        settings.branch.city_id !== 3;
    const isBranchInServiceAreas =
        settings.branch.city_id &&
        foodPandaServiceAreas.includes(settings.branch.city_id);
    return isBranchInBangkok || isBranchInServiceAreas;
};

export const setServiceDeliveryCancellationInterval = () => dispatch => {
    dispatch(getServiceDeliveryCancellation());
    setInterval(() => {
        dispatch(getServiceDeliveryCancellation());
    }, 60 * 1000);
}

export const getServiceDeliveryCancellation = () => (dispatch, getState) => {
    const { settings, syncErrorMessage } = getState();
    return axios({
        url: `${API3_URL}/companies/${settings.company.slug}/branches/${settings.branch.slug}/service-delivery/cancellation`,
        headers: { Authorization: `Bearer ${settings.token}` },
    }).then((res) => {
        const hasServiceDeliveryErrorMessage = syncErrorMessage.carts.some(cart => cart.error_type === 'service_delivery');
        if (res.data.data > 0 && !hasServiceDeliveryErrorMessage) {
            dispatch({
                type: ADD_TO_SYNC_ERROR,
                payload: {
                    reference_code: "บริการจัดส่ง",
                    sync_at: moment().format("YYYY-MM-DD HH:mm:ss"),
                    message: "รายการออเดอร์ ถูกยกเลิก",
                    error_type: "service_delivery",
                },
            });
        } else if (res.data.data === 0 && hasServiceDeliveryErrorMessage) {
            dispatch({ type: DELETE_SYNC_ERROR, payload: "บริการจัดส่ง" });
        }
    }).catch(error => {
        console.error('getServiceDeliveryCancellation => ' + error);
    });
};

export const listenServiceDeliveryCancellationOnSocket = () => (dispatch, getState) => {
    const API_SOCKET_URL = process.env.API_SOCKET_URL;
    const { company, branch } = getState().settings;
    const eventName = `sdo-inbox-${company.id}_${branch.id}`;

    const socket = io(API_SOCKET_URL);
    socket.on('connect', () => console.log(`SDO: socket connected to ${eventName}`));
    socket.on(eventName, data => {
        const { id, reference_code } = data;
        console.log(`SDO: received new notification! - ${reference_code}`);
        dispatch(getServiceDeliveryCancellation());
        dispatch(notify({
            id: "serviceDelivery",
            title: "ข้อความเเจ้งเตือน",
            message: "บริการจัดส่ง รายการ<br />ออเดอร์ถูกยกเลิก",
            status: "error",
            dismissible: true,
            dismissAfter: 0,
            closeButton: true,
            allowHTML: true,
            buttons: [
                {
                    name: "แก้ไข",
                    primary: true,
                    onClick: () => dispatch(openServicesModal(ServicesModalScreen.FOODPANDA, id)),
                },
            ],
        }));
    });
};

export const servicesSearchHistoryCancellation = value => dispatch => {
    dispatch({ type: SERVICES_SEARCH_HISTORY_CANCELLATION, payload: value });
}

export const openServiceDeliverySearchHistory = () => dispatch => {
    dispatch(servicesSearchHistoryCancellation(true));
    dispatch(openServicesModal(ServicesModalScreen.HISTORY));
};
