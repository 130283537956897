import { 
    SET_PRINTER_TABLE_HEADER
    , SELECT_PRINTER
 } from 'Redux/actionTypes'

const initialState = {
    headers : []
    , selected: {
        id: 0
    }
}

export default (state=initialState, { type, payload }) => {
    switch (type) {

        case SET_PRINTER_TABLE_HEADER: 
            return Object.assign({},state,{headers : payload});
        case SELECT_PRINTER:
            localStorage.setItem('choosedPrinter',payload.id);
            return Object.assign({},state,{selected : payload});
        default:
            return state
    }
}