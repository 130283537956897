import { SEARCH_PRODUCTS
    , CLEAR_PRODUCTS
    , SEARCH_CUSTOMERS
    , SEARCH_RECEIPTS
    , SELECT_PRODUCT
    , NOT_FOUND_PRODUCT
    , ADD_PRODUCT_SET1
    , ADD_TO_CART
    , EDIT_TO_CART
    , EDIT_TO_CART_AND_PLUS
    , EDIT_PRODUCT_SET
    , ON_BLUR_SEARCH
    , SET_FIND_ALL_PRODUCTS
    , UPDATE_PRODUCT_PAGINATE
    , SET_PRODUCT_LOADING
    , ERROR_ADD_TO_CART
    , OPEN_PRODUCT_ERROR_MODAL
    , CLOSE_PRODUCT_ERROR_MODAL
} from 'Redux/actionTypes';

import axiosHelper from 'Redux/middleware/axios';
import { checkRemainingSet
    , verifyProductSet
    , selectReceipt
    , selectCustomer
    , addToCart
    , getQuickPos
    , notiUnitProduct
    , AddMessageLogToCart
} from 'Redux/actions';

import {
    convertProductToProductSet2,
    convertProductToProductSet1
} from 'Business';

import { addNotification, removeNotification} from 'reapop';
import db from '../../utils/db'
import { selectProduct } from "./product";
import { addMultipleToCart, checkCertRemainingQuantity } from "./cart";

let helper = new axiosHelper();

const queryProducts = (query, products) => {
    let res = []

    for (let i = 0; i < products.length; i++) {
        if (String(products[i].name).toLowerCase().includes(query.toLowerCase())) {
            res.push(products[i])
        } else {
            if (String(products[i].reference_code).toLowerCase().includes(query.toLowerCase())) {
                res.push(products[i])
            } else {
                if (products[i].units) {
                    products[i].units.find(unit => unit.pivot.barcode === query) && res.push(products[i])
                } else {
                    products[i].barcode === query && res.push(products[i])
                }
            }
        }
    }

    return res
}

const checkProducts = (data, query, paginate) => (dispatch, getState) => {

    const quickPos = getQuickPos();
    const { cart, detail: initDetail, customer, employee: objEmp, prescription } = getState()
    const { employee } = objEmp;

    const customerInfo = {
        id: customer.id,
        ingredient_allergies: customer.ingredient_allergies,
        name: customer.name,
        price_level: customer.price_level,
        product_allergies: customer.product_allergies,
        reference_code: customer.reference_code,
        retail_price_level: customer.retail_price_level,
        wholesale_price_level: customer.wholesale_price_level
    }

    const products = data.map((product) => {

        if(product.is_product_set){
            product = verifyProductSet(product);
            product.remaining_quantity = checkRemainingSet(product);
            product.units = [{
                name: 'ชุด'
                , retailPrice : { price: product.total_amount }
                , remaining_quantity: product.remaining_quantity
                , id: 0
                , pivot : {
                  quantity_of_smallest_unit : 1,
                  is_smallest_unit: true
                }
            }];
        }
        return product;
    })

    if (
        products.length > 1 ||
        (products.length === 1 && products[0].remaining_quantity <= 0) || 
        (products.length === 1 && products[0].is_product_set && products[0].errors && products[0].errors.length > 0)
    ) {
        return dispatch({
            type: SEARCH_PRODUCTS,
            payload: {
                products,
                query,
                paginate
            },
        })
    } else if (
        // If 1 and it's normal product
        products.length === 1 && !products[0].is_product_set)
    {
        const product = products[0];
        const { units } = product;
        let unit;

        // If turn off quick pos then select units
        if (!quickPos) {
            if (units.length > 0) {
                return dispatch({
                    type: SELECT_PRODUCT,
                    payload: {
                        product: products[0],
                        quantity: cart.present_quantity,
                        query,
                    },
                });
            }
            return dispatch(notiUnitProduct(product));
        }

        const isLinemanEp = prescription.data && prescription.data.category == 'lm';
        const isMedcareEp = prescription.data && prescription.data.category == 'mc';
        const isPayInStore = prescription.data && prescription.data.model_no == "PAY_IN_STORE";
        const epHasSetPrice = isLinemanEp || isMedcareEp || isPayInStore;
        const epHasSetQuantity = isLinemanEp || isMedcareEp || isPayInStore;

        // Product with single unit
        if (products[0].units.length === 1) {
            if (prescription.data && epHasSetPrice && units[0].id !== prescription.unitId) {
                return dispatch(addNotification({
                    title: 'ผิดพลาด',
                    message: 'ไม่พบสินค้าที่ค้นหา',
                    status: 'warning',
                    dismissAfter: 3000,
                    id: 'notFoundProductSearchMsg'
                }));
            }
            unit = units[0];
        } else {
            // Matched unit barcode
            const matchedUnitBarcode = products[0].units.filter(unit => {
                const matchedBarcode = unit.barcodes.filter(barcode => barcode === query);
                return matchedBarcode.length > 0;
            });

            if (prescription.data && epHasSetPrice) {
                const prescriptionUnit = units.find(unit => unit.id === prescription.unitId);
                if (!prescriptionUnit) {
                    return dispatch(addNotification({
                        title: 'ผิดพลาด',
                        message: 'ไม่พบสินค้าที่ค้นหา',
                        status: 'warning',
                        dismissAfter: 3000,
                        id: 'notFoundProductSearchMsg'
                    }));
                }
                unit = prescriptionUnit;
            } else if (matchedUnitBarcode.length === 1) {
                unit = matchedUnitBarcode[0];
            } else {
                // เลือกหน่วยเล็กสุดให้อัตโนมัติ หากมีหน่วยเล็กสุดและเปิด Quick POS
                const isSmallestUnit = products[0].units.find(unit => unit.pivot.is_smallest_unit === 1)
                if (isSmallestUnit) {
                    unit = isSmallestUnit
                }
            }
        }

        if (!unit) {
            if (units.length > 0) {
                return dispatch({
                    type: SELECT_PRODUCT,
                    payload: {
                        product: products[0],
                        quantity: cart.present_quantity,
                        query,
                    },
                });
            }
            return dispatch(notiUnitProduct(product));
        }

        let price = 0;
        let sales_quantity = 0;

        if (!prescription.data) {
            price = unit[`price${customer.price_level}`];
            sales_quantity = cart.present_quantity;
        } else {
            const prescriptionDetail = _.find(prescription.data.details, {
                id: prescription.detailId,
                product_id: product.id,
                unit_id: prescription.unitId,
            });

            if (prescriptionDetail && epHasSetQuantity) {
                sales_quantity = parseInt(prescriptionDetail.quantity, 10)
            } else {
                sales_quantity = cart.present_quantity
            }

            if (prescriptionDetail && epHasSetPrice) {
                if (prescription.data.category == 'mc') {
                    price = parseFloat(prescriptionDetail.pharmacy_price);
                } else {
                    price = parseFloat(prescriptionDetail.partner_price);
                }
            } else {
                price = unit[`price${customer.price_level}`];
            }
        }

        if (!price) {
            for (let i = 1; i <= 5; i++) {
                if (unit['price' + i] > 0) {
                    price = unit['price' + i];
                    break;
                }
            }
        }

        if (!price || price < 0) {
            return dispatch({
                type: SELECT_PRODUCT,
                payload: {
                    product: products[0],
                    quantity: cart.present_quantity,
                    query,
                },
            });
        }

        const detail = {
            ...initDetail,
            product,
            unit,
            unit_id: unit.id,
            sales_quantity: sales_quantity,
            price_per_unit: price,
            net_amount: price * sales_quantity,
            product_id: product.id,
        };

        const errRemainingQuantity = checkCertRemainingQuantity(detail, cart);

        if (errRemainingQuantity) {
            dispatch(addNotification({
                title: 'ผิดพลาด',
                message: 'ไม่สามารถเพิ่มสินค้าได้มากเกินจำนวนที่คงเหลือ',
                status: 'error',
                dismissAfter: 3000,
                id: 'cartErrorMsg'
            }))
            return dispatch({
                type: ERROR_ADD_TO_CART
            })
        }

        const isSameProduct = cart.details.find((d) => {
            return product.id === d.product_id && unit.id === d.unit_id;
        });

        if (isSameProduct) {
            return dispatch({
                type: EDIT_TO_CART_AND_PLUS,
                payload: detail,
            });
        } else {
            dispatch(AddMessageLogToCart(`POS Add to cart [s1] with employee_id is ${employee.id}`))
            return dispatch({
                type: ADD_TO_CART,
                payload: {
                    detail: detail,
                    sales_receipt_status_id: cart.current_status_id,
                    employee_id: employee.id,
                    customer_id: customer.id,
                    customer: customerInfo,
                },
            });
        }

    } else if (
        // If 1 and it's product sets
        products.length === 1 && products[0].is_product_set
    ) {
        let product = products[0];

        if (!quickPos) {
            return dispatch(selectProduct(products[0]));
        }

        if (product.sets_type === '1') {

            let detail = convertProductToProductSet1(initDetail, cart, product);

            const errRemainingQuantity = checkCertRemainingQuantity(detail, cart);

            if (errRemainingQuantity) {
                dispatch(addNotification({
                    title: 'ผิดพลาด',
                    message: 'ไม่สามารถเพิ่มสินค้าได้มากเกินจำนวนที่คงเหลือ',
                    status: 'error',
                    dismissAfter: 3000,
                    id: 'cartErrorMsg'
                }))
                return dispatch({
                    type: ERROR_ADD_TO_CART
                })
            }

            const { details: prevDetails } = cart

            let sameProductSet = prevDetails.find((d) => { return d.product_set_id === detail.product_set_id });

            if(typeof(sameProductSet) !== 'undefined'){

                let updateDetails = prevDetails.map((d) => {
                    if(d.product_set_id === detail.product_set_id){
                        const sales_quantity = +d.sales_quantity + +detail.sales_quantity;

                        return Object.assign({},d,{
                            sales_quantity: sales_quantity
                            , net_amount: sales_quantity * d.price_per_unit
                            , products: d.products.map((p) => {
                                return Object.assign({},p,{
                                    quantity: (p.quantity_per_set)? p.quantity_per_set * sales_quantity : (p.quantity / detail.sales_quantity) * sales_quantity
                                })
                            })
                        })
                    }
                    return d;
                })

                return dispatch({
                    type: EDIT_PRODUCT_SET,
                    payload: updateDetails
                })
            }

            detail = Object.assign({}, detail, {
                net_amount: detail.price_per_unit * cart.present_quantity,
                products: detail.products.map((p) => {
                    return Object.assign({}, p, {
                        quantity: (p.quantity_per_set)? p.quantity_per_set * cart.present_quantity : (p.quantity / detail.sales_quantity) * cart.present_quantity
                    })
                })
            });

            if (detail.price_per_unit === 0) {
                return dispatch(addNotification({
                    title: 'ผิดพลาด',
                    message: 'ไม่สามารถขายสินค้าราคา 0 บาทได้',
                    status: 'error',
                    dismissAfter: 3000,
                    id: 'cartErrorMsg'
                }))
            }

            dispatch(AddMessageLogToCart(`POS Add to cart [s2] with employee_id is ${employee.id}`))
            return dispatch({
                type: ADD_TO_CART,
                payload: {
                    detail: detail
                    , sales_receipt_status_id: cart.current_status_id
                    , employee_id: employee.id
                    , customer_id: customer.id
                    , customer: customerInfo
                },
            })
        } else {
            const details = convertProductToProductSet2( initDetail, cart, product);

            dispatch(addMultipleToCart(details));
        }
    } else {
        return dispatch(addNotification({
            title: 'ผิดพลาด',
            message: 'ไม่พบสินค้าที่ค้นหา',
            status: 'warning',
            dismissAfter: 3000,
            id: 'notFoundProductSearchMsg'
        }));
    }
}

export const searchProducts = query => (dispatch, getState) => {
    const { settings: {company, branch, token, offline_mode} ,notifications} = getState();
    const { findAllProducts, productPage, productPerPage } = getState().search;

    if (offline_mode) {
        return db.table('products').toArray().then(products => {
            const resProducts = queryProducts(query, products)
            dispatch(checkProducts(resProducts, query))
        }).catch(err => {
            console.log(err);
            return dispatch(addNotification({
                title: 'ผิดพลาด',
                message: 'การค้นหาผิดพลาด',
                status: 'error',
                dismissAfter: 3000,
                id: 'errMsgSearch',
            }))
        })
    } else {
        const fields = 'price,formula,ingredients,remaining_quantity,unit,cost,bureauOfDrugControls,wishlist';
        const limit = productPerPage;
        const is_all = findAllProducts ? 1 : 0

        dispatch({ type: SET_PRODUCT_LOADING, payload: true });

        return helper.get(`/companies/${company.slug}/products?branch_id=${branch.id}&fields=${fields}&limit=${limit}&q=${encodeURIComponent(query)}&form_page=pos&is_all=${is_all}&page=${productPage}`, {}, {
            'Authorization': `Bearer ${token}`
        }, 3).then(res => {
            if(notifications.find(notification => notification.id === 'addRetailUnit')) { dispatch(removeNotification('addRetailUnit'));}
            dispatch(checkProducts(res.products, query, res.pagination))
        }).catch((err) => {
            console.log('searchProducts : ' + err);
            return dispatch(addNotification({
                title: 'ผิดพลาด',
                message: 'การค้นหาผิดพลาด',
                status: 'error',
                dismissAfter: 3000,
                id: 'errMsgSearch',
            }))
        }).finally(() => {
            dispatch({ type: SET_PRODUCT_LOADING, payload: false });

            if (getState().search.productLoading) {
                setTimeout(() => dispatch({ type: SET_PRODUCT_LOADING, payload: false }), 3000);
            }
        });
    }
}

export const productsTypeahead = query => (dispatch, getState) => {
    const { company, branch, token } = getState().settings;
    const { findAllProducts } = getState().search;

    const limit = 100;
    const is_all = findAllProducts ? 1 : 0

    return helper.get(`/companies/${company.slug}/products?branch_id=${branch.id}&limit=${limit}&q=${query}&form_page=pos&is_all=${is_all}`, {}, {
        'Authorization': `Bearer ${token}`
    }, 3).then(res => res.products)
    .catch(err => {
        console.log(err);
        return [];
    })
};

export const clearProducts = () => dispatch => {
    return dispatch({ type: CLEAR_PRODUCTS })
}

const queryCustomers = (query, customers) => {
    let res = []

    for (let i = 0; i < customers.length; i++) {
        if (String(customers[i].name).toLowerCase().includes(query)) {
            res.push(customers[i])
        } else {
            if (String(customers[i].reference_code).toLowerCase().includes(query)) {
                res.push(customers[i])
            } else {
                if (customers[i].mobile_number.includes(query)) {
                    res.push(customers[i])
                } else {
                    customers[i].citizen_id.includes(query) && res.push(customers[i])
                }
            }
        }
    }

    return res
}

const checkCustomers = (customers, silent) => dispatch => {
    if (customers.length > 1) {
        return dispatch({ type: SEARCH_CUSTOMERS, payload: customers })
    } else if (customers.length === 1) {
        return dispatch(selectCustomer(customers[0]))
    } else {
        if (silent) return;
        return dispatch(addNotification({
            id: 'searchMember',
            message: 'ไม่พบข้อมูลที่ค้นหา',
            status: 'warning',
            dismissible: true,
            dismissAfter: 3000,
        }))
    }
}

export const searchCustomers = (query, silent) => (dispatch, getState) => {
    const { company, branch, token, offline_mode } = getState().settings

    if (offline_mode) {
        return db.table('customers').toArray().then(customers => {
            const resCustomers = queryCustomers(query, customers)
            dispatch(checkCustomers(resCustomers, silent))
        }).catch(err => {
            console.log(err)
            if (silent) return;
            return dispatch(addNotification({
                id: 'searchMember',
                title: 'ผิดพลาด',
                message: 'การค้นหาผิดพลาด',
                status: 'error',
                dismissible: true,
                dismissAfter: 3000
            }))
        })
    } else {
        const fields = 'first_name,last_name,reference_code'
        return helper.get(`/members?company_id=${company.id}&branch_id=${branch.id}&fields=${fields}&q=${query}`, {}, {
            'Authorization': `Bearer ${token}`
        }, 2).then(res => {
            dispatch(checkCustomers(res.customers, silent))
        }).catch(err => {
            console.log(err)
            if (silent) return;
            return dispatch(addNotification({
                id: 'searchMember',
                title: 'ผิดพลาด',
                message: 'การค้นหาผิดพลาด',
                status: 'error',
                dismissible: true,
                dismissAfter: 3000
            }))
        })
    }
}

export const searchReceipts = query => (dispatch, getState) => {
    const { company, branch, token } = getState().settings
    const salesReceiptDetails = 'product,unit,salesReturnDetails'
    const salesReceipts = 'employee,details,member,customer,abb,prescription,coupons'
    const prescription = 'messenger_details';

    return helper.get(`/companies/${company.slug}/sales-receipts?branch_id=${branch.id}&include[sales_receipt_details]=${salesReceiptDetails}&include[sales_receipts]=${salesReceipts}&include[prescriptions]=${prescription}&q=${query}`,
    {},{
        'Authorization': `Bearer ${token}`
    }, 2).then(res => {
        const receipts = res.data
        if (receipts.length > 1) {
            return dispatch({ type: SEARCH_RECEIPTS, payload: receipts })
        } else if (receipts.length === 1) {
            return dispatch(selectReceipt(receipts[0]))
        } else {
            return dispatch(addNotification({
                id: 'searchReceipts',
                message: 'ไม่พบข้อมูลที่ค้นหา',
                status: 'warning',
                dismissible: true,
                dismissAfter: 3000
            }))
        }
    }).catch(res => {
        console.log(res)
        return dispatch(addNotification({
            id: 'searchReceipts',
            title: 'ผิดพลาด',
            message: 'การค้นหาผิดพลาด',
            status: 'warning',
            dismissible: true,
            dismissAfter: 3000
        }))
    })
}

export const onBlurSearchTxt = () => dispatch => {
    return dispatch({ type: ON_BLUR_SEARCH })
}

export const setFindAllProducts = checked => dispatch => {
    return dispatch({ type: SET_FIND_ALL_PRODUCTS, payload: checked })
}

export const updateProductPaginate = page => (dispatch, getState) => {
    const { product_query } = getState().search;

    dispatch({ type: UPDATE_PRODUCT_PAGINATE, payload: page });
    return dispatch(searchProducts(product_query));
}

export const openProductErrorModal = (errors) => dispatch => {
    dispatch({ type: OPEN_PRODUCT_ERROR_MODAL ,payload: errors});
};
export const closeProductErrorModal = () => dispatch => {
    dispatch({ type: CLOSE_PRODUCT_ERROR_MODAL });
};
