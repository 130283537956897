import {
    CLOSE_QR_CONNECTED,
    QR_CANCEL_ERROR,
    QR_CHECKOUT,
    QR_CONNECTED,
    QR_INQUIRE,
    QR_INQUIRE_ERROR,
    QR_LOADING,
    QR_PAID,
    QR_PAYMENT_CLOSE,
    QR_PAYMENT_ERROR,
    QR_PAYMENT_OPEN,
    QR_PAYMENT_SUCCESS,
    QR_REFRESH,
    QR_RESULT_CLOSE,
    QR_TRIGGER_CHECKOUT,
    QR_TRIGGER_PAID,
    SET_UUID_FOR_QR,
} from 'Redux/actionTypes';
import { checkedOut, closeConfirmBox, showConfirmBox } from 'Redux/actions';
import axiosHelper from 'Redux/middleware/axios';
import { connectSocket, disconnectSocket, qrPaymentSocket, qrWindowSocket } from 'Redux/middleware/ioHelper';

import { addNotification } from 'reapop';

let qrWindow = null;

const helper = new axiosHelper();

export const handleQRPaymentResult = (res) => (dispatch, getState) => {
    return dispatch({
        type: QR_PAYMENT_SUCCESS,
        payload: res,
    });
};

export const onSubmitQRResult = () => (dispatch, getState) => {
    const { qr } = getState();

    dispatch({ type: QR_RESULT_CLOSE });

    switch (qr.payFor) {
        case 'service_delivery':
            return;
        case 'sale_receipt':
        default:
            return dispatch(checkedOut());
    }
};

export const onCloseQRPayment = () => (dispatch, getState) => {
    const { qr, settings } = getState();
    const { token, company, branch, uuid } = settings;
    const { slug: company_slug } = company;
    const { slug: branch_slug } = branch;
    const { id, payFor } = qr;

    return inquire(company_slug, branch_slug, id, token, payFor).then((res) => {
        switch (res.txnStatus) {
            case 'PAID':
                dispatch(addNotification({
                    title: 'ผิดพลาด',
                    message: 'ไม่สามารถขอ QR ใหม่ได้เนื่องจากมีการชำระเงินแล้ว',
                    status: 'warning',
                    dismissAfter: 3000,
                    id: 'refreshQR',
                }));
                disconnectSocket();
                res.txnAmount = getState().cart.total_paid_amount;
                return dispatch(handleQRPaymentResult(res));
            case 'EXPIRED':
            case 'CANCELLED':
                disconnectSocket();
                return dispatch({ type: QR_PAYMENT_CLOSE });
            case 'REQUESTED':
                return cancelQR(company_slug, branch_slug, id, token, payFor).then(() => {
                    disconnectSocket();
                    if (uuid != null) {
                        triggerCloseQR(settings);
                    }
                    return dispatch({ type: QR_PAYMENT_CLOSE });
                }).catch((err) => {
                    console.error(err);
                });
        }
    }).catch((err) => {
        console.error(err);
    });
};

export const loadQR = (options = {}) => (dispatch, getState) => {
    dispatch({
        type: QR_LOADING,
    });

    const { settings, cart, employee: mainEmp } = getState();
    const { token, company: { slug: company_slug }, branch: { slug: branch_slug }, uuid } = settings;
    const { employee } = mainEmp;
    const payFor = options.payFor || 'sale_receipt';

    let url;
    let metadata = options.metadata || '';
    switch (payFor) {
        case 'service_delivery':
            url = `/companies/${company_slug}/branches/${branch_slug}/service-delivery/qr-payment`;
            break;
        case 'sale_receipt':
        default:
            url = `/companies/${company_slug}/branches/${branch_slug}/pos/kbank/qr`;
            const arrProduct = cart.details.map((d, i) => {
                return i + ' ' + d.net_amount;
            });
            metadata = arrProduct.join(',');
            break;
    }

    const data = {
        employee_id: employee.id,
        qrType: payFor === 'service_delivery' ? '3' : settings['payment'][cart.payment_types]['qr_type'],
        txnAmount: options.txnAmount || cart.total_paid_amount,
        reference1: options.reference1 || cart.reference_code,
        metadata,
        payFor,
    };

    return helper.post(url,
        data,
        {
            Authorization: `Bearer ${token}`,
        }, 3)
        .then(res => {
            if (res.statusCode === '00') {
                // Request success
                dispatch(connectedSocket('ไม่สามารถเชื่อมต่อ Notification Server ได้ กรุณากดปุ่มตรวจสอบการชำระแทน'));

                if (uuid) {
                    triggerQrConnected(cart, settings, res);
                }

                qrPaymentSocket(res.id).then((res) => {
                    dispatch(handleQRPaymentResult(res));
                    return disconnectSocket();
                }).catch((err) => {
                    console.error(err);
                });

                return dispatch({
                    type: QR_PAYMENT_OPEN,
                    payload: {
                        ...res,
                        payFor,
                    },
                });
            } else {
                // Request error
                let errorMessage;
                switch (res.errorCode) {
                    case 'invalid_txn_amount':
                        errorMessage = 'จำนวนเงินเกินกว่า 50,000 ไม่สามารถชำระเงินผ่าน QR ได้ กรุณาเลือกวิธีชำระเงินแบบอื่น';
                        break;
                    case 'invalid_merchant':
                    case 'authentication_error':
                        errorMessage = 'บัญชีถูกระงับหรือปิดไปแล้ว กรุณาเลือกบัญชีอื่นหรือติดต่อธนาคาร';
                        break;
                    default:
                        errorMessage = res.errorDesc;
                }
                dispatch(addNotification({
                    title: 'ผิดพลาด',
                    message: errorMessage,
                    status: 'error',
                    dismissAfter: 10000,
                    id: 'inquireQR',
                }));
                return dispatch({ type: QR_PAYMENT_ERROR });
            }
        }).catch((err) => {
            console.error(err);
            return dispatch({ type: QR_PAYMENT_ERROR });
        });
};

export const refreshQR = () => (dispatch, getState) => {
    const { qr, settings } = getState();
    const { token, company, branch } = settings;
    const { slug: company_slug } = company;
    const { slug: branch_slug } = branch;
    const { id, payFor } = qr;
    dispatch({ type: QR_REFRESH });

    return inquire(company_slug, branch_slug, id, token, payFor).then((res) => {
        switch (res.txnStatus) {
            case 'PAID':
                dispatch(addNotification({
                    title: 'ผิดพลาด',
                    message: 'ไม่สามารถขอ QR ใหม่ได้เนื่องจากมีการชำระเงินแล้ว',
                    status: 'warning',
                    dismissAfter: 3000,
                    id: 'refreshQR',
                }));
                res.txnAmount = getState().cart.total_paid_amount;
                return dispatch(handleQRPaymentResult(res));
            case 'EXPIRED':
            case 'CANCELLED':
                return dispatch(loadQR());
            case 'REQUESTED':
                return cancelQR(company_slug, branch_slug, id, token, payFor).then(() => {
                    return dispatch(loadQR({
                        qrType: qr.payFor === 'service_delivery' ? '3' : null,
                        reference1: qr.reference1,
                        payFor: qr.payFor,
                    }));
                }).catch((err) => {
                    console.error(err);
                    return dispatch({ type: QR_CANCEL_ERROR });
                });
        }
    }).catch((err) => {
        console.error(err);
    });
};

export const inquireQR = () => (dispatch, getState) => {
    const { qr, settings } = getState();
    const { token, company, branch } = settings;
    const { slug: company_slug } = company;
    const { slug: branch_slug } = branch;
    const { id, payFor } = qr;
    dispatch({ type: QR_INQUIRE });

    return inquire(company_slug, branch_slug, id, token, payFor).then((res) => {
        switch (res.txnStatus) {
            case 'PAID':
                res.txnAmount = getState().cart.total_paid_amount;
                return dispatch(handleQRPaymentResult(res));
            case 'EXPIRED':
            case 'CANCELLED':
                const onCancel = () => {
                    dispatch(closeConfirmBox());
                    return dispatch({ type: QR_PAYMENT_CLOSE });
                };
                const onSubmit = () => {
                    dispatch(closeConfirmBox());
                    return dispatch(loadQR());
                };
                return dispatch(showConfirmBox('แจ้งเตือน',
                    'QR หมดอายุ หรือ ยกเลิกไปแล้ว ต้องการขอใหม่หรือไม่',
                    onSubmit,
                    onCancel,
                ));
            case 'REQUESTED':
                dispatch({ type: QR_INQUIRE_ERROR });
                return dispatch(addNotification({
                    title: 'หมายเหตุ',
                    message: 'QR ยังไม่ถูกชำระเงิน',
                    status: 'warning',
                    dismissAfter: 3000,
                    id: 'inquireQR',
                }));
        }
    }).catch((err) => {
        console.error(err);
        return dispatch({ type: QR_INQUIRE_ERROR });
    });
};

export const newQRWindow = () => dispatch => {
    let uuid = uuidv4();
    qrWindow = window.open(`/qr/${uuid}`, '_blank', 'width=580,height=473');

    return dispatch({
        type: SET_UUID_FOR_QR,
        payload: { uuid },
    });
};

export const connectQrWindow = (uuid) => (dispatch) => {
    dispatch(connectedSocket('ไม่สามารถเชื่อมต่อ Notification Server ได้'));
    qrWindowSocket(uuid).then((res) => {
        disconnectSocket();
        switch (res.type) {
            case 'connected': {
                return dispatch({
                    type: QR_CONNECTED,
                    payload: {
                        qr: res.qr,
                        cart: res.cart,
                        settings: res.settings,
                    },
                });
            }
            case 'paid': {
                return dispatch({
                    type: QR_PAID,
                });
            }
            case 'checkout': {
                return dispatch({
                    type: QR_CHECKOUT,
                    payload: {
                        cart: res.cart,
                    },
                });
            }
            case 'refresh': {
                return dispatch({
                    type: QR_CHECKOUT,
                });
            }
            case 'close': {
                return dispatch({ type: QR_PAYMENT_CLOSE });
            }
        }
    });
};

export const closeQRWindow = () => (dispatch) => {
    if (qrWindow != null) {
        qrWindow.close();
        qrWindow = null;
    }
    return dispatch({ type: CLOSE_QR_CONNECTED });
};

export const triggerCheckout = () => (dispatch, getState) => {
    const { settings: { token, uuid }, cart } = getState();

    const data = {
        uuid: uuid,
        cart: cart,
    };
    const url = `/socket/checkout`;
    helper.post(url,
        data,
        {
            Authorization: `Bearer ${token}`,
        }, 3)
        .then((res) => {

        }).catch((err) => {
        console.error(err);
    });

    return dispatch({ type: QR_TRIGGER_CHECKOUT });
};

export const triggerPaidQr = () => (dispatch, getState) => {
    const { settings: { token, uuid } } = getState();

    const data = {
        uuid: uuid,
    };

    const url = `/socket/paid`;
    helper.post(url,
        data,
        {
            Authorization: `Bearer ${token}`,
        }, 3)
        .then((res) => {

        }).catch((err) => {
        console.error(err);
    });
    return dispatch({ type: QR_TRIGGER_PAID });
};

const triggerCloseQR = (settings) => {
    const { token, uuid } = settings;

    const data = {
        uuid: uuid,
    };
    const url = `/socket/qrClose`;
    helper.post(url,
        data,
        {
            Authorization: `Bearer ${token}`,
        }, 3)
        .then((res) => {

        }).catch((err) => {
        console.error(err);
    });
};

const triggerLoadNewQR = () => {
    const { token, uuid } = settings;

    const data = {
        uuid: uuid,
    };
    const url = `/socket/qrRefresh`;
    helper.post(url,
        data,
        {
            Authorization: `Bearer ${token}`,
        }, 3)
        .then((res) => {

        }).catch((err) => {
        console.error(err);
    });
};

const triggerQrConnected = (cart, settings, qr) => {
    const { token, uuid } = settings;

    const data = {
        uuid: uuid,
        cart: cart,
        qr: qr,
        settings: settings,
    };
    const url = `/socket/qrConnected`;
    helper.post(url,
        data,
        {
            Authorization: `Bearer ${token}`,
        }, 3)
        .then((res) => {

        }).catch((err) => {
        console.error(err);
    });

};

const inquire = (company_slug, branch_slug, id, token, payFor) => {
    let url;
    switch (payFor) {
        case 'service_delivery':
            url = `/companies/${company_slug}/branches/${branch_slug}/service-delivery/qr-payment`;
            break;
        case 'sale_receipt':
        default:
            url = `/companies/${company_slug}/branches/${branch_slug}/pos/kbank/qr`;
            break;
    }
    url += `/${id}/inquire`;

    const data = {};

    return helper.post(url,
        data,
        {
            Authorization: `Bearer ${token}`,
        }, 3);
};

const cancelQR = (company_slug, branch_slug, id, token, payFor) => {
    let url;
    switch (payFor) {
        case 'service_delivery':
            url = `/companies/${company_slug}/branches/${branch_slug}/service-delivery/qr-payment`;
            break;
        case 'sale_receipt':
        default:
            url = `/companies/${company_slug}/branches/${branch_slug}/pos/kbank/qr`;
            break;
    }
    url += `/${id}/cancel`;

    const data = {};

    return helper.post(url,
        data,
        {
            Authorization: `Bearer ${token}`,
        }, 3);
};

const connectedSocket = (msg) => dispatch => {
    connectSocket()
        .then(() => {
        })
        .catch((err) => {
            console.error(err);
            disconnectSocket();
            return dispatch(addNotification({
                title: 'ผิดพลาด',
                message: msg,
                status: 'warning',
                dismissAfter: 3000,
                id: 'inquireQR',
            }));
        });
};

const uuidv4 = function () {
    try {
        return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
            (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16),
        );
    } catch (err) {
        return null;
    }
};

