import React from 'react';
import { Modal, ModalBody, Row, Col, Button } from 'reactstrap';

const NotiRemoveCouponModal = (props) => {
    const { language, showModal } = props;
    return (
        <Modal isOpen={showModal} toggle={props.closeModal}>
            <ModalBody>
                <Row>
                    <Col className="text-center">
                        <div className="warning-img mb-3" style={{ height: 195 }}></div>
                        <h4>{language.notiRemoveCouponDesc1}</h4>
                        <h4>{language.notiRemoveCouponDesc2}</h4>
                        <h4>{language.notiRemoveCouponDesc3}</h4>
                    </Col>
                </Row>
                <Row className="mt-4 mb-2">
                    <Col className="text-center">
                        <Button
                            color="danger"
                            style={{ minWidth: 90, marginRight: 5 }}
                            onClick={props.closeModal}
                        >
                            {language.no}
                        </Button>{' '}
                        <Button
                            color="success"
                            style={{ minWidth: 90 }}
                            onClick={() => {
                                props.confirm();
                                props.closeModal();
                            }}
                        >
                            {language.ok}
                        </Button>
                    </Col>
                </Row>
            </ModalBody>
        </Modal>
    );
};

export default NotiRemoveCouponModal;
