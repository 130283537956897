export const CHECK_OUT = 'CHECK_OUT';
export const CLOSE_CHECK_OUT = 'CLOSE_CHECK_OUT';
export const FOCUS_PAID_AMOUNT = 'FOCUS_PAID_AMOUNT';
export const CHECKED_OUT = 'CHECKED_OUT';
export const CLOSE_CHECKED_OUT = 'CLOSE_CHECKED_OUT';
export const TOTAL_PAID_AMOUNT = 'TOTAL_PAID_AMOUNT';
export const FOCUS_SEARCH_AFTER_CLOSE = 'FOCUS_SEARCH_AFTER_CLOSE';
export const SWITCH_PAYMENT_TYPE = 'SWITCH_PAYMENT_TYPE';
export const ON_BLUR_TOTAL_PAID_AMOUNT = 'ON_BLUR_TOTAL_PAID_AMOUNT';
export const ON_BLUR_PAID_AMOUNT = 'ON_BLUR_PAID_AMOUNT';
export const PAY_WITH_ALIPAY_WECHAT = 'PAY_WITH_ALIPAY_WECHAT';