import React from 'react';
import ReactDOM from 'react-dom'
import { Modal
    , ModalHeader
    , ModalBody
    , Row
    , Col
    , Button
 } from 'reactstrap';

import Currency from 'react-currency-formatter';

class QRResultModal extends React.Component {
    render() {
        const { show
            , onClose
            , language
            , accountName
            , txnAmount
            , statusCode
            , errorCode
            , errorDesc
            , onSubmit } = this.props
        return (
            <Modal
                toggle={onClose}
                isOpen={show}
                size="md">
                <ModalHeader toggle={onClose} className='qr-result-header' />
                <ModalBody>
                    <div className='qr-result-content'>
                        {statusCode === "10" ?
                        <div className="text-center text-danger">
                            <span className="fa fa-exclamation-triangle"></span>
                            <h2>{errorCode}</h2>
                            <h2>{errorDesc}</h2>
                        </div> :
                        <div className="text-center text-success">
                            <i className="fa fa-check-circle"></i>
                            <h2 className="mt-2">{language.qr_payment_succ}</h2>
                        </div>}
                    </div>
                    <div className="text-center">
                        <h2>{language.pay} <Currency quantity={txnAmount} symbol='' /> {language.baht}</h2>
                        <h2>{language.account_name}: {accountName}</h2>
                    </div>
                    <div className="qr-result-footer">
                            {this.props.error_code ?
                            <Button
                                onClick={onClose}
                                color="danger"
                                ref="btnCon">
                                {language.qr_re_payment}
                            </Button> :
                            <Button
                                onClick={onSubmit}
                                color="success"
                                ref="btnCon">
                                {language.done}
                            </Button>}
                    </div>
                </ModalBody>
            </Modal>
        )
    }

    componentDidUpdate(preProps){
        const { focus } = this.props
        const { focus: wasFocus } = preProps
        
        setTimeout(function() {
            if (!wasFocus && focus) { 
                ReactDOM.findDOMNode(this.refs.btnCon).focus()
            }
        }.bind(this), 0)
    }
}

export default QRResultModal