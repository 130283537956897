import {
    ADD_TO_CART,
    CHANGE_STATUS_CART,
    CHANGE_UNIT_IN_CART,
    CHECK_OUT,
    CHECKED_OUT,
    CLEAR_BODC12_DATA,
    CONTINUE_CART,
    DOWN_INDEX_CART,
    EDIT_CART_DETAIL,
    EDIT_PRODUCT_SET,
    EDIT_TO_CART,
    EDIT_TO_CART_AND_PLUS,
    FAILURE_ABB,
    FEE_PAYMENT_CHANGE,
    ON_BLUR_CART,
    ON_BLUR_TOTAL_PAID_AMOUNT,
    PRESCRIPTION_CLEAR_DATA,
    PRESCRIPTION_SET_DATA,
    QR_CHECKOUT,
    QR_CONNECTED,
    QR_PAID,
    RECEIVE_ABB,
    RECEIVE_CHECKIN,
    RECEIVE_SETTINGS,
    REMOVE_CUSTOMER,
    REMOVE_TO_CART,
    SAVE_CART,
    SELECT_PRINTER,
    SET_BODC12_DATA,
    SET_CART_VALUE,
    SET_CURRENT_CUSTOMER,
    SET_LINE_PAY_VALUE,
    SWITCH_PAYMENT_TYPE,
    TOTAL_PAID_AMOUNT,
    UP_INDEX_CART,
    UPDATE_CART_WISHLIST,
    UPDATE_LINE_PAY_FEE,
    UPDATE_SEARCH_WISHLIST,
    COUPON_ADD_TO_CART,
    COUPON_REMOVE_FROM_CART,
    ADD_MESSAGE_LOG_TO_CART,
} from '../actionTypes';

import { saveCartToIndexedDB } from '../middleware/dbHelper';
import { calculatePrice, checkAllergic, syncByAxios, updateCertWithMemberPriceLevel } from "../actions";
import { number } from 'Utilz/currency';
import store from '../store'

import moment from 'moment';

const initialState = {
    reference_code: null,
    details: [],
    bank_account_id: 0,
    company_id: null,
    branch_id: null,
    employee: {},
    employee_id: null,
    current_status_id: 2,
    created_at: null,
    tax_types: null,
    tax_percentage: 0,
    histories: [],
    member: {
        reference_code: 'ลูกค้าทั่วไป',
        name: 'ลูกค้าทั่วไป'
    },
    customer_id: null,
    member_id: null,
    prescription_reference_code: null,
    prescription_id: null,
    bodc12: null,
    net_amount: 0,
    has_allergic: false,
    total_discount_amount: 0,
    price: 0,
    total_price: 0,
    display_amount: 0,
    tax_amount: 0,
    total_amount: 0,
    total_paid_amount: 0,
    paid_amount: 0,
    credit_include: '',
    change_amount: 0,
    present_quantity: 1,
    payment_types: 'cash',
    current_index: -1,
    abb: {
        reference_code: null
    },
    credit_types: null,
    credit_charge: 0,
    fee: 0,
    printer: {
        id: 0
    },
    printer_license: null,
    danger_note: null,
    pos_logs: [],
    buyer_auth_code: '',
    edit_detail: {},
    coupon: null,
};

const initialHistory = {
    employee_id: null,
    sales_receipt_status_id: null,
    created_at: null
};

export default (state = initialState, { type, payload }) => {
    let ref_code =
        state.reference_code === null
            ? 'SR' + moment().format('YYMMDD-HHmmss') + String(moment().millisecond()).padStart(3, '0')
            : state.reference_code;
    let res;

    switch (type) {
        case EDIT_CART_DETAIL: {
            return {...state, edit_detail: payload};
        }
        case FEE_PAYMENT_CHANGE:{
            res = Object.assign({}, state, {credit_include: payload});
            res = calculatePrice(res);

            return res;
        }
        case QR_PAID:
            return initialState;
        case QR_CHECKOUT:
        case QR_CONNECTED:
            return Object.assign({}, state, payload.cart);
        case SELECT_PRINTER: {
            const res = Object.assign({}, state, {
                printer: payload,
                printer_license: payload.printer_license
            });
            if (state.reference_code !== null) {
                saveCartToIndexedDB(res);
            }
            return res;
        }
        case ON_BLUR_TOTAL_PAID_AMOUNT: {
            return calculatePrice(state, ON_BLUR_TOTAL_PAID_AMOUNT);
        }
        case SWITCH_PAYMENT_TYPE: {
            let res = Object.assign({}, state, payload);
            res = calculatePrice(res);
            return res;
        }
        case RECEIVE_CHECKIN:
            return Object.assign({}, state, {
                employee: payload,
                employee_id: payload.id
            });
        case ON_BLUR_CART:
            return Object.assign({}, state, { current_index: -1 });
        case UP_INDEX_CART: {
            if (
                state.current_index >= -1 &&
                state.current_index < state.details.length - 1
            ) {
                return Object.assign({}, state, {
                    current_index: state.current_index + 1
                });
            } else {
                return Object.assign({}, state);
            }
        }
        case DOWN_INDEX_CART: {
            if (state.current_index >= 0) {
                return Object.assign({}, state, {
                    current_index: state.current_index - 1
                });
            } else {
                return Object.assign({}, state);
            }
        }
        case CHANGE_STATUS_CART: {
            const history = Object.assign({}, initialHistory, {
                employee_id: payload.employee_id,
                sales_receipt_status_id: payload.sales_receipt_status_id,
                created_at: moment().format('YYYY-MM-DD HH:mm:ss')
            });
            res = Object.assign({}, state, {
                histories: [...state.histories, history]
            });

            saveCartToIndexedDB(res);

            return res;
        }

        case EDIT_PRODUCT_SET: {
            res = Object.assign({}, state, {
                details: payload,
                present_quantity: 1
            });

            res = calculatePrice(res);
            saveCartToIndexedDB(res);

            return res;
        }
        case RECEIVE_SETTINGS:
            res = Object.assign({}, state, {
                tax_percentage: payload.tax_percentage,
                tax_types: payload.tax_types,
                company_id: payload.company.id,
                branch_id: payload.branch.id
            });
            return res;
        case SET_CART_VALUE:
            res = Object.assign({}, state, { [payload.path]: payload.value });
            res = calculatePrice(res, payload.path);
            return res;
        case CHANGE_UNIT_IN_CART: {
            const {edit_detail} = state;
            const updateDetails = state.details.map(d => {
                if(d.product_id === edit_detail.product_id &&
                    d.unit_id === edit_detail.unit_id){
                        const sales_quantity = number(payload.sales_quantity).value;
                        const net_amount = number(payload.price_per_unit).multiply(sales_quantity).value;
                        return {
                            ...d,
                            ...payload,
                            sales_quantity,
                            net_amount,
                        }
                    }
                return d;
            });

            res = Object.assign({}, state, {
                details: updateDetails,
                present_quantity: 1,
                edit_detail: initialState.edit_detail
            });
            res = calculatePrice(res);

            saveCartToIndexedDB(res);

            return res;
        }
        case EDIT_TO_CART: {
            const updateDetails = state.details.map(d => {
                if (
                    d.product_id === payload.product_id &&
                    d.unit_id === payload.unit_id
                ) {
                    const sales_quantity = number(payload.sales_quantity).value;
                    const net_amount = number(payload.price_per_unit).multiply(sales_quantity).value;
                    return {
                        ...d,
                        ...payload,
                        sales_quantity,
                        net_amount,
                    };
                }
                return d;
            });

            res = Object.assign({}, state, {
                details: updateDetails,
                present_quantity: 1,
                edit_detail: initialState.edit_detail
            });
            res = calculatePrice(res);

            saveCartToIndexedDB(res);

            return res;
        }
        case EDIT_TO_CART_AND_PLUS: {
            const updateDetails = state.details.map(d => {
                if (
                    d.product_id === payload.product_id &&
                    d.unit_id === payload.unit_id
                ) {
                    const sales_quantity = number(d.sales_quantity).add(payload.sales_quantity).value;
                    const net_amount = number(payload.price_per_unit).multiply(sales_quantity).value;
                    return {
                        ...d,
                        ...payload,
                        sales_quantity,
                        net_amount,
                    };
                }
                return d;
            });

            res = Object.assign({}, state, {
                details: updateDetails,
                present_quantity: 1,
                edit_detail: initialState.edit_detail
            });
            res = calculatePrice(res);

            saveCartToIndexedDB(res);

            return res;
        }
        case REMOVE_TO_CART: {
            res = Object.assign({}, state, {
                details: [...state.details.filter(x => x !== payload)]
            });

            if (res.details.length === 0) {
                res.total_discount_amount = 0;
            }

            res = calculatePrice(res);

            saveCartToIndexedDB(res);

            return res;
        }
        case ADD_TO_CART: {
            res = Object.assign({}, state, {
                details: [...state.details, payload.detail],
                reference_code: ref_code,
                present_quantity: 1,
                customer_id: payload.customer_id || state.customer_id,
                member: !payload.customer_id ? state.member : payload.customer,
                pos_logs: [...state.pos_logs],
            });
            res = calculatePrice(res);
            res = checkAllergic(res);

            if (state.reference_code === null) {
                const history = Object.assign({}, initialHistory, {
                    employee_id: payload.employee_id,
                    sales_receipt_status_id: payload.sales_receipt_status_id,
                    created_at: moment().format('YYYY-MM-DD HH:mm:ss')
                });

                res = Object.assign({}, res, {
                    histories: [...state.histories, history],
                    created_at: moment().format('YYYY-MM-DD HH:mm:ss'),
                });
            }

            saveCartToIndexedDB(res);

            return res;
        }
        case CHECK_OUT: {
            res = Object.assign({}, state, {
                total_paid_amount: state.net_amount,
                abb: initialState.abb,
                paid_amount:
                    !state.paid_amount || state.paid_amount <= 0
                        ? initialState.paid_amount
                        : state.paid_amount
            });

            res = calculatePrice(res, 'CHECK_OUT');

            res = Object.assign({}, res, {
                total_paid_amount: res.total_price
            });

            saveCartToIndexedDB(res);

            return res;
        }
        case TOTAL_PAID_AMOUNT: {
            res = Object.assign({}, state, {
                total_paid_amount: payload.total_paid_amount,
                total_discount_amount: payload.total_discount_amount
            });

            res = calculatePrice(res);

            saveCartToIndexedDB(res);

            return res;
        }
        case CHECKED_OUT: {
                const lastHistory = Object.assign({}, initialHistory, {
                    employee_id: payload.employee.id,
                    sales_receipt_status_id: 3,
                    created_at: moment().format('YYYY-MM-DD HH:mm:ss')
                });

                res = Object.assign({}, state, {
                    current_status_id: 3,
                    employee: payload.employee,
                    employee_id: payload.employee.id,
                    histories: [...state.histories, lastHistory],
                    checkout_at: moment().format('YYYY-MM-DD HH:mm:ss'),
                    pos_logs: [...state.pos_logs],
                });

                saveCartToIndexedDB(res);
                store.dispatch(syncByAxios(res));

                return Object.assign({}, initialState, {
                    tax_percentage: state.tax_percentage,
                    tax_types: state.tax_types,
                    company_id: state.company_id,
                    branch_id: state.branch_id,
                    employee: state.employee,
                    employee_id: state.employee_id,
                    printer: state.printer,
                    printer_license: state.printer_license
                });
        }
        case SAVE_CART: {
            res = Object.assign({}, initialState, {
                tax_percentage: state.tax_percentage,
                tax_types: state.tax_types,
                company_id: state.company_id,
                branch_id: state.branch_id,
                employee: state.employee,
                employee_id: state.employee_id
            });

            return res;
        }
        case CONTINUE_CART: {
            res = Object.assign({}, payload)

            res = calculatePrice(res);

            res = checkAllergic(res);

            saveCartToIndexedDB(res);

            return res;
        }
        case SET_CURRENT_CUSTOMER: {
            res = Object.assign({}, state, {
                customer_id: payload.id,
                member: payload
            });

            res = checkAllergic(res);
            res = updateCertWithMemberPriceLevel(res);

            if (state.reference_code !== null) {
                saveCartToIndexedDB(res);
            }

            return res;
        }
        case REMOVE_CUSTOMER: {
            res = Object.assign({}, state, {
                customer_id: initialState.customer_id,
                member: initialState.member
            });

            res = checkAllergic(res);

            if (state.reference_code !== null) {
                saveCartToIndexedDB(res);
            }

            return res;
        }
        case RECEIVE_ABB: {
            res = Object.assign({}, state, { abb: payload });

            saveCartToIndexedDB(res);

            return res;
        }
        case FAILURE_ABB: {
            res = Object.assign({}, initialState, {
                tax_percentage: state.tax_percentage,
                tax_types: state.tax_types,
                company_id: state.company_id,
                branch_id: state.branch_id,
                employee: state.employee,
                employee_id: state.employee_id
            });

            return res;
        }
        case UPDATE_SEARCH_WISHLIST:
        case UPDATE_CART_WISHLIST: {
            if (state.details.length > 0) {
                const updateDetails = state.details.map(detail => {
                    if (detail.product_set_type === '1') {
                        const updateProduct = detail.products.map(product => {
                            if (product.product_id === payload.id) {
                                product.wishlist_item = payload.wishlist_item;
                            }
                            return Object.assign({}, product);
                        });
                        return Object.assign({}, detail, {
                            products: updateProduct
                        });
                    } else {
                        if (detail.product_id === payload.id) {
                            detail.product.wishlist_item =
                                payload.wishlist_item;
                            return Object.assign({}, detail);
                        }
                        return detail;
                    }
                });
                return Object.assign({}, state, { details: updateDetails });
            }
        }
        case SET_LINE_PAY_VALUE: {
            if (payload.path === 'fee_type') {
                let res = Object.assign({}, state, {
                    credit_include: `credit_fee_${payload.value}`
                });

                res = calculatePrice(res);

                return res;
            } else {
                return Object.assign({}, state);
            }
        }
        case UPDATE_LINE_PAY_FEE: {
            let res = Object.assign({}, state, { fee: payload.feePercentage });
            res = calculatePrice(res);
            return res;
        }
        case PRESCRIPTION_SET_DATA: {
            return calculatePrice(state);
        }
        case PRESCRIPTION_CLEAR_DATA: {
            return calculatePrice(state);
        }
        case SET_BODC12_DATA: {
            const res = Object.assign({}, state, { bodc12: payload });

            saveCartToIndexedDB(res);

            return res;
        }
        case CLEAR_BODC12_DATA: {
            const res = Object.assign({}, state, { bodc12: null });

            saveCartToIndexedDB(res);

            return res;
        }
        case COUPON_ADD_TO_CART: {
            res = Object.assign({}, state, { coupon: payload });
            res = calculatePrice(res);
            return res;
        }
        case COUPON_REMOVE_FROM_CART: {
            res= Object.assign({}, state, { coupon: null, total_discount_amount: 0 });
            res = calculatePrice(res);
            return res;
        }
        case ADD_MESSAGE_LOG_TO_CART: {
            return Object.assign({}, state, { pos_logs: [...state.pos_logs, payload] })
        }
        default:
            return state;
    }
};
