import React from 'react';
import { Row, Col, Button } from 'reactstrap';
import classnames from 'classnames';

const CallScreen = (props) => {
    const { status, patient } = props.voice;
    return (
        <React.Fragment>
            <Row className={status === 'open' ? 'mb-4' : 'mb-5'}>
                <Col md="12" className="text-center">
                    <div
                        className={classnames({
                            'incoming-call-img': status === 'pending',
                            'avatar-call-img': status === 'open',
                        })}
                    ></div>
                    <h5 className="font-weight-bold">
                        {patient.name ? patient.name : 'ไม่ทราบชื่อ'}
                    </h5>
                </Col>
            </Row>
            {status === 'open' && (
                <Row className="mb-3">
                    <Col md="12" className="text-center">
                        <Button
                            color="primary"
                            size="sm"
                            style={{ minWidth: 90, marginRight: 20 }}
                            onClick={props.registerCustomer}
                        >
                            <i className="fa fa-user-plus"></i>{' '}
                            {props.isCustomer ? 'อัพเดทข้อมูลสมาชิก' : 'บันทึกเป็นสมาชิก'}
                        </Button>
                    </Col>
                </Row>
            )}
            <Row className="mb-4">
                <Col md="12" className="text-center">
                    {status === 'open' && (
                        <Button
                            color="danger"
                            size="lg"
                            style={{ minWidth: 90, marginRight: 20 }}
                            onClick={props.hangUp}
                        >
                            <i className="fa fa-phone"></i> วางสาย
                        </Button>
                    )}
                    {status === 'pending' && (
                        <React.Fragment>
                            <Button
                                color="danger"
                                size="lg"
                                style={{ minWidth: 90, marginRight: 20 }}
                                onClick={props.reject}
                            >
                                <i className="fa fa-phone"></i> ปฏิเสธ
                            </Button>
                            <Button
                                color="success"
                                size="lg"
                                style={{ minWidth: 90 }}
                                onClick={props.accept}
                            >
                                <i className="fa fa-phone"></i> รับสาย
                            </Button>
                        </React.Fragment>
                    )}
                </Col>
            </Row>
        </React.Fragment>
    );
};

export default CallScreen;
