import React from 'react'
import { Row, Col, FormGroup, Button } from 'reactstrap'
import { priceFormat } from 'Utilz/currency';

class CashPage extends React.Component {
    constructor(props) {
        super(props)
        this._inputDot = this._inputDot.bind(this)
        this._inputDigit = this._inputDigit.bind(this)
        this._setCash = this._setCash.bind(this)
        this._handleKeyDown = this._handleKeyDown.bind(this)
        this._onChangePaidAmount = this._onChangePaidAmount.bind(this)
        this._handleFocus = this._handleFocus.bind(this)
    }

    _onChangePaidAmount(e) {
        const value = e.target.value
        if (value == '' || (/^[0-9.\b]+$/).test(value)) {
            this.props.setCartValue('paid_amount', value)
            this.props.calculateChangeAmount()
        }
    }

    _inputDigit(digit) {
        this.props.inputDigit(digit)
        this.props.calculateChangeAmount()
    }

    _inputDot() {
        this.props.inputDot('paid_amount')
    }

    _setCash(amount) {
        this.props.setCash(amount)
        this.props.calculateChangeAmount()
    }

    _handleKeyDown(e) {
        this.props.onBlurPaidAmount();
        const keyCode = e.keyCode
        if (keyCode === 13) {
            this.props.checkOutSubmit()
        } else if (keyCode === 190 || keyCode === 110) {
            e.preventDefault()
            this._inputDot()
        }
    }

    _handleFocus(e) {
        e.target.select()
    }

    render() {
        const { language, cart } = this.props
        return (
            <div>
                <Row>
                    <Col sm="6">
                        <FormGroup>
                            <label>{language.cash_amount}</label>
                            <div className="input-group input-group-xl">
                                <input
                                    className="form-control"
                                    name="paid_amount"
                                    value={cart.paid_amount}
                                    onChange={this._onChangePaidAmount}
                                    onKeyDown={this._handleKeyDown}
                                    onFocus={this._handleFocus}
                                    onBlur={this.props.onBlurPaidAmount}
                                    ref="paidAmountInput"
                                    maxLength="6"
                                />
                                <div className="input-group-append">
                                    <span className="input-group-text">฿</span>
                                </div>
                            </div>
                        </FormGroup>
                    </Col>
                    <Col sm="6">
                    <FormGroup>
                        <label>{language.change_amount}</label>
                        <h2 className="change-amount">{priceFormat(cart.change_amount)} ฿</h2>
                    </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col sm="9">
                    <table className="table number-pad-table">
                        <tbody>
                        <tr>
                            <td>
                                <a onClick={() => this._inputDigit(1)}>1</a>
                            </td>
                            <td>
                                <a onClick={() => this._inputDigit(2)}>2</a>
                            </td>
                            <td>
                                <a onClick={() => this._inputDigit(3)}>3</a>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <a onClick={() => this._inputDigit(4)}>4</a>
                            </td>
                            <td>
                                <a onClick={() => this._inputDigit(5)}>5</a>
                            </td>
                            <td>
                                <a onClick={() => this._inputDigit(6)}>6</a>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <a onClick={() => this._inputDigit(7)}>7</a>
                            </td>
                            <td>
                                <a onClick={() => this._inputDigit(8)}>8</a>
                            </td>
                            <td>
                                <a onClick={() => this._inputDigit(9)}>9</a>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <a onClick={this._inputDot}>.</a>
                            </td>
                            <td>
                                <a onClick={() => this._inputDigit(0)}>0</a>
                            </td>
                            <td>
                                <a onClick={() => this._setCash(String(cart.paid_amount).substring(0, cart.paid_amount.length - 1) || 0)}>{language.delete}</a>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                    </Col>
                    <Col sm="3">
                        <div className="buck-pad-table">
                            <Button onClick={() => this._setCash(1000)} block>1000 ฿</Button>
                            <Button onClick={() => this._setCash(500)} block>500 ฿</Button>
                            <Button onClick={() => this._setCash(100)} block>100 ฿</Button>
                            <Button onClick={() => this._setCash(50)} block>50 ฿</Button>
                            <Button onClick={() => this._setCash(20)} block>20 ฿</Button>
                        </div>
                    </Col>
                </Row>
            </div>
        )
    }

    componentDidUpdate(prevProps, prevState) {
        const { focus } = this.props
        if (focus) {
            this.refs.paidAmountInput.focus()
            this.refs.paidAmountInput.select()
        }
    }
}

export default CashPage