import { 
    CONFIRM_BOX
    , ALERT_BOX
    , CLOSE_CONFIRM_BOX
} from 'Redux/actionTypes'

export const showConfirmBox = (title,txt,onSubmit,onCancel) => dispatch => {
    return dispatch({
        type: CONFIRM_BOX,
        payload: {
            type: 'CONFIRM',
            title,
            txt,
            onSubmit,
            onCancel
        }
    })
}

export const showAlertBox = (title,txt,onCancel) => dispatch => {
    return dispatch({
        type: ALERT_BOX,
        payload: {
            type: 'ALERT',
            title,
            txt,
            onCancel
        }
    })
}

export const closeConfirmBox = () => dispatch => {
    return dispatch({type: CLOSE_CONFIRM_BOX});
}