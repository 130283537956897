import { OPEN_SALE_RETURN
    , CLOSE_SALE_RETURN
    , OPEN_CANCEL_SALE_RETURN
    , CLOSE_CANCEL_SALE_RETURN
    , OPEN_CONFIRM_TAX_INVOICE
    , CLOSE_CONFIRM_TAX_INVOICE
    , SYNC_SALE_RETURN
    , CLEAR_RECEIPT
    , SET_RETURN_VALUE
    , SELECT_RECEIPT
    , CHANGE_DETAILS
    , CLICK_CHECKBOX_DETAIL
    , SALE_RETURN_LOADING } from '../actionTypes'
import axiosHelper from '../middleware/axios'
import { groupDetails, calculateSaleReturn } from 'Redux/actions'
import { notify } from 'reapop'
import moment from 'moment'

let helper = new axiosHelper()

export const openSaleReturn = () => dispatch => {
    return dispatch({ type: OPEN_SALE_RETURN })
}

export const closeSaleReturn = () => dispatch => {
    return dispatch({ type: CLOSE_SALE_RETURN })
}

export const cancelSaleReceipt = (referenceCode) => dispatch => {
    return dispatch({
        type: OPEN_CANCEL_SALE_RETURN,
        payload: referenceCode
    })
}

export const closeCancelSaleReturn = () => dispatch => {
    return dispatch({ type: CLOSE_CANCEL_SALE_RETURN })
}


export const confirmTaxInvoice = (referenceCode) => dispatch => {
    return dispatch({
        type: OPEN_CONFIRM_TAX_INVOICE,
        payload: referenceCode
    })
}

export const closeConfirmTaxInvoice = () => dispatch => {
    return dispatch({ type: CLOSE_CONFIRM_TAX_INVOICE })
}

export const syncSalesReturn = (receipt, token) => dispatch => {
    helper.post('/sales-returns', receipt, {
        'Authorization': `Bearer ${token}`
    }, 2).then(res => {
        const salesReturn = res.data.attributes
        if (salesReturn) {
            dispatch(notify({
                id: 'salesReturn',
                title: 'สำเร็จ',
                message: 'บันทึกข้อมูลเรียบร้อยแล้ว',
                status: 'success',
                dismissible: true,
                dismissAfter: 3000
            }))
            dispatch({ type: SYNC_SALE_RETURN })
        }
    }).catch(err => {
        console.error(err);
        dispatch(notify({
            id: 'salesReturn',
            title: 'ล้มเหลว',
            message: 'บันทึกข้อมูลไม่สำเร็จ กรุณาลองใหม่อีกครั้ง',
            status: 'error',
            dismissible: true,
            dismissAfter: 3000
        }))
        dispatch({ type: SYNC_SALE_RETURN })
    })
}
export const syncConfirmTaxInvoiceSaleReceipt = (receipt, token) => dispatch => {
    helper.post('/medex/tax-invoice', receipt, {
        'Authorization': `Bearer ${token}`
    }, 3).then(res => {
        const salesReturn = res.data
        if (salesReturn.statusCode==200) {
            dispatch(notify({
                id: 'salesReturn',
                title: 'สำเร็จ',
                message: 'บันทึกข้อมูลเรียบร้อยแล้ว',
                status: 'success',
                dismissible: true,
                dismissAfter: 3000
            }))
            dispatch({ type: SYNC_SALE_RETURN })
        }else{
            
            dispatch(notify({
                id: 'salesReturn',
                title: 'ล้มเหลว',
                message: 'บันทึกข้อมูลไม่สำเร็จ '+salesReturn.message,
                status: 'error',
                dismissible: true,
                dismissAfter: 3000
            }))
            dispatch({ type: SYNC_SALE_RETURN })
        }
    }).catch(err => {
        console.error(err);
        dispatch(notify({
            id: 'salesReturn',
            title: 'ล้มเหลว',
            message: 'บันทึกข้อมูลไม่สำเร็จ กรุณาลองใหม่อีกครั้ง',
            status: 'error',
            dismissible: true,
            dismissAfter: 3000
        }))
        dispatch({ type: SYNC_SALE_RETURN })
    })
}

export const confirmCancelSaleReceipt = (referenceCode) => (dispatch, getState)=> {
    const { search, settings, saleReturn: saleReturnState } = getState()
    const { employee } = getState().employee
    let saleReturn = search.receipts.find(receipt => receipt.attributes.reference_code === referenceCode)

    if (!saleReturn) {
        saleReturn = saleReturnState
    }

    const { attributes: saleReturnAttr, relationships, id } = saleReturn
    const { abb, customer, details, member } = relationships
    
    const initSR = getState().saleReturn
    const initDetail = Object.assign({}, getState().detail, { quantity: 0 })

    let receipt = {
        reference_code: 'SRT' + moment().format('YYMMdDD-HHmmss'),
        company_id: saleReturnAttr.company_id,
        branch_id: saleReturnAttr.branch_id,
        current_status_id: 4,
        details: groupDetails(details, initDetail),
        employee_id: employee.id,
        histories: [{
            employee_id: employee.id,
            sales_return_status_id: 1
        }],
        member_id: (member === null) ? initSR.member_id : member.id,
        sales_receipt_id: id || saleReturn.sales_receipt_id,
        tax_amount: saleReturnAttr.tax_amount,
        tax_types: saleReturnAttr.tax_types,
        total_amount: saleReturnAttr.total_amount,
        note: initSR.note,
        tax_percentage: saleReturnAttr.tax_percentage,
        net_amount: initSR.net_amount,
        result_amount: initSR.result_amount,
        total_discount_amount: saleReturnAttr.total_discount_amount
    }

    receipt = Object.assign({}, receipt, {
        details: receipt.details.map(d => {
            receipt.net_amount += (d.price_per_unit * d.remaining_quantity)
            return Object.assign({}, d, {
                quantity: d.remaining_quantity,
                net_amount: d.price_per_unit * d.remaining_quantity
            })
        })
    })

    receipt = calculateSaleReturn(receipt)
    dispatch(syncSalesReturn(receipt, settings.token))
}
export const confirmTaxInvoiceSaleReceipt = (referenceCode) => (dispatch, getState)=> {
    const { settings} = getState()
    let receipt = {
        reference_code:referenceCode
    }
    dispatch(syncConfirmTaxInvoiceSaleReceipt(receipt, settings.token))
}

export const clearReceipt = () => dispatch => {
    return dispatch({ type: CLEAR_RECEIPT })
}

export const setReturnValue = (path, value) => dispatch => {
    return dispatch({
        type: SET_RETURN_VALUE,
        payload: {
            path,
            value
        }
    })
}

export const selectReceipt = (saleReturn) => (dispatch, getState) => {
    const { attributes: saleReturnAttr, relationships, id } = saleReturn
    const { abb, customer, details, employee, member } = relationships
    const { attributes: employeeAttr } = employee
    
    const initSR = getState().saleReturn
    const initDetail = Object.assign({}, getState().detail, {
        checked: false,
        quantity: 0
    })

    let saleReceiptCanVoid = true
    details.map(detail => {
        if (detail.relationships.sales_return_details.length > 0) {
            saleReceiptCanVoid = false
        }
    })

    let receipt = {
        reference_code: saleReturnAttr.reference_code,
        company_id: saleReturnAttr.company_id,
        branch_id: saleReturnAttr.branch_id,
        current_status_id: saleReturnAttr.current_status_id,
        created_at: saleReturnAttr.created_at,
        details: groupDetails(details, initDetail),
        employee: employeeAttr,
        employee_id: employeeAttr.id,
        histories: [{
            employee_id: employeeAttr.id,
            sales_return_status_id: 1
        }],
        member: (customer === null) ? null : customer.attributes,
        member_id: (member === null) ? initSR.member_id : member.id,
        sales_receipt_id: id,
        tax_amount: saleReturnAttr.tax_amount,
        tax_types: saleReturnAttr.tax_types,
        total_amount: saleReturnAttr.total_amount,
        tax_percentage: saleReturnAttr.tax_percentage,
        net_amount: initSR.net_amount,
        total_discount_amount: saleReturnAttr.total_discount_amount,
        total_paid_amount: saleReturnAttr.total_amount - saleReturnAttr.total_discount_amount,
        paid_amount: saleReturnAttr.paid_amount,
        change_amount: saleReturnAttr.change_amount,
        credit_include: saleReturnAttr.credit_include,
        abb: (abb === null) ? initSR.abb : abb.attributes,
        customer_id: (customer === null) ? initSR.customer_id : customer.id,
        saleReceiptCanVoid: saleReceiptCanVoid,
        attributes: saleReturnAttr,
        relationships: relationships
    }

    dispatch({ type: SELECT_RECEIPT, payload: receipt })
}

export const handleDetailChange = (id, e) => dispatch => {
    const { type, name, value, checked } = e.target
    if (type === 'checkbox') {
        return dispatch({
            type: CLICK_CHECKBOX_DETAIL,
            payload: {
                checked,
                id
            }
        })
    } else {
        return dispatch({
            type: CHANGE_DETAILS,
            payload: {
                path: name,
                value,
                id
            }
        })
    }
}

export const confirmReturnProduct = () => (dispatch, getState) => {
    const { saleReturn, settings } = getState()
    const { employee } = getState().employee
    dispatch({ type: SALE_RETURN_LOADING });

    const returnProducts = saleReturn.details.filter(detail => detail.checked === true)
    let receipt = Object.assign({}, saleReturn, {
        reference_code: 'SRT' + moment().format('YYMMdDD-HHmmss'),
        current_status_id: 3,
        details: returnProducts,
        employee: employee,
        employee_id: employee.id,
        histories: [...saleReturn.histories, {
            employee_id: employee.id,
            sales_return_status_id: 1
        }]
    })
    
    dispatch(syncSalesReturn(receipt, settings.token))
}
