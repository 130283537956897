import React from 'react'
import { Row, Col, Table } from 'reactstrap'
import classnames from 'classnames'

class SaleReturnForm extends React.Component {
    constructor(props) {
        super(props)
        this._onBlurQuantity = this._onBlurQuantity.bind(this)
    }

    _onBlurQuantity(e, detail) {
        const { name, value } = e.target
        if (value > detail.remaining_quantity || !value || value == 0) {
            this.props.handleDetailChange(detail.id, {
                target: { name, value: detail.remaining_quantity }
            })
        }
    }

    _displayPrice(price) {
        return Number(price).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
    }

    render() {
        const { language, saleReceipt, printSaleReceipt } = this.props
        const detailChecked = saleReceipt.details.filter(detail => detail.checked === true)
        const canReturnProduct = detailChecked.length > 0 ? false : true

        const detailsList = saleReceipt.details.map((detail, index) => {
            const checked = detail.checked ? true : false
            const outOfQuantity = detail.remaining_quantity === 0
            return (
                <tr key={index}
                className={classnames({'table-danger': outOfQuantity})}>
                    <td style={{ width: '50px' }}>
                        <div className="form-check">
                            <input
                                type="checkbox"
                                className="form-check-input position-static"
                                name="checked"
                                onChange={(e) => this.props.handleDetailChange(detail.id, e)}
                                checked={checked}
                                disabled={outOfQuantity}
                                title={outOfQuantity ? `${language.can_not_return}`: undefined}
                            />
                        </div>
                    </td>
                    <td>{detail.product.name}</td>
                    <td>{detail.sales_price_per_unit}</td>
                    <td style={{ width: '200px' }}>
                        <div className="input-group">
                            <input
                                type="number"
                                className="form-control"
                                name="price_per_unit"
                                value={detail.price_per_unit}
                                onChange={(e) => this.props.handleDetailChange(detail.id, e)}
                                min="0"
                                disabled={!checked}
                            />
                            <div className="input-group-append">
                                <span className="input-group-text">฿</span>
                            </div>
                        </div>
                    </td>
                    <td>{detail.remaining_quantity} / {detail.sales_quantity}</td>
                    <td style={{ width: '200px' }}>
                        <div className="input-group">
                            <input
                                type="number"
                                className="form-control"
                                name="quantity"
                                value={detail.quantity}
                                onChange={(e) => this.props.handleDetailChange(detail.id, e)}
                                onBlur={(e) => this._onBlurQuantity(e, detail)}
                                min="1"
                                max={detail.remaining_quantity}
                                disabled={!checked}
                            />
                            <div className="input-group-append">
                                <span className="input-group-text">{detail.unit.name}</span>
                            </div>
                        </div>
                    </td>
                    <td style={{ width: '50px' }}>{checked ? detail.net_amount : 0}</td>
                </tr>
            )
        })
        return (
            <Row className="mt-4">
                <Col>
                    <Row>
                        <Col sm="6">
                            <h5>{language.receipt_no}: {saleReceipt.attributes.reference_code}</h5>
                            <div className="mt-3">
                                <h5>{language.transaction_person}: {saleReceipt.employee.first_name} {saleReceipt.employee.last_name}</h5>
                                {saleReceipt.customer_id && <h5>{language.customer}: {saleReceipt.member.first_name} {saleReceipt.member.last_name}</h5>}
                            </div>
                        </Col>
                        <Col sm="6">
                            <div className="float-right">
                                <span className="text-danger float-right">{language.date} {saleReceipt.created_at}</span>
                                <div>
                                    <label className="salereturn-label">{language.bill_discount} {this._displayPrice(saleReceipt.total_discount_amount)} ฿</label>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Table className="salereturn-table text-center">
                                <thead>
                                    <tr>
                                        <th>{language.select}</th>
                                        <th>{language.product}</th>
                                        <th>{language.price_per_unit}/{language.unit}</th>
                                        <th>{language.price_return}/{language.unit}</th>
                                        <th>{language.remaining}/{language.purchase_amount}</th>
                                        <th>{language.amount_return}</th>
                                        <th>{language.total}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {detailsList}
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                    <Row className="mt-3">
                        <Col sm="6">
                            <div className="form-group">
                                <label>{language.note}</label>
                                <textarea
                                    className="form-control"
                                    name="note"
                                    value={saleReceipt.note}
                                    onChange={(e) => this.props.setReturnValue(e.target.name, e.target.value)}
                                    rows="4">
                                </textarea>
                            </div>
                        </Col>
                        <Col sm="6">
                            <div className="mt-5 text-right">
                                {saleReceipt.tax_types !== 'no_vat' && <Row>
                                    <Col sm="4">{language.tax} ({saleReceipt.tax_percentage} %)</Col>
                                    <Col sm="8">{this._displayPrice(!canReturnProduct ? saleReceipt.tax_amount : 0)} ฿</Col>
                                </Row>}
                                <Row className="mt-2">
                                    <Col sm="4">{language.return_as_cash}</Col>
                                    <Col sm="8">{this._displayPrice(!canReturnProduct ? saleReceipt.total_amount : 0)} ฿</Col>
                                </Row>
                                <Row className="mt-2">
                                    <Col sm="4">{language.total}</Col>
                                    <Col sm="8">{this._displayPrice(!canReturnProduct ? saleReceipt.result_amount : 0)} ฿</Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <button
                                type="button"
                                className="btn btn-danger btn-block"
                                onClick={this.props.clearReceipt}>
                                {language.cancel}
                            </button>
                        </Col>
                        <Col>
                            <button
                                type="button"
                                className="btn btn-success btn-block"
                                onClick={this.props.confirmReturnProduct}
                                disabled={canReturnProduct}>
                                {language.return_product}
                            </button>
                        </Col>
                        <Col>
                            <button
                                type="button"
                                className="btn btn-primary btn-block"
                                onClick={printSaleReceipt}>
                                {language.print}
                            </button>
                        </Col>
                        {saleReceipt.saleReceiptCanVoid && <Col>
                            <button
                                type="button"
                                className="btn btn-danger btn-block"
                                onClick={() => this.props.cancelSaleReceipt(saleReceipt.reference_code)}>
                                {language.cancel_bill}
                            </button>
                        </Col>}
                    </Row>
                </Col>
            </Row>
        )
    }
}

export default SaleReturnForm