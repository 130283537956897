import React from 'react'
import { Modal, ModalHeader, ModalBody, Button } from 'reactstrap'

class ConfirmSendTaxInvoice extends React.Component {
    render() {
        const { confirmSendTaxInvoice, closeConfirmTaxInvoice, language } = this.props
        const referenceCode = confirmSendTaxInvoice.reference_code
        return (
            <Modal isOpen={confirmSendTaxInvoice.showModal}
                toggle={closeConfirmTaxInvoice}
                style={{ maxWidth: '40%' }}>
                <ModalHeader toggle={closeConfirmTaxInvoice}>{language.confirm_tax_invoice}: {referenceCode}</ModalHeader>
                <ModalBody>
                    <div className="warning-img"></div>
                    <h5 className="text-center" style={{ color: '#ff6eaf', marginBottom: '10px' }}>{language.tax_invoice_no}: {referenceCode}</h5>
                    <div className="text-danger text-center" style={{ fontSize: '16px' }}>{language.tax_invoice_not_change}</div>
                    <div className="text-center mt-4">
                        <Button
                            color="danger"
                            className="mr-2"
                            onClick={closeConfirmTaxInvoice}>
                            {language.close}
                        </Button>
                        <Button
                            color="primary"
                            onClick={() => this.props.confirmTaxInvoiceSaleReceipt(referenceCode)}>
                            {language.confirm}
                        </Button>
                    </div>
                </ModalBody>
            </Modal>
        )
    }
}

export default ConfirmSendTaxInvoice