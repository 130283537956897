import React from 'react'
import { Row, Col } from 'reactstrap'
import moment from 'moment'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'

import InputFormGroup from 'Components/Input/InputFormGroup'
import SelectFromGroup from 'Components/Input/SelectFormGroup'

class CustomerInfoForm extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            nationality: [],
            sex: [],
            bloodType: [],
            priceLevel: [],
            date: moment()
        }
        this._onChange = this._onChange.bind(this)
        this._handleChangeDatePicker = this._handleChangeDatePicker.bind(this)
        this._onBlurDatePicker = this._onBlurDatePicker.bind(this)
    }

    _onChange(e) {
        const { name, value } = e.target
        this.props.setCustomerValue(name, value)
    }

    _handleChangeDatePicker(date, e) {
        if (date !== null) {
            const dateStr = date.format('YYYY-MM-DD')
            if (this._validDate(dateStr)) {
                this.setState({ date })
                this.props.setCustomerValue('birth_date', dateStr)
            }
        }
    }

    _validDate(value) {
        return (/(1[89]|2[0-6])\d{2}-(0?[1-9]|1[0-2])-(0?[1-9]|[12]\d|3[01]){1}$/).test(value)
    }
    
    _onBlurDatePicker(e) {
        const value = e.target.value
        if (this._validDate(value)) {
            const newDate = new Date(value)
            const year = moment(newDate).format('YYYY')
            const monthDay = moment(newDate).format('MM-DD')
            const yearConverted = year > moment().format('YYYY') ? String(Number(year) - 543) : year
            const date = moment(`${yearConverted}-${monthDay}`)
            this._handleChangeDatePicker(date)
        }
    }

    render() {
        const { language, customer, errors } = this.props
        const { data } = customer
        const { nationality, sex, bloodType, priceLevel, date } = this.state
        return (
            <Row>
                <Col md="6">
                    <InputFormGroup
                        label={language.name}
                        type="text"
                        name="first_name"
                        value={data.first_name}
                        onChange={this._onChange}
                        placeholder={language.name}
                    />
                    <InputFormGroup
                        label={language.last_name}
                        type="text"
                        name="last_name"
                        value={data.last_name}
                        onChange={this._onChange}
                        placeholder={language.last_name}
                    />
                    <div className="form-row">
                        <InputFormGroup md="6"
                            label={language.phone_number}
                            type="text"
                            name="mobile_number"
                            value={data.mobile_number}
                            onChange={this._onChange}
                            placeholder={language.phone_number}
                            error={errors.mobile_number}
                            isRequired
                        />
                        <InputFormGroup md="6"
                            label={language.email}
                            type="email"
                            name="email"
                            value={data.email}
                            onChange={this._onChange}
                            placeholder={language.email}
                            error={errors.email}
                        />
                    </div>
                    <div className="form-row">
                        <InputFormGroup md="6"
                            label={language.career}
                            type="text"
                            name="occupation"
                            value={data.occupation}
                            onChange={this._onChange}
                            placeholder={language.career}
                        />
                        <div className="form-group col-md-6">
                            <label>{language.birthday}:</label>
                            <DatePicker
                                className={`form-control ${errors.birth_date ? 'is-invalid' : ''}`}
                                selected={date}
                                onChange={this._handleChangeDatePicker}
                                dateFormat='YYYY-MM-DD'
                                placeholderText={language.birthday_field}
                                disabledKeyboardNavigation
                                maxDate={moment()}
                                value={data.birth_date}
                                onChangeRaw={(e) => {
                                    this.props.setCustomerValue('birth_date', e.target.value)
                                }}
                                onBlur={this._onBlurDatePicker}
                            />
                            {errors.birth_date &&<small className="text-danger">{errors.birth_date}</small>}
                        </div>
                    </div>
                    <div className="form-group">
                        <label>{language.note}:</label>
                        <textarea
                            className="form-control"
                            name="note"
                            value={data.note}
                            onChange={this._onChange}
                            placeholder={language.note}
                            rows="3"
                            maxlength="255"
                            >
                        </textarea>
                        <div className="text-right">
                            <small className="text-danger">{language.input_maxlength_warning_255}</small>
                        </div>
                    </div>
                </Col>
                <Col md="6">
                    <InputFormGroup
                        label={language.company_name}
                        type="text"
                        name="company_name"
                        value={data.company_name}
                        onChange={this._onChange}
                        placeholder={language.company_name}
                    />
                    <div className="form-row">
                        <InputFormGroup md="6"
                            label={language.contact_name}
                            type="text"
                            name="contact_name"
                            value={data.contact_name}
                            onChange={this._onChange}
                            placeholder={language.contact_name}
                        />
                        <InputFormGroup md="6"
                            label={language.contact_number}
                            type="text"
                            name="phone_number"
                            value={data.phone_number}
                            onChange={this._onChange}
                            placeholder={language.contact_number}
                        />
                    </div>
                    <div className="form-row">
                        <InputFormGroup md="7"
                            label={language.id_card}
                            type="text"
                            name="citizen_id"
                            value={data.citizen_id}
                            onChange={this._onChange}
                            placeholder={language.id_card_field}
                        />
                        <SelectFromGroup md="5"
                            label={language.nationality}
                            name="nationality"
                            value={data.nationality}
                            onChange={this._onChange}
                            placeholder={language.select_nationality}
                            options={nationality}
                            error={errors.nationality}
                        />
                    </div>
                    <div className="form-row">
                        <SelectFromGroup md="6"
                            label={language.gender}
                            name="sex"
                            value={data.sex}
                            onChange={this._onChange}
                            placeholder={language.select_gender}
                            options={sex}
                            error={errors.sex}
                        />
                        <SelectFromGroup md="6"
                            label={language.blood_type}
                            name="blood_type"
                            value={data.blood_type}
                            onChange={this._onChange}
                            placeholder={language.select_blood_type}
                            options={bloodType}
                        />
                    </div>
                    <SelectFromGroup
                        label={language.price_level}
                        name="price_level"
                        value={data.price_level}
                        onChange={this._onChange}
                        placeholder={language.select_price_level}
                        options={priceLevel}
                        error={errors.price_level}
                    />
                </Col>
            </Row>
        )
    }

    componentDidMount() {
        const { language, customer } = this.props
        const { data } = customer
        this.setState({
            nationality: [
                { id: 1, name: `${language.thai}`, value: 'Thai' },
                { id: 2, name: `${language.other}`, value: 'Other' }
            ],
            sex: [
                { id: 1, name: `${language.male}`, value: 'M' },
                { id: 2, name: `${language.female}`, value: 'F' }
            ],
            bloodType: [
                { id: 1, name: 'A', value: 'A' },
                { id: 2, name: 'B', value: 'B' },
                { id: 3, name: 'O', value: 'O' },
                { id: 4, name: 'AB', value: 'AB' }
            ],
            priceLevel: [
                { id: 1, name: `${language.price_company_setting} ${data.company_default_customer_retail_price_level ?
                `(level ${data.company_default_customer_retail_price_level})` : ''}`, value: '0' },
                { id: 2, name: `${language.price_level1}`, value: '1' },
                { id: 3, name: `${language.price_level2}`, value: '2' },
                { id: 4, name: `${language.price_level3}`, value: '3' },
                { id: 5, name: `${language.price_level4}`, value: '4' },
                { id: 6, name: `${language.price_level5}`, value: '5' }
            ]
        })
    }
}

export default CustomerInfoForm