import { FOCUS_SEARCH_PRODUCT
    , OPEN_SALE_RETURN
    , OPEN_AWAITING_CART
    , FOCUS_SEARCH_CUSTOMER } from '../actionTypes';

import { checkCouponBeforeSaveCart } from './awaitingCart'
import { isAble } from './shared'

export const showBillModal = () => dispatch =>{
    if (isAble('CREATE_SALES_RETURN')) {
        return dispatch({ type: OPEN_SALE_RETURN })
    }
}

export const pauseBill = () => (dispatch, getState) =>{
    const { reference_code } = getState().cart
    if (reference_code) {
        return dispatch(checkCouponBeforeSaveCart())
    }
}

export const showPauseBill = () => (dispatch, getState) =>{
    const { carts } = getState().awaitingCart
    if (carts.length > 0) {
        return dispatch({ type: OPEN_AWAITING_CART })
    }
}

export const focusSearchProduct = () => dispatch =>{
    return dispatch({
        type : FOCUS_SEARCH_PRODUCT
    })
}

export const focusSearchCustomer = () => dispatch =>{
    return dispatch({ type: FOCUS_SEARCH_CUSTOMER })
}

export const showIndexProductInCart = () => dispatch =>{
    console.log('showIndexProductInCart')
}

export const showErrorMsgModal = () => dispatch =>{
    console.log('showErrorMsgModal')
}

