const cityIds = [
    1, 5, 6, 7, 8, 9, 11, 12, 13, 14, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26,
    27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 39, 40, 41, 42, 45, 46, 47, 49,
    52, 53, 54, 55, 58, 60, 61, 63, 66, 67, 68, 71, 74, 79, 84, 97, 136, 139,
    142, 151, 152, 153, 160, 171, 178, 179, 181, 189, 202, 204, 206, 215, 222,
    235, 250, 253, 256, 259, 260, 273, 312, 326, 330, 346, 355, 393, 401, 402,
    421, 424, 431, 446, 448, 460, 461, 462, 477, 482, 491, 517, 529, 552, 568,
    569, 573, 574, 580, 581, 582, 586, 595, 597, 603, 616, 625, 628, 633, 657,
    678, 683, 713, 730, 736, 739, 748, 762, 777, 782, 787, 797, 805, 807, 809,
    812, 814, 820, 823, 833, 834, 836, 843, 844, 849, 864, 872, 880, 881, 884, 887, 905, 918,
    920, 921, 927, 928, 932, 936, 943, 945, 954, 965, 977, 978, 985, 994,
];

export default cityIds;
