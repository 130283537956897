import React from "react";

import DeliveryNoteReceipt from "./DeliveryNoteReceipt";

const PrintWrapper = (props) => {
    return (
        <div style={{ display: "none" }}>
            <DeliveryNoteReceipt {...props} />
        </div>
    );
};

export default PrintWrapper;
