const keyboardMap = new Map([
    [3592, 0],
    [3653, 1],
    [47, 2],
    [45, 3],
    [3616, 4],
    [3606, 5],
    [3640, 6],
    [3638, 7],
    [3588, 8],
    [3605, 9],
]);

const charTarget = ['จ', 'ๅ', '/', '-', 'ภ', 'ถ', 'ุ', 'ึ', 'ค', 'ต'];

export default str => {
    let result = '';

    for (let i = 0; i < str.length; i++) {
        if (charTarget.indexOf(str[i]) < 0) return str;
        const codePoint = str.codePointAt(i);
        const value = keyboardMap.get(codePoint);
        result += value;
    }

    return result;
};
