import React from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import ArincareClosePlatform from 'Images/arincare_will_close_platform.png';
import { dateFormat, timeFormat, DateTime } from '../Transform/DateTimeFormat';

class NotiWindowWillClosePlatform extends React.Component {
    constructor(props) {
        super(props);
        this.handleModal = this.handleModal.bind(this);
        this.state = {
            modal: true,
        }
    }

    handleModal() {
        this.setState({
            modal: !this.state.modal,
        })
    }

    notifyPlatform() {
        return (
            <>
                <link href="https://fonts.googleapis.com/css?family=Kanit" rel="stylesheet"/>
                <div>
                    <Modal isOpen={ this.state.modal } dataBackdrop="static" dataKeyboard="false">
                        <ModalHeader className="close-platform-header">
                            เเจ้งเตือนปิดปรับปรุงระบบชั่วคราว
                        </ModalHeader>
                        <ModalBody>
                            <div className="img-body-close-platform">
                                <img src={ ArincareClosePlatform } />
                            </div>
                            <div className="modal-body-close-platform-0">
                                ประกาศ ปิดปรับปรุงระบบชั่วคราว
                            </div>
                            <div className="modal-body-close-platform-1">
                                ตั้งเเต่เวลา { timeFormat(DateTime.startTime) } น. 
                                วันที่ { dateFormat(DateTime.startDate) }<br/>
                                ถึงเวลา  { timeFormat(DateTime.endTime) } น. 
                                วันที่ { dateFormat(DateTime.endDate) }
                            </div>
                            <div className="modal-body-close-platform-2">
                                เพื่อพัฒนาประสิทธิภาพการใช้งานให้ดีขึ้น<br/>
                                โดยในช่วงเวลาดังกล่าว <br/>
                                ระบบ Arincare &nbsp;
                                <span className="important-close-platform">
                                    จะไม่สามารถเข้าใช้งานได้ชั่วคราว
                                </span>
                            </div>
                            <div className="modal-body-close-platform-3">
                                ***ขออภัยในความไม่สะดวก ณ ที่นี้ค่ะ***
                            </div>
                        </ModalBody>
                        <ModalFooter className="modal-footer-close-platform">
                            <Button outline color="danger" onClick={ this.handleModal }>
                                รับทราบแล้ว
                            </Button>
                        </ModalFooter>
                    </Modal>
                </div>
            </>
        );      
    }
    render() {
        return (
            this.notifyPlatform()
        );
    }
}

export default NotiWindowWillClosePlatform;