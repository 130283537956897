import { REQUEST_LOGIN
    , RECEIVE_LOGIN
    , RECEIVE_COMPANIES
    , FAILURE_COMPANIES
    , LOGIN_ERROR
    , SET_CURRENT_PAGE } from '../actionTypes'
import axiosHelper from '../middleware/axios';
import { getSettings } from './settings'
import * as Sentry from '@sentry/browser'

let helper = new axiosHelper();

export const getUserLogin = data => dispatch => {
    dispatch({ type: REQUEST_LOGIN })
    return helper.post('/login', data, {}, 1).then(res => {
        dispatch({ type: RECEIVE_LOGIN, payload: res.token })
        dispatch(getCompany(res.token))
    }).catch((err) => {
        console.error(err);
        dispatch({ type: LOGIN_ERROR })
    })
}

export const getBranchLogin = data => dispatch => {
    dispatch({ type: REQUEST_LOGIN })
    return helper.post('/branches/login', {
        username: data.email.substring(2),
        password: data.password
    }, {}, 3).then(res => {
        if (res.code === '00') {
            dispatch({ type: RECEIVE_LOGIN, payload: res.token })

            const company = {
                slug: res.company_slug
            }

            const branch = {
                slug: res.branch_slug
            }

            dispatch(setupCompanyBranch(company, branch))
        } else if (res.code === '10') {
            dispatch({ type: LOGIN_ERROR })
        }
    }).catch((err) => {
        console.error(err);
        dispatch({ type: LOGIN_ERROR })
    })
}

export const getCompany = token => dispatch => {
    return helper.get('/companies', {}, {
        Authorization : `Bearer ${token}`
    }, 3).then(res => {
        return dispatch({ type: RECEIVE_COMPANIES, payload: res.companies })
    }).catch((err) => {
        console.error(err);
        return dispatch({ type: FAILURE_COMPANIES })
    })
}

export const setupCompanyBranch = (company, branch) => (dispatch, getState) => {
    const { token } = getState().login
    
    const settings = {
        company: {
            slug: company.slug
        },
        branch: {
            slug: branch.slug
        },
        token
    }

    Sentry.setTags({
        companyId: company.id,
        branchId: branch.id,
    })

    return dispatch(getSettings(settings)).then(res => {
        if (!res.settingFailed) {
            localStorage.setItem('permission', true)
            return dispatch(setCurrentPage(3))
        }
    })
}

export const setCurrentPage = pageNumber => dispatch => {
    dispatch({ type: SET_CURRENT_PAGE, payload: pageNumber })
}
