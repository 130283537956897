import { SELECT_RECEIPT
    , SEARCH_RECEIPTS
    , SYNC_SALE_RETURN
    , CLEAR_RECEIPT
    , CLOSE_SALE_RETURN
    , SET_RETURN_VALUE
    , CHANGE_DETAILS
    , CLICK_CHECKBOX_DETAIL } from '../actionTypes'
import { calculateSaleReturn } from '../actions/shared'

const initialState = {
    reference_code: null,
    company_id: null,
    branch_id: null,
    employee_id: null,
    member: {},
    member_id: null,
    current_status_id: 3,
    sales_receipt_id: null,
    tax_amount: 0,
    tax_types: null,
    tax_percentage: 0,
    net_amount: 0,
    total_amount: 0,
    total_discount_amount: 0,
    result_amount: 0,
    note: '',
    details: [],
    histories:[],
    abb: null,
    customer_id: null
}

export default (state=initialState, { type, payload }) => {
    let res
    let netAmount = 0
    switch (type) {
        case SELECT_RECEIPT:
            return Object.assign({}, state, payload)
        case CLOSE_SALE_RETURN:
        case CLEAR_RECEIPT:
        case SYNC_SALE_RETURN:
        case SEARCH_RECEIPTS:
            return initialState
        case SET_RETURN_VALUE: 
            return Object.assign({}, state, { [payload.path]: payload.value })
        case CHANGE_DETAILS: {
            const updateDetails = state.details.map(detail => {
                if (detail.id === payload.id) {
                    res = Object.assign({}, detail, { [payload.path]: payload.value })
                    res.net_amount = res.price_per_unit * res.quantity
                    return res
                }
                return detail
            })

            res = Object.assign({}, state, { details: updateDetails })
            for (let i in res.details) {
                const detail = res.details[i]
                detail.checked ? netAmount += detail.net_amount : 0
            }

            res.net_amount = netAmount
            res = calculateSaleReturn(res)
            return res
        }
        case CLICK_CHECKBOX_DETAIL: {
            const updateDetails = state.details.map(detail => {
                if (detail.id === payload.id) {
                    res = Object.assign({}, detail, { checked: payload.checked })
                    if (res.checked) {
                        res.quantity = res.remaining_quantity
                        res.net_amount = res.price_per_unit * res.quantity
                    } else {
                        res.quantity = 0
                        res.net_amount = 0
                    }
                    return res
                }
                return detail
            })

            res = Object.assign({}, state, { details: updateDetails })
            for (let i in res.details) {
                const detail = res.details[i]
                detail.checked ? netAmount += detail.net_amount : 0
            }

            res.net_amount = netAmount
            res = calculateSaleReturn(res)
            return res
        }
        default:
            return state
    }
}