import React from 'react'
import { Button, Modal, ModalHeader, ModalBody, Row, Col, Table } from 'reactstrap'

class SearchCustomerModal extends React.Component {
    render() {
        const { showSearchCustomers, language, closeSearchCustomers } = this.props
        const customerList = this.props.customers.map(customer => (
            <tr key={customer.id} className="text-center">
                <td>{customer.reference_code}</td>
                <td>{customer.name}</td>
                <td>
                    <Button color="info" onClick={() => this.props.selectCustomer(customer)}>{language.select}</Button>
                </td>
            </tr>
        ))
        return (
            <div>
                <Modal isOpen={showSearchCustomers}
                    toggle={closeSearchCustomers}
                    size="lg">
                    <ModalHeader toggle={closeSearchCustomers}>{language.search_customer}</ModalHeader>
                    <ModalBody>
                        <div className="product-search-result-container">
                            <div className="product-search-result-body">
                                <Table striped hover>
                                    <thead>
                                        <tr className="header-row">
                                            <td>{language.member_code}</td>
                                            <td>{language.member_name}</td>
                                            <td></td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {customerList}
                                    </tbody>
                                </Table>
                            </div>
                        </div>
                    </ModalBody>
                </Modal>
            </div>
        )
    }
}

export default SearchCustomerModal