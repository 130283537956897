import { SET_DETAIL_VALUE
, INITIAL_CART_DETAIL
, ON_BLUR_AMOUNT_PRODUCT } from '../actionTypes'

export const setDetailValue = (path,value) => dispatch => {
    return dispatch({
        type: SET_DETAIL_VALUE,
        payload: {
            path: path,
            value: value
        }
    })
}

export const initialDetail = () => dispatch => {
    return dispatch({
        type: INITIAL_CART_DETAIL,
        payload:''
    });
}

export const onBlurAmountProduct = () => dispatch => {
    return dispatch({
        type: ON_BLUR_AMOUNT_PRODUCT
    })
}