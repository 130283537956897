import React from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import ArincareWillLaunchNewFeature from 'Images/arincare_will_launch_new_feature.png';
import { dateFormat,  DateTime } from '../Transform/DateTimeFormat';
class NotiWindowUpdatePlatform extends React.Component {
    constructor(props) {
        super(props);
        this.handleModalNotifyUpdatePlatform = this.handleModalNotifyUpdatePlatform.bind(this);
        this.state = {
            handleModalNotifyUpdatePlatform: true,
        }
    }
    handleModalNotifyUpdatePlatform() {
        this.setState({
            handleModalNotifyUpdatePlatform: !this.state.handleModalNotifyUpdatePlatform,
        })
    }
    notifyUpdatePlatform() {
        const rawHTML = '<ul><li><strong>จัดการข้อมูลพิมพ์ฉลากยา ในหน้าหลังบ้าน (APP)</strong>  <br/>หัวข้อ ข้อมูลสินค้า > สำหรับพิมพ์</li><li><strong>จัดการข้อมูลพิมพ์ฉลากยา ในหน้าขาย (POS)</strong></li><li><strong>การพิมพ์ฉลากยาเปล่า ในหน้าหลังบ้าน (APP)</strong> <br/>หัวข้อ ข้อมูลสินค้า > จัดการฉลากยา</li></ul>';
        const updateFeatureLinkManual  = process.env.UPDATE_PLATFORM_LINK_MANUAL;
        let rawFeatureLinkManual = '';
        if(process.env.UPDATE_PLATFORM_LINK_MANUAL!=''){
            rawFeatureLinkManual = `<a href="`+updateFeatureLinkManual+`" target="_blank" title="คลิกเพื่อดูข้อมูลยา"> ***คู่มือการใช้งาน*** </a>`;
        }
        
        return (
            
            <>
                <link href="https://fonts.googleapis.com/css?family=Kanit" rel="stylesheet"/>
                <div>
                    <Modal isOpen={ this.state.handleModalNotifyUpdatePlatform } databackdrop="static" datakeyboard="false">
                        <ModalHeader className="close-platform-header">
                            แจ้งอัปเดต
                        </ModalHeader>
                        <ModalBody>
                            <div className="img-body-close-platform">
                                <img src={ ArincareWillLaunchNewFeature } />
                            </div>
                            <div className="modal-body-close-platform-0-orange">
                                แจ้งอัพเดทระบบ System Maintenance
                            </div>
                            <div className="modal-body-close-platform-1">
                                ในวันที่ { dateFormat(DateTime.startUpdateDate) }
                            </div>
                            <div className='modal-body-close-platform-2-left'>
                                หัวข้อที่อัปเดต มีดังนี้<br/>
                                <div dangerouslySetInnerHTML={{ __html: rawHTML }}></div>
                            </div>
                            <div className="modal-body-close-platform-3">
                                <div dangerouslySetInnerHTML={{ __html: rawFeatureLinkManual }}></div>
                            </div>
                        </ModalBody>
                        <ModalFooter className="modal-footer-close-platform">
                            <Button outline color="danger" onClick={ this.handleModalNotifyUpdatePlatform }>
                                รับทราบแล้ว
                            </Button>
                        </ModalFooter>
                    </Modal>
                </div>
            </>
        );      
    }
    render() {
        return (
            this.notifyUpdatePlatform()
        );
    }
}
export default NotiWindowUpdatePlatform;