import { SET_CURRENT_CUSTOMER
    , REMOVE_CUSTOMER
    , SET_CUSTOMER_VALUE
    , CLOSE_CUSTOMER_REGISTER
    , ADD_ALLERGY_INFO
    , REMOVE_ALLERGY_INFO
    , EDIT_INFO_CUSTOMER
    , ALLERGY_CHANGE
    , CHECKED_OUT
    , SAVE_CART
    , CONTINUE_CART } from '../actionTypes'

const initialState = {
    id: null,
    ingredient_allergies: [],
    name: '',
    price_level: 1,
    product_allergies: [],
    reference_code: null,
    retail_price_level: 1,
    wholesale_price_level: 1,
    data: {
        first_name: '',
        last_name: '',
        company_name: '',
        contact_name: '',
        phone_number: '',
        mobile_number: '',
        email: '',
        citizen_id: '',
        nationality: 'Thai',
        occupation: '',
        birth_date: '',
        sex: '',
        blood_type: '',
        note: '',
        price_level: '0',
        allergic_to_products: [],
        allergic_to_ingredients: [],
        customer_diseases: []
    }
}

export default (state=initialState, { type, payload }) => {
    switch (type) {
        case SET_CURRENT_CUSTOMER:
            return Object.assign({}, state, payload)
        case CHECKED_OUT:
        case SAVE_CART:
        case REMOVE_CUSTOMER:
            return initialState
        case SET_CUSTOMER_VALUE:
            return Object.assign({}, state, { data: {...state.data, [payload.path]: payload.value} })
        case CLOSE_CUSTOMER_REGISTER:
            return Object.assign({}, state, { data: initialState.data })
        case ADD_ALLERGY_INFO:
            return Object.assign({}, state, {
                data: {
                    ...state.data,
                    [payload.path]: [...state.data[payload.path], payload.allergic]
                }
            })
        case REMOVE_ALLERGY_INFO:
            return Object.assign({}, state, {
                data: {
                    ...state.data,
                    [payload.path]: state.data[payload.path].filter((item, idx) => payload.index !== idx)
                }
            })
        case EDIT_INFO_CUSTOMER:
            return Object.assign({}, state, {
                data: {
                    ...payload,
                    price_level: payload.retail_price_level
                }
            })
        case ALLERGY_CHANGE: {
            const newAllergy = state.data[payload.path].map((allergy, idx) => {
                if (payload.index !== idx) return allergy
                return {...allergy, [payload.key]: payload.value}
            })

            return Object.assign({}, state, {
                data: {
                    ...state.data,
                    [payload.path]: newAllergy
                }
            })
        }
        case CONTINUE_CART: {
            if (payload.member) {
                return Object.assign({}, state, payload.member)
            }
        }   
        default:
            return state
    }
}