import { SWITCH_LANGUAGE } from '../actionTypes'
import language from '../localize'

let languageDefault = 'th'
const getLanguage = localStorage.getItem('language')

if (getLanguage) {
    languageDefault = getLanguage
}

export default (state=language[languageDefault], { type, payload }) => {
    switch (type) {
        case SWITCH_LANGUAGE:
            return language[payload]
        default:
            return state
    }
}