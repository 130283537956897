import React from 'react'
import { ButtonGroup, Button, Row, Col } from 'reactstrap'

class TransferPage extends React.Component {

    render() {
        const { bankAccounts: bAcc, setCartValue, cart} = this.props;
        const bankAccounts = bAcc.map((b,index) => {
            return(
                <Button 
                    key={'bank_account_'+ b.id}
                    outline
                    style={{ height: '130px' }}
                    onClick={() => { setCartValue('bank_account_id',b.id) }}
                    active={ cart.bank_account_id === b.id }>
                    <Row>
                        <Col sm={4}>
                            <h4>{b.bank.name}</h4>
                            <span>{b.name}</span>
                        </Col>
                        <Col sm={8}><h2>{b.number}</h2></Col>
                    </Row>
                </Button>
            )
        })
        return (
            <div className='transferPage'>
                <ButtonGroup 
                    vertical 
                    style={{ width: '100%', marginBottom: '15px' }}>
                    { bankAccounts }
                </ButtonGroup>
            </div>
        )
    }
}

export default TransferPage