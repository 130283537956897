import React from 'react';
import { Modal, ModalBody } from 'reactstrap';

class ImageLightBox extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLandscape: false,
        };
        this._onImageLoad = this._onImageLoad.bind(this);
    }

    _onImageLoad({ target: img }) {
        this.setState({ isLandscape: img.offsetWidth > img.offsetHeight });
    }

    render() {
        const { open, src } = this.props;
        return (
            <Modal
                isOpen={open}
                toggle={this.props.close}
                size={this.state.isLandscape ? 'lg' : 'md'}
                contentClassName="chat-widget_lightbox__content"
                zIndex={99999}
            >
                <ModalBody className="chat-widget_lightbox__body">
                    <button
                        type="button"
                        className="close chat-widget_lightbox__close"
                        onClick={this.props.close}
                    >
                        <span>&times;</span>
                    </button>
                    {src && (
                        <img
                            src={src}
                            className="col-md-12 img-responsive"
                            onLoad={this._onImageLoad}
                        />
                    )}
                </ModalBody>
            </Modal>
        );
    }

    componentWillReceiveProps(nextProps) {
        if (!nextProps.open) this.setState({ isLandscape: false });
    }
}

export default ImageLightBox;
