import React from 'react'
import { Dropdown, DropdownToggle, DropdownMenu, Button } from 'reactstrap'
import { Scrollbars } from 'react-custom-scrollbars'

class SyncErrorNotification extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            dropdownOpen: false
        }
        this._toggle = this._toggle.bind(this)
        this._syncAgain = this._syncAgain.bind(this)
        this._continueSyncError = this._continueSyncError.bind(this)
        this._openServiceDeliverySearchHistory = this._openServiceDeliverySearchHistory.bind(this)
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.errors.length === 0) {
            this.setState({ dropdownOpen: false })
        }
    }

    _toggle() {
        this.setState({ dropdownOpen: !this.state.dropdownOpen })
    }

    _syncAgain(referenceCode) {
        this.props.syncAgain(referenceCode)
        this.setState({ dropdownOpen: false })
    }

    _continueSyncError(cart) {
        this.props.continueSyncError(cart)
        this.setState({ dropdownOpen: false })
    }

    _openServiceDeliverySearchHistory() {
        this.props.openServiceDeliverySearchHistory()
        this.setState({ dropdownOpen : false })
    }

    render() {
        const { language, errors } = this.props
        const errorList = this.props.errors
        .sort((a, b) => new Date(b.sync_at) - new Date(a.sync_at))
        .map(cart => {
            return (
                <li key={cart.reference_code}>
                    <div className="error-message">
                        <div className="error-message-body">
                            <div className="d-flex justify-content-between">
                                <h5 className="text-primary" style={{ fontSize: '14px' }}>{cart.reference_code}</h5>
                                <span className="text-muted" style={{ fontSize: '12px' }}>{cart.sync_at}</span>
                            </div>
                            <span
                                dangerouslySetInnerHTML={{ __html: cart.message.replace(/\n/g, '<br>') }}
                                className="text-muted d-block mb-1"
                                style={{ fontSize: '14px' }}
                            />
                            {cart.error_type && cart.error_type === 'service_delivery' ? (
                                    <Button
                                        color="primary"
                                        size="sm"
                                        className="mr-1"
                                        onClick={this._openServiceDeliverySearchHistory}
                                        outline
                                    >
                                        <i className="fa fa-pencil"></i> {language.edit}
                                    </Button>
                                ) : (
                                    <React.Fragment>
                                        {cart.old_status_id !== 2 && (
                                            <Button
                                                color="primary"
                                                size="sm"
                                                className="mr-1"
                                                onClick={() => this._syncAgain(cart.reference_code)}
                                                outline
                                            >
                                                <i className="fa fa-refresh"></i> {language.sync_again}
                                            </Button>
                                        )}
                                        <Button
                                            color="success"
                                            size="sm"
                                            className="mr-1"
                                            onClick={() => this._continueSyncError(cart.reference_code)}
                                            outline
                                        >
                                            <i className="fa fa-caret-right"></i> {language.sell_again}
                                        </Button>
                                        <Button
                                            color="danger"
                                            size="sm"
                                            onClick={() => this.props.deleteSyncError(cart.reference_code)}
                                            outline
                                        >
                                            <i className="fa fa-trash"></i> {language.delete}
                                        </Button>
                                    </React.Fragment>
                                )}
                        </div>
                    </div>
                </li>
            )
        })

        return (
            <Dropdown
                isOpen={this.state.dropdownOpen}
                toggle={this._toggle}
                className="error-message-notify">
                <DropdownToggle style={{padding : 0}}>
                    <div className='link mr-2 shadow-box' style={{padding:8}}>
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M12.0469 5.64708C12.3789 5.64708 13.1677 5.85703 13.1677 5.85703C15.3047 6.34673 16.8089 8.31511 16.8089 10.5375V16.5193L17.2458 16.9583L17.6108 17.325H6.38916L6.75422 16.9583L7.19114 16.5193V10.5375C7.19114 8.31516 8.69531 6.34673 10.8323 5.85703C10.8323 5.85703 11.6781 5.64708 11.9531 5.64708M12 2.25C11.1749 2.25 10.5441 2.88366 10.5441 3.7125V4.39495C7.77778 5.02884 5.69114 7.56375 5.69114 10.5375V15.9L3.75 17.85V18.825H20.25V17.85L18.3089 15.9V10.5375C18.3089 7.56375 16.2222 5.02884 13.4559 4.39495V3.7125C13.4559 2.88366 12.8251 2.25 12 2.25ZM13.9411 19.8H10.0588C10.0588 20.8725 10.9322 21.75 12 21.75C13.0678 21.75 13.9411 20.8725 13.9411 19.8Z" fill="#454545"/>
                        </svg>
                    </div>

                    <div className="notify">
                        <span className="point">!</span>
                    </div>
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-lg border-0 p-0" right>
                    <div className="dropdown-header">{language.notifications}</div>
                    <div className="dropdown-body">
                        <Scrollbars style={{ height: 275 }}>
                            <ul className="list-unstyled">
                                {errorList}
                            </ul>
                        </Scrollbars>
                    </div>
                </DropdownMenu>
            </Dropdown>
        )
    }
}

export default SyncErrorNotification