import React from 'react';

class ImageContainer extends React.Component {
    state = {
        status: 'loading',
    };

    onLoad = (e) => {
        if (this.state === 'loaded') return;
        this.setState({ status: 'loaded' });
    };

    onError = (e) => {
        this.setState({ status: 'error' });
    };

    render() {
        const { status } = this.state;
        return (
            <React.Fragment>
                <img
                    style={{
                        opacity: status === 'loaded' ? 1 : 0,
                        transition: 'opacity 1s',
                    }}
                    onLoad={this.onLoad}
                    onError={this.onError}
                    {...this.props}
                />
                {status === 'loading' && (
                    <div
                        className="font-weight-bold"
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            minWidth: 190,
                            minHeight: 180,
                        }}
                    >
                        กำลังโหลดรูปภาพ
                    </div>
                )}
                {status === 'error' && (
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            minWidth: 190,
                            minHeight: 100,
                        }}
                    >
                        ไม่สามารถโหลดภาพได้
                    </div>
                )}
            </React.Fragment>
        );
    }
}

export default ImageContainer;
