import { connect } from 'react-redux';
import { Button, Card, Table } from 'reactstrap';
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { notify } from 'reapop';
import moment from 'moment';
import Pagination from 'react-js-pagination';
import { openServicesModal } from 'Redux/actions';
import { ServiceDeliveryProviders, ServiceDeliveryStatuses, ServicesModalScreen } from './constants';

const API3_URL = process.env.API3_URL;

const DeliveryList = (props) => {
    const { dispatch, language, settings, filter } = props;

    const [list, setList] = useState(null);
    const [page, setPage] = useState(1);

    useEffect(() => {
        getList();
    }, [filter, page]);

    const getList = async () => {
        try {
            const response = await axios({
                method: 'GET',
                url: `${API3_URL}/companies/${settings.company.slug}/branches/${settings.branch.slug}/service-delivery`,
                headers: { 'Authorization': `Bearer ${settings.token}` },
                params: {
                    ...filter,
                    page,
                },
            });

            setList(response.data);
        } catch (error) {
            console.error(error);
            dispatch(notify({
                id: 'delivery',
                title: 'Service Delivery',
                message: 'โหลดออเดอร์ไม่สำเร็จ',
                status: 'error',
                dismissible: true,
                dismissAfter: 5000,
            }));
        }
    };

    const handleChangePage = (page) => {
        setPage(page);
    };

    const handleOpenOrder = (orderId) => () => {
        dispatch(openServicesModal(ServicesModalScreen.FOODPANDA, orderId));
    };

    const renderItem = (order) => {
        const provider = ServiceDeliveryProviders.find((provider) => provider.id === order.provider_id);
        const status = ServiceDeliveryStatuses.find((status) => status.id === order.status);
        return (
            <tr key={order.id}>
                <td>{moment(order.created_at).format('DD/MM/YYYY HH:mm:ss')}</td>
                <td>{order.reference_code}</td>
                <td>{order.receiver_first_name} {order.receiver_last_name}</td>
                <td className="text-center">
                    <Button
                        size="sm"
                        color={status.color || 'success'}
                        style={{
                            minWidth: 100,
                            padding: '0 5px',
                            color: '#fff',
                            opacity: 1,
                        }}
                        disabled
                    >
                        {status.name}
                    </Button>
                </td>
                <td>
                    <a href="#" onClick={handleOpenOrder(order.id)}>
                        ดูรายละเอียด
                    </a>
                </td>
            </tr>
        );
    };

    return (
        <>
            <Card>
                <Table style={{ fontSize: '80%' }}>
                    <thead>
                        <tr>
                            <th>วันที่สร้างออเดอร์</th>
                            <th>รหัสรายการสินค้า</th>
                            <th>ชื่อผู้รับ</th>
                            <th className="text-center">สถานะ</th>
                            <th />
                        </tr>
                    </thead>
                    <tbody>
                        {list && list.data.map(renderItem)}
                        {list && !list.data.length && (
                            <tr>
                                <td colSpan={99} className="text-center">ไม่มีข้อมูล</td>
                            </tr>
                        )}
                        {!list && (
                            <tr>
                                <td colSpan={99} className="text-center">กำลังโหลด...</td>
                            </tr>
                        )}
                    </tbody>
                </Table>
            </Card>

            {list && (
                <Pagination
                    activePage={list.current_page}
                    itemsCountPerPage={list.per_page}
                    pageRangeDisplayed={list.last_page}
                    totalItemsCount={list.total}
                    onChange={handleChangePage}
                    innerClass="pagination"
                    itemClass="page-item"
                    linkClass="page-link"
                    prevPageText="Prev"
                    nextPageText="Next"
                />
            )}
        </>
    );
};

const mapStateToProps = state => ({
    settings: state.settings,
    language: state.language,
});

export default connect(mapStateToProps)(DeliveryList);

