import React from 'react';


const QRHeader = (props) => {

    return(
        <div className="qr-header" style={{textAlign: props.align}}>
            <img src={props.logo} alt="" width={props.width}/>
        </div>
    )

}

export default QRHeader;