export const PRESCRIPTION_OPEN_PICKUP_MODAL = 'PRESCRIPTION_OPEN_PICKUP_MODAL';
export const PRESCRIPTION_CLOSE_PICKUP_MODAL = 'PRESCRIPTION_CLOSE_PICKUP_MODAL';
export const PRESCRIPTION_SET_DATA = 'PRESCRIPTION_SET_DATA';
export const PRESCRIPTION_CLEAR_DATA = 'PRESCRIPTION_CLEAR_DATA';
export const PRESCRIPTION_OPEN_CANCEL_MODAL = 'PRESCRIPTION_OPEN_CANCEL_MODAL';
export const PRESCRIPTION_CLOSE_CANCEL_MODAL = 'PRESCRIPTION_CLOSE_CANCEL_MODAL';
export const PRESCRIPTION_OPEN_LIST_MODAL = 'PRESCRIPTION_OPEN_LIST_MODAL';
export const PRESCRIPTION_CLOSE_LIST_MODAL = 'PRESCRIPTION_CLOSE_LIST_MODAL';
export const PRESCRIPTION_OPEN_INFO_MODAL = 'PRESCRIPTION_OPEN_INFO_MODAL';
export const PRESCRIPTION_CLOSE_INFO_MODAL = 'PRESCRIPTION_CLOSE_INFO_MODAL';
export const PRESCRIPTION_SET_INBOX_COUNT_MODAL = 'PRESCRIPTION_SET_INBOX_COUNT_MODAL';
export const PRESCRIPTION_SET_PICKUP_NOTE = 'PRESCRIPTION_SET_PICKUP_NOTE';
export const PRESCRIPTION_SET_UNIT = 'PRESCRIPTION_SET_UNIT';
export const PRESCRIPTION_SET_DETAIL_STATUS_TYPE = 'PRESCRIPTION_SET_DETAIL_STATUS_TYPE';
export const SHOW_NOTI_PRESCRIPTION = 'SHOW_NOTI_PRESCRIPTION';
export const CLOSE_NOTI_PRESCRIPTION = 'CLOSE_NOTI_PRESCRIPTION';