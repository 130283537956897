import React from 'react';
import {  FormGroup,
    Input,
    Row,
    Col
} from 'reactstrap';
import Currency from 'react-currency-formatter';

import SelectFromGroup from 'Components/Input/SelectFormGroup';

import {feeIncludeType} from 'Utilz/shared';

class AlipayWechatPage extends React.Component {
    _displayPrice(price) {
        return Number(price).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
    }

    render() {
        const { language, cart, merchant, setCartValue, onCreditChange} = this.props
        const qrTypeName = {
            thai_qr: 'K PLUS Shop',
            alipay: 'Alipay',
            wechat_pay: 'WeChat Pay',
        }
        const lang = localStorage.getItem('language') || 'th'

        return (
            <div className="qr-payment-container">
                <div className="header-shop">
                    <h5 className="shop-name">ชื่อร้านค้า: {merchant ? merchant.shop[`shop_name_${lang}`] : 'Unknown'}</h5>
                    <h5>Merchant ID: {merchant ? merchant.id : 'Unknown'}</h5>
                </div>
                <div className="highlight">
                    <Row>
                        <Col md={4} className={'text-right'}>
                            {`${language[`fee_${cart.payment_types}`]} : `}
                        </Col>
                        <Col md={8}>
                            <SelectFromGroup
                                value={cart.credit_include}
                                onChange={onCreditChange}
                                placeholder={language.credit_include}
                                options={feeIncludeType}
                            />
                        </Col>
                        <Col md={4} className={'text-right'}>Fee Rate 3% : </Col>
                        <Col md={8} className={'text-left'}><Currency quantity={ Number(cart.credit_charge) } currency='THB' symbol=''></Currency></Col>
                    </Row>
                    <h4 className="highlight-header">{language.net_payment_summary}</h4>
                    <h1>{this._displayPrice(cart.total_paid_amount)} {language.baht}</h1>
                    <h4>{language[`scan_code_${cart.payment_types}_user`]}</h4>
                    <FormGroup>
                        <Input onChange={(e) => {setCartValue('buyer_auth_code', e.target.value)}} 
                        placeholder={language[`scan_code_${cart.payment_types}_user`]} 
                        value={cart.buyer_auth_code}/>
                    </FormGroup>
                </div>
                <div className="footer-shop">
                    <strong className="text-danger">{language.check_payment_info} {qrTypeName[cart.payment_types]} {language.correct_info}</strong>
                </div>
            </div>
        )
    }
}

export default AlipayWechatPage