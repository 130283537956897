import React from 'react'
import { Route, Redirect } from 'react-router-dom'

const PrivateRoute = ({ component: Component, ...rest }) => {
    const authenticated = localStorage.getItem('permission')
    return (
        <Route
            {...rest}
            render={props =>
            authenticated
            ? <Component {...props} />
            : <Redirect to={{ pathname: '/login' }} />}
    />
    )
}

export default PrivateRoute