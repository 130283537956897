import Dexie from 'dexie'
import db from '../../utils/db'
import store from '../store'
import { OPEN_NOTI_REFRESH } from '../actionTypes'

export const saveCartToIndexedDB = (cart) => {
    
    return new Promise(async (resolve, reject) => {
        
        try {
            const { reference_code } = cart;
            if (await db.table('carts').where({ reference_code }).count() === 0) {
                await db.table('carts').add(cart);
            } else {
                await db.table('carts').update(reference_code, cart);
            }
            
            resolve(true);
        } catch (err) {
            if (err.name == 'ConstraintError' && err.message == 'Key already exists in the object store.') {
                const { reference_code } = cart;
                await db.table('carts').update(reference_code, cart);

                resolve(true);
            } else if (process.env.ENABLE_NOTIFY_INDEXEDDB_ERROR === 'true') {
                store.dispatch({
                    type: OPEN_NOTI_REFRESH,
                    payload: {
                        type: 'indexeddb_error',
                        code: '#21'
                    }
                });

                console.error(err);
            } else {
                console.error(err);
            }
        }
    })
}

export const handleIndexedDBError = (event) => {
    const reason = event.reason;
    if (reason && reason instanceof Dexie.DexieError) {
        console.error(reason);
    }
}
