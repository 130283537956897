import React from 'react';
import { Button, ButtonGroup, Col, Modal, ModalBody, ModalHeader, Nav, NavItem, NavLink, Row, TabContent, Table, TabPane } from 'reactstrap';
import moment from 'moment';
import ACTable from 'Components/DataGrid/Table';
import classnames from 'classnames';
import CustomerEncounter from '../Form/CustomerEncounter';
import CustomerRegisterForm from 'Components/Form/CustomerRegisterForm';

class CustomerInfo extends React.Component {
    state = {
        activeTab: '1',
        duration: 1,
        headers: [],
        mode: 'show', //show, edit
    };

    componentWillReceiveProps(nextProps) {
        if (!nextProps.showCustomerInfo) {
            this.setState({
                activeTab: '1',
                duration: 1,
            });
        }
    }

    _getSalesOrders = (duration) => {
        this.setState({ duration });
        this.props.getSalesOrders(duration);
    };

    _switchLangauge(language) {
        const { getTableHeader } = this.props;
        const headers = [getTableHeader('', 'name', 'text', 8), getTableHeader(`${language.recent_purchase}`, 'last_purchase_at', 'text-center', 4)];
        this.setState({ headers: headers });
    }

    componentDidUpdate(prevProps) {
        const { language } = this.props;
        const { language: wasLanguage } = prevProps;

        if (language !== wasLanguage) {
            this._switchLangauge(language);
        }

    }

    componentDidMount() {
        const { language } = this.props;
        this._switchLangauge(language);
    }

    toggle(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab,
            });
        }
    }

    handleEditCustomer = () => {
        const { editInfoCustomer, customerInfo } = this.props;
        const { customer } = customerInfo;

        editInfoCustomer(customer);

        this.setState({
            mode: 'edit',
        });
    };

    onSaveCustomer = (data) => {
        const { registerCustomer, closeCustomerRegister, getCustomerInfo } = this.props;
        return registerCustomer(data).then(() => {
            getCustomerInfo().then(() => {
                closeCustomerRegister();
                this.setState({
                    mode: 'show',
                });
            });
        });
    };

    onCancelEditCustomer = () => {
        const { closeCustomerRegister } = this.props;
        closeCustomerRegister();
        this.setState({
            mode: 'show',
        });
    };

    onClose = () => {
        const { closeCustomerInfo, closeCustomerRegister } = this.props;

        closeCustomerInfo();

        setTimeout(() => {
            closeCustomerRegister();
            this.setState({
                mode: 'show',
            });
        }, 500);
    };

    render() {
        const { showCustomerInfo, language, customerInfo } = this.props;
        const { mode } = this.state;
        const { customer, sales_orders } = customerInfo;
        const productAllergicList = customer.allergic_to_products.map((item, index) => <li key={index}>{item.product_name}</li>);
        const genericNameList = customer.allergic_to_ingredients.map((item, index) => <li key={index}>{item.ingredient_name}</li>);
        const customerDiseases = customer.customer_diseases.map((item, index) => <li key={index}>{item.disease_name}</li>);
        const salesOrdersList = sales_orders.map((order, index) => {
            const createdAt = moment(order.created_at).format('YYYY-M-D');
            return (
                <tr key={index}>
                    <td style={{ textAlign: 'left', width: '13%' }}>{createdAt}</td>
                    <td style={{ textAlign: 'left', width: '23%' }}>{order.reference_code}</td>
                    <td style={{ textAlign: 'left' }}>{order.product_name}</td>
                    <td style={{ textAlign: 'right', width: '10%' }}>{order.sales_quantity}</td>
                    <td style={{ textAlign: 'center' }}>{order.unit_name}</td>
                    <td style={{ textAlign: 'right', width: '20%' }}>{order.price_per_unit}</td>
                </tr>
            );
        });
        const noSalesOrders = (
            <tr className="text-center">
                <td colSpan="6">{language.no_purchase_history}</td>
            </tr>
        );

        return (
            <Modal
                className="new-modal"
                isOpen={showCustomerInfo}
                toggle={this.onClose}
                size="lg"
                style={{ maxWidth: '100%' }}
            >
                <ModalHeader className="bg-primary" toggle={this.onClose}>
                    {language.member_profile}: {customer.reference_code} | {customer.name}
                </ModalHeader>

                <Nav className="bg-primary" tabs>
                    <NavItem>
                        <NavLink
                            className={classnames({ active: this.state.activeTab === '1' })}
                            onClick={() => {
                                this.toggle('1');
                            }}
                            style={{ cursor: 'pointer' }}
                        >
                            {language.customerSummary}
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            className={classnames({ active: this.state.activeTab === '2' })}
                            onClick={() => {
                                this.toggle('2');
                            }}
                            style={{ cursor: 'pointer' }}
                        >
                            {language.customerEncounter}
                        </NavLink>
                    </NavItem>
                </Nav>

                <ModalBody>
                    <TabContent activeTab={this.state.activeTab}>
                        <TabPane tabId="1">
                            {mode === 'show' ? (
                                <>
                                    {mode === 'show' && (
                                        <div className="text-right">
                                            <Button
                                                className="mb-3"
                                                color="warning"
                                                type="button"
                                                onClick={this.handleEditCustomer}
                                            >
                                                {language.edit}
                                            </Button>
                                        </div>
                                    )}
                                    <div className="card member-info">
                                        <h6 className="card-header">{language.general_info}</h6>
                                        <div className="card-body">
                                            <Row>
                                                <Col md="8">
                                                    <strong>{language.name}:</strong>
                                                    <span>{customer.name}</span>
                                                </Col>
                                                <Col md="4">
                                                    <strong>{language.reference_code}:</strong>
                                                    <span>{customer.reference_code}</span>
                                                </Col>
                                            </Row>
                                            <Row className="mt-2">
                                                <Col md="8">
                                                    <strong>{language.company_name}:</strong>
                                                    <span>{customer.company_name}</span>
                                                </Col>
                                                <Col md="4">
                                                    <strong>{language.contact_name}:</strong>
                                                    <span>{customer.contact_name}</span>
                                                </Col>
                                            </Row>
                                            <Row className="mt-2">
                                                <Col md="4">
                                                    <strong>{language.phone_number}:</strong>
                                                    <span>{customer.mobile_number}</span>
                                                </Col>
                                                <Col md="4">
                                                    <strong>{language.contact_number}:</strong>
                                                    <span>{customer.phone_number}</span>
                                                </Col>
                                                <Col md="4">
                                                    <strong>{language.career}:</strong>
                                                    <span>{customer.occupation}</span>
                                                </Col>
                                            </Row>
                                            <Row className="mt-2">
                                                <Col md="4">
                                                    <strong>{language.nationality}:</strong>
                                                    <span>
                                                {!customer.nationality ?
                                                    '' :
                                                    customer.nationality === 'Thai' ?
                                                    language.thai :
                                                    language.other}
                                            </span>
                                                </Col>
                                                <Col md="4">
                                                    <strong>{language.birthday}:</strong>
                                                    <span>{customer.birth_date}</span>
                                                </Col>
                                                <Col md="4">
                                                    <strong>{language.gender}:</strong>
                                                    <span>
                                                {!customer.sex ?
                                                    '' :
                                                    customer.sex === 'M' ?
                                                    language.male :
                                                    language.female}
                                            </span>
                                                </Col>
                                            </Row>
                                            <Row className="mt-2">
                                                <Col md="4">
                                                    <strong>{language.blood_type}:</strong>
                                                    <span>{customer.blood_type}</span>
                                                </Col>
                                                <Col md="8">
                                                    <strong>{language.price_level}:</strong>
                                                    <span>
                                                {customer.retail_price_level == 0 ?
                                                    customer.company_default_customer_retail_price_level :
                                                    customer.retail_price_level}
                                            </span>
                                                </Col>
                                            </Row>
                                        </div>
                                    </div>
                                    <div className="card member-medical">
                                        <h6 className="card-header">{language.medical_info}</h6>
                                        <div className="card-body">
                                            <Row>
                                                <Col md="4">
                                                    <strong className="text-danger">{language.product_allergic}</strong>
                                                    <ul>
                                                        {productAllergicList}
                                                    </ul>
                                                </Col>
                                                <Col md="4">
                                                    <strong className="text-danger">{language.generic_name}</strong>
                                                    <ul>
                                                        {genericNameList}
                                                    </ul>
                                                </Col>
                                                <Col md="4">
                                                    <strong className="text-danger">{language.customer_diseases}</strong>
                                                    <ul>
                                                        {customerDiseases}
                                                    </ul>
                                                </Col>
                                            </Row>
                                        </div>
                                    </div>
                                    <Row>
                                        <Col style={{ display: 'table', paddingBottom: '10px', 'borderCollapse': 'seprate', 'borderSpacing': '5px' }}>
                                            <div style={{ display: 'table-cell', width: '50%' }} className="card member-note">
                                                <div>
                                                    <h6 className="card-header">{language.note}</h6>
                                                    <div className="card-body">{customer.note}</div>
                                                </div>
                                            </div>
                                            <div style={{ display: 'table-cell', width: '50%' }} className="card member-top5">
                                                <div>
                                                    <h6 className="card-header">{language.top5_bougth_products}</h6>
                                                    <div className="card-body">
                                                        <ACTable datas={customer.top_5_products}
                                                                 headers={this.state.headers}
                                                                 notFoundTxt={language.not_found_product}
                                                                 onClickRow={() => {
                                                                 }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                    <div className="card member-history">
                                        <div className="card-header" style={{ padding: '6px 1.25rem' }}>
                                            <h6 className="float-left" style={{ marginTop: '7px', marginBottom: 0 }}>{language.purchase_history}</h6>
                                            <div className="float-right">
                                                <ButtonGroup size="sm">
                                                    <Button
                                                        color="warning"
                                                        onClick={() => this._getSalesOrders(1)}
                                                        active={this.state.duration === 1}>1 {language.month}</Button>
                                                    <Button
                                                        color="warning"
                                                        onClick={() => this._getSalesOrders(3)}
                                                        active={this.state.duration === 3}>3 {language.month}</Button>
                                                    <Button
                                                        color="warning"
                                                        onClick={() => this._getSalesOrders(6)}
                                                        active={this.state.duration === 6}>6 {language.month}</Button>
                                                    <Button
                                                        color="warning"
                                                        onClick={() => this._getSalesOrders(12)}
                                                        active={this.state.duration === 12}>1 {language.year}</Button>
                                                </ButtonGroup>
                                            </div>
                                        </div>
                                        <Table className="member-history-table">
                                            <thead>
                                                <tr>
                                                    <th style={{ textAlign: 'left' }}>{language.purchase_date}</th>
                                                    <th style={{ textAlign: 'left' }}>{language.receipt_no}</th>
                                                    <th style={{ textAlign: 'left' }}>{language.product}</th>
                                                    <th style={{ textAlign: 'right' }}>{language.quantity}</th>
                                                    <th style={{ textAlign: 'center' }}>{language.unit}</th>
                                                    <th style={{ textAlign: 'right' }}>{language.price_sale}/{language.unit}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {sales_orders.length > 0 ? salesOrdersList : noSalesOrders}
                                            </tbody>
                                        </Table>
                                    </div>
                                </>
                            ) : (
                                <CustomerRegisterForm
                                    language={language}
                                    setCustomerValue={this.props.setCustomerValue}
                                    customer={this.props.customer}
                                    addAllergyInfo={this.props.addAllergyInfo}
                                    removeAllergyInfo={this.props.removeAllergyInfo}
                                    getAllergy={this.props.getAllergy}
                                    clearAllergy={this.props.clearAllergy}
                                    handleAllergyChange={this.props.handleAllergyChange}
                                    onSubmit={this.onSaveCustomer}
                                    onCancel={this.onCancelEditCustomer}
                                />
                            )}

                        </TabPane>

                        <TabPane tabId="2">
                            <CustomerEncounter active={this.state.activeTab === '2'} />
                        </TabPane>
                    </TabContent>
                </ModalBody>
            </Modal>
        );
    }
}

export default CustomerInfo;
