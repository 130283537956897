import React from 'react';
import { Button, Table, Tooltip } from 'reactstrap';
import { connect } from 'react-redux';
import { searchProducts, setPrescriptionUnit, displayPriceEp } from 'Redux/actions';

const wikiUrl = process.env.WIKI_URL;

class PrescriptionCartInfo extends React.Component {
    state = {
        tooltipOpen: false,
    };

    isItemHavingInCart = (epsDetail) => {
        const { srDetails } = this.props;
        return srDetails.some((srDetail) => {
            return (epsDetail.product && epsDetail.product.id === srDetail.product.id)
                || epsDetail.ingredient && srDetail.product.ingredients.some((ingredient) => ingredient.id === epsDetail.ingredient.id);
        });
    };

    searchProducts = (epsDetail) => () => {
        const { searchProducts, setPrescriptionUnit } = this.props;
        setPrescriptionUnit(epsDetail.unit_id, epsDetail.id);
        if (epsDetail.product) {
            searchProducts(epsDetail.product.reference_code);
        } else if (epsDetail.ingredient) {
            searchProducts(epsDetail.ingredient.name);
        } else {
            searchProducts(epsDetail.name);
        }
    };

    renderIcon = (epsDetail) => {
        const isItemHavingInCart = this.isItemHavingInCart(epsDetail);

        if (isItemHavingInCart) {
            return <i className="fa fa-check-circle text-success" />;
        }

        switch (epsDetail.statusType) {
            case 'MATCH':
                return isItemHavingInCart ? <i className="fa fa-check-circle text-success" /> : '';
            case 'NOT_MATCH':
                return <i className="fa fa-exclamation-circle text-warning" />;
            case 'NOT_FOUND':
                return <i className="fa fa-times-circle text-danger" />;
            default:
                return '';
        }
    };

    toggleTooltip = (key) => () => {
        this.setState({
            [key]: !this.state[key],
        });
    };

    render() {
        const { prescription, language } = this.props;
        if (!prescription) return null;
        return (
            <div style={{ maxHeight: 'calc(50% - 50px)', overflow: 'auto' }}>
                <div className="px-3 pb-3 font-weight-bold">{language.prescription}: {prescription.reference_code}</div>
                <Table>
                    <thead className="eps-thead">
                        <tr>
                            <th />
                            <th>{language.product}</th>
                            <th>{language.ingredient}</th>
                            <th>{language.dosage}</th>
                            <th>{language.price}</th>
                            <th>{language.quantity}</th>
                            <th>{language.unit}</th>
                            <th />
                        </tr>
                    </thead>
                    <tbody>
                        {prescription.details.map((detail, index) => (
                            <tr key={detail.id}>
                                <td style={{ paddingLeft: 12 }}>
                                    {this.renderIcon(detail)}
                                </td>
                                <td>
                                    {/* {prescription.category != 'lm' ? detail.name : ''} */}
                                    {!!detail.product && (detail.product.has_wiki ? (
                                        <a
                                            href={`${wikiUrl}/products/${detail.product.reference_code}`}
                                            target="_blank"
                                            title="คลิกเพื่อดูข้อมูลยา">
                                            {detail.product.name}
                                        </a>
                                    ) : detail.product.name)}
                                    {!detail.name && !detail.product && '-'}
                                </td>
                                <td>{detail.ingredient ? detail.ingredient.name : '-'}</td>
                                <td>{prescription.category == 'lm' ? '-' : detail.dosage || '-'}</td>
                                <td>{displayPriceEp(detail, prescription)}</td>
                                <td>{detail.quantity}</td>
                                <td>{detail.unit ? detail.unit.name : ''}</td>
                                <td style={{ padding: '3px 8px' }}>
                                    <Button onClick={this.searchProducts(detail)} size="sm" outline>
                                        <i className="fa fa-search" />
                                    </Button>
                                    {detail.note && (
                                        <>
                                            <Button className="ml-1" id={'tooltipOpen' + index} size="sm">
                                                <i className="fa fa-info" />
                                            </Button>
                                            <Tooltip
                                                isOpen={this.state['tooltipOpen' + index]}
                                                toggle={this.toggleTooltip('tooltipOpen' + index)}
                                                target={'tooltipOpen' + index}
                                                placement="right"
                                                arrow
                                            >
                                                {detail.note}
                                            </Tooltip>
                                        </>
                                    )}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    language: state.language,
    prescription: state.prescription.data,
    srDetails: state.cart.details,
});

const mapDispatchToProps = {
    searchProducts,
    setPrescriptionUnit,
};

export default connect(mapStateToProps, mapDispatchToProps)(PrescriptionCartInfo);
