import {
    RECEIVE_SETTINGS,
    EMPTY_SETTINGS,
    SET_STATUS_ONLINE,
    SET_UUID_FOR_QR,
    QR_CONNECTED,
    CLOSE_QR_CONNECTED,
    UPDATE_LINE_PAY_FEE,
    SET_LINE_PAY_VALUE,
    SET_PRINTERS,
    SETTING_QUICK_POS,
    SETTING_NOTI_SOUND_LOOP,
    SETTING_NOTI_SOUND_PLAY,
} from '../actionTypes';

const statusOnline = navigator.onLine;

const initialState = {
    token: '',
    me: {},
    company: {
        lastSalesReceipt: []
    },
    branch: {
        province_id: null,
        phone_no: '',
        showOnBill: {},
        telepharmacy: {
            voice: false,
            chat: false,
        },
    },
    payment: {
        thai_qr: {
            shop: {},
            id: null
        },
        bank_accounts: [],
        line_pay: {}
    },
    tax_percentage: 7,
    tax_types: 'vat_included',
    tax_show: true,
    printBill: null,
    printAddress: null,
    status_online: statusOnline,
    uuid: null,
    offline_mode: false,
    printers: [],
    notiSoundIsPlay: false,
    notiSoundIsLoop: false,
};

export default (state = initialState, { type, payload }) => {
    switch (type) {
        case CLOSE_QR_CONNECTED:
            return Object.assign({}, state, { uuid: null });
        case QR_CONNECTED:
            return Object.assign({}, state, payload.settings);
        case SET_UUID_FOR_QR:
            return Object.assign({}, state, { uuid: payload.uuid });
        case SET_STATUS_ONLINE:
            return Object.assign({}, state, { status_online: payload });
        case RECEIVE_SETTINGS:
            // For send error log to api
            window.company_id = payload.company ? payload.company.id : undefined;
            window.branch_id = payload.branch ? payload.branch.id : undefined;

            return Object.assign({}, state, payload);
        case EMPTY_SETTINGS:
            return initialState;
        case UPDATE_LINE_PAY_FEE:
            return Object.assign({}, state, {
                payment: {
                    ...state.payment,
                    line_pay: {
                        ...state.payment.line_pay,
                        fee_percentage: payload.feePercentage
                    }
                }
            });
        case SET_LINE_PAY_VALUE:
            return Object.assign({}, state, {
                payment: {
                    ...state.payment,
                    line_pay: {
                        ...state.payment.line_pay,
                        fee_type: payload.value
                    }
                }
            });
        case SET_PRINTERS: 
            return Object.assign({}, state, { printers: payload});
        case SETTING_NOTI_SOUND_LOOP:
            return Object.assign({}, state, { notiSoundIsLoop: payload });
        case SETTING_NOTI_SOUND_PLAY:
            return Object.assign({}, state, { notiSoundIsPlay: payload });
        default:
            return state;
    }
};
