import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import { createActionLog } from 'redux-action-log';
import { routerMiddleware } from 'react-router-redux';
import history from '../routes/history';
import reducer from './reducers';

const actionLog = createActionLog({ limit: null, snapshotInterval: null });

const createStoreWithMiddleware = compose(
    applyMiddleware(
        thunk.withExtraArgument({ getActionLog: actionLog.getLog, clearActionLog: actionLog.clear }),
        routerMiddleware(history)
    ),
    actionLog.enhancer,
    process.env.NODE_ENV === 'development' && window.__REDUX_DEVTOOLS_EXTENSION__
        ? window.__REDUX_DEVTOOLS_EXTENSION__()
        : (f) => f
)(createStore);

export default createStoreWithMiddleware(reducer);
