import { 
    CONFIRM_BOX
    , ALERT_BOX
    , CLOSE_CONFIRM_BOX
 } from 'Redux/actionTypes'

const initialState = {
    show: false,
    title: '',
    txt: '',
    type: '',
    onSubmit: () => {},
    onCancel: () => {},
    size: "md"
}

export default (state=initialState, { type, payload }) => {
    switch (type) {
        case CONFIRM_BOX:
            return Object.assign({},state,{show: true, title: payload.title, txt: payload.txt, type: 'CONFIRM', onSubmit: payload.onSubmit, onCancel: payload.onCancel});
        case ALERT_BOX:
            return Object.assign({},state,{show: true, title: payload.title, txt: payload.txt, type: 'ALERT', onCancel: payload.onCancel});
        case CLOSE_CONFIRM_BOX:
            return Object.assign({}, initialState);
        default:
            return state
    }
}