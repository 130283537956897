const defaultOptions = {
    id: "",
    pageStyle: "",
    onBeforePrint: null,
    onAfterPrint: null,
};

const printWindowId = "printWindow";

const print = (options = defaultOptions) => {
    const { id, pageStyle } = options;
    const contentEl = document.getElementById(id);
    if (!contentEl) {
        return;
    }

    const printWindow = document.createElement("iframe");
    printWindow.id = printWindowId;
    printWindow.style.position = "absolute";
    printWindow.style.top = "-1000px";
    printWindow.style.left = "-1000px";
    printWindow.style.visibility = "hidden";

    printWindow.onload = () => {
        printWindow.onload = null;

        const domDoc = printWindow.contentWindow.document;
        if (domDoc) {
            domDoc.body.append(contentEl.cloneNode(true));

            if (pageStyle) {
                const styleEl = domDoc.createElement("style");
                styleEl.appendChild(domDoc.createTextNode(pageStyle));
                domDoc.head.appendChild(styleEl);
            }
        }

        triggerPrint(printWindow, options);
    };

    document.body.appendChild(printWindow);
};

const startPrint = (target, options) => {
    const { onAfterPrint } = options;

    setTimeout(() => {
        if (target.contentWindow) {
            target.contentWindow.focus(); // Needed for IE 11

            if (target.contentWindow.print) {
                target.contentWindow.print();

                if (onAfterPrint) {
                    onAfterPrint();
                }
            } else {
                console.log("browser don't have a `print` method for iframe.");
            }

            handleRemoveIframe();
        } else {
            console.log("failed `contentWindow` of iframe didn't load.");
        }
    }, 500);
};

const triggerPrint = (target, options) => {
    const { onBeforePrint } = options;
    if (onBeforePrint) {
        const onBeforePrintOutput = onBeforePrint();
        if (
            onBeforePrintOutput &&
            typeof onBeforePrintOutput.then === "function"
        ) {
            onBeforePrintOutput
                .then(() => {
                    startPrint(target, options);
                })
                .catch((error) => {
                    console.error(error);
                });
        } else {
            startPrint(target, options);
        }
    } else {
        startPrint(target, options);
    }
};

const handleRemoveIframe = () => {
    const documentPrintWindow = document.getElementById(printWindowId);
    if (documentPrintWindow) {
        document.body.removeChild(documentPrintWindow);
    }
};

export default print;
