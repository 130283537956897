import React from 'react'
import { Button, Modal, ModalHeader, ModalBody, Row, Col, Table } from 'reactstrap'
import Pagination from 'react-js-pagination'
import Loadable from 'react-loading-overlay';
import { priceFormat } from 'Utilz/currency';

import SearchSaleReturnForm from 'Components/Form/SearchSaleReturnForm'
import SaleReturnForm from 'Components/Form/SaleReturnForm'

class SaleReturnModal extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            perPage: 7,
            pageNumber: 1,
            data: [],
            isLoaded: false
        }
        this._printSaleReceipt = this._printSaleReceipt.bind(this)
        this._handlePageChange = this._handlePageChange.bind(this)
    }

    componentWillReceiveProps(nextProps) {
        const { perPage, pageNumber, data } = this.state
        const { receipts } = this.props
        if (receipts.length === 0 && data.length === 0) {
            this.setState({ data: nextProps.receipts.slice(0 * perPage, (0 + 1) * perPage) })
        } else if (nextProps.receipts.length !== 0) {
            if (receipts[0].id === nextProps.receipts[0].id) {
                this.setState({
                    data: nextProps.receipts.slice((pageNumber - 1) * perPage, ((pageNumber - 1) + 1) * perPage)
                })
            } else {
                this.setState({
                    data: nextProps.receipts.slice(0 * perPage, (0 + 1) * perPage),
                    pageNumber: 1
                })
            }
        }

        if (!nextProps.showSaleReturn) {
            this.setState({ data: [], pageNumber: 1, isLoaded: false })
        }

        if (nextProps.showSaleReturn && !this.state.isLoaded) {
            this.setState({ isLoaded: false })
            this.props.searchReceipts('').then(() => {
                this.setState({
                    isLoaded: true
                })
            })
        }
    }

    _printSaleReceipt(receipt) {
        this.props.convertToSaleReceipt(receipt);
        setTimeout(this.props.printSaleReceipt, 500);
    }

    _handlePageChange(pageNumber) {
        this.setState({ pageNumber })
        --pageNumber
        const { perPage } = this.state
        this.setState({ data: this.props.receipts.slice(pageNumber * perPage, (pageNumber + 1) * perPage) })
    }

    render() {
        const { searchReceipts
            , language
            , showSaleReturn
            , openSaleReturn
            , closeSaleReturn
            , receipts
            , selectedSaleReceipt
            , clearReceipt
            , setReturnValue
            , confirmTaxInvoice
            , cancelSaleReceipt
            , handleDetailChange
            , confirmReturnProduct
            , loader } = this.props

        const receiptList = this.state.data.map((receipt, index) => {
            let saleReceiptCanVoid = true
            let saleReceiptIsSync = true
            let isArincareTrading = false
            receipt.relationships.details.map(detail => {
                if (detail.relationships.sales_return_details.length > 0) {
                    saleReceiptCanVoid = false
                }
            })
            let numbers = [0]
            for (let i = 0; i < receipts.length; i+=7) { numbers.push(i+1) }
            const { attributes, relationships } = receipt

            if(receipt.relationships.details.length > 0 && attributes.current_status_id==3){
                saleReceiptIsSync = false
            }
            if(process.env.ARINCARE_TRADING_COMPANY_ID == attributes.company_id){
                isArincareTrading = true
            }
            return (
                <tr key={index}>
                    <td>{index + numbers[this.state.pageNumber]}</td>
                    <td>{attributes.reference_code}</td>
                    <td>{relationships.employee !== null && <span>{relationships.employee.attributes.first_name} {relationships.employee.attributes.last_name}</span>}</td>
                    <td>{relationships.customer !== null && <span>{relationships.customer.attributes.first_name} {relationships.customer.attributes.last_name}</span>}</td>
                    <td style={{ textAlign: 'center' }}>{attributes.payment_types.toUpperCase()}</td>
                    <td style={{ textAlign: 'center' }}>{priceFormat(attributes.total_amount)}</td>
                    <td>{attributes.updated_at}</td>
                    <td style={{ fontSize: '20px' }}>  
                        {saleReceiptIsSync &&
                            <button class="btn btn-secondary" title="กำลังซิงค์ข้อมูลสินค้า" type="submit">
                                        <i class="fa fa-clock-o" aria-hidden="true"></i>กำลังซิงค์ข้อมูลสินค้า
                            </button>
                        }
                        {isArincareTrading &&
                            <i className="fa fa-file fa-lg mr-3 text-primary link"  
                            onClick={() => this.props.confirmTaxInvoice(attributes.reference_code)}                        
                            title={language.send_tax_invoice}></i>
                        } 
                        {!saleReceiptIsSync &&
                            <i className="fa fa-print fa-lg mr-3 text-primary link"
                            onClick={() => this._printSaleReceipt(receipt)}
                            title={language.print}></i>
                        } 
                        {!saleReceiptIsSync &&
                            <i className="fa fa-exchange fa-lg mr-3 text-success link"
                            onClick={() => this.props.selectReceipt(receipt)}
                            title={language.return_product}></i>
                        }                   
                        
                        {(saleReceiptCanVoid && !saleReceiptIsSync) && 
                        <i className="fa fa-trash fa-lg text-danger link"
                        onClick={() => this.props.cancelSaleReceipt(attributes.reference_code)}
                        title={language.cancel_bill}></i>}
                    </td>
                </tr>
            )
        })
        const pageRange = Math.ceil(receipts.length / this.state.perPage)
        return (
            <div className="mb-2 mr-2">
                <Button color="danger" onClick={openSaleReturn}><i className="fa fa-file-text-o"></i> {language.bill_list}</Button>
                <Modal isOpen={showSaleReturn}
                    toggle={closeSaleReturn}
                    size="lg"
                    style={{ maxWidth: '82%' }}
                    backdrop="static"
                >
                    <Loadable active={loader} spinner>
                        <ModalHeader toggle={closeSaleReturn}>{language.bill_list}</ModalHeader>
                        <ModalBody>
                            <Row>
                                <Col>
                                    <SearchSaleReturnForm
                                        language={language}
                                        searchReceipts={searchReceipts}
                                        focus={this.props.focus}
                                        isLoading={!this.state.isLoaded}
                                    />
                                </Col>
                            </Row>
                            {selectedSaleReceipt.sales_receipt_id &&
                            <SaleReturnForm
                                language={language}
                                saleReceipt={selectedSaleReceipt}
                                clearReceipt={clearReceipt}
                                setReturnValue={setReturnValue}
                                confirmTaxInvoice={confirmTaxInvoice}
                                cancelSaleReceipt={cancelSaleReceipt}
                                handleDetailChange={handleDetailChange}
                                confirmReturnProduct={confirmReturnProduct}
                                printSaleReceipt={() => this._printSaleReceipt(selectedSaleReceipt)}
                            />}
                            {receipts.length > 1 && !selectedSaleReceipt.sales_receipt_id &&
                            <Row className="mt-2">
                                <Col>
                                    <Table className="salereturn-table">
                                        <thead>
                                            <tr>
                                                <th>{language.index}</th>
                                                <th>{language.reference_number}</th>
                                                <th>{language.transaction_person}</th>
                                                <th>{language.customer}</th>
                                                <th style={{ textAlign: 'center' }}>{language.payment_type}</th>
                                                <th>{language.payment_net_amount}</th>
                                                <th>{language.transaction_date}</th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {receiptList}
                                        </tbody>
                                    </Table>
                                    <Pagination
                                        activePage={this.state.pageNumber}
                                        itemsCountPerPage={this.state.perPage}
                                        pageRangeDisplayed={pageRange}
                                        totalItemsCount={receipts.length}
                                        onChange={this._handlePageChange}
                                        innerClass="pagination"
                                        itemClass="page-item"
                                        linkClass="page-link"
                                        prevPageText="Prev"
                                        nextPageText="Next"
                                    />
                                </Col>
                            </Row>}
                        </ModalBody>
                    </Loadable>
                </Modal>
            </div>
        )
    }
}

export default SaleReturnModal