import { EventEmitter } from 'events';
import axios from 'axios';

const API3_URL = process.env.API3_URL;

class TwilioService extends EventEmitter {
    constructor() {
        super();
        this.state = {
            branchSlug: '',
            token: null,
            identity: '',
            pharmacistName: '',
        };
        this.isAvailable = {
            voice: false,
            chat: false,
        };
    }

    getClientToken() {
        const { identity, branchSlug } = this.state;
        return new Promise((resolve, reject) => {
            axios({
                url: `${API3_URL}/telepharmacy/token`,
                method: 'post',
                data: {
                    identity,
                    type: 'pos',
                    branch_slug: branchSlug,
                },
            })
                .then((res) => {
                    const { token } = res.data;
                    this.state.token = token;
                    resolve(token);
                })
                .catch((err) => reject(err));
        });
    }

    setClientIdentity(branchSlug, macId) {
        this.state.identity = `${branchSlug}_${macId}`;
    }

    setClientMacId(id) {
        localStorage.setItem('macId', id);
    }

    getClientMacId() {
        return localStorage.getItem('macId');
    }

    setIsAvailable(type, value) {
        this.isAvailable[type] = value;
    }

    setPharmacist(employee) {
        this.state.pharmacistName = `${employee.first_name} ${employee.last_name}`;
    }
}

export default TwilioService;
