import React from 'react';
import charConverter from 'Utilz/charConverter';
import ProductTypeahead from '../Input/ProductTypeahead';

class SearchProductForm extends React.Component {
    state = {
        query: '',
        onClick: false,
    };

    onChangeSearch = e => {
        this.setState({ query: e.target.value });
    };

    onSubmitSearch = (e, value) => {
        e.preventDefault();

        let regex = /[0-9][*]/;
        let trimQuery = !value ? this.state.query.trim() : value;

        // เช็คบาร์โค้ดสินค้า กรณีที่ user ลืมเปลี่ยนภาษา
        if (isNaN(trimQuery) && trimQuery.length >= 4) {
            trimQuery = charConverter(trimQuery);
        }

        if (regex.test(trimQuery)) {
            const num = Number(trimQuery.replace('*', ''));
            this.props.setCartValue('present_quantity', num);
            this.setState({ query: '' });
        } else if (trimQuery === '0*') {
            this.props.setCartValue('present_quantity', 1);
            this.setState({ query: '' });
        } else if (trimQuery.length === 40 && trimQuery.substr(0, 4).toLowerCase() === 'eps-') {
            this.props.openPickupPrescriptionModalWithQrCode(trimQuery);
        } else if (trimQuery.length >= 2) {
            this.setState({ onClick: true });
            this.props.searchProducts(trimQuery).then(() => {
                if (this.refs.txtSearch) {
                    this.setState({
                        onClick: false,
                        query: '',
                    });
                }
            });
        } else if (this.state.query.trim().length === 0) {
            this.props.checkOut();
        }
    };

    _setQuery = value => {
        this.setState({ query: value });
    };

    render() {
        const { language } = this.props;
        return (
            <form onSubmit={this.onSubmitSearch}>
                <div className="input-group">
                    <div className="input-group-prepend">
                        <span className="input-group-text">
                            <i className="fa fa-barcode"></i>
                        </span>
                    </div>
                    <input
                        type="text"
                        className="form-control"
                        onChange={this.onChangeSearch}
                        placeholder={language.search_product}
                        ref="txtSearch"
                        value={this.state.query}
                        onBlur={this.props.onBlur}
                    />
                    <div className="input-group-append">
                        <button className="btn btn-outline-secondary">
                            {!this.state.onClick ? (
                                <i className="fa fa-search"></i>
                            ) : (
                                `${language.searching}...`
                            )}
                        </button>
                    </div>
                </div>
            </form>
        );
        // return (
        //     <div>
        //         <div className="input-group">
        //             <div className="input-group-prepend">
        //                 <span className="input-group-text">
        //                     <i className="fa fa-barcode"></i>
        //                 </span>
        //             </div>
        //             <ProductTypeahead
        //                 value={this.state.query}
        //                 onChange={this.onChangeSearch}
        //                 searchProducts={this.props.productsTypeahead}
        //                 onSubmit={this.onSubmitSearch}
        //                 onBlur={this.props.onBlur}
        //                 placeholder={language.search_product}
        //                 language={language}
        //                 setQuery={this._setQuery}
        //             />
        //             <div className="input-group-append">
        //                 <button className="btn btn-outline-secondary">
        //                     {!this.state.onClick ? (
        //                         <i className="fa fa-search"></i>
        //                     ) : (
        //                         `${language.searching}...`
        //                     )}
        //                 </button>
        //             </div>
        //         </div>
        //     </div>
        // );
    }

    componentDidUpdate(preProps) {
        const { focus } = this.props;
        const { focus: wasfocus } = preProps;
        if (!wasfocus && focus) {
            this.refs.txtSearch.focus();
            // document.querySelector('#search-products-input').focus();
        }
    }
}

export default SearchProductForm;
