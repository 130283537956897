import React, { Component } from 'react';
import { Modal, ModalHeader, ModalBody, Table, Button, Row, Col } from 'reactstrap'

class ProductErrorModal extends Component {
    render() {
        const { productErrorModal, closeProductErrorModal, productErrorModalMessages, language } = this.props
        return (
            <Modal
                isOpen={productErrorModal}
                toggle={closeProductErrorModal}
                size="lg">
                <ModalHeader>{language.product_problem}</ModalHeader>
                <ModalBody>
                    <Table className="addstockset-tabel" hover>
                        <thead>
                            <tr className="header-row">
                                <th style={{ width: '20%' }}>{language.reference_code}</th>
                                <th style={{ width: '30%' }}>{language.product}</th>
                                <th style={{ width: '20%' }}>{language.unit}</th>
                                <th style={{ width: '30%' }}>{language.note}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                productErrorModalMessages.map((errors, index) => {
                                    return (
                                        <tr key={index}>
                                            <td style={{ textAlign: 'center'}}>{errors.product.reference_code || '-'}</td>
                                            <td style={{ textAlign: 'center'}}>{errors.product.name || '-'}</td>
                                            <td style={{ textAlign: 'center'}}>{errors.product.unit_name || '-'}</td>
                                            <td style={{ textAlign: 'center'}} color="danger">{language[errors.message] || errors.message || '-'}</td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </Table>
                    <Row>
                        <Col md={{ size: 2, offset: 5 }}>
                            <Button
                                color="danger"
                                onClick={closeProductErrorModal}
                                block>
                                {language.close}
                            </Button>
                        </Col>
                    </Row>
                </ModalBody>
            </Modal>
        );
    }
}

export default ProductErrorModal;