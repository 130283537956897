import React from 'react';

export const DateTime = {
    startDate: process.env.CLOSE_PLATFORM_START_DATE,
    endDate: process.env.CLOSE_PLATFORM_END_DATE,
    startTime: process.env.CLOSE_PLATFORM_START_TIME,
    endTime: process.env.CLOSE_PLATFORM_END_TIME,
    startUpdateDate: process.env.UPDATE_PLATFORM_DATE,
}

export function dateFormat(dateInput) {
    let datePart = dateInput.match(/\d+/g);
    let year = datePart[0];
    let month = datePart[1]; 
    let day = datePart[2];

    return <>{ day + '/' + month + '/'+ year }</>;
}

export function timeFormat(timeInput) {
    let timePart = timeInput.match(/\d+/g);
    let hour = timePart[0];
    let minute = timePart[1];

    return <>{ hour + '.' + minute }</>;
}
