import React from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, Badge } from 'reactstrap';
import { Scrollbars } from 'react-custom-scrollbars';
import classnames from 'classnames';
import { uniqBy, dateTimeFormat } from 'Redux/actions/telepharmacy';

class CallDropdownPanel extends React.Component {
    state = {
        open: false,
    };

    toggle = () => {
        this.setState({ open: !this.state.open });
    };

    render() {
        const { language, settings } = this.props;
        const voice = this.props.voice;
        const chat = this.props.chat;
        const {
            branch: {
                telepharmacy: { voice: voiceIsisAvailable, chat: chatIsisAvailable },
            },
        } = settings;
        const hasCalls = voice.calls.length > 0;
        const calls = uniqBy(voice.calls, 'callSid');
        const callList = calls
            .sort((a, b) => b.timestamp - a.timestamp)
            .map((call) => {
                return (
                    <li key={call.callSid}>
                        <div className="error-message-body">
                            <div className="d-flex justify-content-between">
                                <h5 className="text-danger" style={{ fontSize: '14px' }}>
                                    {call.patient && call.patient.name
                                        ? call.patient.name
                                        : 'ไม่ทราบชื่อ'}
                                </h5>
                                <span className="text-muted" style={{ fontSize: '12px' }}>
                                    {call.timestamp ? dateTimeFormat(call.timestamp) : ''}
                                </span>
                            </div>
                            <span className="text-muted d-block mb-1" style={{ fontSize: '14px' }}>
                                หมายเลขอ้างอิง: {call.callSid}
                            </span>
                        </div>
                    </li>
                );
            });

        return (
            <Dropdown
                isOpen={this.state.open}
                toggle={this.toggle}
                className="error-message-notify"
            >
                <DropdownToggle style={{padding : 0}}>
                    <div className='link mr-2 shadow-box' style={{ padding : 8 }}>
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clipPath="url(#clip0_10911_70)">
                                <path d="M20 2H4C2.9 2 2 2.9 2 4V22L6 18H20C21.1 18 22 17.1 22 16V4C22 2.9 21.1 2 20 2ZM20 16H6L4 18V4H20V16Z" fill="#454545"/>
                                <path d="M15.505 11.19C14.89 11.19 14.295 11.09 13.74 10.91C13.565 10.85 13.37 10.895 13.235 11.03L12.45 12.015C11.035 11.34 9.71 10.065 9.005 8.6L9.98 7.77C10.115 7.63 10.155 7.435 10.1 7.26C9.915 6.705 9.82 6.11 9.82 5.495C9.82 5.225 9.595 5 9.325 5H7.595C7.325 5 7 5.12 7 5.495C7 10.14 10.865 14 15.505 14C15.86 14 16 13.685 16 13.41V11.685C16 11.415 15.775 11.19 15.505 11.19Z" fill="#454545"/>
                            </g>
                            <defs>
                                <clipPath id="clip0_10911_70">
                                    <rect width="24" height="24" fill="white"/>
                                </clipPath>
                            </defs>
                        </svg>
                    </div>
                    {voiceIsisAvailable && hasCalls && (
                        <Badge
                            color="danger"
                            style={{ position: 'absolute', top: 0, left: 30, fontSize: '65%' }}
                        >
                            {voice.calls.length}
                        </Badge>
                    )}
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-lg border-0 p-0" right>
                    <div style={dropdownHeaderStyles}>
                        <div className="pull-left">Telepharmacy</div>
                        <div className="pull-right">
                            {voiceIsisAvailable && (
                                <span className="link mr-2" onClick={this.props.toggleVoiceBusy}>
                                    <i
                                        className={classnames('fa fa-phone fa-lg fa-fw', {
                                            'text-success': voice.isOn,
                                            'text-danger': !voice.isOn,
                                        })}
                                        title={`สถานะการโทร: ${
                                            !voice.isOn ? 'ออฟไลน์' : 'ออนไลน์'
                                        }`}
                                    ></i>
                                </span>
                            )}
                            {chatIsisAvailable && (
                                <span className="link" onClick={this.props.toggleChatBusy}>
                                    <i
                                        className={classnames('fa fa-comments fa-lg fa-fw', {
                                            'text-success': chat.isOn,
                                            'text-danger': !chat.isOn,
                                        })}
                                        title={`สถานะการแชท: ${!chat.isOn ? 'ออฟไลน์' : 'ออนไลน์'}`}
                                    ></i>
                                </span>
                            )}
                        </div>
                        <div className="clearfix"></div>
                    </div>
                    {voiceIsisAvailable && (
                        <div className="dropdown-body">
                            {hasCalls ? (
                                <Scrollbars style={{ height: 275 }}>
                                    <ul className="list-unstyled">{callList}</ul>
                                </Scrollbars>
                            ) : (
                                <div className="py-5 text-center">ไม่มีสายที่ไม่ได้รับ</div>
                            )}
                        </div>
                    )}
                </DropdownMenu>
            </Dropdown>
        );
    }
}

const dropdownHeaderStyles = {
    color: '#000',
    fontSize: 15,
    backgroundColor: '#fff',
    padding: '0.5rem',
    borderTopLeftRadius: '0.25rem',
    borderTopRightRadius: '0.25rem',
    borderLeft: '1px solid #00000026',
    borderRight: '1px solid #00000026',
};

export default CallDropdownPanel;
