import React from 'react'
import classnames from 'classnames'

const SelectFormGroup = ({ label, name, value, onChange, placeholder, options, md, error, ...rest }) => (
    <div className={classnames('form-group', md ? `col-md-${md}` : false)} {...rest}>
        {label && <label>{label}:</label>}
        <select
            className={classnames('form-control', { 'is-invalid': error })}
            name={name}
            value={value}
            onChange={onChange}>
            <option value="" disabled >{placeholder}</option>
            {options.map(item => <option key={item.id} value={item.value}>{item.name}</option>)}
        </select>
        {error && <div className="invalid-feedback">{error}</div>}
    </div>
)

export default SelectFormGroup