import { 
    LOAD_AWAITING_CART,
    OPEN_AWAITING_CART,
    CLOSE_AWAITING_CART,
    TOGGLE_AWAITING_CART,
    DELETE_AWAITING_CART,
    SAVE_CART,
    CONTINUE_CART,
    ADD_TO_AWAITING_CART,
    LOAD_SYNC_ERROR,
    DELETE_SYNC_ERROR,
    ADD_TO_SYNC_ERROR,
    FAILURE_ABB,
    SHOW_NOTI_REMOVE_COUPON,
    CLOSE_NOTI_REMOVE_COUPON,
} from '../actionTypes'
import db from '../../utils/db'
import moment from 'moment'
import { notify, removeNotification } from 'reapop'
import {
    syncSaleReceipt,
    waitDispatchSync,
    addLogToCart
} from 'Redux/actions'
import { initial } from 'lodash'

export const openAwaitingCart = () => dispatch => {
    return dispatch({ type: OPEN_AWAITING_CART })
}

export const closeAwaitingCart = () => dispatch => {
    return dispatch({ type: CLOSE_AWAITING_CART })
}

export const registerSync = referenceCode => dispatch => {
    return db.table('carts').get(referenceCode, cart => {
        return dispatch(syncByIndexedDB(cart))
    })
}

export const syncByIndexedDB = cart => dispatch => {
    db.table('settings').get(0, settings => {
        if (!settings.offline_mode) {
            dispatch(syncSaleReceipt(cart))
        } else {
            if (cart.current_status_id === 1) {
                dispatch(addToAwaitingCart(cart.reference_code))
            } else if (cart.current_status_id === 3) {
                addLogToCart(cart, 'POS is in offline mode waiting for sync');
            } else if (cart.current_status_id === 4) {
                db.table('carts').get(cart.reference_code, cart => {
                    !cart.id && db.table('carts').delete(cart.reference_code).then(() => {
                        dispatch(removeNotification('deleteCart'))
                    })
                })
            }
        }
    })
}

export const loadAwaitingCarts = (companyId, branchId) => dispatch => {
    return db.table('carts').toArray().then(carts => {
        const awaitingCarts = carts.filter(cart => cart.current_status_id === 1 &&
        cart.company_id === companyId &&
        cart.branch_id === branchId)

        dispatch({ type: LOAD_AWAITING_CART, payload: awaitingCarts })
    })
}

export const loadSyncErrors = (companyId, branchId) => dispatch => {
    db.table('carts').toArray().then(carts => {
        const errorMessages =  carts.filter(cart => cart.current_status_id === 5 &&
        cart.company_id === companyId &&
        cart.branch_id === branchId)
        dispatch({ type: LOAD_SYNC_ERROR, payload: errorMessages })

        errorMessages.length > 0 && dispatch(notify({
            id: 'syncErrors',
            title: `มีบิลขายผิดพลาด ${errorMessages.length} รายการ`,
            message: 'กรุณาติดต่อทีมงานเพื่อดำเนินการแก้ไข',
            status: 'warning',
            dismissible: true,
            dismissAfter: 5000
        }))
    })
}

export const loadCarts = () => dispatch => {

    db.table('settings').get(0, settings => {
        const { company, branch, offline_mode } = settings

        !offline_mode && dispatch(loadSyncErrors(company.id, branch.id))

        db.table('carts').toArray().then(carts => {
            carts.filter(cart => cart.current_status_id === 2 && cart.company_id === company.id).map(item => {
                addLogToCart(item, `POS Change histories [a1] with employee_id is ${item.employee_id}`, false);
                const updateCart = {
                    current_status_id: 1,
                    employee: item.employee,
                    histories: [...item.histories, {
                        employee_id: item.employee_id,
                        sales_receipt_status_id: 1,
                        created_at: moment().format('YYYY-MM-DD HH:mm:ss')
                    }],
                    coupon: null,
                }

                if (item.coupon) updateCart.total_discount_amount = 0;

                db.table('carts').update(item.reference_code, updateCart).then(() => {

                    dispatch(registerSync(item.reference_code));
                    dispatch(loadAwaitingCarts(company.id, branch.id));

                });

                

            }).length === 0 && dispatch(loadAwaitingCarts(company.id, branch.id))

            carts
                .filter(cart => (cart.current_status_id === 3 || cart.current_status_id === 4) && cart.company_id === company.id)
                .forEach((cart, i) => {
                    cart = addLogToCart(cart, 'POS come back online, re-sync');
                    dispatch(waitDispatchSync(syncByIndexedDB(cart), i));
                })
        })
    })
}

export const addToAwaitingCart = referenceCode => dispatch => {

    return db.table('settings').get(0, settings => {
        const { branch } = settings
        return db.table('carts').get(referenceCode, cart => {
            if (cart.current_status_id === 1 && cart.branch_id === branch.id) {
                dispatch({ type: ADD_TO_AWAITING_CART, payload: cart })
                dispatch(removeNotification('pauseCart'))
            }
        })
    })
}

export const addToSyncErrorMessage = referenceCode => dispatch => {
    db.table('carts').get(referenceCode, cart => {
        if (cart.current_status_id === 5) {
            dispatch({ type: ADD_TO_SYNC_ERROR, payload: cart })
        }
    })
}

export const deleteAwaitingCart = (referenceCode) => (dispatch, getState) => {
    dispatch(notify({
        id: 'deleteCart',
        message: 'กำลังดำเนินการ...',
        status: 'loading',
        dismissible: true,
        dismissAfter: 10000
    }))

    const { employee } = getState().employee

    db.table('carts').get(referenceCode, cart => {
        addLogToCart(cart, `POS Change histories [a2] with employee_id is ${employee.id}`, false);
        const updateCart = {
            current_status_id: 4,
            histories: [...cart.histories, {
                employee_id: employee.id,
                sales_receipt_status_id: 4,
                created_at: moment().format('YYYY-MM-DD HH:mm:ss')
            }]
        }

        db.table('carts').update(cart.reference_code, updateCart).then(() => {
            dispatch(registerSync(cart.reference_code))
            dispatch({ type: DELETE_AWAITING_CART, payload: cart.reference_code })
        })
    })
}

const setDeleteSyncError = referenceCode => ({
    type: DELETE_SYNC_ERROR,
    payload: referenceCode
})

export const deleteSyncError = referenceCode => dispatch => {
    db.table('carts').delete(referenceCode)
    dispatch(setDeleteSyncError(referenceCode))
}

export const checkCouponBeforeSaveCart = () => async (dispatch, getState) => {
    const { coupon } = getState().cart

    if (!coupon) {
        return dispatch(saveCart());
    } else {
        return dispatch(showNotiRemoveCouponModal());
    }
}

export const saveCart = () => (dispatch, getState) => {
    dispatch(notify({
        id: 'pauseCart',
        message: 'กำลังดำเนินการ...',
        status: 'loading',
        dismissible: true,
        dismissAfter: 10000
    }))

    const { reference_code, coupon } = getState().cart
    const { employee } = getState().employee
    const { company, branch } = getState().settings

    return db.table('carts').get(reference_code, cart => {
        addLogToCart(cart, `POS Change histories [a3] with employee_id is ${employee.id}`, false);
        const updateCart = {
            current_status_id: 1,
            employee: employee,
            histories: [...cart.histories, {
                employee_id: employee.id,
                sales_receipt_status_id: 1,
                created_at: moment().format('YYYY-MM-DD HH:mm:ss')
            }],
            coupon: null,
        }
        
        if (coupon) updateCart.total_discount_amount = 0;

        return db.table('carts').update(cart.reference_code, updateCart).then(async () => {
            if (cart.id) {
                await dispatch(addToAwaitingCart(cart.reference_code))
            } else {
                await dispatch(registerSync(cart.reference_code))
            }

            dispatch({ type: SAVE_CART })
            await dispatch(loadAwaitingCarts(company.id, branch.id))
        })
    })
}

const updateExistCart = (referenceCode, employee, coupon) => {
    return db.table('carts').get(referenceCode, cart => {
        addLogToCart(cart, `POS Change histories [a4] with employee_id is ${employee.id}`, false);
        const updateCart = {
            current_status_id: 1,
            employee: employee,
            histories: [...cart.histories, {
                employee_id: employee.id,
                sales_receipt_status_id: 1,
                created_at: moment().format('YYYY-MM-DD HH:mm:ss')
            }],
            coupon: null,
        }

        if (coupon) updateCart.total_discount_amount = 0;

        return db.table('carts').update(cart.reference_code, updateCart)
    })
}

export const continueCart = referenceCode => (dispatch, getState) => {
    dispatch({ type: CLOSE_AWAITING_CART })

    const { settings, customer, cart: cartState, awaitingCart } = getState()
    const { employee } = getState().employee

    if (cartState.reference_code !== null) {
        const existAwaitingCart = awaitingCart.carts.find(cart => cart.reference_code === cartState.reference_code)

        if (existAwaitingCart) {
            updateExistCart(cartState.reference_code, employee, cartState.coupon)
        } else {
            updateExistCart(cartState.reference_code, employee, cartState.coupon).then(() => {
                dispatch(addToAwaitingCart(cartState.reference_code))
            })
        }
    }

    db.table('carts').get(referenceCode, cart => {
        let obj = Object.assign({}, cart, {
            current_status_id: 2,
            employee: employee,
            histories: [...cart.histories, {
                employee_id: employee.id,
                sales_receipt_status_id: 2,
                created_at: moment().format('YYYY-MM-DD HH:mm:ss')
            }],
            tax_types: settings.tax_types,
            tax_percentage: settings.tax_percentage,
            pos_logs: [...cart.pos_logs, `POS Change histories [a5] with employee_id is ${employee.id}`],
        })

        if (!obj.member.id) {
            obj.member = {
                id: null,
                ingredient_allergies: {},
                name: '',
                price_level: 1,
                product_allergies: {},
                reference_code: null,
                retail_price_level: 1,
                birth_date: null,
            }
        }

        dispatch({ type: CONTINUE_CART, payload: obj })
        dispatch({ type: TOGGLE_AWAITING_CART, payload: cart.reference_code })
    })
}

export const continueSyncError = referenceCode => (dispatch, getState) => {
    const { cart: cartState, syncErrorMessage, settings } = getState()
    const { employee } = getState().employee
    
    if (cartState.reference_code !== null) {
        const existsyncErrorMessage = syncErrorMessage.carts.find(cart => cart.reference_code === cartState.reference_code)
        if (!existsyncErrorMessage) {
            updateExistCart(cartState.reference_code, employee).then(() => {
                dispatch(addToAwaitingCart(cartState.reference_code))
            })
        }
    }

    db.table('carts').get(referenceCode, cart => {
        let obj = Object.assign({}, cart, {
            current_status_id: 2,
            employee: employee,
            histories: [...cart.histories, {
                employee_id: employee.id,
                sales_receipt_status_id: 2,
                created_at: moment().format('YYYY-MM-DD HH:mm:ss')
            }],
            tax_types: settings.tax_types,
            tax_percentage: settings.tax_percentage,
            paid_amount: !cart.paid_amount ? 0 : cart.paid_amount,
            pos_logs: [...cart.pos_logs, `POS Change histories [a6] with employee_id is ${employee.id}`],
        })

        dispatch({ type: CONTINUE_CART, payload: obj })
        dispatch(setDeleteSyncError(cart.reference_code))
    })
}

export const syncAgain = referenceCode => (dispatch, getState) => {
    const { company, branch, status_online } = getState().settings
    const { employee } = getState().employee

    if (status_online) {
        db.table('carts').get(referenceCode, cart => {
            addLogToCart(cart, `POS Change histories [a7] with employee_id is ${employee.id}`, false);
            const updateCart = {
                company_id: company.id,
                branch_id: branch.id,
                employee: employee,
                employee_id: employee.id,
                current_status_id: cart.old_status_id,
                histories: cart.histories.map(history => {
                    if (history.employee_id === 0) {
                        return {
                            ...history,
                            employee_id: employee.id
                        }
                    }
                    return history
                })
            }

            db.table('carts').update(cart.reference_code, updateCart).then(() => {
                dispatch(registerSync(cart.reference_code))
                dispatch(setDeleteSyncError(cart.reference_code))
            })
        })
    } else {
        dispatch(notify({
            id: 'offlineSync',
            message: 'กรุณาเชื่อมต่ออินเตอร์เน็ต',
            status: 'info',
            dismissible: true,
            dismissAfter: 3000
        }))
    }
}

export const getAbbFailed = referenceCode => dispatch => {
    db.table('carts').update(referenceCode, {
        current_status_id: 5,
        old_status_id: 2,
        message: 'Failed to reserve abb.',
        sync_at: moment().format('YYYY-MM-DD HH:mm:ss')
    }).then(() => {
        dispatch(addToSyncErrorMessage(referenceCode))
        dispatch({ type: FAILURE_ABB })
    })
}

const showNotiRemoveCouponModal = () => dispatch => {
    return dispatch({ type: SHOW_NOTI_REMOVE_COUPON });
}

export const closeNotiRemoveCouponModal = () => dispatch => {
    return dispatch({ type: CLOSE_NOTI_REMOVE_COUPON });
}
