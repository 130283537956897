import { SET_INVENTORY_VALUE, SEARCH_PRODUCTS } from '../actionTypes';

const initialState = {
    product_id: null,
    quantity: 0,
    unit_id: '',
    price1: 0,
    price2: 0,
    price3: 0,
    price4: 0,
    price5: 0,
    activeTab: 1,
    loading: false,
};

export default (state = initialState, { type, payload }) => {
    switch (type) {
        case SET_INVENTORY_VALUE:
            return {
                ...state,
                [payload.path]: payload.value,
            };
        case SEARCH_PRODUCTS:
            return initialState;
        default:
            return state;
    }
};
