import { SELECT_PRODUCT
    , SELECT_PRODUCT_SET
    , ADD_STOCK_SET
    , CLOSE_ADD_STOCK_SET
    , DELETE_STOCK_SET
    , UPDATE_SEARCH_WISHLIST
    , UPDATE_CART_WISHLIST
    , SUBMIT_RETAIL_UNIT
    , CANCEL_RETAIL_UNIT
} from '../actionTypes'

import { searchProducts
        , addToCart 
        , findUnitDetails} from 'Redux/actions';

import {
    convertProductToProductSet2,
    convertProductToProductSet1,
    addRetailUnits
} from 'Business';

import axiosHelper from '../middleware/axios'
import { notify, addNotification} from 'reapop'
import { addMultipleToCart, checkCertRemainingQuantity } from "./cart";

let helper = new axiosHelper()

export const selectProduct  = product => (dispatch,getState) => {

    if(product.is_product_set === 1 && product.sets_type === '1'){

        const { detail: initDetail, cart } = getState();

        const detail = convertProductToProductSet1(initDetail,cart,product);

        return dispatch({
            type: SELECT_PRODUCT_SET,
            payload: detail
        })
    }else if(product.is_product_set === 1 ){

        const { detail, cart, settings } = getState();

        const details = convertProductToProductSet2(detail,cart,product);

        dispatch(addMultipleToCart(details));
    }else{
        const { cart } = getState();
        return dispatch({
            type: SELECT_PRODUCT,
            payload: {
                product
                , quantity: cart.present_quantity
            }
        })
    }
}

export const closeAddStockSet = () => dispatch => {
    return dispatch({ type: CLOSE_ADD_STOCK_SET })
}

export const addDummy = (product, isProductSet) => (dispatch, getState) => {
    const { company, branch, token } = getState().settings
    const productState = getState().product
    const { employee } = getState().employee

    if (employee.branch_role_id === 1) {
        if (product.is_product_set) {
            const products = product.products.filter(
                product => {
                    let unit = findUnitDetails(product.units, product.unit_id);
                    return product.remaining_quantity < (product.quantity * unit.quantity_of_smallest_unit) 
                }
            )
            return dispatch({
                type: ADD_STOCK_SET,
                payload: {
                    name: product.name,
                    products
                }
            })
        } else {
            dispatch(notify({
                id: 'addDummy',
                message: 'ระบบกำลังทำการเพิ่มสต๊อค...',
                status: 'loading',
                dismissible: true,
                dismissAfter: 10000
            }))

            const isSmallestUnit = product.units.find(unit => unit.pivot.is_smallest_unit === 1)
            const data = {
                company_id: company.id,
                branch_id: branch.id,
                name: product.name,
                product_id: product.id,
                quantity_of_smallest_unit: isSmallestUnit.pivot.unit_id
            }

            return helper.post('/dummies', data, {
                'Authorization': `Bearer ${token}`
            }, 2).then(res => {
                if (res.message) {
                    dispatch(notify({
                        id: 'addDummy',
                        title: 'สำเร็จ',
                        message: 'เพิ่มสต๊อคสินค้าเรียบร้อยแล้ว',
                        status: 'success',
                        dismissible: true,
                        dismissAfter: 3000
                    }))
                    if (isProductSet) {
                        dispatch({ type: DELETE_STOCK_SET, payload: product.id })
                        if (productState.products.length === 1) {
                            dispatch(closeAddStockSet())
                        }
                    } else {
                        dispatch(searchProducts(product.name))
                    }
                }
            }).catch(err => {
                console.error(err);
                dispatch(notify({
                    id: 'addDummy',
                    title: 'ล้มเหลว',
                    message: 'ระบบเพิ่มสต๊อคสินค้าไม่สำเร็จ',
                    status: 'error',
                    dismissible: true,
                    dismissAfter: 3000
                }))
            })
        }
    } else {
        dispatch(notify({
            id: 'addDummy',
            title: 'ล้มเหลว',
            message: 'ไม่มีสิทธิ์ในการเพิ่มสต๊อคสินค้า',
            status: 'error',
            dismissible: true,
            dismissAfter: 3000
        }))
    }
}

const setSearchWistList = (id, wishlist_item) => ({
    type: UPDATE_SEARCH_WISHLIST,
    payload: {
        id,
        wishlist_item
    }
})

const setCartWistList = (id, wishlist_item) => ({
    type: UPDATE_CART_WISHLIST,
    payload: {
        id,
        wishlist_item
    }
})

export const addToWishList = (product, where) => (dispatch, getState) => {
    const { company, token } = getState().settings
    const { employee } = getState().employee

    dispatch(notify({
        id: 'wishlist',
        message: 'กำลังเพิ่มสินค้าที่ต้องการสั่งซื้อ...',
        status: 'loading',
        dismissible: true,
        dismissAfter: 10000
    }))

    return helper.post(`/companies/${company.slug}/products/${product.reference_code}/wishlist/add`, {
        company_id: company.id,
        product_id: product.id,
        username: employee.user_id,
        employee_code: employee.reference_code,
        where: where
    }, {
        'Authorization': `Bearer ${token}`
    }, 3).then(res => {
        if (res.response_message.STATUS === 'SUCCESS') {
            dispatch(notify({
                id: 'wishlist',
                message: 'เพิ่มสินค้าที่ต้องการสั่งซื้อเรียบร้อยแล้ว',
                status: 'success',
                dismissible: true,
                dismissAfter: 1000
            }))
            if (where === 'search') {
                dispatch(setSearchWistList(product.id, res.wishlist_item))
            } else if (where === 'cart') {
                dispatch(setCartWistList(product.id, res.wishlist_item))
            }
        }
    }).catch((err) => {
        console.error(err);
        dispatch(notify({
            id: 'wishlist',
            message: 'ไม่สามารถเพิ่มสินค้าที่ต้องการสั่งซื้อได้',
            status: 'error',
            dismissible: true,
            dismissAfter: 1000
        }))
    })
}

export const removeWishList = (product, where) => (dispatch, getState) => {
    const { company, token } = getState().settings

    dispatch(notify({
        id: 'wishlist',
        message: 'กำลังลบสินค้าที่ต้องการสั่งซื้อ...',
        status: 'loading',
        dismissible: true,
        dismissAfter: 10000
    }))

    return helper.delete(`/companies/${company.slug}/products/${product.reference_code}/wishlist/${product.wishlist_item.id}`, {}, {
        'Authorization': `Bearer ${token}`
    }, 3).then(res => {
        if (res.response_message.STATUS === 'SUCCESS') {
            dispatch(notify({
                id: 'wishlist',
                message: 'ลบสินค้าที่ต้องการสั่งซื้อเรียบร้อยแล้ว',
                status: 'success',
                dismissible: true,
                dismissAfter: 1000
            }))
            if (where === 'search') {
                dispatch(setSearchWistList(product.id, null))
            } else if (where === 'cart') {
                dispatch(setCartWistList(product.id, null))
            }
        }
    }).catch((err) => {
        console.error(err);
        dispatch(notify({
            id: 'wishlist',
            message: 'ไม่สามารถลบสินค้าที่ต้องการสั่งซื้อได้',
            status: 'error',
            dismissible: true,
            dismissAfter: 1000
        }))
    })
}

export const submitAddRetailUnit = (units) => async (dispatch,getState) => {

    dispatch(addNotification({
        id: 'addRetailUnit',
        message: 'กำลังดำเนินการ...',
        status: 'loading',
        dismissible: true,
        dismissAfter: 10000
    }));
    const {settings: {token, company: {slug : company_slug}}, product: {id : product_id, reference_code}, notifications} = getState();

    try{
        const resp = await addRetailUnits(token, company_slug, product_id, units);
        dispatch({type: SUBMIT_RETAIL_UNIT});
        return dispatch(searchProducts(reference_code));
    } catch(err){
        return dispatch(addNotification({
            title: 'ผิดพลาด',
            message: 'เกิดข้อผิดพลาดไม่สามารถเพิ่มหน่วยขายปลีกได้ !!',
            status: 'error',
            dismissAfter: 3000,
            id: 'addRetailErrMsg'
        }))
    }

}

export const cancelAddRetailUnit = () => dispatch => {
    return dispatch({type: CANCEL_RETAIL_UNIT});
}
