import React from 'react'
import { connect } from 'react-redux'
import { Container, Row, Col } from 'reactstrap'
import IdleTimer from 'react-idle-timer'

import Header from 'Containers/Header'
import Search from 'Containers/Search'
import Cart from 'Containers/Cart'
import Footer from 'Containers/Footer'
import Customer from 'Containers/Customer'
import FinalPrice from 'Containers/FinalPrice'
import GlobalShortCut from 'Containers/GlobalShortcut';
import ConfirmModal from 'Components/Modal/ConfirmModal';
import NotiWrongTimeModal from 'Components/Modal/NotiWrongTimeModal';

import { loadSettings
    , loadCarts
    , getAds
    , checkDuplicateTab } from 'Redux/actions';

class PosPage extends React.Component {
    constructor(props) {
        super(props)
        this.idleTimer = null;
        
        checkDuplicateTab()
    }

    componentWillMount() {
        this.props.loadSettings()
        this.props.loadCarts()
    }

    render() {
        const { loader, confirm, language } = this.props
        
        const idleTimeout = parseInt(process.env.ADS_IDLE_TIMEOUT) || 1000*300

        if (loader.loadSettings) {
            return (
                <div className="loading-overlay">
                    <div className="loading-square">
                        <div className="loading-square-part loading-square-sky"></div>
                        <div className="loading-square-part loading-square-blue"></div>
                        <div className="loading-square-blend"></div>
                    </div>
                </div>
            )
        }

        return (
            <GlobalShortCut>
                <IdleTimer
                    ref={ref => this.idleTimer = ref}
                    onIdle={this.props.getAds}
                    timeout={idleTimeout}>
                    <Header />
                    <Container fluid>
                        <Row className="mt-2">
                            <Col xs="8">
                                <Search />
                                <Cart />
                                <Footer />
                            </Col>
                            <Col xs="4">
                                <Customer />
                                <FinalPrice />
                                <ConfirmModal language={language} {...confirm}/>
                                <NotiWrongTimeModal
                                    language={language}
                                    notiWrongTime={this.props.notiWrongTime}
                                />
                            </Col>
                        </Row>
                    </Container>
                </IdleTimer>
            </GlobalShortCut>
        )
    }
}

const mapStateToProps = state => {
    return {
        loader: state.loader,
        confirm: state.confirm,
        language: state.language,
        notiWrongTime: state.modal.notiWrongTime,
    }
}

export default connect(mapStateToProps, {
    loadSettings
    , loadCarts
    , getAds })(PosPage)
