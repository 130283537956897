import React from 'react'
import { Modal, ModalHeader, ModalBody, Table, Button, Row, Col } from 'reactstrap'
import { findUnitDetails } from 'Redux/actions';

class AddStockSetModal extends React.Component {
    render() {
        const { addStockSet, closeAddStockSet, language } = this.props
        const productList = this.props.products.map((product, index) => {
            const unitSelected = product.units.find(unit => unit.pivot.is_smallest_unit === 1)
            let unit = findUnitDetails(product.units, product.unit_id);
            return (
                <tr key={index}>
                    <td style={{ textAlign: 'left', width: '12%' }}>{product.reference_code}</td>
                    <td style={{ textAlign: 'left' }}>{product.name}</td>
                    <td style={{ textAlign: 'right', width: '14%' }}>{product.quantity * unit.quantity_of_smallest_unit}</td>
                    <td style={{ textAlign: 'center' }}>{unitSelected.name}</td>
                    <td style={{ textAlign: 'right', width: '18%' }}>{product.remaining_quantity}</td>
                    <td style={{ textAlign: 'center' }}>
                        <Button
                            color="primary"
                            onClick={() => this.props.addDummy(product, 1)}>
                            <i className="fa fa-shopping-cart"></i> {language.add_to_stock}
                        </Button>
                    </td>
                </tr>
            )
        })
        return (
            <Modal
                isOpen={addStockSet.showModal}
                toggle={closeAddStockSet}
                size="lg">
                <ModalHeader toggle={closeAddStockSet}>{language.product_not_enough}</ModalHeader>
                <ModalBody>
                    <p>{language.cannot_sell_set} "{addStockSet.productName}" {language.as_not_enough}</p>
                    <Table className="addstockset-tabel" hover>
                        <thead>
                            <tr className="header-row">
                                <th></th>
                                <th></th>
                                <th style={{ textAlign: 'right' }}>{language.quantity_in_set}</th>
                                <th style={{ textAlign: 'center' }}>{language.unit}</th>
                                <th style={{ textAlign: 'right' }}>{language.remaining_quantity}</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {productList}
                        </tbody>
                    </Table>
                    <Row>
                        <Col style={{ padding: '10px',paddingLeft: '20px', paddingRight: '20px' }}>
                            <span className="text-danger">
                                {language.add_to_stock_warning}
                            </span>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={{ size: 2, offset: 5 }}>
                            <Button
                                color="danger"
                                onClick={closeAddStockSet}
                                block>
                                {language.cancel}
                            </Button>
                        </Col>
                    </Row>
                </ModalBody>
            </Modal>
        )
    }
}

export default AddStockSetModal