import React from 'react'
import ReactDOM from 'react-dom'
import { Modal, ModalBody, Row, Col, Button } from 'reactstrap'
import { priceFormat } from 'Utilz/currency';

class CheckedOutModal extends React.Component {
    constructor(props) {
        super(props)
        this._printBill = this._printBill.bind(this)
        this._handleKeyDown = this._handleKeyDown.bind(this)
    }

    _printBill() {
        this.props.print('checkout')
    }

    _handleKeyDown(e) {
        const keyCode = e.keyCode

        if (keyCode === 13) {
            this.props.closeCheckedOut()    
            setTimeout(() => {
                this.props.onClose()
            })
        } else if (keyCode === 80) {
            this._printBill()
        } else if (keyCode === 81) {
            this.props.employeeLogout()
        }
    }

    render() {
        const { showCheckedOutModal, language, closeCheckedOut, saleReceipt, employeeLogout, onBlur} = this.props

        return (
            <Modal isOpen={showCheckedOutModal}
                size="lg"
                toggle={closeCheckedOut}
                onKeyDown={this._handleKeyDown}>
                <ModalBody>
                    <Row>
                        <Col className="text-center">
                            <h1>{language.save_sale_finish}</h1>
                            <div className="summary-detail">
                                <div className="text-success ok-sign-area">
                                    <i className="fa fa-check-circle"></i>
                                </div>
                                <h2>{language.receipt_number} {saleReceipt.reference_code ? `#${saleReceipt.reference_code}` : '#SRxxxxx-xxxxx'}</h2>
                                {saleReceipt.prescription_reference_code && (
                                    <h2>{language.prescription} #{saleReceipt.prescription_reference_code}</h2>
                                )}
                                {saleReceipt.abb.reference_code !== null  && <h2>{language.tax_invoice} #{saleReceipt.abb.reference_code}</h2>}
                                <h3>{language.membership}: {saleReceipt.member.name}</h3>
                                <h3 className="mt-3">{language.total_price} {priceFormat(saleReceipt.net_amount)} {language.baht}</h3>
                                <h3>
                                    {language.discount} {priceFormat(saleReceipt.total_discount_amount)} {language.baht}
                                    {!!saleReceipt.coupon && <span> | Coupon code: {saleReceipt.coupon.owner} - {saleReceipt.coupon.code}</span>}
                                </h3>
                                <h3>{language.price_payment} {priceFormat(saleReceipt.total_paid_amount)} {language.baht}</h3>
                                {
                                    saleReceipt.credit_charge > 0 &&
                                    <h3>{language.credit_charge} {priceFormat(saleReceipt.credit_charge)} {language.baht}</h3>
                                }
                                <h3>{language.cash_receive} {priceFormat(saleReceipt.paid_amount)} {language.baht}</h3>
                                <h1 className="change_amount">{language.change_amount} {priceFormat(saleReceipt.change_amount)} {language.baht}</h1>
                                <label>{language.press_key} "Q" {language.press_continue}</label>
                            </div>
                            <Row>
                                <Col className="text-center">
                                    <Button color='primary' onClick={closeCheckedOut} ref='btnCon' onBlur={onBlur}><i className="fa fa-play"></i> {language.continue_transaction}</Button>{' '}
                                    <Button color="success" onClick={this._printBill}><i className="fa fa-print"></i> {language.print_receipt} (P)</Button>{' '}
                                    <Button color='danger' onClick={employeeLogout}><i className="fa fa-sign-out"></i> {language.logout} (Q)</Button>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </ModalBody>
            </Modal>
        )
    }

    componentDidUpdate(preProps){
        const { focus } = this.props;
        const { focus: wasFocus } = preProps
        
        setTimeout(function() {
            if(!wasFocus && focus){ 
                if (this.refs.btnCon) {
                    ReactDOM.findDOMNode(this.refs.btnCon).focus();
                }
            }
        }.bind(this), 500);
    }
}

export default CheckedOutModal
