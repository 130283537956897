import React from 'react';
import classnames from 'classnames';

const InputFormGroup = ({ label, type, name, value, disabled, onChange, placeholder, md, isRequired, error, small, step, hint }) => (
    <div className={classnames('form-group', md ? `col-md-${md}` : false)}>
        <label>{isRequired && <span className="text-danger">*</span>} {label}:</label>
        {hint && <div className="text-muted small">{hint}</div>}
        <input
            type={type}
            step={step}
            className={classnames('form-control', { 'is-invalid': error, 'form-control-sm': small })}
            name={name}
            value={value}
            disabled={disabled}
            onChange={onChange}
            placeholder={placeholder}
        />
        {error && <div className="invalid-feedback">{error}</div>}
    </div>
)

export default InputFormGroup
