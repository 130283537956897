import {
    QR_CONNECTED,
    QR_PAID,
    QR_PAYMENT_CLOSE,
    QR_PAYMENT_OPEN,
    QR_PAYMENT_SUCCESS,
    QR_RESULT_CLOSE,
} from 'Redux/actionTypes';

import moment from 'moment';

const initialState = {
    errorCode: null,
    errorDesc: null,
    statusCode: null,
    id: null,
    accountName: '',
    qrCode: '',
    expDt: moment().format(),
    txnAmount: 0,
    payFor: 'sale_receipt', // "sale_receipt", "service_delivery"
};

export default (state = initialState, { type, payload }) => {
    switch (type) {
        case QR_PAYMENT_CLOSE:
        case QR_PAID:
            return Object.assign({}, state, { id: null });
        case QR_PAYMENT_SUCCESS:
            return Object.assign({}, state, payload);
        case QR_RESULT_CLOSE:
            return initialState;
        case QR_PAYMENT_OPEN:
            return Object.assign({}, initialState, payload);
        case QR_CONNECTED:
            return Object.assign({}, state, payload.qr);
        default:
            return state;
    }
}
