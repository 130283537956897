import React from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import CustomerRegisterForm from 'Components/Form/CustomerRegisterForm';

class CustomerRegister extends React.Component {
    render() {
        const { showCustomerRegister, registerCustomer, closeCustomerRegister, language } = this.props;
        return (
            <Modal
                isOpen={showCustomerRegister}
                toggle={closeCustomerRegister}
                size="lg"
                style={{ maxWidth: '100%' }}
            >
                <ModalHeader
                    toggle={closeCustomerRegister}
                >
                    {this.props.customer.data.id ? language.edit_member : language.register_member}
                </ModalHeader>
                <ModalBody>
                    <CustomerRegisterForm
                        language={language}
                        setCustomerValue={this.props.setCustomerValue}
                        customer={this.props.customer}
                        addAllergyInfo={this.props.addAllergyInfo}
                        removeAllergyInfo={this.props.removeAllergyInfo}
                        getAllergy={this.props.getAllergy}
                        clearAllergy={this.props.clearAllergy}
                        handleAllergyChange={this.props.handleAllergyChange}
                        onSubmit={registerCustomer}
                        onCancel={closeCustomerRegister}
                        showCustomerRegister={showCustomerRegister}
                    />
                </ModalBody>
            </Modal>
        );
    }
}

export default CustomerRegister;
