import React, { Fragment } from 'react'
import { Row, Col, Button } from 'reactstrap'

class SuccessForm extends React.Component {
    render() {
        const { language } = this.props
        return (
            <Fragment>
                <div className="text-center my-4">
                    <h3>{language.done_title}</h3>
                </div>
                <Row className="justify-content-center mx-0">
                    <Col className="col-lg-8">
                        <div className="text-success ok-sign-area">
                            <i className="fa fa-check-circle"></i>
                        </div>
                        <div className="form-group my-4">
                            <Button
                                color="success"
                                onClick={this.props.backToPos}
                                block>
                                {language.done}
                            </Button>
                        </div>
                    </Col>
                </Row>
            </Fragment>
        )
    }
}

export default SuccessForm