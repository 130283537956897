export const ServicesModalScreen = {
    DELIVERY_MENU: 'DELIVERY_MENU',
    FOODPANDA: 'FOODPANDA',
    HISTORY: 'HISTORY',
};

export const ServiceDeliveryStatus = {
    WAITING_FOR_PAYMENT: 'WAITING_FOR_PAYMENT',
    CONFIRMED: 'CONFIRMED',
    WAITING_FOR_TRANSPORT: 'WAITING_FOR_TRANSPORT',
    WAITING_TIMEOUT: 'WAITING_TIMEOUT',
    ASSIGNED_TO_TRANSPORT: 'ASSIGNED_TO_TRANSPORT',
    NEAR_SENDER: 'NEAR_SENDER',
    PICKED_UP: 'PICKED_UP',
    NEAR_RECEIVER: 'NEAR_RECEIVER',
    DELIVERED: 'DELIVERED',
    PROVIDER_CANCELLED: 'PROVIDER_CANCELLED',
    CANCELLED: 'CANCELLED',
    RETRY: 'RETRY',
};

export const ServiceDeliveryStatuses = [
    {
        id: 'all',
        name: 'ทั้งหมด',
    },
    {
        id: ServiceDeliveryStatus.WAITING_FOR_PAYMENT,
        name: 'รอชำระเงิน',
        color: 'warning',
    },
    {
        id: ServiceDeliveryStatus.CONFIRMED,
        name: 'ยืนยันแล้ว',
        color: 'warning',
    },
    {
        id: ServiceDeliveryStatus.WAITING_FOR_TRANSPORT,
        name: 'กำลังหาคนขับ',
        color: 'info',
    },
    {
        id: ServiceDeliveryStatus.WAITING_TIMEOUT,
        name: 'ทำรายการไม่สำเร็จ',
        color: 'danger',
    },
    {
        id: ServiceDeliveryStatus.ASSIGNED_TO_TRANSPORT,
        name: 'คนขับกำลังไปรับพัสดุ',
        color: 'info',
    },
    {
        id: ServiceDeliveryStatus.NEAR_SENDER,
        name: 'คนขับใกล้ถึงร้าน',
        color: 'info',
    },
    {
        id: ServiceDeliveryStatus.PICKED_UP,
        name: 'อยู่ระหว่างขนส่ง',
        color: 'info',
    },
    {
        id: ServiceDeliveryStatus.NEAR_RECEIVER,
        name: 'คนขับใกล้ถึงผู้รับ',
        color: 'info',
    },
    {
        id: ServiceDeliveryStatus.DELIVERED,
        name: 'ทำรายการสำเร็จ',
        color: 'success',
        forceColor: '#67d786'
    },
    {
        id: ServiceDeliveryStatus.CANCELLED,
        name: 'ยกเลิกออเดอร์',
        color: 'danger',
    },
    {
        id: ServiceDeliveryStatus.PROVIDER_CANCELLED,
        name: 'ทำรายการไม่สำเร็จ',
        color: 'danger',
    },
    {
        id: ServiceDeliveryStatus.RETRY,
        name: 'ยกเลิกออเดอร์ (ลองใหม่)',
        color: 'danger',
    },
];

export const ServiceDeliveryProviders = [
    {
        id: 'FOODPANDA',
        name: 'foodpanda',
    },
];

export const ServiceDeliveryPaymentStatus = {
    WAITING: 'WAITING',
    PAID: 'PAID',
    WAITING_FOR_REFUND: 'WAITING_FOR_REFUND',
    REFUNDED: 'REFUNDED',
    TRANSFER_TO_NEW_ORDER: 'TRANSFER_TO_NEW_ORDER',
    FREE: 'FREE',
};

export const ServiceDeliveryPaymentStatuses = [
    {
        id: 'all',
        name: 'ทั้งหมด',
    },
    {
        id: ServiceDeliveryPaymentStatus.WAITING,
        name: 'รอชำระเงิน',
    },
    {
        id: ServiceDeliveryPaymentStatus.PAID,
        name: 'ชำระด้วย Thai QR',
    },
    {
        id: ServiceDeliveryPaymentStatus.WAITING_FOR_REFUND,
        name: 'ชำระด้วย Thai QR (รอคืนเงิน)',
    },
    {
        id: ServiceDeliveryPaymentStatus.REFUNDED,
        name: 'คืนเงินแล้ว',
    },
    {
        id: ServiceDeliveryPaymentStatus.TRANSFER_TO_NEW_ORDER,
        name: 'ถูกโอนไปยังออเดอร์ใหม่',
    },
    {
        id: ServiceDeliveryPaymentStatus.FREE,
        name: 'บริการฟรี',
    },
];


