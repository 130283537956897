export const ensureMediaPermissions = async () => {
    const devices = await getEnumerateDevices();
    const shouldAskForMediaPermissions = devices.every(
        (device) => !(device.deviceId && device.label)
    );
    // if (shouldAskForMediaPermissions) {
    //     const mediaStream = await navigator.mediaDevices.getUserMedia({ audio: true });
    //     mediaStream.getTracks().forEach((track) => track.stop());
    //     return shouldAskForMediaPermissions;
    // }
    return shouldAskForMediaPermissions;
};

export const getDevices = async () => {
    // await ensureMediaPermissions();
    // const devices = await getEnumerateDevices();
    // const kinds = ['audioinput', 'audiooutput'];
    // return kinds.reduce((device, kind) => {
    //     device[kind] = getDevicesOfKind(devices, kind);
    //     return device;
    // }, {});
};

const getEnumerateDevices = () => {
    return navigator.mediaDevices.enumerateDevices();
};

const getDevicesOfKind = (devices, kind) => {
    return devices.filter((device) => device.kind === kind);
};

export const getUserMedia = async (constraints, cb) => {
    try {
        const stream = await navigator.mediaDevices.getUserMedia(constraints);
        cb(null, stream);
        stream.getTracks().forEach((track) => track.stop());
    } catch (error) {
        cb(error);
        console.dir(error);
    }
};

export default {
    getUserMedia,
    // getDevices,
};
