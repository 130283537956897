import React from 'react';
import Barcode from 'react-barcode';
import { numberFormat, priceFormat } from 'Utilz/currency';
import print from 'Utilz/print';

class PrintPage extends React.Component {
    state = {
        settings: {
            company: {
                name: '',
                lastSalesReceipt: [],
            },
            branch: {
                name: '',
                address: '',
                phone_no: '',
                showOnBill: {
                    company: true,
                    branch: true,
                    address: true,
                    phone: true,
                },
            },
            tax_show: 0,
            tax_types: 'no_vat',
        },
        employee: {
            company_tax_id: '',
            employee: {
                first_name: '',
            },
        },
        saleReceipt: {
            reference_code: '',
            prescription_reference_code: '',
            created_at: '',
            member: {
                name: '',
            },
            printer_license: '',
            abb: {
                reference_code: '',
            },
            details: [],
            price: 0,
            total_discount_amount: 0,
            total_paid_amount: 0,
            tax_percentage: 0,
            tax_amount: 0,
            payment_types: 'cash',
            paid_amount: 0,
            credit_charge: 0,
            change_amount: 0,
            coupon: null,
        },
    };

    componentDidMount() {
        const printSalesReceiptData = localStorage.getItem('printSalesReceipt');
        const data = JSON.parse(printSalesReceiptData);

        if (data === null) {
            return this.setState({ settings: null, employee: null, saleReceipt: null });
        }

        const { settings, employee, saleReceipt } = data;

        this.setState({ settings, employee, saleReceipt }, () => {
            print({ id: 'printSalesReceipt', onAfterPrint: this._onAfterPrint });
        });
    }

    _onAfterPrint = () => {
        localStorage.removeItem('printSalesReceipt');
        window.parent.focus();
        window.close();
    };

    render() {
        const { settings, employee, saleReceipt } = this.state;

        if (settings === null || employee === null || saleReceipt === null) {
            return null;
        }

        const {
            company: { name: companyName = '', lastSalesReceipt = [] },
            branch: {
                name: branchName = '',
                address = '',
                phone_no: phone = '',
                showOnBill: {
                    company: showCompanyOnBill = true,
                    branch: showBranchOnBill = true,
                    address: showAddressOnBill = true,
                    phone: showPhoneOnBill = true,
                },
            },
            tax_show: showTax = 0,
            tax_types: taxType = 'no_vat',
        } = settings;

        const {
            company_tax_id: companyTaxId = '',
            employee: { first_name: employeeName = '' },
        } = employee;

        const {
            reference_code: saleReceiptReferenceCode = '',
            prescription_reference_code: prescriptionReferenceCode = '',
            created_at: createdAt = '',
            member: { name: memberName = '' },
            printer_license: printerLicense = '',
            abb: { reference_code: abbReferenceCode = '' },
            details = [],
            price: summaryPrice = 0,
            total_discount_amount: totalDiscountAmount = 0,
            total_paid_amount: totalPaidAmount = 0,
            tax_percentage: taxPercentage = 0,
            tax_amount: taxAmount = 0,
            payment_types: paymentType = 'cash',
            paid_amount: paidAmount = 0,
            credit_charge: creditCharge = 0,
            change_amount: changeAmount = 0,
        } = saleReceipt;

        return (
            <div id="printSalesReceipt">
                <div>
                    {showCompanyOnBill && (
                        <h4
                            style={{
                                textAlign: txtCenter,
                                fontSize: fontSize14,
                                padding,
                                margin,
                                fontFamily,
                                width: maxWidth,
                            }}
                        >
                            {companyName}
                        </h4>
                    )}
                    {showBranchOnBill && (
                        <h6
                            style={{
                                textAlign: txtCenter,
                                fontSize: fontSize11,
                                fontWeight: fwBold,
                                padding,
                                margin,
                                width: maxWidth,
                            }}
                        >
                            {showCompanyOnBill && 'สาขา'} {branchName}
                        </h6>
                    )}
                    {showAddressOnBill && (
                        <h6
                            style={{
                                textAlign: txtCenter,
                                fontSize: fontSize11,
                                padding,
                                margin,
                                width: maxWidth,
                            }}
                        >
                            {address}
                        </h6>
                    )}
                    {showPhoneOnBill && (
                        <h6
                            style={{
                                textAlign: txtCenter,
                                fontSize: fontSize11,
                                padding,
                                margin,
                                width: maxWidth,
                            }}
                        >
                            Tel: {phone}
                        </h6>
                    )}
                </div>
                <div>
                    {abbReferenceCode && (
                        <table style={{ width: maxWidth }}>
                            <tbody>
                                <tr>
                                    <td style={{ fontSize: fontSize8 }}>ใบกำกับภาษีอย่างย่อ #{abbReferenceCode}</td>
                                </tr>
                                <tr>
                                    <td style={{ fontSize: fontSize8 }}>เลขประจำตัวผู้เสียภาษี {companyTaxId}</td>
                                </tr>
                                {printerLicense && (
                                    <tr>
                                        <td style={{ fontSize: fontSize8 }}>POS No. {printerLicense}</td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    )}
                    <table style={{ width: maxWidth }}>
                        <tbody>
                            <tr>
                                <td style={{ fontSize: fontSize8 }}>หมายเลขใบเสร็จ {saleReceiptReferenceCode}</td>
                            </tr>
                            {createdAt && (
                                <tr>
                                    <td style={{ fontSize: fontSize8 }}>
                                        เมือวันที่ {createdAt.split(' ')[0]} เวลา {createdAt.split(' ')[1]}
                                    </td>
                                </tr>
                            )}
                            {prescriptionReferenceCode && (
                                <tr>
                                    <td style={{ fontSize: fontSize8 }}>ใบสั่งยา #{prescriptionReferenceCode}</td>
                                </tr>
                            )}
                            <tr>
                                <td>
                                    <div
                                        style={{
                                            float: 'left',
                                            textAlign: 'left',
                                        }}
                                    >
                                        <span style={{ fontSize: fontSize8 }}>{memberName}</span>
                                    </div>
                                    <div
                                        style={{
                                            float: 'right',
                                            textAlign: 'right',
                                        }}
                                    >
                                        <span style={{ fontSize: fontSize8 }}>พนักงานขาย {employeeName}</span>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <table style={{ fontSize: fontSize11, width: maxWidth }}>
                        <thead>
                            <tr>
                                <th style={{ width: row1, textAlign: txtLeft }}>#</th>
                                <th style={{ width: row2, textAlign: txtLeft }}>ชื่อ</th>
                                <th style={{ width: row4 }}></th>
                                <th style={{ width: row5, textAlign: txtRight }}>ราคา</th>
                                <th style={{ width: row6, textAlign: txtRight }}>&nbsp;&nbsp;&nbsp;รวม</th>
                            </tr>
                        </thead>
                        <tbody>
                            {details.map((detail, dIndex) => {
                                const {
                                    product: { id: productId = 0, name: productName = '' },
                                    products = [],
                                    unit: { id: unitId = 0, name: unitName = '' },
                                    sales_quantity: salesQuantity = 1,
                                    price_per_unit: pricePerUnit = 0,
                                    net_amount: netAmount = 0,
                                } = detail;

                                return (
                                    <React.Fragment key={`detail_${productId}_${unitId}_${dIndex}`}>
                                        <tr>
                                            <td
                                                valign="top"
                                                style={{ width: row1, fontSize: fontSize8, textAlign: txtLeft }}
                                            >
                                                {dIndex + 1}
                                            </td>
                                            {productName.length >= 18 ? (
                                                <td
                                                    valign="top"
                                                    style={{ width: row2, fontSize: fontSize5, textAlign: txtLeft }}
                                                >
                                                    {productName}
                                                </td>
                                            ) : (
                                                <td
                                                    valign="top"
                                                    style={{ width: row2, fontSize: fontSize8, textAlign: txtLeft }}
                                                >
                                                    {productName}
                                                </td>
                                            )}
                                            <td
                                                valign="top"
                                                style={{ width: row4, fontSize: fontSize8, textAlign: txtRight }}
                                            >
                                                {numberFormat(salesQuantity)} {unitName}
                                            </td>
                                            <td
                                                valign="top"
                                                style={{ width: row5, fontSize: fontSize8, textAlign: txtRight }}
                                            >
                                                {priceFormat(pricePerUnit)}
                                            </td>
                                            <td
                                                valign="top"
                                                style={{ width: row6, fontSize: fontSize8, textAlign: txtRight }}
                                            >
                                                {priceFormat(netAmount)}
                                            </td>
                                        </tr>
                                        {products.map((product, pIndex) => {
                                            const {
                                                name: pName = '',
                                                quantity = 1,
                                                unit: { name: pUnitName = '' },
                                                product_is_free: isFree = 0,
                                            } = product;
                                            return (
                                                <tr key={`product_${productId}_${unitId}_${pIndex}`}>
                                                    <td valign="top" style={{ width: row1, fontSize: fontSize8 }}></td>
                                                    {pName.length >= 18 ? (
                                                        <td
                                                            valign="top"
                                                            style={{
                                                                width: row2,
                                                                fontSize: fontSize5,
                                                                textAlign: txtLeft,
                                                            }}
                                                        >
                                                            {pName}
                                                        </td>
                                                    ) : (
                                                        <td
                                                            valign="top"
                                                            style={{
                                                                width: row2,
                                                                fontSize: fontSize8,
                                                                textAlign: txtLeft,
                                                            }}
                                                        >
                                                            {pName}
                                                        </td>
                                                    )}
                                                    <td
                                                        valign="top"
                                                        style={{
                                                            width: row4,
                                                            fontSize: fontSize8,
                                                            textAlign: txtRight,
                                                        }}
                                                    >
                                                        {numberFormat(quantity)} {pUnitName}
                                                    </td>
                                                    <td
                                                        valign="top"
                                                        style={{
                                                            width: row5,
                                                            fontSize: fontSize8,
                                                            textAlign: txtRight,
                                                        }}
                                                    >
                                                        {isFree === 1 && <span>FREE</span>}
                                                    </td>
                                                    <td
                                                        valign="top"
                                                        style={{
                                                            width: row6,
                                                            fontSize: fontSize8,
                                                            textAlign: txtRight,
                                                        }}
                                                    ></td>
                                                </tr>
                                            );
                                        })}
                                    </React.Fragment>
                                );
                            })}
                        </tbody>
                    </table>
                    <table
                        style={{
                            borderCollapse: 'collapse',
                            borderSpacing: 0,
                            width: maxWidth,
                        }}
                    >
                        <tfoot>
                            {showTax == 1 && taxType !== 'no_vat' && (
                                <tr
                                    style={{
                                        borderTop: border,
                                        fontSize: fontSize8,
                                    }}
                                >
                                    <td
                                        style={{
                                            textAlign: txtRight,
                                            width: footerW,
                                        }}
                                    >
                                        ยอดชำระสุทธิ
                                    </td>
                                    <td
                                        style={{
                                            paddingLeft: paddingL,
                                            textAlign: txtRight,
                                        }}
                                    >
                                        {priceFormat(summaryPrice)}
                                    </td>
                                </tr>
                            )}
                            <tr
                                style={{
                                    borderTop: border,
                                    fontSize: fontSize8,
                                }}
                            >
                                <td
                                    style={{
                                        textAlign: txtRight,
                                        width: footerW,
                                    }}
                                >
                                    ส่วนลด
                                </td>
                                <td
                                    style={{
                                        paddingLeft: paddingL,
                                        textAlign: txtRight,
                                    }}
                                >
                                    {priceFormat(totalDiscountAmount)}
                                </td>
                            </tr>
                            <tr
                                style={{
                                    borderTop: border,
                                    fontSize: fontSize8,
                                }}
                            >
                                <td
                                    style={{
                                        textAlign: txtRight,
                                        width: footerW,
                                    }}
                                >
                                    ยอดชำระรวม
                                </td>
                                <td
                                    style={{
                                        paddingLeft: paddingL,
                                        textAlign: txtRight,
                                    }}
                                >
                                    {priceFormat(totalPaidAmount)}
                                </td>
                            </tr>
                            {showTax == 1 && taxType !== 'no_vat' && (
                                <tr
                                    style={{
                                        borderTop: border,
                                        fontSize: fontSize8,
                                    }}
                                >
                                    <td
                                        style={{
                                            textAlign: txtRight,
                                            width: footerW,
                                        }}
                                    >
                                        ภาษีมูลค่าเพิ่ม {taxPercentage}%
                                    </td>
                                    <td
                                        style={{
                                            paddingLeft: paddingL,
                                            textAlign: txtRight,
                                        }}
                                    >
                                        {priceFormat(taxAmount)}
                                    </td>
                                </tr>
                            )}
                            <tr style={{ fontSize: fontSize8 }}>
                                <td
                                    style={{
                                        textAlign: txtRight,
                                        width: footerW,
                                    }}
                                >
                                    {paymentType === 'transfer'
                                        ? 'โอนเงิน'
                                        : paymentType === 'credit'
                                        ? 'บัตรเครดิต'
                                        : paymentType === 'thai_qr'
                                        ? 'THAI QR'
                                        : paymentType === 'alipay'
                                        ? 'Alipay'
                                        : paymentType === 'wechat_pay'
                                        ? 'WeChat Pay'
                                        : paymentType === 'line_pay'
                                        ? 'Rabbit LINE Pay'
                                        : 'เงินสด'}
                                </td>
                                <td
                                    style={{
                                        paddingLeft: paddingL,
                                        textAlign: txtRight,
                                    }}
                                >
                                    {priceFormat(paidAmount)}
                                </td>
                            </tr>
                            {creditCharge > 0 && (
                                <tr style={{ fontSize: fontSize8 }}>
                                    <td
                                        style={{
                                            textAlign: txtRight,
                                            width: footerW,
                                        }}
                                    >
                                        ค่าธรรมเนียม
                                    </td>
                                    <td
                                        style={{
                                            paddingLeft: paddingL,
                                            textAlign: txtRight,
                                        }}
                                    >
                                        {priceFormat(creditCharge)}
                                    </td>
                                </tr>
                            )}
                            {paymentType === 'cash' && (
                                <tr style={{ fontSize: fontSize8 }}>
                                    <td
                                        style={{
                                            textAlign: txtRight,
                                            width: footerW,
                                        }}
                                    >
                                        เงินทอน
                                    </td>
                                    <td
                                        style={{
                                            paddingLeft: paddingL,
                                            textAlign: txtRight,
                                        }}
                                    >
                                        {priceFormat(changeAmount)}
                                    </td>
                                </tr>
                            )}
                            {!!saleReceipt.coupon && (
                                <tr style={{ fontSize: fontSize8 }}>
                                    <td style={{ textAlign: txtRight }} colSpan={2}>
                                        Coupon code: {saleReceipt.coupon.owner} - {saleReceipt.coupon.code}
                                    </td>
                                </tr>
                            )}
                        </tfoot>
                    </table>
                    <div style={{ textAlign: txtCenter }}>
                        <div
                            style={{
                                borderBottom: border,
                                borderTop: border,
                                fontSize: fontSize8,
                            }}
                        >
                            {lastSalesReceipt.map((item, index) => {
                                switch (item.bold_type + item.size_type) {
                                    case '10':
                                        return (
                                            <div key={index} style={{ fontWeight: fwBold }}>
                                                {item.message}
                                            </div>
                                        );
                                    case '01':
                                        return (
                                            <div key={index} style={{ fontSize: fontSize14 }}>
                                                {item.message}
                                            </div>
                                        );
                                    case '11':
                                        return (
                                            <div key={index} style={{ fontWeight: fwBold, fontSize: fontSize14 }}>
                                                {item.message}
                                            </div>
                                        );
                                    default:
                                        return (
                                            <div key={index} style={{ fontSize: fontSize8 }}>
                                                {item.message}
                                            </div>
                                        );
                                }
                            })}
                        </div>
                        {saleReceiptReferenceCode && (
                            <Barcode value={saleReceiptReferenceCode} style={{ textAlign: txtCenter }} height={50} />
                        )}
                        <div style={{ fontFamily }}>Powered by Arincare.com</div>
                    </div>
                </div>
            </div>
        );
    }
}

const margin = 0;
const padding = 0;
const paddingL = '10px';
const fontFamily = 'Tahoma';
const fontSize5 = '14px';
const fontSize8 = '15px';
const fontSize11 = '16px';
const fontSize14 = '18px';
const txtLeft = 'left';
const txtCenter = 'center';
const txtRight = 'right';
const fwBold = 'bold';
const row1 = '2px';
const row2 = '40px';
const row3 = '30px';
const row4 = '45px';
const row5 = '40px';
const row6 = '40px';
const border = '1px solid #808080';
const footerW = '85%';
const maxWidth = '100%';

export default PrintPage;
