import React from 'react';
import { Row, Col } from 'reactstrap';


class ACHeader extends React.Component{

    render(){
        let num = Math.floor(12 / this.props.headers.length) ;
        let col = 0;
        let headers = this.props.headers.map((header,index) => {
            col = (header.size) ? header.size : num;
            return(
                <Col key={ index } sm={ col } md={ col } lg={ col } >
                    { header.title }
                </Col>
            )
        })
        
        return(
            <Row  className="header-row">
                { headers }
            </Row>
        )
    }
}

export default ACHeader;