import React, { useState } from 'react';
import { connect } from 'react-redux';
import { notify } from 'reapop';
import { Modal, ModalBody, Button } from 'reactstrap';

const DeliveryUserCancelOrderModal = (props) => {
    const { isOpen, language, onConfirm, onClose, dispatch } = props;
    const reasonOptions = [
        {
            text: 'รอคนขับนานเกินไป',
            value: 'WAITED_TOO_LONG',
        },
        {
            text: 'คนขับปฎิเสธออเดอร์',
            value: 'RIDER_DECLINE_ORDER',
        },
        {
            text: 'แก้ไขข้อมูลการจัดส่ง',
            value: 'EDIT_DELIVERY_INFO',
        },
    ];

    const [reasonSelected, setResonSelected] = useState(null);
    const [reasonTextByUser, setResonTextByUser] = useState('');

    const handleConfirm = () => {
        if (reasonTextByUser.length > 250) {
            dispatch(notify({
                id: 'cancel_delivery',
                title: 'ข้อความแจ้งเตือน',
                message: 'สามารถระบุหมายเหตุเพิ่มเติมได้ไม่เกิน 250 ตัวอักษร',
                status: 'error',
                dismissible: true,
                dismissAfter: 3000,
            }));
            return;
        }

        let reason = '';
        const reasonOption = reasonOptions.find(
            (reason) => reason.value === reasonSelected
        );

        if (!reasonOption) {
            if (reasonTextByUser.trim() !== '') {
                reason = reasonTextByUser;
            }
        } else {
            if (reasonTextByUser.trim() === '') {
                reason = reasonOption.text;
            } else {
                reason = `${reasonOption.text} ${reasonTextByUser}`;
            }
        }

        onConfirm(reason);
    };

    const handleClose = () => {
        setResonSelected(null);
        setResonTextByUser('');
        onClose();
    };

    return (
        <Modal isOpen={isOpen}>
            <ModalBody>
                <h4 className="mb-4">หมายเหตุการยกเลิกออเดอร์</h4>
                {reasonOptions.map((reason, i) => (
                    <div
                        key={i}
                        className="form-check"
                        style={{ marginBottom: 15 }}
                    >
                        <input
                            type="radio"
                            id={reason.value}
                            className="form-check-input"
                            value={reason.value}
                            checked={reason.value === reasonSelected}
                            onChange={(e) => setResonSelected(e.target.value)}
                        />
                        <label
                            htmlFor={reason.value}
                            className="form-check-label"
                            style={{ marginLeft: 15 }}
                        >
                            {reason.text}
                        </label>
                    </div>
                ))}
                <div
                    className="form-group"
                    style={{
                        marginLeft: 35,
                        marginBottom: 20,
                        paddingRight: 15,
                    }}
                >
                    <label htmlFor="OTHER">เพิ่มเติม</label>
                    <textarea
                        id="OTHER"
                        className="form-control"
                        value={reasonTextByUser}
                        onChange={(e) => setResonTextByUser(e.target.value)}
                        rows="4"
                        placeholder="โปรดระบุสาเหตุ"
                    ></textarea>
                </div>
                <div className="text-center">
                    <Button
                        color="default"
                        style={{ minWidth: 80, marginRight: 15 }}
                        onClick={handleClose}
                    >
                        {language.close}
                    </Button>
                    <Button
                        color="warning"
                        style={{ minWidth: 80 }}
                        onClick={handleConfirm}
                    >
                        {language.confirm}
                    </Button>
                </div>
            </ModalBody>
        </Modal>
    );
};

const mapStateToProps = (state) => ({
    language: state.language,
});

export default connect(mapStateToProps)(DeliveryUserCancelOrderModal);
