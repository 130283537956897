import axiosHelper from 'Redux/middleware/axios';

const helper = new axiosHelper();

export const convertProductToProductSet2 = ( initialdetail, cart, product) => {
    let details = product.products.map((p) => {
        return Object.assign({},initialdetail,{
            product: p
            , unit:  p.units.find((u) => { return u.id === p.unit_id })
            , product_set_id: product.products_sets_id
            , product_id: p.id
            , unit_id: p.unit_id
            , sales_quantity: p.quantity * cart.present_quantity
            , price_per_unit: p.price_per_unit
            , net_amount: p.price_per_unit * (p.quantity * cart.present_quantity)
            , product_set_type: product.sets_type
        });
    })

    return details;
}

export const convertProductToProductSet1 = ( initialdetail, cart, product) => {
    
    const detail = Object.assign({}, initialdetail,{
        product_set_id: product.products_sets_id
        , products: product.products.map((p) => {
            return Object.assign({},p,{
                unit: p.units.find((u) => {
                    return u.id === p.unit_id
                }),
                quantity_per_set: p.quantity
            })
        })
        , product: {
            units:[{
                name: 'ชุด'
                , retailPrice : { price: product.total_amount } 
                , remaining_quantity: product.remaining_quantity
                , id: 0
                , pivot : {
                  quantity_of_smallest_unit : 1,
                  is_smallest_unit: true
                }
            }]
            , name : product.name
            , costs: {
                first_expire_lot: product.total_amount
            }
            , remaining_quantity: product.remaining_quantity
        }
        , product_set_type: product.sets_type
        , unit: {
            name: 'ชุด'
            , id: 0
            , pivot : {
              quantity_of_smallest_unit : 1
            }
        }
        , price_per_unit: product.total_amount
        , sales_quantity: cart.present_quantity
    })

    return detail;
}

export const isBarcode = (txt) =>{
    if(!txt) return false;
    const regex = new RegExp(/^[0-9]*$/gm);

    return regex.test(txt);
}

export const getAllUnits = async (product_id, company_slug, token) =>{

    const resp = await helper.get(`/companies/${company_slug}/products/${product_id}/units`,{},{
                        'Authorization': `Bearer ${token}`
                    }, 3);
    
    return Promise.resolve(resp);
}

export const addRetailUnits = async (token, company_slug, product_id, units) => {

    const resp = await helper.put(`/companies/${company_slug}/products/${product_id}/units`,{ units: units},{
        'Authorization': `Bearer ${token}`
    }, 3);
    return Promise.resolve(resp);

}