import React from 'react'
import ReactDOM from 'react-dom'
import { Modal, ModalHeader, ModalBody, Table, Button, UncontrolledTooltip } from 'reactstrap'
import Pagination from 'react-js-pagination'
import Loadable from 'react-loading-overlay';

class SearchProductModal extends React.Component {

    render() {
        const { showSearchProducts, clearProducts, language, settings, employee } = this.props

        const canAddInventory = ['OWNER', 'ADMINISTRATOR', 'MANAGER'].includes(employee.role.slug);
        const productList = this.props.products.map((product, id) => {

            let remaining_quantity;
            if(product.units.length > 0){
                const {pivot: {quantity_of_smallest_unit}} = product.units[0];
                remaining_quantity = Math.floor(product.remaining_quantity / quantity_of_smallest_unit);
            }

            let errors = product.errors || [];
            
            return (
                <tr key={id} className="text-center">
                    <td>{product.reference_code}</td>
                    <td>{product.name}</td>
                    <td>
                        {
                        /*
                        {!product.is_product_set && !settings.offline_mode ?
                        product.wishlist_item ?
                        <span>
                            <i className="fa fa-check-circle fa-lg text-primary link"
                            id={'Tooltip-' + product.reference_code + id}
                            onClick={() => this.props.removeWishList(product, 'search')}></i>
                            <UncontrolledTooltip placement="top" target={'Tooltip-' + product.reference_code + id}>Remove from Wish List</UncontrolledTooltip>
                        </span> :
                        <span>
                            <i className="fa fa-heart fa-lg text-danger link"
                            id={'Tooltip-' + product.reference_code + id}
                            onClick={() => this.props.addToWishList(product, 'search')}></i>
                            <UncontrolledTooltip placement="top" target={'Tooltip-' + product.reference_code + id}>Add to Wish List</UncontrolledTooltip>
                        </span> : ''} 
                        */
                        }
                    </td>
                    <td>
                        {((remaining_quantity > 0 || settings.offline_mode) && errors.length === 0) ? 
                        <Button
                            color="success"
                            onClick={() => this.props.selectProduct(product)}
                            ref={'button_' + id}>
                            <i className="fa fa-shopping-cart"></i> {language.add_to_cart}
                        </Button> 
                        :(errors.length > 0)?
                        <Button
                            color="link"
                            ref={'button_' + id}
                            onClick={() => this.props.openProductErrorModal(errors)}
                        >
                            <i className="fa fa-exclamation-triangle"></i> {language.product_problem}
                        </Button>
                        :(remaining_quantity !== undefined) ?
                        <Button
                            color="primary"
                            onClick={() => {
                                if (!Boolean(product.is_product_set)) {
                                    this.props.selectProduct(product);
                                    this.props.setInventoryValue('activeTab', 2);
                                } else {
                                    this.props.addDummy(product);
                                }
                            }}
                            disabled={settings.offline_mode || !canAddInventory}
                            ref={'button_' + id}>
                            <i className="fa fa-shopping-cart"></i> {language.add_to_stock}
                        </Button>:
                        <Button
                            color="warning"
                            onClick={() => this.props.notiUnitProduct(product)}
                            ref={'button_' + id}>
                            <i className="fa fa-shopping-cart"></i> {language.add_retail_unit_btn}
                        </Button>
                    }
                    </td>
                </tr>
            )
        });

        return (
            <Modal 
            isOpen={showSearchProducts} 
            toggle={ clearProducts }
            size="lg"
            >
                <ModalHeader toggle={clearProducts}>{language.search_result}: {this.props.query}</ModalHeader>
                <ModalBody>
                    <Loadable color={'black'} active={this.props.search.productLoading} background={'#ffffff80'} spinner>
                        <div className="product-search-result-container">
                            <div className="product-search-result-body">
                                <Table striped hover>
                                    <thead>
                                        <tr className="header-row">
                                            <td>{language.reference_code}</td>
                                            <td>{language.name}</td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {productList}
                                    </tbody>
                                </Table>
                            </div>
                        </div>
                    </Loadable>
                    {!settings.offline_mode && this.props.search.productTotal !== 0 && <Pagination
                        activePage={this.props.search.productPage}
                        pageRangeDisplayed={10}
                        itemsCountPerPage={this.props.search.productPerPage}
                        totalItemsCount={this.props.search.productTotal}
                        onChange={(page) => this.props.updateProductPaginate(page)}
                        innerClass="pagination"
                        itemClass="page-item"
                        linkClass="page-link"
                        prevPageText="Prev"
                        nextPageText="Next"
                    />}
                </ModalBody>
            </Modal>
        )
    }

    componentDidUpdate(preProps){

        const { focus } = this.props;
        const { focus: wasFocus } = preProps
        
        setTimeout(function() {
            if(!wasFocus && focus){ 
                ReactDOM.findDOMNode(this.refs.button_0).focus();
            }
        }.bind(this), 0);
    }
}

export default SearchProductModal