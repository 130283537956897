import {
    SELECT_PRINTER

} from 'Redux/actionTypes';
import moment from "moment";
import db from "../../utils/db";
import { number } from 'Utilz/currency';
import currency from "currency.js";

export const getTableHeader = (title,field,type,size,renderCell) => {
    return {
        title: title,
        field: field,
        type: type,
        size: size,
        renderCell: renderCell,
    }
}

export const verifyProductSet = (product_set) => {

    let errors = []
    for(var i = 0;i < product_set.products.length; i++){
        var product = product_set.products[i];
        let unit = product.units.find((u) => u.id === product.unit_id);
        if(unit === undefined){
            errors.push({
                message: 'product_unit_in_product_set_invalid',
                product: {
                    reference_code: product.reference_code,
                    name: product.name,
                    unit: '-',
                }
            })
        }
    }
    product_set.errors = errors;
    return product_set;
}

export const checkRemainingSet = (product_set) => {
    var remaining_quantity_list = [];
    for(var i = 0;i < product_set.products.length; i++){
        var product = product_set.products[i];
        let unit = findUnitDetails(product.units, product.unit_id);
        remaining_quantity_list.push(product.remaining_quantity / (product.quantity * unit.quantity_of_smallest_unit));
    }
    var min_quantity_for_set = Math.floor(Math.min.apply(0,remaining_quantity_list));

    return min_quantity_for_set;
}

export const findUnitDetails = (units, unit_id) => {
    let unit_detail = units.find((u) => u.id === unit_id) || {};
    return {
        id: unit_detail.id || null,
        name: unit_detail.name || null,
        is_smallest_unit: unit_detail.pivot ? unit_detail.pivot.is_smallest_unit : 1,
        quantity_of_smallest_unit: unit_detail.pivot ? unit_detail.pivot.quantity_of_smallest_unit : 1,
    };
}

export const isAble = slug => (dispatch,getState) =>{

    const { employee:e } = getState();
    const { employee } = e;

    for(let i = 0;i < employee.role.permissions.length; i++){
        if(employee.role.permissions[i].slug === slug){
            return true;
        }
    }

    return false;
}

export const displayPriceEp = (detail, prescription) => {
    if (prescription.model_no == 'PAY_IN_STORE') {
        return detail.partner_price;
    } else if (prescription.category == 'mc') {
        return detail.pharmacy_price;
    }
    return '-';
}

export const calculatePrice = (cart,where) => {
    if (window.ignoreCalculatePrice) {
        console.log('Ignore calculate price!');
        return Object.assign({}, cart,{
            net_amount: 0,
            total_price: 0,
            tax_amount: 0,
            price: 0,
            total_amount: 0,
            credit_charge: 0,
            total_paid_amount: 0,
            paid_amount: 0,
            change_amount: 0,
            total_discount_amount: 0,
            display_amount: 0,
        })
    }

    let { details,
        tax_types,
        tax_percentage,
        payment_types,
        total_paid_amount: init_total_paid_amount,
        fee,
        credit_include,
        paid_amount: init_paid_amount,
        total_discount_amount: init_total_discount_amount,
        total_price: init_total_price,
        tax_amount: init_tax_amount,
        price: init_price,
        total_amount: init_total_amount,
        change_amount: init_change_amount
    } = cart

    let total_price = init_total_price;
    let tax_amount = init_tax_amount;
    let price = init_price;
    let total_amount = init_total_amount;
    let total_paid_amount = init_total_paid_amount;

    let display_amount = 0;
    let net_amount = 0;
    let paid_amount = init_paid_amount;
    let change_amount = init_change_amount
    let credit_charge = 0;

    net_amount = details.reduce((prev, curr) => prev + number(curr.net_amount).value, 0);

    display_amount = net_amount;

    if (cart.coupon) {
        const discount_amount = Number.isNaN(parseFloat(cart.coupon.discount_amount)) ? 0 : parseFloat(cart.coupon.discount_amount);
        if (cart.coupon.discount_amount_type === 'PERCENTAGE') {
            init_total_discount_amount = number(discount_amount).divide(100).multiply(net_amount).value;
        } else {
            init_total_discount_amount = discount_amount;
        }

        if (init_total_discount_amount >= net_amount) {
            init_total_discount_amount = net_amount;
            total_paid_amount = 0;
        }
    }

    const totalDiscountAmount = number(init_total_discount_amount).value;
    let totalAfterDiscount = number(net_amount).subtract(totalDiscountAmount).value;
    if (totalAfterDiscount < 0) totalAfterDiscount = 0;

    if (tax_types === 'vat_included') {
        const vatRate = number(tax_percentage).divide(100).add(1).value;
        const vatAmount = number(totalAfterDiscount).divide(vatRate).value;
        tax_amount = number(totalAfterDiscount).subtract(vatAmount).value;
        price = number(totalAfterDiscount).subtract(tax_amount).value;
        total_price = number(price).add(tax_amount).value;
        total_amount = total_price;
    } else if (tax_types === 'vat_excluded') {
        tax_amount = number(tax_percentage).divide(100).multiply(totalAfterDiscount).value;
        price = totalAfterDiscount;
        total_price = number(totalAfterDiscount).add(tax_amount).value;
        display_amount = number(net_amount).add(tax_amount).value;
        total_amount = total_price;
    } else if (tax_types === 'no_vat') {
        price = totalAfterDiscount
        total_price = totalAfterDiscount;
        total_amount = total_price;
    }

    total_paid_amount = total_amount;

    switch (payment_types) {
        case 'cash': {
            // change_amount = !paid_amount ? 0 : parseFloat(paid_amount) - total_paid_amount
            change_amount = !paid_amount ? 0 : number(paid_amount).subtract(total_paid_amount).value;
        }
        break
        case 'thai_qr':
        case 'transfer': {
            paid_amount = total_paid_amount
        }
        break
        case 'alipay':
        case 'wechat_pay':
        case 'credit':
        case 'line_pay': {
            const totalAfterDiscount = number(total_price).value;
            credit_charge = currency(fee,{symbol: "",precision: 4}).divide(100).multiply(totalAfterDiscount).value;

            if (where !== 'total_paid_amount') {
                if (credit_include === 'credit_fee_included') {
                    total_paid_amount = totalAfterDiscount;
                } else if (credit_include === 'credit_fee_excluded') {
                    total_paid_amount = number(totalAfterDiscount).add(credit_charge).value;
                }
            }

            paid_amount = total_paid_amount;
            total_amount = total_paid_amount
        }
        break
    }

    if (number(paid_amount).value < total_paid_amount) change_amount = 0;

    return Object.assign({},cart,{
        net_amount: net_amount,
        total_price: total_price,
        tax_amount: tax_amount,
        price: price,
        total_amount: total_amount,
        credit_charge,
        total_paid_amount: total_paid_amount,
        paid_amount: paid_amount,
        change_amount: change_amount,
        total_discount_amount: (/^[0-9.%\b]+$/).test(init_total_discount_amount) ? init_total_discount_amount : totalDiscountAmount,
        display_amount
    })
}

export const calculateSaleReturn = (receipt) => {
    const { tax_types, tax_percentage } = receipt
    let total_amount = receipt.total_amount
    let result_amount = receipt.result_amount
    let net_amount = receipt.net_amount
    let tax_amount = receipt.tax_amount

    if (tax_types === 'vat_included') {
        total_amount = receipt.net_amount
        net_amount = total_amount / (1 + (tax_percentage / 100))
        tax_amount = total_amount - net_amount
    } else if (tax_types === 'vat_excluded') {
        tax_amount = receipt.net_amount * (tax_percentage / 100)
        total_amount = receipt.net_amount + tax_amount
    } else if (tax_types === 'no_vat') {
        total_amount = receipt.net_amount
    }

    result_amount = total_amount

    return Object.assign({}, receipt, {
        total_amount: total_amount,
        result_amount: result_amount,
        net_amount: net_amount,
        tax_amount: tax_amount
    })
}

export const checkPattern = (pattern,value) => {

    if(pattern.test(value)){
        return true;
    }else{
        return false;
    }
}

export const print = (idIFrame, beforePrintCallBack, afterPrintCallBack) => (dispatch) => {

    var beforePrint = function() {
        document.body.removeChild(this.__container__);

        if (beforePrintCallBack) {
            beforePrintCallBack();
        }
    };

    var afterPrint = function() {
        document.body.removeChild(this.__container__);

        if (afterPrintCallBack) {
            afterPrintCallBack();
        }
    };

    function setPrint () {
        this.contentWindow.__container__ = this;
        this.contentWindow.onbeforeunload = beforePrint;
        this.contentWindow.onafterprint = afterPrint;
        this.contentWindow.focus(); // Required for IE
        this.contentWindow.print();
    }

    let strHtml = document.getElementById(idIFrame);

    var oHiddFrame = document.createElement("iframe");
    oHiddFrame.onload = setPrint;
    oHiddFrame.style.visibility = "hidden";
    oHiddFrame.style.position = "fixed";
    oHiddFrame.style.right = "0";
    oHiddFrame.style.bottom = "0";
    oHiddFrame.srcdoc = strHtml.innerHTML;
    document.body.appendChild(oHiddFrame);
}

export const checkAllergic = (cart) => {
    const { prescriptionData } = window;
    const { member } = cart;
    let details = cart.details;

    details.map(detail => {
        detail.is_allergic = false;
        detail.allergic_message = '';

        if (member.id && details.length > 0) {
            if (detail.product_set_type != 1) {
                const allergic = member.product_allergies.find(allergy => allergy.product_id === detail.product.id);
                if (allergic) {
                    detail.is_allergic = true;
                    detail.allergic_message = 'แพ้ยาตัวนี้';
                }

                member.ingredient_allergies.map(allergy => {
                    const allergic = detail.product.ingredients ? detail.product.ingredients.find(ingredient => ingredient.id === allergy.ingredient_id) : false;
                    if (allergic) {
                        detail.is_allergic = true;
                        detail.allergic_message = detail.allergic_message ?
                            `${detail.allergic_message}, ${allergy.ingredient_name}` :
                            `แพ้ตัวยา ${allergy.ingredient_name}`;
                    }
                });
            }
        }

        if (!detail.is_allergic && prescriptionData) {
            prescriptionData.allergic_to_ingredients.forEach(ingredient => {
                const allergic = detail.product.ingredients.find(pIngredient => pIngredient.name === ingredient.name);
                if (allergic) {
                    detail.is_allergic = true;
                    detail.allergic_message = `แพ้ตัวยา ${ingredient.name} (ข้อมูลจากใบสั่งยา)`;
                }
            });
        }
    });

    return Object.assign({}, cart, { details });
};

export const updateCertWithMemberPriceLevel = (cart) => {
    const { member, details } = cart;

    // Skip when no member info
    if (!member.id) {
        return cart;
    }

    const { price_level } = member;

    details.forEach(detail => {
        detail.price_per_unit = detail.unit[`price${price_level}`] || detail.price_per_unit;
        detail.net_amount = detail.price_per_unit * detail.sales_quantity;
    });

    return calculatePrice(cart);
};

export const setDefaultPrinter = () => (dispatch, getState) => {
    const { printers } = getState().settings
    let selected = undefined;

    const id = parseInt(localStorage.getItem('choosedPrinter'));
    selected = printers.find((p) => { return p.id === id });

    selected = (selected) ? selected : printers.find((p) => { return p.printer_license });

    if(selected){
        return dispatch({
            type: SELECT_PRINTER,
            payload: selected
        })
    }else{
        return dispatch({
            type: SELECT_PRINTER,
            payload: {
                id: 0,
                printer_license: null
            }
        })
    }

}

export const getDgMed = (details) =>{

    let dgMeds = [];
    let res = [];

    for(let i = 0; i < details.length; i++){
        const detail = details[i];
        if(detail.product_set_type === '1'){
            dgMeds = dgMeds.concat(checkDgMed(detail.products));
        }else{
            dgMeds = dgMeds.concat(checkDgMed([detail.product]));
        }
    }

    res = res.concat(dgMeds).unique('id');

    res = res.map((item) => { return item.name; });

    return res;
}

export const calDiscountPercent = (net_amount,total_discount_amount) => {
    return Number((net_amount * (parseFloat(total_discount_amount)) / 100).toFixed(2))
}

export const setCookie = (name, value, days = 1) => {
    let expires = '';
    if (days) {
        let date = new Date();
        date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
        expires = '; expires=' + date.toUTCString();
    }
    document.cookie = name + '=' + (value || '') + expires + '; path=/';
};

export const getCookie = name => {
    name = name + '=';
    let ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];

        while (c.charAt(0) == ' ') c = c.substring(1, c.length);
        if (c.indexOf(name) == 0) return c.substring(name.length, c.length);
    }
    return null;
};

const checkDgMed = (products) => {

    let res = [];
    let default_bdc = [];
    let bdc = [];

    for(let i = 0; i < products.length; i++){
        const product = products[i];

        const { bureauOfDrugControls, defaultBureauOfDrugControls} = product;
        if (!bureauOfDrugControls || !defaultBureauOfDrugControls) continue

        default_bdc = defaultBureauOfDrugControls.filter((x) => { return x.report_id === 2 || x.report_id === 3});
        bdc = bureauOfDrugControls.filter((x) => { return x.id === 2 || x.id === 3});

        if(bdc.length > 0 || default_bdc.length > 0){
            res.push(product);
        }
    }

    return res;
}

Array.prototype.unique = function(field) {
    var arr = this.concat();

    for(var i=0; i<arr.length; ++i) {

        for(var j=i+1; j<arr.length; ++j) {

            if(arr[i][field] === arr[j][field])
              arr.splice(j--, 1);
        }
    }

    return arr;
};
export const addLogToCart = (cart, message, updateDB = true, withInfo = true) => {
    if (withInfo) {
        message = `[${moment().format('YYYY-MM-DD HH:mm:ss')}] ${message} by POS version ${process.env.APP_VERSION}`;
    }

    cart.pos_logs = [
        ...cart.pos_logs || [],
        message,
    ];
    if( cart.pos_logs.length-5 > 0){
        length = cart.pos_logs.length-5
        cart.pos_logs.splice(0,length)
    }
    if (updateDB) {
        db.table('carts').update(cart.reference_code, { pos_logs: cart.pos_logs });
    }   
    return cart;
};

export const checkDuplicateTab = () => {
    if (window.IsDuplicate()) {
        window.location.pathname = '/ไม่อนุญาตให้ใช้งานมากกว่า1แท็บ';
        window.close();
    }
}
