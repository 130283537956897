import DeliveryList from "./DeliveryList";
import React, { useState } from "react";
import { connect } from "react-redux";
import { Button, Card, CardBody, Col, Row } from "reactstrap";
import Select from "react-select";
import {
    ServiceDeliveryProviders,
    ServiceDeliveryStatus,
    ServiceDeliveryStatuses,
} from "./constants";
import { servicesSearchHistoryCancellation } from "Redux/actions";
import moment from "moment";

const ServicesHistory = (props) => {
    const { language, isSearchCancellation, setSearchCancellation } = props;
    const initialForm = {
        from_date: "",
        to_date: "",
        status: [],
        provider_id: "FOODPANDA",
        requested_at: null,
    };

    let initialStatuses = [{ label: "ทั้งหมด", value: "all" }];

    if (isSearchCancellation) {
        initialForm.status = [
            ServiceDeliveryStatus.WAITING_TIMEOUT,
            ServiceDeliveryStatus.PROVIDER_CANCELLED,
        ];
        initialStatuses = [
            {
                label: "ทำรายการไม่สำเร็จ",
                value: ServiceDeliveryStatus.WAITING_TIMEOUT,
            },
            {
                label: "ทำรายการไม่สำเร็จ",
                value: ServiceDeliveryStatus.PROVIDER_CANCELLED,
            },
        ];
        setSearchCancellation(false);
    }

    const [form, setForm] = useState(initialForm);
    const [statuses, setStatuses] = useState(initialStatuses);
    const [submittedForm, setSubmittedForm] = useState(form);

    const handleChange = (event) => {
        setForm({
            ...form,
            [event.target.name]: event.target.value,
        });
    };

    const handleSelectStatuses = (data, event) => {
        if (event.action === "select-option") {
            if (event.option.value === "all") {
                data = [{ label: "ทั้งหมด", value: "all" }];
            } else {
                data = data.filter((status) => status.value !== "all");
            }
        }

        if (data.length === 0) {
            data[0] = { label: "ทั้งหมด", value: "all" };
        }

        setStatuses(data);
        setForm({
            ...form,
            status: data
                .filter((status) => status.value !== "all")
                .map((status) => status.value),
        });
    };

    const handleSearch = () => {
        setSubmittedForm({
            ...form,
            requested_at: moment().toISOString(),
        });
    };

    return (
        <>
            <Card className="mb-2">
                <CardBody>
                    <Row>
                        <Col xs={5}>
                            <Row>
                                <Col xs={4}>
                                    <label>ตั้งแต่วันที่</label>
                                </Col>
                                <Col xs={8}>
                                    <input
                                        type="date"
                                        className="form-control form-control-sm"
                                        name="from_date"
                                        value={form.from_date}
                                        onChange={handleChange}
                                    />
                                </Col>
                            </Row>
                            <Row className="mt-2">
                                <Col xs={4}>
                                    <label>สถานะรายการ</label>
                                </Col>
                                <Col xs={8}>
                                    <Select
                                        options={ServiceDeliveryStatuses.map(
                                            (s) => {
                                                return {
                                                    label: s.name,
                                                    value: s.id,
                                                };
                                            }
                                        )}
                                        placeholder="สถานะรายการ"
                                        value={statuses}
                                        onChange={handleSelectStatuses}
                                        isSearchable={true}
                                        isMulti={true}
                                        bindValue="id"
                                        bindLabel="name"
                                    />
                                </Col>
                            </Row>
                        </Col>

                        <Col xs={5}>
                            <Row>
                                <Col xs={4}>
                                    <label>ถึงวันที่</label>
                                </Col>
                                <Col xs={8}>
                                    <input
                                        type="date"
                                        className="form-control form-control-sm"
                                        name="to_date"
                                        value={form.to_date}
                                        onChange={handleChange}
                                    />
                                </Col>
                            </Row>
                            <Row className="mt-2">
                                <Col xs={4}>
                                    <label>บริการ</label>
                                </Col>
                                <Col xs={8}>
                                    <select
                                        className="form-control form-control-sm"
                                        name="provider_id"
                                        value={form.provider_id}
                                        onChange={handleChange}
                                    >
                                        {ServiceDeliveryProviders.map(
                                            (item) => (
                                                <option
                                                    key={item.id}
                                                    value={item.id}
                                                >
                                                    {item.name}
                                                </option>
                                            )
                                        )}
                                    </select>
                                </Col>
                            </Row>
                        </Col>

                        <Col xs={2}>
                            <Button
                                className="mt-3 text-white"
                                color="warning"
                                block
                                onClick={handleSearch}
                            >
                                ค้นหา
                            </Button>
                        </Col>
                    </Row>
                </CardBody>
            </Card>

            <DeliveryList filter={submittedForm} />
        </>
    );
};

const mapStateToProps = (state) => ({
    language: state.language,
    isSearchCancellation: state.services.modalScreenHistorySearchCancellation,
});

const mapDispatchToProps = {
    setSearchCancellation: servicesSearchHistoryCancellation,
};

export default connect(mapStateToProps, mapDispatchToProps)(ServicesHistory);
