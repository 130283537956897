import { COUPON_CLOSE_MODAL, COUPON_OPEN_MODAL } from 'Redux/actionTypes';

const initialState = {
    modalOpen: false,
};

export default (state = initialState, { type, payload }) => {
    switch (type) {
        case COUPON_OPEN_MODAL:
            return Object.assign({}, state, { modalOpen: true });
        case COUPON_CLOSE_MODAL:
            return Object.assign({}, state, { modalOpen: false });
        default:
            return state;
    }
}
