import React from 'react';
import { Modal
    , ModalHeader
    , ModalBody
    , ModalFooter
    , Row
    , Col
    , Button
} from 'reactstrap';

import ModalTemplate from 'Components/Shared/ModalTemplate';
import ConfirmBtn from 'Components/Shared/ConfirmBtn';

const ConfirmModal = (props) => {
    // const btnSubmit = <Button onClick={ props.onSubmit} color="success">{ props.language.confirm}</Button>;
    const btnCancel = <Button onClick={ props.onCancel} color="danger" autoFocus>{ props.language.cancel}</Button>;
    return(
        <ModalTemplate
            onCancel={props.onCancel}
            show={props.show}
            size={props.size}
            title={props.title}
        >
            <ModalBody>
                { props.children ?
                <div>{props.children}</div>
                    :
                <div>
                    {props.txt}
                </div>
                }
           
            </ModalBody>
            <ModalFooter>
                     <div className="confirm-modal-footer">
                        {
                            props.type === 'ALERT' ? 
                            btnCancel :
                            // <div>
                            // {btnCancel}
                            // {btnSubmit}
                            // </div>
                            <ConfirmBtn
                                onCancel={props.onCancel}
                                onSubmit={props.onSubmit}
                                txtCancel={props.language.cancel}
                                txtConfirm={props.language.confirm}
                            />
                        }
                     </div>
            </ModalFooter>
        </ModalTemplate>
    )
}

export default ConfirmModal;