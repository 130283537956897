import { REQUEST_CHECKIN
    , RECEIVE_CHECKIN
    , CHECKIN_ERROR
    , EMPLOYEE_LOGOUT
    , RECEIVE_SETTINGS
} from '../actionTypes'

const initialState = {
    loading: false,
    employee: {
        price_level: 1,
        role: {
            permissions: []
        },
        first_name: null,
        id: 0
    },
    addresses: "",
    phone: null,
    company_tax_id: null,
    is_using_abb: false,
    is_abb_offline: false,
    is_using_alipay: false,
    company_tax_id: null,
    branch_reference_code: null,
    choosed_printer: {
        lisence: null
    },
}

export default (state=initialState, { type, payload }) => {
    switch (type) {
        case REQUEST_CHECKIN:
            return Object.assign({},state,{ loading: true })
        case RECEIVE_CHECKIN:
            return Object.assign({},state,{
                loading: false,
                employee: payload
            })
        case CHECKIN_ERROR:
            return Object.assign({},state,{ loading: false, error: true  })
        case EMPLOYEE_LOGOUT:
            return initialState
        case RECEIVE_SETTINGS:
            return Object.assign({},state, {
                addresses: payload.branch.address,
                phone: payload.branch.phone_no,
                is_using_abb: payload.branch.is_using_abb === 1,
                is_abb_offline: payload.branch.is_abb_offline === 1,
                is_using_alipay: payload.branch.is_using_alipay === 1,
                branch_reference_code: payload.branch.reference_code,
                company_tax_id: payload.company.tax_id
            })
        default:
            return state
    }
}