import React from 'react';
import { AsyncTypeahead, Highlighter } from 'react-bootstrap-typeahead';

import 'react-bootstrap-typeahead/css/Typeahead.min.css';

class ProductTypeahead extends React.Component {
    state = {
        isLoading: false,
        options: [],
        selected: [],
        event: null,
    };

    _handleSearch = query => {
        this.setState({ isLoading: true });
        this.props.searchProducts(query).then(products => {
            this.setState({
                isLoading: false,
                options: products,
            });
        });
    };

    _handleChange = (value, event) => {
        this.props.onChange(event);
    };

    _handleKeyDown = e => {
        if (!this.state.event) {
            e.persist();
            this.setState({ event: e });
        }

        if (e.keyCode === 13) {
            const value = document.getElementById('search-products-input').value;
            if (!value) this.props.onSubmit(e, '');
            const option = this.state.options.find(option => option.name === value);
            const item = [{ name: value }];

            if (!option) {
                this.setState({ options: item, selected: item });
                this.props.onSubmit(e, value);
            } else {
                this.setState({ selected: item });
                this.props.onSubmit(e, option.reference_code);
            }
        }
    };

    _handleSelect = item => {
        if (item && item.length) {
            const hasSelected = !!this.state.selected.length;
            this.props.setQuery(item[0].name);
            this.setState({ selected: item });
            if (!hasSelected) {
                this.props.onSubmit(this.state.event, item[0].reference_code);
            }
        }
    };

    _renderMenuItemChildren = (option, props, index) => {
        return [
            <Highlighter key="name" search={props.text}>
                {!option.reference_code ? option.name : `${option.name} - ${option.reference_code}`}
            </Highlighter>,
        ];
    };

    render() {
        const { language, placeholder } = this.props;
        const inputProps = {
            id: 'search-products-input',
        };
        const filterBy = (option, props) => [props.labelKey, 'reference_code'];
        return (
            <AsyncTypeahead
                {...this.state}
                id="search-products"
                labelKey="name"
                minLength={2}
                onSearch={this._handleSearch}
                onChange={this._handleSelect}
                onInputChange={this._handleChange}
                onKeyDown={this._handleKeyDown}
                onBlur={this.props.onBlur}
                inputProps={inputProps}
                placeholder={placeholder}
                searchText={`${language.searching}...`}
                promptText={language.searchProdcutsPromptText}
                emptyLabel={language.searchProdcutsNotFound}
                renderMenuItemChildren={this._renderMenuItemChildren}
                filterBy={filterBy}
                useCache={false}
            />
        );
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.value && !nextProps.value) {
            this.setState({ selected: [] });
        }
    }
}

export default ProductTypeahead;
