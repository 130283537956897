import React from 'react'
import classNames from 'classnames';

class SearchSaleReturnForm extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            query: '',
            loading: false
        }
        this._onChangeSearch = this._onChangeSearch.bind(this)
        this._onSubmitSearch = this._onSubmitSearch.bind(this)
    }

    _onChangeSearch(e) {
        this.setState({ query: e.target.value })
    }

    _onSubmitSearch(e) {
        e.preventDefault()
        
        this.setState({ loading: true })
        this.props.searchReceipts(this.state.query).then(() => {
            this.setState({
                query: '',
                loading: false
            })
        })
    }

    render() {
        const { language, isLoading } = this.props
        return (
            <form onSubmit={this._onSubmitSearch}>
                <div className="input-group input-group-lg">
                    <div className="input-group-prepend">
                        <span className="input-group-text">
                            <i className="fa fa-barcode"></i>
                        </span>
                    </div>
                    <input
                        type="text"
                        className="form-control"
                        value={this.state.query}
                        onChange={this._onChangeSearch}
                        placeholder={language.search}
                        ref="saleReturnInput"
                    />
                    <div className="input-group-append">
                        <button 
                            className={classNames({
                                btn: true,
                                'btn-outline-secondary': true,
                                'cursor-not-allowed': this.state.loading || isLoading
                            })} 
                            disabled={this.state.loading || isLoading}
                        >
                            {this.state.loading || isLoading ? `${language.loading}...` : <i className="fa fa-search"></i>}
                            </button>
                    </div>
                </div>
            </form>
        )
    }

    componentDidUpdate(prevProps) {
        const { focus } = this.props
        const { focus: wasfocus } = prevProps
        if (wasfocus && focus) {
            this.refs.saleReturnInput.focus()
        }
    }
}

export default SearchSaleReturnForm