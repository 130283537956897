import React from 'react'

class QrPaymentPage extends React.Component {
    _displayPrice(price) {
        return Number(price).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
    }

    render() {
        const { language, cart, merchant } = this.props
        const qrTypeName = {
            thai_qr: 'K PLUS Shop',
            alipay: 'Alipay',
            wechat_pay: 'WeChat Pay',
        }
        const lang = localStorage.getItem('language') || 'th'
        return (
            <div className="qr-payment-container">
                <div className="header-shop">
                    <h5 className="shop-name">ชื่อร้านค้า: {merchant ? merchant.shop[`shop_name_${lang}`] : 'Unknown'}</h5>
                    <h5>Merchant ID: {merchant ? merchant.id : 'Unknown'}</h5>
                </div>
                <div className="highlight">
                    <h4 className="highlight-header">{language.net_payment_summary}</h4>
                    <h1>{this._displayPrice(cart.total_paid_amount)} {language.baht}</h1>
                </div>
                <div className="footer-shop">
                    <strong className="text-danger">{language.check_payment_info} {qrTypeName[cart.payment_types]} {language.correct_info}</strong>
                </div>
            </div>
        )
    }
}

export default QrPaymentPage