import {
    COUPON_ADD_TO_CART,
    COUPON_CLOSE_MODAL,
    COUPON_OPEN_MODAL,
    COUPON_REMOVE_FROM_CART,
    CHECKOUT_LOADING,
    CHECKOUT_SUCCESS,
} from 'Redux/actionTypes';
import axios from 'axios';
import { notify, addNotification, removeNotification } from 'reapop';
import { closeCheckOut } from 'Redux/actions';

const API3_URL = process.env.API3_URL;

export const openCouponModal = () => (dispatch) => {
    dispatch({ type: COUPON_OPEN_MODAL });
};

export const closeCouponModal = () => (dispatch) => {
    dispatch({ type: COUPON_CLOSE_MODAL });
};

export const useCoupon = (coupon) => (dispatch) => {
    dispatch({ type: COUPON_ADD_TO_CART, payload: coupon });
};

export const removeCartCoupon = () => (dispatch) => {
    dispatch({ type: COUPON_REMOVE_FROM_CART });
};

const checkCouponFailed = ({ title = '', message = '' }, closeCheckOutModal = true) => (dispatch, getState) => {
    const { showCheckOutModal } = getState().modal;

    dispatch(notify({
        id: 'coupon',
        title,
        message,
        status: 'error',
        dismissible: true,
        dismissAfter: 3000
    }));
    if (closeCheckOutModal && showCheckOutModal) dispatch(closeCheckOut());
};

export const checkCoupon = () => async (dispatch, getState) => {
    const { settings, cart } = getState();
    const { employee } = getState().employee;
    const { code, owner } = cart.coupon;
    const { net_amount } = getState().cart;

    dispatch({ type: CHECKOUT_LOADING });
    dispatch(addNotification({
        id: 'checkCoupon',
        message: 'กำลังตรวจสอบคูปอง...',
        status: 'loading',
        dismissible: true,
        dismissAfter: 10000,
    }));

    try {
        const res = await axios({
            url: `${API3_URL}/coupon/pos`,
            headers: { 'Authorization': `Bearer ${settings.token}` },
            params: {
                code: code,
                type: 'ARINCARE',
                owner: owner,
                branch_id: settings.branch.id,
                employee_id: employee.id,
                net_amount: net_amount
            },
        }).then(res => res.data)
        .catch(err => err.response.data);
        if (res.statusCode === 200) {
            return true;
        } else if (res.statusCode === 400) {
            dispatch(checkCouponFailed({
                title: 'ไม่สามารถใช้คูปองได้',
                message: res.message,
            }));
            dispatch(removeCartCoupon());
            return false;
        } else {
            dispatch(checkCouponFailed({
                message: 'เกิดปัญหาบางอย่างในการตรวจสอบคูปอง',
            }, false));
            return false;
        }
    } catch (err) {
        console.error(err);
        dispatch(checkCouponFailed({
            message: 'เกิดข้อผิดพลาดในการตรวจสอบคูปอง',
        }, false));
        return false;
    } finally {
        dispatch({ type: CHECKOUT_SUCCESS });
        dispatch(removeNotification('checkCoupon'))
    }
};

export const checkCouponWhenUpdatedCart = () => async (dispatch, getState) => {
    const { cart } = getState();
    if (cart.net_amount < cart.coupon.min_amount) {
        dispatch(checkCouponFailed({
            title: 'ไม่สามารถใช้คูปองได้',
            message: `ยอดซื้อขั้นต่ำของการใช้คูปองนี้ ${cart.coupon.min_amount} บาท`,
        }));
        dispatch(removeCartCoupon());
        return false;
    }
    return true;
}

export const getCouponByCode = (opts) => async (dispatch, getState) => {
    const { branch, token } = getState().settings;
    const { employee } = getState().employee;
    const { code, type } = opts;
    const { net_amount } = getState().cart;

    try {
        const res = await axios({
            url: `${API3_URL}/coupon/pos`,
            headers: { 'Authorization': `Bearer ${token}` },
            params: {
                code: code,
                type: 'ARINCARE',
                owner: type,
                branch_id: branch.id,
                employee_id: employee.id,
                net_amount: net_amount
            },
        }).then(res => res.data)
        .catch(err => err.response);
        if (res.statusCode === 200) {
            return {
                error: null,
                coupon: res.data,
            }
        } else {
            return {
                error: res.data.message,
                coupon: null,
            }
        }
    } catch (error) {
        return {
            error: 'Failed to check coupon.',
            coupon: null,
        }
    }
};

export const getCouponByCodeAndUse = (code) => async (dispatch) => {
    const { error, coupon } = await dispatch(getCouponByCode({ code }));
    if (error) {
        return dispatch(notify({
            id: 'coupon',
            title: 'เกิดข้อผิดพลาดในการใช้คูปอง',
            message: error,
            status: 'error',
            dismissible: true,
            dismissAfter: 3000
        }));
    }

    if (coupon) {
        return dispatch(useCoupon(coupon));
    }
};
